import Button from '@react/react-spectrum/Button';
import { Card, CardBody, CardCoverPhoto } from '@react/react-spectrum/Card';
import Dialog from '@react/react-spectrum/Dialog';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { Step, StepList } from '@react/react-spectrum/StepList';
import { boundMethod } from 'autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import CAMERA_GIF from '../assets/help/Camera.gif';
import DOWNLOAD_GIF from '../assets/help/Download.gif';
import FOLD_GIF from '../assets/help/Fold.gif';
import '../css/HelpModal.css';
import { Analytics, EventName } from '../services/Analytics';

const stepsData = [
    {
        title: 'Fold your package',
        body: 'Use the Crease Selection tool to select and fold your package.',
        imageURL: FOLD_GIF,
    },
    {
        title: 'Control the camera',
        body: 'Use the Orbit and Dolly tools to view your object from different angles in 3D.',
        imageURL: CAMERA_GIF,
    },
    {
        title: 'Export your model',
        body: 'Download your folded package as a 3D model for easy use in Adobe Dimension and other 3D tools.',
        imageURL: DOWNLOAD_GIF,
    },
];

@observer
export class HelpModal extends Component {
    private static dialogKey?: number;

    private startTime: number = performance.now();

    @observable
    private selectedIndex: number = 0;

    static show() {
        HelpModal.dialogKey = ModalContainer.show(<HelpModal />);
    }

    private get elapsedTime(): number {
        return (performance.now() - this.startTime) / 1000;
    }

    private close() {
        if (HelpModal.dialogKey !== undefined) {
            ModalContainer.hide(HelpModal.dialogKey);
        }
    }

    @boundMethod
    private onCancel() {
        this.close();
        const tourStep = this.selectedIndex;
        const elapsedTime = this.elapsedTime;
        Analytics.event(EventName.TourCancel, { tourStep, elapsedTime });
    }

    @boundMethod
    private setSelectedIndex(index: number) {
        this.selectedIndex = index;
        Analytics.event(EventName.TourStepSelect, { tourStep: index });
    }

    @boundMethod
    private previous() {
        let selectedIndex = this.selectedIndex - 1;
        if (selectedIndex < 0) {
            this.onCancel();
            return;
        }
        this.setSelectedIndex(selectedIndex);
    }

    @boundMethod
    private next() {
        let selectedIndex = this.selectedIndex + 1;
        if (selectedIndex >= stepsData.length) {
            this.close();
            const elapsedTime = this.elapsedTime;
            Analytics.event(EventName.TourComplete, { elapsedTime });
            return;
        }
        this.setSelectedIndex(selectedIndex);
    }

    render() {
        return (
            <Dialog className="HelpModal" onCancel={this.onCancel}>
                <Card
                    className="HelpModal__card"
                    allowsSelection={false}
                    selected={false}
                    size="L"
                    variant="standard"
                >
                    <CardCoverPhoto src={stepsData[this.selectedIndex].imageURL} />
                    <CardBody>
                        <StepList interaction="on" selectedIndex={this.selectedIndex} onChange={this.setSelectedIndex} size="L">
                            { stepsData.map((step, i) => {
                                return (
                                    <Step key={i}>
                                        {step.title}
                                    </Step>
                                );
                            })}
                        </StepList>
                        <div className="HelpModal__body">
                            {stepsData[this.selectedIndex].body}
                        </div>
                        <Button
                            label={this.selectedIndex === 0 ? 'Skip Tour' : 'Back'}
                            onClick={this.previous}
                            quiet
                            variant="action"
                        />
                        <Button
                            label={this.selectedIndex === stepsData.length - 1 ? 'Finish Tour' : 'Next'}
                            onClick={this.next}
                            quiet
                            variant="action"
                        />
                    </CardBody>
                </Card>
            </Dialog>
        );
    }
}