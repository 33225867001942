export enum IMSEnvironment {
    STAGING,
    PRODUCTION,
}

export type UserProfile = {
    userId: string;
    displayName: string;
    email: string;
    pictureUrl: string;
    featureFlags: string[];
};

/**
 * AccountService provides access to Identity Management Services (IMS) through imslib.js. 
 */
export interface AccountService {
    /**
     * Initializes the service and checks whether the user is signed in or not.
     * @param imsClientId The IMS client ID of the application.
     * @param imsEnvironment Staging or production IMS environment.
     * @returns A promise yielding the user's profile (if signed in) or undefined (if not).
     */
    initialize(
        imsClientId: string,
        imsEnvironment: IMSEnvironment,
    ): Promise<UserProfile | undefined>;

    /**
     * Starts the sign-in process.
     * @param username The user's email address (optional).
     */
    signIn(username?: string): void;

    /**
     * Starts the sign-out process.
     */
    signOut(): void;
}
