import { boundMethod } from 'autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, RefObject } from 'react';
import ReactDOM from 'react-dom';
import Link from '@react/react-spectrum/Link';
import LinkOutLight from '@react/react-spectrum/Icon/LinkOutLight';
import { SideNav, SideNavItem, SideNavHeading } from '@react/react-spectrum/SideNav';
import { Analytics, EventName } from '../services/Analytics';
import { Footer } from './Footer';
import { AppHeader } from './AppHeader';
import { EXAMPLES } from './Examples-prod';
import { HEADER_HEIGHT } from '../common/constants';
import '../css/LearnContent.css';

import PREP_CREASES from '../assets/learn/prep-creases.png';
import PREP_CUTS from '../assets/learn/prep-cuts.png';
import PREP_INTERIOR from '../assets/learn/prep-interior.png';
import PREP_LAYERS from '../assets/learn/prep-layers.png';
import PREP_ARTWORK from '../assets/learn/prep-artwork.png';

import EXPORT_ARTWORK from '../assets/learn/export-artwork.png';
import EXPORT_DIELINE from '../assets/learn/export-dieline.png';
import EXPORT_PNG from '../assets/learn/export-png.png';
import EXPORT_SVG from '../assets/learn/export-svg.png';

import IMPORT_3D from '../assets/learn/import-3d.png';
import IMPORT_DROP from '../assets/learn/import-drop.png';
import IMPORT_MODAL from '../assets/learn/import-modal.png';
import IMPORT_PROPERTIES from '../assets/learn/import-properties.png';

import FOLD_ORBIT_TOOL from '../assets/learn/fold-orbit-tool.png';
import FOLD_SELECT from '../assets/learn/fold-select.png';
import FOLD_3D from '../assets/learn/fold-3d.png';
import FOLD_ANGLE from '../assets/learn/fold-angle.png';
import FOLD_ANGLE_ON_CANVAS from '../assets/learn/fold-angle-on-canvas.png';
import FOLD_CONSTRAINT from '../assets/learn/fold-constraint.png';
import FOLD_DIELINE from '../assets/learn/fold-dieline.png';
import FOLD_DOLLY_TOOL from '../assets/learn/fold-dolly-tool.png';
import FOLD_FINISHED from '../assets/learn/fold-finished.png';
import FOLD_FOLDING from '../assets/learn/fold-folding.png';
import FOLD_FOLDED from '../assets/learn/fold-folded.png';
import FOLD_MULTIPLE from '../assets/learn/fold-multiple.png';
import FOLD_SELECTION_TOOL from '../assets/learn/fold-selection-tool.png';
import FOLD_WINDOW from '../assets/learn/fold-window.png';
import FOLD_WINDOW_TOOL from '../assets/learn/fold-window-tool.png';

import MODEL_BOTTOM from '../assets/learn/model-bottom.png';
import MODEL_PREVIEW from '../assets/learn/model-preview.png';
import MODEL_EXPORT from '../assets/learn/model-export.png';

import DN_HERO from '../assets/learn/dn-hero.png';
import DN_IMPORT from '../assets/learn/dn-import.png';

import ANIM_1_EXPAND from '../assets/learn/anim-1-expand.png';
import ANIM_2_ADD from '../assets/learn/anim-2-add.png';
import ANIM_3_KEYFRAMES from '../assets/learn/anim-3-keyframes.png';
import ANIM_4_PLAY from '../assets/learn/anim-4-play.png';
import ANIM_5_UPDATE from '../assets/learn/anim-5-update.png';
import ANIM_6_DELETE from '../assets/learn/anim-6-delete.png';
import ANIM_7_TIMING from '../assets/learn/anim-7-timing.png';

import TIPS_STROKE from '../assets/learn/tips-stroke.png';
import TIPS_GAP from '../assets/learn/tips-gap.png';
import TIPS_PERF from '../assets/learn/tips-perf.png';
import TIPS_OFFSET from '../assets/learn/tips-offset.png';

interface LearnSectionData {
    id: string;
    menuTitle: string;
    title: string;
    subsections: LearnSubSection[];
}

const gettingStartedContent: LearnSectionData[] = [
    {
        id: 'prepareFiles',
        menuTitle: 'Prepare your files',
        title: 'Step 1: Prepare your Illustrator Dieline & Artwork',
        subsections: [
            { imageURL: PREP_LAYERS, text: <p>In order to import a dieline into Fantastic Fold, you must create layers called “Cuts” and “Creases” that contain the vector paths of your dieline.  Your artwork and other layers can be organized however you like.</p> },
            { imageURL: PREP_CUTS, text: <p>The “Cuts” layer should include all paths that are intended to be cut.  Fantastic Fold will ignore areas outside of the outermost closed boundary formed by cut lines.  If cut lines form any closed boundaries within the interior of the design area, these areas will be cut out — as is the case for handle holes, slots, and windows.</p> },
            { imageURL: PREP_CREASES, text: <p>The “Creases” layer should include all paths that are intended to be folded.  Fantastic Fold does not fold along perforations, so instead of a sequence of small cuts, draw a single crease line in the “Creases” layer.</p> },
            { imageURL: PREP_ARTWORK, text: <div>
                <p>Any artwork that is part of the printed design should be placed in layers that are separate from the “Cuts” and “Creases” layers.  The sample files use a layer called “Artwork”.</p>
                <p>Artwork is optional.  Fantastic Fold can create a 3D model from just the “Cuts” and “Creases” layers if you prefer not to include artwork.</p>
                </div> },
            { imageURL: PREP_INTERIOR, text: <p>Artwork is typically created for the exterior of a package.  You may also create artwork for the interior if you want.  To create interior artwork, mirror the dieline horizontally and place the mirrored copy on a second artboard.</p> },
            { text: <p>Note: You can include additional layers (printing instructions or manufacturing details, for example), or break your artwork into multiple layers.  The precise organization is up to you, so long as the cut lines are in a layer named “Cuts” and the crease lines are in a layer named “Creases”.</p> },
        ],
    },
    {
        id: 'exportFiles',
        menuTitle: 'Export your files',
        title: 'Step 2: Export your Dieline & Artwork Files',
        subsections: [
            { imageURL: EXPORT_DIELINE, text: <p>First, hide the Artwork layer and any other optional layers, leaving only the Cuts and Creases layers visible.</p> },
            { imageURL: EXPORT_SVG, text: <div>
                    <p>Select the <b>File &gt; Export &gt; Export As...</b> menu item.</p>
                    <p>Choose the <b>SVG</b> format.</p>
                    <p>Enable the <b>Use Artboards</b> checkbox to ensure that the blank space around the dieline is preserved.</p>
                    <p>Save the SVG file with a name and location of your choice. Accept the default settings in the <b>SVG Options</b> dialog.</p>
                </div> },
            { imageURL: EXPORT_ARTWORK, text: <p>Hide the Cuts and Creases layers and any other optional layers, so only the Artwork layer is visible.</p> },
            { imageURL: EXPORT_PNG, text: <div>
                <p>Select the <b>File &gt; Export &gt; Export As...</b> menu item.</p>
                <p>Choose the <b>PNG</b> format.</p>
                <p>Enable the <b>Use Artboards</b> checkbox to ensure that the blank space around the dieline is preserved.</p>
                <p>Save the PNG file with a name and location of your choice.</p>
                <p>In the <b>PNG Options</b> dialog, you may experiment with the resolution and settings that work best for your dieline. 72 pixels per inch may be sufficient for large packages, but 150 or 300 pixels per inch may be needed to resolve details in smaller packages.</p>
                </div> },
        ],
    },
    {
        id: 'importFiles',
        menuTitle: 'Import to Fantastic Fold',
        title: 'Step 3: Import a Dieline & Artwork to Fantastic Fold',
        subsections: [
            { imageURL: IMPORT_DROP, text: <div>
                <p>On the Home screen of Fantastic Fold, the bottom right area allows you to import an SVG dieline. Either drag-and-drop the SVG file you exported previously, or click <b>select a file from your computer</b> to find the file.</p>
                <p>If you exported from an Illustrator file that contains artboards for both the exterior and the interior, choose the SVG file created from the exterior artboard.</p>
            </div> },
            { imageURL: IMPORT_MODAL, text: <p>Fantastic Fold will analyze your dieline and point out any potential problems.  If you’re happy with the preview of your dieline, click the <b>Import</b> button to finalize the import.</p> },
            { imageURL: IMPORT_3D, text: <p>You can now interact with your dieline in 3D.</p> },
            { imageURL: IMPORT_PROPERTIES, text: <div>
                <p>In the <b>Package Properties</b> panel on the right side of Fantastic Fold, use the <b>Material</b> drop-down to select a material with the appropriate thickness for your dieline.</p>
                <p>You can also import your artwork in the <b>Package Properties</b> panel by clicking on <b>Exterior Art</b> or <b>Interior Art</b>, then selecting a previously saved PNG file.</p>
            </div> },
        ],
    },
    {
        id: 'foldModel',
        menuTitle: 'Fold a dieline in 3D',
        title: 'Step 4: Fold a Dieline in 3D',
        subsections: [
            { text: <p>In the 3D view, you can navigate around your dieline using the camera tools.</p> },
            { imageURL: FOLD_ORBIT_TOOL, text: <p>Use the <b>Orbit Tool</b> to rotate around your dieline.  You can also rotate while other tools are active, by dragging while holding the right mouse button down or by clicking and dragging with two fingers on a trackpad.</p> },
            { imageURL: FOLD_DOLLY_TOOL, text: <p>Use the <b>Dolly Tool</b> to move closer to or further from your dieline.  You can also dolly while other tools are active by using the mouse scroll wheel or using a two-finger swipe or pinch on a trackpad.</p> },
            { imageURL: FOLD_SELECTION_TOOL, text: <p>To start folding your dieline into a 3D shape, first select the <b>Crease Selection Tool</b> in the toolbar.</p> },
            { imageURL: FOLD_SELECT, text: <div>
                <p>Select one or more creases.  Selected creases will be highlighted in blue.</p>
                <p>There are several ways to select creases:</p>
                <ul>
                    <li>Click on a crease to select it.</li>
                    <li>Hold the Shift key while clicking a crease to add it to or remove it from the selection.</li>
                    <li>Click and drag in the view to select all creases within a rectangular marquee.</li>
                    <li>Use <b>⌘A</b> (macOS) or <b>Ctrl+A</b> (Windows) to select all creases.</li>
                </ul>
            </div> },
            { imageURL: FOLD_FOLDING, text: <div>
                <p>Once you’ve selected one or more creases, the fold angle widget will appear.</p>
                <p>You can fold a crease interactively by clicking and dragging either of the spherical handles.  As you drag one of the spherical handles, the surrounding panel will move with it, while the panel surrounding the opposite handle will remain anchored in place.</p>
                <p>By default, the fold angle will change in increments of 1 degree. Hold the Shift key while dragging to snap to 45-degree increments. Or for precise manipulation, hold the <b>⌘</b> key (macOS) or <b>Ctrl</b> key (Windows) while dragging to use 0.1-degree increments.</p>
            </div> },
            { imageURL: FOLD_ANGLE, text: <p>You can also use the <b>Crease Properties</b> panel to type in a specific fold angle.</p> },
            { imageURL: FOLD_ANGLE_ON_CANVAS, text: <p>Or you can click on one of the spherical handles and then type in a fold angle.</p> },
            { imageURL: FOLD_FOLDED, text: <p>Once you have altered or specified the fold angle associated with a crease, Fantastic Fold will mark this crease as “constrained.”  Constrained creases (shown in orange) will try to maintain the fold angle you have specified, while unconstrained creases are free to fold as needed.</p> },
            { imageURL: FOLD_CONSTRAINT, text: <p>Click the <b>Remove Constraint</b> button (or press the Backspace or Delete key) while a constrained crease is selected to return that crease to the unconstrained state.</p> },
            { imageURL: FOLD_MULTIPLE, text: <p>As you set fold angles, the dieline will fold into a 3D shape.</p> },
            { imageURL: FOLD_DIELINE, text: <p>Click on the <b>Dieline View</b> thumbnail in the bottom-left corner to switch to the unfolded view, where it may be easier to make selections.</p> },
            { imageURL: FOLD_3D, text: <p>Click on the <b>3D View</b> thumbnail to switch back to the folded view and see your folds take effect.</p> },
            { imageURL: FOLD_FINISHED, text: <p>Continue to fold all creases until you are satisfied with the appearance of the 3D model.</p> },
            { imageURL: FOLD_WINDOW_TOOL, text: <p>You can use the <b>Window Draw Tool</b> to add transparent plastic to any of the holes in the package.</p> },
            { imageURL: FOLD_WINDOW, text: <p>Click within a hole to add or remove plastic.</p> },
        ],
    },
    {
        id: 'exportModel',
        menuTitle: 'Export a 3D model',
        title: 'Step 5: Export a 3D Model',
        subsections: [
            { imageURL: MODEL_BOTTOM, text: <div>
                <p>Before exporting, it’s best to orient your 3D model so that it rests on the ground properly.</p>
                <p>In the <b>Package Properties</b> panel, click the <b>Set Package Bottom</b> button.  Then click on one panel to indicate which part of the 3D model should rest on the ground.</p>
            </div> },
            { imageURL: MODEL_PREVIEW, text: <p>Click the <b>Preview</b> button (near the top of Fantastic Fold) to see your 3D model in perspective and with shadows on a virtual ground plane.</p> },
            { imageURL: MODEL_EXPORT, text: <div>
                <p>If you’re happy with the appearance and orientation of your model, click the <b>Export 3D Model</b> button at the top of the application.</p>
                <p>Fantastic Fold will export and download your 3D model and its textures as a GLB file, a common file format supported by most 3D applications.</p>
            </div> },
        ],
    },
    {
        id: 'importDimension',
        menuTitle: 'Import into Adobe Dimension',
        title: 'Step 6: Stage your 3D Model in Adobe Dimension',
        subsections: [
            { imageURL: DN_IMPORT, text: <p>Open Adobe Dimension and select <b>File &gt; Import &gt; 3D Model</b> from the menu.  Navigate to the GLB file that you exported from Fantastic Fold.</p> },
            { imageURL: DN_HERO, text: <div>
                <p>You can use Dimension’s robust feature set to add background images, lighting, additional 3D elements, and even effects on the materials like glossy and metallic masks.</p>
                <p><Link href="https://helpx.adobe.com/dimension/tutorials.html" target="_blank">Learn more about using Dimension <LinkOutLight className="LearnSubSection_linkIcon" size="S"/></Link></p>
            </div> },
        ],
    },
    {
        id: 'createAnimationg',
        menuTitle: 'Create an animation',
        title: 'Step 7: Create an Animation',
        subsections: [
            { imageURL: ANIM_1_EXPAND, text: <p>To create an animation in Fantastic Fold, first expand the keyframe section of the <b>Animation</b> panel.</p> },
            { imageURL: ANIM_2_ADD, text: <p>With the package in its initial pose for your animation (often completely flat), press the <b>Add keyframe</b> button to record a keyframe.</p> },
            { imageURL: ANIM_3_KEYFRAMES, text: <p>Change the fold angles to pose the package differently, then press the <b>Add keyframe</b> button to record another keyframe. Continue in this manner until you reach the final pose of the package.</p> },
            { imageURL: ANIM_4_PLAY, text: <p>Once you have recorded at least two keyframes, you can use the playback controls to view the animation.</p> },
            { imageURL: ANIM_5_UPDATE, text: <p>You can change an existing keyframe by selecting it, altering the fold angles of the package, and then pressing the <b>Update keyframe</b> button.</p> },
            { imageURL: ANIM_6_DELETE, text: <p>You can also remove a keyframe by selecting it and then pressing the <b>Delete keyframe</b> button.</p> },
            { imageURL: ANIM_7_TIMING, text: <p>The speed of the animation is controlled by two durations: the <b>Transition</b> duration is the amount of time the animation takes to move from one keyframe to the next, while the <b>Hold</b> duration is the amount of time each keyframe is displayed before moving on.</p> },
            { text: <p>To record an animation, we recommend switching to <b>Preview</b> mode and using your preferred screen capture software. On macOS, you can record a portion of the screen using the <b>Screenshot</b> application (press <b>⇧⌘5</b>). On Windows 10, you can record using the Game Bar (press <b>Win+G</b>).</p> },
        ],
    },
];

const additonalResourcesContent: LearnSectionData[] = [
    {
        id: 'templates',
        menuTitle: 'Example dielines',
        title: 'Example Dielines',
        subsections: EXAMPLES.map(example => {
            const filename = example.name.toLowerCase().split(' ').join('-');
            return {
                imageURL: example.image,
                text: <div>
                    <p><b>{example.name}</b></p>
                    Illustrator document: <Link href={example.illustratorFile} download={`${filename}.ai`}>{filename}.ai</Link><br/>
                    Dieline as SVG: <Link href={example.dieline} download={`${filename}-dieline.svg`}>{filename}-dieline.svg</Link><br/>
                    Exterior artwork: <Link href={example.exteriorArtwork} download={`${filename}-exterior.png`}>{filename}-exterior.png</Link><br/>
                    Interior artwork: <Link href={example.interiorArtwork} download={`${filename}-interior.png`}>{filename}-interior.png</Link><br/>
                    Material: {example.substrateName}
                </div>,
            }
        }),
    },
    {
        id: 'troubleshooting',
        menuTitle: 'Tips for importing dielines',
        title: 'Tips for Importing Dielines',
        subsections: [
            { text: <p>Fantastic Fold’s dieline importer will automatically check your dieline for problems and alert you to any potential issues.  Here are some common dieline problems that may make it difficult for Fantastic Fold to properly fold your dieline:</p> },
            { imageURL: TIPS_STROKE, text: <p>Your dieline should be drawn in vector format, with all cut paths on a “Cuts” layer and all crease paths on a “Creases” layer.  Fantastic Fold ignores stroke and fill styling, including color and dash styling.  Be sure that your dieline is composed of single-line paths rather than filled shapes.</p> },
            { imageURL: TIPS_OFFSET, text: <p>Creases that meet near one another but not quite at a single point can result in configurations that are impossible to fold up.  You can fix this problem by adjusting the crease lines so that all creases intersect at a common point.</p> },
            { imageURL: TIPS_PERF, text: <p>Fantastic Fold will not recognize a series of small cuts as a perforation.  If you want to fold along a perforated line, you should instead draw it as a single crease line in the “Creases” layer.  Feel free to use dashed lines wherever you want, since Fantastic Fold ignores stroke dash styling.</p> },
            { imageURL: TIPS_GAP, text: <p>Be sure that crease lines extend to meet other creases or cuts.  Small gaps can prevent a dieline from folding properly.</p> },
        ],
    },
];

interface LearnSubSection {
    imageURL?: string;
    text: JSX.Element;
}
function LearnSubSection(props: LearnSubSection) {
    return (
        <div className="LearnSubSection">
            { props.imageURL && 
                <img className="LearnSubSection__image" src={props.imageURL} alt=""/>
            }
            {React.cloneElement(props.text, { className: 'LearnSubSection__text' })}
        </div>
    ) 
}

interface LearnSectionProps {
    sectionRef: RefObject<HTMLDivElement>;
    title: string;
    subSections: LearnSubSection[];
}
function LearnSection(props: LearnSectionProps) {
    return (
        <div ref={props.sectionRef} className="LearnSection">
            <hr className="LearnSection__rule"/>
            <h2 className="LearnSection__title">{props.title}</h2>
            {props.subSections.map((props, i) => <LearnSubSection key={i} {...props}/>)}
        </div>
    );
}

@observer
export class LearnContent extends Component {
    private scrollElement?: HTMLElement;
    private sectionRefs: Record<string, RefObject<HTMLDivElement>>;

    @observable
    currentScrollId: string = gettingStartedContent[0].id;

    constructor(props: {}) {
        super(props);

        this.sectionRefs = {};
        gettingStartedContent.forEach(item => {
            this.sectionRefs[item.id] = React.createRef<HTMLDivElement>();
        });
        additonalResourcesContent.forEach(item => {
            this.sectionRefs[item.id] = React.createRef<HTMLDivElement>();
        });
    }

    componentDidMount() {
        Analytics.event(EventName.ShowLearnContent);
        this.scrollElement = ReactDOM.findDOMNode(this) as HTMLElement;
        this.scrollElement.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        this.scrollElement!.removeEventListener('scroll', this.handleScroll);
        this.scrollElement = undefined;
    }

    @boundMethod
    private handleScroll() {
        let currentScrollId = this.currentScrollId;
        let minDist = Infinity;
        Object.keys(this.sectionRefs).forEach(key => {
            const ref = this.sectionRefs[key];
            if (ref.current) {
                if (this.scrollElement!.scrollTop + this.scrollElement!.clientHeight / 2 >
                    ref.current.offsetTop) {
                    const dist = Math.abs(this.scrollElement!.scrollTop - ref.current.offsetTop);
                    if (dist < minDist) {
                        currentScrollId = key;
                        minDist = dist;
                    }
                }
            }
        });
        this.currentScrollId = currentScrollId;
    }

    @boundMethod
    private scrollToSelection(value: string) {
        const ref = this.sectionRefs[value];
        if (ref.current) {
            const top = ref.current.offsetTop - HEADER_HEIGHT;
            // Some browsers (IE, old versions of Edge) don't support scrollTo (#216).
            // Safari's implementation of scrollTo ignores the behavior option.
            if (typeof this.scrollElement!.scrollTo === 'function') {
                this.scrollElement!.scrollTo({ top, behavior: 'smooth' });
            } else {
                this.scrollElement!.scrollTop = top;
            }
        }
    }

    render() {
        return (
            <div className="LearnContent">
                <AppHeader minimal={true}/>
                <div className="LearnContent__material">
                    <SideNav className="LearnContent__menu" autoFocus={false}
                        value={this.currentScrollId} isNested={false} manageTabIndex={false}
                        onSelect={this.scrollToSelection} typeToSelect={false} variant="default">
                        <SideNavHeading label="GETTING STARTED">
                            { gettingStartedContent.map(item => {
                                return (
                                    <SideNavItem key={item.id} value={item.id}>
                                        {item.menuTitle}
                                    </SideNavItem>
                                );
                            })}
                        </SideNavHeading>
                        <SideNavHeading label="ADDITIONAL RESOURCES">
                            { additonalResourcesContent.map(item => {
                                return (
                                    <SideNavItem key={item.id} value={item.id}>
                                        {item.menuTitle}
                                    </SideNavItem>
                                );
                            })}
                        </SideNavHeading>
                    </SideNav>
                    <div className="LearnContent__description">
                        <h1>Getting Started with Project Fantastic Fold</h1>
                        <p>Fantastic Fold helps you transform a 2D dieline into a 3D model, making it easier than ever to visualize your designs in 3D. Fantastic Fold currently accepts dieline files in SVG format and artwork in PNG format.  The recommended tool for simultaneously preparing dieline and artwork content is Adobe Illustrator.  Fantastic Fold works best in Chrome or Firefox.</p>
                        { gettingStartedContent.map(item => {
                            return (
                                <LearnSection key={item.id} sectionRef={this.sectionRefs[item.id]} title={item.title}
                                    subSections={item.subsections}/>
                            );
                        })}
                        <h1>Additional Resources</h1>
                        { additonalResourcesContent.map(item => {
                            return (
                                <LearnSection key={item.id} sectionRef={this.sectionRefs[item.id]} title={item.title}
                                    subSections={item.subsections}/>
                            );
                        })}
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}