import Button from '@react/react-spectrum/Button';
import DropdownButton from '@react/react-spectrum/DropdownButton';
import Download from '@react/react-spectrum/Icon/Download';
import Feedback from '@react/react-spectrum/Icon/Feedback';
import HelpOutline from '@react/react-spectrum/Icon/HelpOutline';
import Home from '@react/react-spectrum/Icon/Home';
import LinkOutLight from '@react/react-spectrum/Icon/LinkOutLight';
import Settings from '@react/react-spectrum/Icon/Settings';
import Visibility from '@react/react-spectrum/Icon/Visibility';
import { MenuItem } from '@react/react-spectrum/Menu';
import OverlayTrigger from '@react/react-spectrum/OverlayTrigger';
import Popover from '@react/react-spectrum/Popover';
import Switch from '@react/react-spectrum/Switch';
import { Tab, TabList } from '@react/react-spectrum/TabList';
import { boundMethod } from 'autobind-decorator';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { AppMode } from '../common/AppMode';
import { SafeLocalStorage } from '../common/SafeLocalStorage';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { TooltipButton } from '../components/TooltipButton';
import { TooltipContent } from '../components/TooltipContent';
import '../css/AppHeader.css';
import { Analytics, EventName } from '../services/Analytics';
import { Theme } from '../store/SettingsStore';
import { export3DModel } from './ExportModal';
import { ExternalLinks } from './ExternalLinks';
import { HelpModal } from './HelpModal';
import { StoreComponent } from './StoreComponent';
import { UserProfileMenu } from './UserProfileMenu';

interface AppHeaderProps {
    minimal?: boolean; // This is for secondary pages (eg learn) that do not need FF UI.
}

@inject('store')
@observer
export class AppHeader extends StoreComponent<AppHeaderProps> {
    componentDidMount() {
        // The first time we enter edit mode, show the introductory tour if the user hasn't seen it.
        when(() => this.props.store.ui.appMode === AppMode.Edit,
            () => {
                if (SafeLocalStorage.getItem('hasVisited') !== 'true') {
                    SafeLocalStorage.setItem('hasVisited', 'true');
                    this.showHelpModal();
                }
            },
        );
    }

    private get selectedTabIndex() {
        switch (this.props.store.ui.appMode) {
            case AppMode.Home:
                return 0;
            case AppMode.Preview:
                return 1;
            case AppMode.Edit:
                return 1;
            default:
                throw new Error('Unsupported app mode.');
        }
    }

    @boundMethod
    private onChangeMode(tabIndex: number) {
        this.setAppMode(tabIndex === 0 ? AppMode.Home : AppMode.Edit);
    }

    @boundMethod
    private togglePreviewMode() {
        this.setAppMode(this.props.store.ui.appMode === AppMode.Preview ? AppMode.Edit : AppMode.Preview);
    }

    private setAppMode(mode: AppMode) {
        if (mode === AppMode.Preview) {
            this.props.store.ui.showFlat = false;
        }
        this.props.store.ui.appMode = mode;
        this.props.store.selection.deselectAll();
        Analytics.event(EventName.ModeSelect, { mode });
    }

    @boundMethod
    private saveGLB() {
        // Reorient model on bottom face.
        const { info, geometry, render, ui, substrateThickness } = this.props.store;
        const { bottomFaceIndex, triangleMesh, overlappingGroups } = geometry;
        render.orientModelOnGroundPlane(bottomFaceIndex, triangleMesh);
        render.thickenedMesh.updatePositions(substrateThickness, info.isSubstrateHorizontal,
            ui.showFlat, overlappingGroups);
        
        export3DModel(info.filename, 'glb', render.thickenedMesh, render.plasticWindows, geometry.creaseBounds,
            substrateThickness);
    }

    @boundMethod
    private showHelpModal(event?: React.MouseEvent) {
        HelpModal.show();
        Analytics.event(EventName.TourStart, { tourTrigger: event ? 'interactive' : 'automatic' });
    }

    @boundMethod
    private updateAutoCentering(state: boolean) {
        this.props.store.setEnableAutoCentering(state);
        Analytics.event(EventName.PreferencesAutoCenter, { state });
    }

    render() {
        // Throw up help dialog if appropriate.
        const { appMode } = this.props.store.ui;
        const previewKey = 'cmdOrCtrl+y';
        const exportKey = 'cmdOrCtrl+e';
        const previewButtonTooltip = (
            <TooltipContent heading="Toggle preview mode" shortcut={previewKey} />
        );
        const exportButtonTooltip = (
            <TooltipContent heading="Export and download a file containing your 3D model"
                shortcut={exportKey} />
        );
        return (
            <div className={'AppHeader' + (this.props.minimal ? ' AppHeader__minimal' : '')}>
                <div className="AppHeader__left">
                    <span className="AppHeader__title">Project Fantastic Fold</span>
                    <div className="AppHeader__beta">private beta</div>
                    { this.props.minimal &&
                    <TabList aria-label="Default" selectedIndex={-1}
                        onChange={() => { window.location.href = process.env.PUBLIC_URL; }}>
                        <Tab>
                            <Home size="S" />
                        </Tab>
                    </TabList>
                    }
                    { !this.props.minimal &&
                    <TabList aria-label="Default" selectedIndex={this.selectedTabIndex}
                        onChange={this.onChangeMode}>
                        <Tab>
                            <Home size="S" />
                        </Tab>
                        <Tab disabled={this.props.store.info.hasNoDieline}>
                            Fold
                        </Tab>
                    </TabList>
                    }
                </div>
                <div className="AppHeader__center">
                { !this.props.minimal && (appMode === "edit" || appMode === "preview") &&
                    <>
                        <KeyboardShortcut keys={previewKey} onPressed={this.togglePreviewMode} />
                        <TooltipButton
                            icon={<Visibility />}
                            tooltip={previewButtonTooltip}
                            placement="bottom"
                            tooltipVariant="info"
                            variant="action"
                            quiet
                            selected={appMode === AppMode.Preview}
                            onClick={this.togglePreviewMode}>
                            Preview
                        </TooltipButton>
                        <div className="AppHeader__divider" />
                        <KeyboardShortcut keys={exportKey} onPressed={this.saveGLB} />
                        <TooltipButton
                            icon={<Download />}
                            tooltip={exportButtonTooltip}
                            placement="bottom"
                            tooltipVariant="info"
                            variant="action"
                            quiet
                            onClick={this.saveGLB}>
                            Export 3D Model
                        </TooltipButton>
                    </>
                }
                </div>
                <div className="AppHeader__right">
                    <Button icon={<Feedback size="S" />} label='Feedback' variant='action'
                        onClick={ExternalLinks.launchFeedback} quiet />
                    { !this.props.minimal &&
                        <React.Fragment>
                            <DropdownButton icon={<HelpOutline size="M" />}>
                                <MenuItem onClick={this.showHelpModal}>
                                    Take the Introductory Tour
                                </MenuItem>
                                <MenuItem onClick={ExternalLinks.launchHelp}>
                                    <div className="AppHeader__menuItemLabel">
                                        Learn More <LinkOutLight className="AppHeader__menuItemIcon" size="S" />
                                    </div>
                                </MenuItem>
                            </DropdownButton>
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['click']}>
                                <Button
                                    icon={<Settings />}
                                    quiet={true}
                                    variant="action"
                                />
                                <Popover title="Preferences">
                                    <div className="AppHeader__column">
                                        <Switch
                                            label="Use dark theme"
                                            checked={this.props.store.preferences.theme === Theme.Dark}
                                            onChange={this.props.store.preferences.toggleTheme} />
                                        <Switch
                                            label="Enable auto-centering in 3D view"
                                            checked={this.props.store.preferences.enableAutoCentering}
                                            onChange={this.updateAutoCentering} />
                                    </div>
                                </Popover>
                            </OverlayTrigger>
                        </React.Fragment>
                    }
                    <UserProfileMenu />
                </div>
            </div>
        );
    }
}
