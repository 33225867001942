import { action } from 'mobx';
import React, { Component, ReactElement } from 'react';

/** Properties of the FileOpener component. */
interface FileOpenerProps {
    /** A comma-separated list of accepted file extensions. */
    accept?: string;

    /** The event handler that is called when the user selects a file. */
    onFileSelected?: (file: File) => void;
}

/**
 * A wrapper component that prompts the user to select a file when a child component is clicked.
 * 
 * This component is typically wrapped around a button or a link, as in this example:
 * 
 *      <FileOpener accept=".jpg,.jpeg,.png" onFileSelected={this.openImage}>
 *          <Button>Open image...</Button>
 *      </FileOpener>
 */
export class FileOpener extends Component<FileOpenerProps> {
    private _fileInput = React.createRef<HTMLInputElement>()

    render() {
        const modifiedChildren = React.Children.map(this.props.children, child =>
            React.cloneElement(child as ReactElement, { onClick: this._openFileSelector }));
        return (
            <>
                {modifiedChildren}
                <input
                    ref={this._fileInput}
                    type="file"
                    style={{ display: 'none' }}
                    accept={this.props.accept}
                    onChange={this._handleFileSelected} />
            </>
        );
    }

    @action.bound
    private _openFileSelector() {
        const fileInput = this._fileInput.current;
        if (fileInput) {
            // Clear out the old value. This is important in case the same file is selected twice.
            fileInput.value = '';
            // Open file explorer.
            fileInput.click();
        } else {
            throw new Error('No file input found.');
        }
    }

    @action.bound
    private _handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
        // Make sure exactly one file has been selected.
        const { files } = e.target;
        if (!files || files.length === 0) {
            return;
        }
        if (files.length > 1) {
            throw new Error('Multiple files selected.');
        }

        if (this.props.onFileSelected) {
            this.props.onFileSelected(files[0]);
        }
    }
}
