import FieldLabel from '@react/react-spectrum/FieldLabel';
import Heading from '@react/react-spectrum/Heading';
import LockClosed from '@react/react-spectrum/Icon/LockClosed';
import LockOpen from '@react/react-spectrum/Icon/LockOpen';
import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { MAX_ANGLE_DECIMAL_PLACES } from '../common/constants';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { NumberInput } from '../components/NumberInput';
import { TooltipButton } from '../components/TooltipButton';
import '../css/CreasePropertiesPanel.css';
import '../css/RightPanel.css';
import { Analytics, EventName } from '../services/Analytics';
import { StoreComponent } from './StoreComponent';

@inject('store')
@observer
export class CreasePropertiesPanel extends StoreComponent {
    render() {
        const { render, selection, lastSelectedEdge } = this.props.store;
        const { selectedEdges } = selection;
        let foldAngle = lastSelectedEdge ? lastSelectedEdge.foldAngle : undefined;
        if (foldAngle !== undefined && lastSelectedEdge && lastSelectedEdge.assignment === 'V') {
            foldAngle *= -1;
        }
        const removeConstraintsDisabled = selectedEdges.every((edgeIndex) =>
            render.crease.edges[edgeIndex].foldAngle === null);
        const removeConstraintsLabel = selectedEdges.length === 1 ? 'Remove Constraint' :
            'Remove Constraints';
        return (
            <div className="CreasePropertiesPanel RightPanel">
                <Heading variant="subtitle2">
                    CREASE PROPERTIES
                </Heading>
                <FieldLabel
                    label="Fold Angle"
                    labelFor="FoldAngleInput"
                    position="left">
                    <NumberInput
                        id="FoldAngleInput"
                        className="CreasePropertiesPanel__foldAngleInput"
                        value={foldAngle}
                        decimalPlaces={MAX_ANGLE_DECIMAL_PLACES}
                        suffix="°"
                        min={-180}
                        max={180}
                        placeholder="unconstrained"
                        onChange={this.setFoldAngle}
                        quiet
                    />
                    <TooltipButton
                        variant="tool"
                        icon={removeConstraintsDisabled ? <LockOpen /> : <LockClosed />}
                        tooltip={removeConstraintsLabel}
                        disabled={removeConstraintsDisabled}
                        onClick={this.removeConstraints}
                    />
                </FieldLabel>
                <KeyboardShortcut keys="backspace,delete" onPressed={this.removeConstraints} />
            </div>
        );
    }

    @boundMethod
    private setFoldAngle(foldAngle?: number) {
        const { geometry, selection } = this.props.store;
        const { selectedEdges } = selection;
        geometry.setFoldAngles(foldAngle === undefined ? null : foldAngle, selectedEdges);
        Analytics.event(EventName.EdgeAngleType, { edgeCount: selectedEdges.length });
    }

    @boundMethod
    private removeConstraints() {
        // Delete constraints.
        const { geometry, selection } = this.props.store;
        const { selectedEdges } = selection;
        geometry.setFoldAngles(null, selectedEdges);
        Analytics.event(EventName.EdgeAngleUnconstrain, { edgeCount: selectedEdges.length });
    }
}
