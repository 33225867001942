import { AppMode } from '../common/AppMode';
import { ToolId } from '../common/ToolId';
import { AnalyticsData, AnalyticsProvider } from './AnalyticsProvider';

export enum EventName {
    AccountSignedIn = 'account-signed-in',
    AccountSignedOut = 'account-signed-out',
    AccountSignOut = 'account-sign-out',
    AccountSignUp = 'account-sign-up',
    AccountView = 'account-view',
    AnimationHoldType = 'animation-hold-type',
    AnimationKeyframeAdd = 'animation-keyframe-add',
    AnimationKeyframeRemove = 'animation-keyframe-remove',
    AnimationKeyframeSelect = 'animation-keyframe-select',
    AnimationKeyframeUpdate = 'animation-keyframe-update',
    AnimationPause = 'animation-pause',
    AnimationPlay = 'animation-play',
    AnimationScrub = 'animation-scrub',
    AnimationTransitionType = 'animation-transition-type',
    ArtworkDelete = 'artwork-delete',
    ArtworkHide = 'artwork-hide',
    ArtworkImportCancel = 'artwork-import-cancel',
    ArtworkImportComplete = 'artwork-import-complete',
    ArtworkImportError = 'artwork-import-error',
    ArtworkImportStart = 'artwork-import-start',
    ArtworkShow = 'artwork-show',
    ArtworkSwap = 'artwork-swap',
    BottomFaceSelectCancel = 'bottom-face-select-cancel',
    BottomFaceSelectComplete = 'bottom-face-select-complete',
    BottomFaceSelectStart = 'bottom-face-select-start',
    DielineAnalysisCancel = 'dieline-analysis-cancel',
    DielineAnalysisComplete = 'dieline-analysis-complete',
    DielineAnalysisError = 'dieline-analysis-error',
    DielineAnalysisStart = 'dieline-analysis-start',
    DielineImportCancel = 'dieline-import-cancel',
    DielineImportComplete = 'dieline-import-complete',
    DielineImportError = 'dieline-import-error',
    DielineProblemHide = 'dieline-problem-hide',
    DielineProblemShow = 'dieline-problem-show',
    EdgeAngleDrag = 'edge-angle-drag',
    EdgeAngleType = 'edge-angle-type',
    EdgeAngleResetAll = 'edge-angle-reset-all',
    EdgeAngleUnconstrain = 'edge-angle-unconstrain',
    EdgeSelectAll = 'edge-select-all',
    EdgeSelectClick = 'edge-select-click',
    EdgeSelectNone = 'edge-select-none',
    EdgeSelectScribble = 'edge-select-scribble',
    ExampleDownload = 'example-download',
    ExampleImport = 'example-import',
    LinkLaunchHelp = 'link-launch-help',
    LinkLaunchFeedback = 'link-launch-feedback',
    MaterialSelect = 'material-select',
    ModelExportComplete = 'model-export-complete',
    ModelExportError = 'model-export-error',
    ModelExportStart = 'model-export-start',
    ModeSelect = 'mode-select',
    PreferencesAutoCenter = 'preferences-auto-center',
    ShowAppContent = 'show-app-content',
    ShowLearnContent = 'show-learn-content',
    ShowSignUp = 'show-sign-up',
    ToolSelect = 'tool-select',
    TourCancel = 'tour-cancel',
    TourComplete = 'tour-complete',
    TourStart = 'tour-start',
    TourStepSelect = 'tour-step-select',
    ViewFlatHide = 'view-flat-hide',
    ViewFlatShow = 'view-flat-show',
}

type ModeData = { mode: AppMode };
type ErrorData = { error: string, stack?: string };
type FileSizeData = { fileSize: number };
type ElapsedTimeData = { elapsedTime: number };
type ExampleNameData = { exampleName: string };
type ToolData = { tool: ToolId };
type ModifierData = { modifier: 'none' | 'shift' };
type EdgeCountData = { edgeCount: number };
type MaterialNameData = { materialName: string };
type SideData = { side: 'interior' | 'exterior' };
type SizeData = { width: number, height: number };
type FileSourceData = { fileSource: 'dragAndDrop' | 'select' };
type ProgressData = { progress: number };
type DielineData = { 
    components: number,
    vertices: number,
    edges: number,
    faces: number,
    curvedCreases: number,
    triangleVertices?: number,
    triangleEdges?: number,
    triangleFaces?: number,
    problemVertices?: number,
    problemFaces?: number,
};
type TourTriggerData = { tourTrigger: 'automatic' | 'interactive' };
type TourStepData = { tourStep: number };
type BooleanData = { state: boolean };
type AnimationKeyframeData = { keyframeCount: number };
type AnimationPlayData = { keyframeCount: number, stepCount: number };

export class Analytics {
    private static provider?: AnalyticsProvider;
    private static appName?: string;
    private static appVersion?: string;
    private static context?: AnalyticsData;
    private static events: { name: string, data: AnalyticsData }[] = [];

    /**
     * Sets the service provider that actually implements the analytics.
     * @param provider The analytics provider.
     */
    static setProvider(provider: AnalyticsProvider) {
        // Store the provider.
        Analytics.provider = provider;

        // Catch up if initialize, setContext, or event methods were already called.
        if (Analytics.appName && Analytics.appVersion) {
            provider.initialize(Analytics.appName, Analytics.appVersion);
        }
        if (Analytics.context) {
            provider.setContext(Analytics.context);
        }
        Analytics.events.forEach(({ name, data }) => provider.event(name, data));
        Analytics.events = [];
    }

    /**
     * Sets application information that is included in crash reports sent to the analytics service.
     * @param appName The name of the application.
     * @param appVersion The version of the application.
     */
    static initialize(appName: string, appVersion: string) {
        Analytics.appName = appName;
        Analytics.appVersion = appVersion;
        if (Analytics.provider) {
            Analytics.provider.initialize(appName, appVersion);
        }
    }

    /**
     * Sets information that is included with each event reported to the analytics service.
     * @param context Keys and values.
     */
    static setContext(context: AnalyticsData) {
        Analytics.context = context;
        if (Analytics.provider) {
            Analytics.provider.setContext(context);
        }
    }

    /**
     * Reports an event to the analytics service. Note that events reported before calling
     * setProvider are discarded.
     * @param name The name of the event.
     * @param data The data associated with the event.
     */
    static event(name: EventName.AccountSignedIn): void;
    static event(name: EventName.AccountSignedOut): void;
    static event(name: EventName.AccountSignOut): void;
    static event(name: EventName.AccountSignUp): void;
    static event(name: EventName.AccountView): void;
    static event(name: EventName.AnimationHoldType): void;
    static event(name: EventName.AnimationKeyframeAdd, data: AnimationKeyframeData): void;
    static event(name: EventName.AnimationKeyframeRemove, data: AnimationKeyframeData): void;
    static event(name: EventName.AnimationKeyframeSelect, data: AnimationKeyframeData): void;
    static event(name: EventName.AnimationKeyframeUpdate, data: AnimationKeyframeData): void;
    static event(name: EventName.AnimationPause): void;
    static event(name: EventName.AnimationPlay, data: AnimationPlayData): void;
    static event(name: EventName.AnimationScrub): void;
    static event(name: EventName.AnimationTransitionType): void;
    static event(name: EventName.ModeSelect, data: ModeData): void;
    static event(name: EventName.ModelExportStart): void;
    static event(name: EventName.ModelExportError, data: ErrorData): void;
    static event(name: EventName.ModelExportComplete, data: FileSizeData & ElapsedTimeData): void;
    static event(name: EventName.LinkLaunchFeedback): void;
    static event(name: EventName.LinkLaunchHelp): void;
    static event(name: EventName.ExampleImport, data: ExampleNameData): void;
    static event(name: EventName.ExampleDownload, data: ExampleNameData): void;
    static event(name: EventName.ToolSelect, data: ToolData): void;
    static event(name: EventName.EdgeSelectClick, data: ModifierData): void;
    static event(name: EventName.EdgeSelectScribble): void;
    static event(name: EventName.EdgeSelectAll): void;
    static event(name: EventName.EdgeSelectNone): void;
    static event(name: EventName.EdgeAngleDrag, data: EdgeCountData): void;
    static event(name: EventName.EdgeAngleType, data: EdgeCountData): void;
    static event(name: EventName.EdgeAngleResetAll): void;
    static event(name: EventName.EdgeAngleUnconstrain, data: EdgeCountData): void;
    static event(name: EventName.MaterialSelect, data: MaterialNameData): void;
    static event(name: EventName.BottomFaceSelectStart): void;
    static event(name: EventName.BottomFaceSelectCancel): void;
    static event(name: EventName.BottomFaceSelectComplete): void;
    static event(name: EventName.ViewFlatShow | EventName.ViewFlatHide): void;
    static event(name: EventName.ArtworkShow, data: SideData): void;
    static event(name: EventName.ArtworkHide, data: SideData): void;
    static event(name: EventName.ArtworkDelete, data: SideData): void;
    static event(name: EventName.ArtworkSwap): void;
    static event(name: EventName.ArtworkImportStart, data: SideData): void;
    static event(name: EventName.ArtworkImportError, data: SideData & ErrorData): void;
    static event(name: EventName.ArtworkImportCancel, data: SideData & ElapsedTimeData): void;
    static event(name: EventName.ArtworkImportComplete,
        data: SideData & SizeData & ElapsedTimeData): void;
    static event(name: EventName.DielineAnalysisStart, data: FileSourceData): void;
    static event(name: EventName.DielineAnalysisError, data: ErrorData): void;
    static event(name: EventName.DielineAnalysisCancel, data: ProgressData & ElapsedTimeData): void;
    static event(name: EventName.DielineAnalysisComplete,
        data: DielineData & ElapsedTimeData): void;
    static event(name: EventName.DielineProblemShow): void;
    static event(name: EventName.DielineProblemHide): void;
    static event(name: EventName.DielineImportError, data: ErrorData): void;
    static event(name: EventName.DielineImportCancel, data: DielineData & ElapsedTimeData): void;
    static event(name: EventName.DielineImportComplete, data: DielineData & ElapsedTimeData): void;
    static event(name: EventName.ShowAppContent): void;
    static event(name: EventName.ShowLearnContent): void;
    static event(name: EventName.ShowSignUp): void;
    static event(name: EventName.TourStart, data: TourTriggerData): void;
    static event(name: EventName.TourStepSelect, data: TourStepData): void;
    static event(name: EventName.TourCancel, data: TourStepData & ElapsedTimeData): void;
    static event(name: EventName.TourComplete, data: ElapsedTimeData): void;
    static event(name: EventName.PreferencesAutoCenter, data: BooleanData): void;
    static event(name: EventName, data: AnalyticsData = {}): void {
        // Send the event to the provider, if available.
        if (Analytics.provider) {
            Analytics.provider.event(name, data);
        } else if (Analytics.events.length < 1000) {
            // Cache the first bunch of events to send if a provider is set later.
            Analytics.events.push({ name, data });
        }
    }
}
