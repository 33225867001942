/**
 * Wraps local storage, preventing exceptions if the browser is configured to block access to local
 * storage.
 */
export class SafeLocalStorage {
    /**
     * Proxy for local storage, used if the browser is configured to block access to local storage.
     */
    private static storage: Record<string, string> = {};

    /**
     * Returns the value of an item in local storage, or null.
     */
    static getItem(key: string) {
        try {
            return window.localStorage.getItem(key);
        }
        catch {
            return key in SafeLocalStorage.storage ? SafeLocalStorage.storage[key] : null;
        }
    }

    /**
     * Sets the value of an item in local storage.
     */
    static setItem(key: string, value: string) {
        try {
            SafeLocalStorage.storage[key] = value;
            window.localStorage.setItem(key, value);
        } catch {
            // Ignore exceptions.
        }
    }
}