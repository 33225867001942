import Heading from '@react/react-spectrum/Heading';
import Alert from '@react/react-spectrum/Icon/Alert';
import Popover from '@react/react-spectrum/Popover';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import { Crease, CreaseEdge, CreaseEdgeLoop, CreaseEdgeRef, CreaseFace, CreaseVertex, geom, vec, Vector2 } from 'crease';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import '../css/CreaseVisualizer.css';
import { Analytics, EventName } from '../services/Analytics';
import { AlertLevel, ProblemArea, ProblemFace, ProblemMessage, problemMessages, ProblemVertex } from './ImportDielineMessages';

const MARGIN = 2;
const PROBLEM_AREA_RADIUS = 2;
const MAGNIFICATION = 16;
const PROBLEM_VERTEX_RADIUS = 2;


interface CreaseVisualizerProps {
    crease?: Crease;
    problemVertices: ProblemVertex[];
    problemFaces: ProblemFace[];
}

@observer
export class CreaseVisualizer extends Component<CreaseVisualizerProps> {
    private rootElementRef = React.createRef<HTMLDivElement>();
    private lastSelectedProblemAreaElement?: HTMLElement;

    @observable
    private selectedProblemArea: ProblemArea | undefined = undefined;

    @observable
    private popoverTranslateX: number = 0;

    @observable
    private popoverTranslateY: number = 0;

    @boundMethod
    private selectProblemArea(e: React.SyntheticEvent, problemArea: ProblemArea) {
        // Calculate the translation required to position the popover next to the magnifier.
        this.lastSelectedProblemAreaElement = e.currentTarget as HTMLElement;
        const target = this.lastSelectedProblemAreaElement.firstElementChild as Element;
        const targetRect = target.getBoundingClientRect();
        const root = this.rootElementRef.current as Element;
        const rootRect = root.getBoundingClientRect();
        this.popoverTranslateX = targetRect.left + (MAGNIFICATION + 1) / 2 * targetRect.width -
            (rootRect.left + rootRect.width) + 12;
        this.popoverTranslateY = targetRect.top - rootRect.top - 40;
        this.selectedProblemArea = problemArea;

        e.stopPropagation();
        e.preventDefault();
        Analytics.event(EventName.DielineProblemShow);
    }

    @boundMethod
    private deselectProblemArea(e: React.SyntheticEvent) {
        this.selectedProblemArea = undefined;
        e.stopPropagation();
        e.preventDefault();
        if (this.lastSelectedProblemAreaElement) {
            this.lastSelectedProblemAreaElement.focus();
        }
        Analytics.event(EventName.DielineProblemHide);
    }

    @boundMethod
    private handlePopoverKeyDown(e: React.KeyboardEvent) {
        if (this.selectedProblemArea &&
            (e.key === 'Escape' || e.key === ' ' || e.key === 'Enter')) {
            this.deselectProblemArea(e);
        }
    }

    private renderProblemMessage(problemMessage: ProblemMessage) {
        return (
            <div className="CreaseVisualizer__item" key={problemMessage.kind}>
                <Heading className="CreaseVisualizer__itemHeading" variant="subtitle2">
                    <Alert className={`CreaseVisualizer__alert--${problemMessage.alertLevel}`} size="S" />
                    <span className="CreaseVisualizer__itemTitle">{problemMessage.title}</span>
                </Heading>
                <div>{problemMessage.description}</div>
            </div>
        );
    }

    private renderPopover() {
        const problemArea = this.selectedProblemArea as ProblemArea;
        const messages = problemMessages.filter(problemMessage =>
            problemArea.problemVertices.some(pv => pv.kind === problemMessage.kind));
        const transform = `translate(${this.popoverTranslateX}px, ${this.popoverTranslateY}px)`;
        return (
            <Popover className="CreaseVisualizer__popover" title={null} open placement="right" style={{ transform }}
                onKeyDown={this.handlePopoverKeyDown}>
                {messages.map(problemMessage => this.renderProblemMessage(problemMessage))}
            </Popover>
        );
    }

    render() {
        const crease = this.props.crease;
        if (!crease) {
            return;
        }

        // Scale and center the dieline within the box from (0, 0) to (100, 100).
        const isEmpty = crease.faces.length === 0;
        const emptyBounds = { min: [0, 0], max: [100, 100] };
        const bounds = isEmpty ? emptyBounds : crease.getBounds();
        const width = bounds.max[0] - bounds.min[0];
        const height = bounds.max[1] - bounds.min[1]
        const scale = Math.min((100 - 2 * MARGIN) / width, (100 - 2 * MARGIN) / height);
        const viewBox = `0 0 ${scale * width + 2 * MARGIN} ${scale * height + 2 * MARGIN}`;
        const translateX = -bounds.min[0] * scale + MARGIN;
        const translateY = -bounds.min[1] * scale + MARGIN;
        const transform = `matrix(${scale} 0 0 ${scale} ${translateX} ${translateY})`;

        // Create a visualizer for each face.
        const faces = crease.faces.map((face: CreaseFace) => {
            const problemFace = this.props.problemFaces.find(p => p.faceIndex === face.index);
            const problemDescription = problemFace ? problemFace.description : undefined;
            return <CreaseFaceVisualizer face={face} key={face.index}
                problemDescription={problemDescription} />
        });

        // Create a visualizer for each edge.
        const edges = crease.edges.map((edge: CreaseEdge) =>
            <CreaseEdgeVisualizer edge={edge} key={edge.index} />
        );

        // Cluster nearby problem vertices into problem areas.
        const problemAreas: ProblemArea[] = [];
        this.props.problemVertices.forEach((problemVertex: ProblemVertex) => {
            const v = crease.vertices[problemVertex.index];
            const p = v.position;
            const closestProblemArea =
                problemAreas.reduce((closest: ProblemArea | undefined, area) =>
                    closest === undefined ||
                        vec.distance(p, area.center) < vec.distance(p, closest.center) ? area : closest,
                    undefined);
            if (closestProblemArea &&
                vec.distance(p, closestProblemArea.center) * scale < PROBLEM_AREA_RADIUS) {
                closestProblemArea.problemVertices.push(problemVertex);
                closestProblemArea.vertices.push(v);
                closestProblemArea.center = geom.getPointAverage(
                    closestProblemArea.vertices.map(vertex => vertex.position));
            } else {
                problemAreas.push({
                    center: p,
                    problemVertices: [problemVertex],
                    vertices: [v],
                });
            }
        });

        // Create a visualizer for each problem area.
        const sortedProblemAreas = problemAreas.sort((a, b) => {
            const delta = vec.subtract(a.center, b.center);
            return Math.abs(delta[1] * scale) < PROBLEM_AREA_RADIUS / 2 ? delta[0] : delta[1];
        });
        const problemAreaVizualizers = sortedProblemAreas.map((problemArea: ProblemArea, i: number) =>
            <ProblemAreaVisualizer problemArea={problemArea} scale={scale} key={i}
                onSelect={this.selectProblemArea} />
        );

        // Render everything.
        return (
            <div className="CreaseVisualizer" ref={this.rootElementRef}>
                <svg className="CreaseVisualizer__svg" viewBox={viewBox}
                    onClick={this.deselectProblemArea}>
                    <g transform={transform} vectorEffect="non-scaling-stroke">
                        {faces}
                        {edges}
                        {problemAreaVizualizers}
                        {this.selectedProblemArea &&
                            <SelectedProblemAreaVisualizer crease={crease}
                                problemArea={this.selectedProblemArea} scale={scale} />
                        }
                    </g>
                </svg>
                {this.selectedProblemArea &&
                    this.renderPopover()
                }
            </div>
        );
    }
}


interface CreaseFaceVisualizerProps {
    face: CreaseFace;
    problemDescription?: string;
}

class CreaseFaceVisualizer extends Component<CreaseFaceVisualizerProps> {
    render() {
        const formatPoint = (p: Vector2) => `${p[0]},${p[1]}`;
        const pathData = this.props.face.edgeLoops.map((loop: CreaseEdgeLoop) =>
            loop.edgeRefs.map((edgeRef: CreaseEdgeRef, i: number) => {
                const points = edgeRef.allPoints.map(formatPoint);
                const start = i === 0 ? `M${points[0]}` : '';
                const command = ['L', 'Q', 'C'][points.length - 2];
                return `${start}${command}${points.slice(1).join(' ')}`;
            }).join('') + 'Z'
        ).join('');
        const className = classNames('CreaseFaceVisualizer', {
            'CreaseFaceVisualizer--problem': !!this.props.problemDescription,
        });
        return (
            <path className={className} d={pathData}>
                <title>{this.props.problemDescription}</title>
            </path>
        );
    }
}


interface CreaseEdgeVisualizerProps {
    edge: CreaseEdge;
}

class CreaseEdgeVisualizer extends Component<CreaseEdgeVisualizerProps> {
    render() {
        const formatPoint = (p: Vector2) => `${p[0]},${p[1]}`;
        const points = this.props.edge.allPoints.map(formatPoint);
        const command = ['L', 'Q', 'C'][points.length - 2];
        const pathData = `M${points[0]}${command}${points.slice(1).join(' ')}`;
        const className = classNames('CreaseEdgeVisualizer', {
            'CreaseEdgeVisualizer--crease': this.props.edge.isCrease,
            'CreaseEdgeVisualizer--cut': !this.props.edge.isCrease,
        });
        return <path className={className} d={pathData} />
    }
}


interface ProblemAreaVisualizerProps {
    problemArea: ProblemArea;
    scale: number;
    onSelect?: (e: React.SyntheticEvent, problemArea: ProblemArea) => void;
}

class ProblemAreaVisualizer extends Component<ProblemAreaVisualizerProps> {
    @boundMethod
    private handleSelect(e: React.SyntheticEvent) {
        if (this.props.onSelect) {
            this.props.onSelect(e, this.props.problemArea);
        }
    }

    @boundMethod
    private handleKeyDown(e: React.KeyboardEvent) {
        if (e.key === ' ' || e.key === 'Enter') {
            this.handleSelect(e);
        }
    }

    render() {
        const cx = this.props.problemArea.center[0];
        const cy = this.props.problemArea.center[1];
        const alertLevel = this.props.problemArea.problemVertices.reduce((_alertLevel: AlertLevel, problemVertex) => {
            return _alertLevel === 'error' ? _alertLevel : problemVertex.alertLevel;
        }, 'warning');
        const className = classNames('ProblemAreaVisualizer__circle',
            `ProblemAreaVisualizer__circle--${alertLevel}`);
        return (
            <g className="ProblemAreaVisualizer" tabIndex={0} onClick={this.handleSelect}
                onKeyDown={this.handleKeyDown}>
                <circle className={className} cx={cx} cy={cy}
                    r={PROBLEM_AREA_RADIUS / this.props.scale} />
                <circle className="ProblemAreaVisualizer__focus" cx={cx} cy={cy}
                    r={1.4 * PROBLEM_AREA_RADIUS / this.props.scale} />
            </g>
        );
    }
}


interface SelectedProblemAreaVisualizerProps {
    problemArea: ProblemArea;
    crease: Crease;
    scale: number;
}

class SelectedProblemAreaVisualizer extends Component<SelectedProblemAreaVisualizerProps> {
    render() {
        const radius = PROBLEM_AREA_RADIUS * MAGNIFICATION / this.props.scale;

        // Build the transform from dieline coordinates to magnified coordinates centered at (0, 0).
        const cx = this.props.problemArea.center[0];
        const cy = this.props.problemArea.center[1];
        const dielineTransform = `scale(${MAGNIFICATION}) translate(${-cx} ${-cy})`;

        // TODO: Figure out where to position the center of the magnifier.
        // For now, center it on the problem area.
        const magnifierTransform = `translate(${cx} ${cy})`;

        // Create a visualizer for each face.
        const faces = this.props.crease.faces.map((face: CreaseFace) =>
            <CreaseFaceVisualizer face={face} key={face.index} />
        );

        // Create a visualizer for each edge.
        const edges = this.props.crease.edges.map((edge: CreaseEdge) =>
            <CreaseEdgeVisualizer edge={edge} key={edge.index} />
        );

        // Create a visualizer for each problem vertex.
        const vertices = this.props.problemArea.vertices.map((vertex: CreaseVertex, i) =>
            <ProblemVertexVisualizer key={vertex.index} vertex={vertex}
                scale={MAGNIFICATION * this.props.scale} alertLevel={this.props.problemArea.problemVertices[i].alertLevel} />
        );

        const alertLevel = this.props.problemArea.problemVertices.reduce((_alertLevel: AlertLevel, problemVertex) => {
            return _alertLevel === 'error' ? _alertLevel : problemVertex.alertLevel;
        }, 'warning');
        const className = classNames('SelectedProblemAreaVisualizer__rim',
            `SelectedProblemAreaVisualizer__rim--${alertLevel}`);

        return (
            <g className="SelectedProblemAreaVisualizer" transform={magnifierTransform}>
                <defs>
                    <filter id="shadow">
                        <feDropShadow dx="0" dy="0" stdDeviation="8" />
                    </filter>
                    <clipPath id="clipCircle">
                        <circle r={radius} />
                    </clipPath>
                </defs>
                <rect className="SelectedProblemAreaVisualizer__underlay" x="-10000" y="-10000" width="30000" height="30000" />
                <circle className="SelectedProblemAreaVisualizer__background" r={radius}
                    style={{ filter: 'url(#shadow)' }} />
                <g clipPath="url(#clipCircle)">
                    <g transform={dielineTransform}>
                        {faces}
                        {edges}
                        {vertices}
                    </g>
                </g>
                <circle className={className} r={radius} />
            </g>
        );
    }
}


interface ProblemVertexVisualizerProps {
    vertex: CreaseVertex;
    scale: number;
    alertLevel: AlertLevel;
}

class ProblemVertexVisualizer extends Component<ProblemVertexVisualizerProps> {
    render() {
        const className = classNames('ProblemVertexVisualizer',
            `ProblemVertexVisualizer--${this.props.alertLevel}`);
        const p = this.props.vertex.position;
        const radius = PROBLEM_VERTEX_RADIUS / this.props.scale;
        return <circle className={className} cx={p[0]} cy={p[1]} r={radius} />
    }
}