import FieldLabel from '@react/react-spectrum/FieldLabel';
import LockClosed from '@react/react-spectrum/Icon/LockClosed';
import LockOpen from '@react/react-spectrum/Icon/LockOpen';
// @ts-ignore
import { trapFocus } from '@react/react-spectrum/utils/FocusManager';
import { boundMethod } from 'autobind-decorator';
import { OptionalNumber } from 'crease';
import React, { Component } from 'react';
import { MAX_ANGLE_DECIMAL_PLACES } from '../common/constants';
import { NumberInput } from '../components/NumberInput';
import { TooltipButton } from '../components/TooltipButton';
import '../css/FoldAnglePopover.css';

export interface FoldAnglePopoverProps {
    left: number;
    top: number;
    foldAngle: OptionalNumber;
    onChange: (foldAngle: OptionalNumber) => void;
    onDismiss: () => void;
}

export class FoldAnglePopover extends Component<FoldAnglePopoverProps> {
    render() {
        const popoverStyle = {
            left: this.props.left,
            top: this.props.top,
        };
        const isUnconstrained = this.props.foldAngle === undefined;
        return (
            <div
                className="FoldAnglePopover"
                style={popoverStyle}
                onFocus={this._onFocus}
                onKeyDown={this._onKeyDown}>
                <FieldLabel label="Fold Angle" labelFor="FoldAnglePopover__numberInput">
                    <NumberInput
                        id="FoldAnglePopover__numberInput"
                        value={this.props.foldAngle}
                        decimalPlaces={MAX_ANGLE_DECIMAL_PLACES}
                        suffix="°"
                        min={-180}
                        max={180}
                        placeholder="unconstrained"
                        onChange={this.props.onChange}
                        onCancel={this.props.onDismiss}
                        onEnter={this.props.onDismiss}
                        style={{ width: 110 }}
                        autoFocus
                    />
                    <TooltipButton
                        variant="tool"
                        icon={isUnconstrained ? <LockOpen /> : <LockClosed />}
                        tooltip="Remove constraint"
                        disabled={isUnconstrained}
                        onClick={this._removeConstraint}
                    />
                </FieldLabel>
            </div>
        );
    }

    @boundMethod
    private _onFocus(e: React.FocusEvent) {
        trapFocus(this, e);
    }

    @boundMethod
    private _onKeyDown(e: React.KeyboardEvent) {
        trapFocus(this, e);
        if (e.key === 'Escape') {
            e.stopPropagation();
            e.preventDefault();
            this.props.onDismiss();
        }
    }

    @boundMethod
    private _removeConstraint() {
        this.props.onChange(undefined);
        this.props.onDismiss();
    }
}
