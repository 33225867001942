import { boundMethod } from 'autobind-decorator';
import { IReactionDisposer, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { TooltipButton } from '../components/TooltipButton';
import '../css/Minimap.css';
import { Analytics, EventName } from '../services/Analytics';
import { StoreComponent } from './StoreComponent';
import { TooltipContent } from '../components/TooltipContent';

@inject('store')
@observer
export class Minimap extends StoreComponent {
    private disposers: IReactionDisposer[] = [];

    @observable
    private snapshot3DDataUrl?: string;
    
    componentDidMount() {
        const { render, ui } = this.props.store;
        this.disposers = [
            reaction(() => ui.showFlat, (showFlat) => {
                if (showFlat) {
                    this.snapshot3DDataUrl = render.renderSnapshot(false);
                }
            })
        ];
    }

    componentWillUnmount() {
        this.disposers.forEach(disposer => disposer());
        this.disposers = [];
    }

    @boundMethod
    private toggleFlatView() {
        this.props.store.setShowFlat(!this.props.store.ui.showFlat);
        Analytics.event(this.props.store.ui.showFlat ? EventName.ViewFlatShow : EventName.ViewFlatHide);
    }

    render() {
        const { snapshot2DDataUrl } = this.props.store.render;
        const { showFlat } = this.props.store.ui;
        return (
            <TooltipButton variant="action" quiet className="Minimap"
                onClick={this.toggleFlatView} placement="right"
                tooltip={<TooltipContent heading="Toggle between dieline view and 3D view"
                    shortcut="d" />}>
                <img className="Minimap__image" alt=""
                    src={showFlat ? this.snapshot3DDataUrl : snapshot2DDataUrl} />
                <div>
                    {showFlat ? "3D View" : "Dieline View"}
                </div>
                <KeyboardShortcut keys="d" onPressed={this.toggleFlatView} />
            </TooltipButton>
        );
    }
}
