// Length conversion factors:
export const MILLIMETERS_PER_INCH = 25.4; // An inch is exactly 25.4 millimeters.
export const CENTIMETERS_PER_INCH = 2.54; // An inch is exactly 2.54 centimeters.
export const CENTIMETERS_PER_METER = 100; // A meter is exactly 100 centimeters.
export const POINTS_PER_INCH = 72; // An inch is exactly 72 points.
export const MILLIMETERS_PER_POINT = MILLIMETERS_PER_INCH / POINTS_PER_INCH;
export const CENTIMETERS_PER_POINT = CENTIMETERS_PER_INCH / POINTS_PER_INCH;
export const METERS_PER_POINT = CENTIMETERS_PER_POINT / CENTIMETERS_PER_METER;

// Triangulation:
export const TRIANGULATION_MINIMUM_ANGLE = 10; // Accept 10 degrees, rather than default 20 degrees.

// Angle display:
export const MAX_ANGLE_DECIMAL_PLACES = 2;

export const EDGE_HOVER_THICKNESS = 6; // pixels
export const SHADOW_INTENSITY = 0.5; // Between 0 and 1.
export const HEADER_HEIGHT = 50;