/**
 * @author alteredq / http://alteredqualia.com/
 *
 * Full-screen textured quad shader
 */
import * as THREE from 'three';



const CopyShader = {

	uniforms: {

		"tDiffuse": { value: null },
		"opacity": { value: 1.0 }

	},

	vertexShader: [

		"varying vec2 vUv;",

		"void main() {",

		"	vUv = uv;",
		"	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

		"}"

	].join( "\n" ),

	fragmentShader:
		`
			uniform float opacity;

			uniform sampler2D tDiffuse;

			varying vec2 vUv;

			void main() {

				vec4 texel = texture2D( tDiffuse, vUv );
				if (texel.a == 0.0) {
					discard;
				}
				gl_FragColor = opacity * texel;

			}

		`,
};

const HeatMapShader = {

	uniforms: {
		baseColor: { value: [1, 0, 0] },
	},

	vertexShader:
    `
		precision highp float;
		precision highp int;

		uniform mat4 modelViewMatrix; // optional
		uniform mat4 projectionMatrix; // optional

		attribute vec3 position;
		attribute float alpha;

		varying float vAlpha;

		void main()	{
			vAlpha = alpha;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
    `,

	fragmentShader:
	`
		precision highp float;
		precision highp int;

		uniform vec3 baseColor;

		varying float vAlpha;

		void main()	{
			gl_FragColor = vec4(baseColor, vAlpha );
		}
    `,
}

const MaskShader = {
	uniforms: {
		faceIndex: { value: 0 },
	},

	vertexShader:
    `
		precision highp float;
		precision highp int;

		uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;

		attribute vec3 position;
		attribute float alpha;

		void main()	{
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}
    `,

	fragmentShader:
    `
		precision highp float;
		precision highp int;

		uniform int faceIndex;

		void main()	{
			gl_FragColor.r = float(faceIndex + 1)/255.0;
		}
    `,
}

const WireframeShader = {

	uniforms: {
		baseColor: { value: [0, 0, 0] },
		thickness: { value: [1, 1, 1] },
	},

	vertexShader: `
		precision highp float;
		precision highp int;
		// uniforms (all provided by default by three.js)
		uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;
		
		// default attributes (from geometry)
		attribute vec3 position;
	
		// instance attributes
		attribute vec3 iOffset;
		attribute vec4 iRotation;
		attribute vec3 iScale;
		attribute float iVisible;// GLSL attribute cannot be int, so we use float.
		uniform vec3 thickness;

		// Shading parameters.
		varying float vVisible;
	
		// apply a rotation-quaternion to the given vector 
		// (source: https://goo.gl/Cq3FU0)
		vec3 rotate(const vec3 v, const vec4 q, const vec3 scale, const vec3 thickness) {
			vec3 scaledVec = vec3(v.x*scale.x*thickness.x, v.y*scale.y*thickness.y, v.z*scale.z*thickness.z);
			vec3 t = 2.0 * cross(q.xyz, scaledVec);
			return scaledVec + q.w * t + cross(q.xyz, t);
		}
	
		void main() {
			vVisible = iVisible;
			// instance-transform, mesh-transform and projection
			gl_Position = projectionMatrix * modelViewMatrix * 
				vec4(iOffset + rotate(position, iRotation, iScale, thickness), 1.0);
			}
	`,

	fragmentShader: `
		precision highp float;
		uniform vec3 baseColor;
		varying float vVisible;
	
		void main() {
			if (vVisible == 0.0) {
				discard;
			}
			gl_FragColor = vec4(baseColor, 1.0);
		}
	`,
}

const WireframeShaderOccluded = {

	uniforms: {
		baseColor: { value: [0, 0, 0] },
		tMask: { value: null as null | THREE.Texture },
		tPanelOrder: {value: null as null | THREE.Texture },
		screenSize: { value: [0, 0] },
		numRows: { value: 0 },
		thickness: { value: [1, 1, 1] },
	},

	vertexShader: `
		precision highp float;
		precision highp int;
		// uniforms (all provided by default by three.js)
		uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;
		
		// default attributes (from geometry)
		attribute vec3 position;
	
		// instance attributes
		attribute vec3 iOffset;
		attribute vec4 iRotation;
		attribute vec3 iScale;
		attribute float iRightFace;// GLSL attribute cannot be int, so we use float.
		attribute float iLeftFace;// GLSL attribute cannot be int, so we use float.
		attribute float iVisible;// GLSL attribute cannot be int, so we use float.
		uniform vec3 thickness;

		// Shading parameters.
		varying float vRightFace;// optional
		varying float vLeftFace;// optional
		varying float vVisible;
	
		// apply a rotation-quaternion to the given vector 
		// (source: https://goo.gl/Cq3FU0)
		vec3 rotate(const vec3 v, const vec4 q, const vec3 scale, const vec3 thickness) {
			vec3 scaledVec = vec3(v.x*scale.x*thickness.x, v.y*scale.y*thickness.y, v.z*scale.z*thickness.z);
			vec3 t = 2.0 * cross(q.xyz, scaledVec);
			return scaledVec + q.w * t + cross(q.xyz, t);
		}
	
		void main() {
			vVisible = iVisible;
			vRightFace = iRightFace;
			vLeftFace = iLeftFace;
			// instance-transform, mesh-transform and projection
			gl_Position = projectionMatrix * modelViewMatrix * 
				vec4(iOffset + rotate(position, iRotation, iScale, thickness), 1.0);
			}
	`,

	fragmentShader:
    `
		precision highp float;
		precision highp int;

		uniform sampler2D tMask;
		uniform sampler2D tPanelOrder;
		uniform vec2 screenSize;
		uniform float numRows;

		uniform mat4 modelViewMatrix;
		uniform mat4 projectionMatrix;

		uniform vec3 baseColor;

		varying float vRightFace;
		varying float vLeftFace;
		varying float vVisible;

		bool testOcclusion(int faceIndex, int faceIndexPlusOne, float faceOrientation) {
			// No adjacent face.
			if (faceIndex < 0) {
				return false;
			}
			// Over background or over adjacent face.
			if (faceIndexPlusOne == 0 || faceIndexPlusOne == faceIndex + 1) {
				return false;
			}

			int offset = 1;
			// if (faceOrientation < 0.0) {
			// 	offset = 1;
			// }
			
			// Get number of occlusing panels.
			float faceLookup = float(2 * faceIndex + offset) / numRows;
			int numOccludingPanels = int(texture2D(tPanelOrder, vec2(0, faceLookup)).r * 255.0);
			if (numOccludingPanels == 0) {
				return false;
			}

			// Test against occluding panels.
			for (int i = 1; i < 100; i++) {
				if (i > numOccludingPanels) {
					return false;
				}
				int occludingPanel = int(texture2D(tPanelOrder, vec2(float(i) / 100.0, faceLookup)).r * 255.0);
				if (faceIndexPlusOne == occludingPanel + 1) {
					return true;
				}
			}
			return false;
		}

		void main()	{
			if (vVisible == 0.0) {
				discard;
			}

			vec2 texcoord = gl_FragCoord.xy / screenSize;
			int faceIndexPlusOne = int(texture2D(tMask, texcoord).r * 255.0);

			float faceOrientation = (projectionMatrix * modelViewMatrix * vec4( 0, 0, 1.0, 1.0 )).z;

			int leftFaceIndex = int(vLeftFace);
			int rightFaceIndex = int(vRightFace);

			if ((leftFaceIndex < 0 || testOcclusion(leftFaceIndex, faceIndexPlusOne, faceOrientation)) && 
				(rightFaceIndex < 0 || testOcclusion(rightFaceIndex, faceIndexPlusOne, faceOrientation))) {
					discard;
			}

			gl_FragColor = vec4(baseColor, 1.0);
		}
    `,
}

export { HeatMapShader, WireframeShader, WireframeShaderOccluded, MaskShader, CopyShader };