import { AnalyticsData, AnalyticsProvider } from './AnalyticsProvider';

/**
 * Types for New Relic's service.
 */
declare global {
    interface Window {
        initializeNewRelic: () => void;
        newrelic: {
            addRelease: (appName: string, appVersion: string) => void;
            addPageAction: (name: string, data: AnalyticsData) => void;
            noticeError: (error: any) => void;
        }
    }
}

/**
 * An implementation of the AnalyticsProvider interface that reports to New Relic.
 */
export class NewRelicAnalyticsProvider implements AnalyticsProvider {
    private context: AnalyticsData = {};

    initialize(appName: string, appVersion: string): void {
        window.newrelic.addRelease(appName, appVersion);
    }

    setContext(context: AnalyticsData): void {
        this.context = context;
    }

    event(name: string, data: AnalyticsData): void {
        // Combine data and context.
        const dataWithContext = Object.assign({}, this.context, data);
        window.newrelic.addPageAction(name, dataWithContext);
    }
}
