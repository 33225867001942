import { Bounds2 } from 'crease';
import { action, computed, IObservableArray, observable } from 'mobx';
import { Substrate } from '../substrates/Substrate';

interface ArtworkInfo {
    texture: THREE.Texture | null;
    url: string | null;
    hidden: boolean;
}

export enum Side {
    Exterior = 0,
    Interior = 1,
}

export class InfoStore {
    private _creaseBounds: Bounds2 = {
        min: [0, 0],
        max: [1, 1],
    };

    @observable
    private _filename: string | null = null;

    @observable
    private _dieline: SVGSVGElement | null = null;

    @observable
    private _substrate: Substrate | null = null;

    @observable
    private _artwork: IObservableArray<ArtworkInfo> = observable([
        { texture: null, url: null, hidden: false },
        { texture: null, url: null, hidden: false },
    ]);

    @computed
    get filename(): string | null {
        return this._filename;
    }

    @computed
    get dieline(): SVGSVGElement | null {
        return this._dieline;
    }

    @computed
    get hasNoDieline(): boolean {
        return this._filename === null;
    }

    @computed
    get substrate(): Substrate | null {
        return this._substrate;
    }

    set substrate(substrate: Substrate | null) {
        if (this._substrate) {
            this._substrate.dispose();
        }
        this._substrate = substrate;
    }

    /** True if the substrate is rotated 90 degrees (for horizontal fluting). */
    @observable
    isSubstrateHorizontal: boolean = false;
    
    getArtwork(side: Side): ArtworkInfo {
        return this._artwork[side];
    }

    @action
    loadCrease(filename: string, creaseBounds: Bounds2, dieline?: SVGSVGElement) {
        this._filename = filename;
        this._creaseBounds = creaseBounds;
        this._dieline = dieline ? dieline : null;
        // Clear substrate and artwork.
        this._substrate = null;
        this.disposeTexture(Side.Exterior);
        this.disposeTexture(Side.Interior);
        this._artwork = observable([
            { texture: null, url: null, hidden: false },
            { texture: null, url: null, hidden: false },
        ]);
    }

    @action
    loadArtwork(url: string, texture: THREE.Texture, side: Side) {
        // Dispose of previous texture.
        this.disposeTexture(side);

        // Modify new texture.
        const { min, max } = this._creaseBounds;
        const viewBox = (this._dieline as SVGSVGElement).viewBox.baseVal;
        texture.offset.set(min[0] / viewBox.width, min[1] / viewBox.height);
        texture.repeat.set((max[0] - min[0]) / viewBox.width, (max[1] - min[1]) / viewBox.height);
        if (side === Side.Interior) {
            this.mirrorTexture(texture);
        }
        texture.anisotropy = 16;
        texture.flipY = false;

        // Modify the store.
        this._artwork[side] = { texture, url, hidden: false };
    }

    @action
    hideArtwork(side: Side) {
        this._artwork[side].hidden = true;
    }

    @action
    showArtwork(side: Side) {
        this._artwork[side].hidden = false;
    }

    @action
    deleteArtwork(side: Side) {
        this.disposeTexture(side);
        this._artwork[side] = { texture: null, url: null, hidden: false };
    }

    @action.bound
    swapArtwork() {
        // Get the existing artwork.
        const exteriorArtwork = this._artwork[Side.Exterior];
        const interiorArtwork = this._artwork[Side.Interior];

        // Mirror the textures.
        this.mirrorTexture(exteriorArtwork.texture);
        this.mirrorTexture(interiorArtwork.texture);

        // Swap the sides.
        this._artwork.replace([interiorArtwork, exteriorArtwork]);
    }

    private disposeTexture(side: Side) {
        const texture = this._artwork[side].texture;
        if (texture) {
            texture.dispose();
        }
    }

    private mirrorTexture(texture: THREE.Texture | null) {
        if (texture) {
            texture.offset.x = 1 - texture.offset.x;
            texture.repeat.x = -texture.repeat.x;
        }
    }
}
