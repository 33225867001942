import * as THREE from 'three';

interface ColorBinding {
    object: object;
    propertyName: string;
    colorVariableName: string;
}

export class ColorBinder {
    private colorBindings: ColorBinding[] = [];

    bind(object: object, propertyName: string, colorVariableName: string) {
        const binding = { object, propertyName, colorVariableName };
        this.colorBindings.push(binding);
        this.applyBinding(binding);
    }

    unbind(object: object, propertyName: string) {
        this.colorBindings = this.colorBindings.filter(binding =>
            binding.object !== object || binding.propertyName !== propertyName);
    }

    update() {
        this.colorBindings.forEach(binding => this.applyBinding(binding));
    }

    private applyBinding(binding: ColorBinding) {
        const elements = document.getElementsByClassName('react-spectrum-provider');
        if (elements.length > 0) {
            const provider = elements[0];
            const style = window.getComputedStyle(provider);
            const color = style.getPropertyValue(binding.colorVariableName).trim();
            (binding.object as any)[binding.propertyName] = new THREE.Color(color);
        }
    }
}
