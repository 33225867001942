import { boundMethod } from 'autobind-decorator';
import { TriangleMesh, vec } from 'crease';
import { inject, observer } from 'mobx-react';
import * as THREE from 'three';
import { HeatMapShader } from '../shaders/Shaders';
import { MeshSurface } from '../view3D/MeshSurface';
import { Tool } from './Tool';

const heatMapMaterial = new THREE.RawShaderMaterial( {
    uniforms: HeatMapShader.uniforms,
    vertexShader: HeatMapShader.vertexShader,
    fragmentShader: HeatMapShader.fragmentShader,
    side: THREE.DoubleSide,
    transparent: true,
    fog: false,
    polygonOffset: true,
    polygonOffsetFactor: -3,
    polygonOffsetUnits: 1,
    depthWrite: false,
    depthTest: false,
});

@inject('store')
@observer
export class HeatMapTool extends Tool {
    private heatMap = new MeshSurface(heatMapMaterial);
    private heatMapAlphaAttribute!: THREE.BufferAttribute;
    private timer: number | null = null;
    private heatMapOpacity = 1; // Overall opacity of heat map visualization (for fade ins)

    toolOnActivate() {
        const { render } = this.props.store;
        render.addObject3Ds([
            this.heatMap.getObject3D(),
        ], render.highlighterScene);

        // Watch for changes to the triangle mesh.
        this.addAutorun(() => {
            this.setTriangleMesh(this.props.store.geometry.triangleMesh);
        });

        // Watch for changes to the crease JSON.
        this.addAutorun(() => {
            const constrainedEdges: number[] = [];
            const creaseJSON = this.props.store.geometry.creaseJSON;
            creaseJSON.edges.forEach((edge, edgeIndex) => {
                if (edge.foldAngle !== undefined) {
                    constrainedEdges.push(edgeIndex);
                }
            });
            // Init/reset a timer to show heat map.
            if (this.timer !== null) {
                window.clearTimeout(this.timer);
            }
            this.timer = window.setTimeout(this.showHeatMap, 1500);
            render.thinMesh.updateActiveConstraintVisibility(constrainedEdges);
        });
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.heatMap.getObject3D(),
        ], render.highlighterScene);

        if (this.timer !== null) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }

        this.heatMap.dispose();
    }

    private setTriangleMesh(triangleMesh: TriangleMesh) {
        this.heatMap.updateGeometry();
        // Heat map has a custom alpha attribute.
        this.heatMapAlphaAttribute = new THREE.BufferAttribute(new Float32Array(triangleMesh.vertices.length), 1);
        this.heatMap.setAlphaAttribute(this.heatMapAlphaAttribute);
    }

    @boundMethod
    protected updatePositions() {
        // Set each vertex of pointcloud to lie in center of crease.
        const { triangleMesh } = this.props.store.geometry;

        const alphaArray = this.heatMapAlphaAttribute.array as Float32Array;

        const heatMapVisiblity = !this.props.store.ui.foldAngleToolDragState && this.timer === null;
        this.heatMap.setVisibility(heatMapVisiblity);

        if (heatMapVisiblity) {
            const { render } = this.props.store;
            const targetDihedrals = render.faceOffsetter.dihedrals(render.solver.targetDihedrals);
            // Get the folded positions of the mesh for setting heat map colors.
            // Passing in no argument to positions() and dihedrals() could give us the flat state.
            const _positions = render.faceOffsetter.positions(render.solver.positions);
            const _dihedrals = render.faceOffsetter.dihedrals(render.solver.dihedrals);
            // Update heat map colors.
            const numEdgesPerVertex = triangleMesh.vertices.map(() => 0);
            const vertexStrain = triangleMesh.vertices.map(() => 0);
            triangleMesh.edges.forEach((edge, edgeIndex) => {
                const { targetLength, edgeVertex1, edgeVertex2 } = edge;
                const position1 = _positions[edgeVertex1];
                const position2 = _positions[edgeVertex2];
                const axialStrain = Math.abs(targetLength - vec.length(vec.subtract(position1, position2))) / targetLength;
                let angleStrain = 0;
                const targetDihedral = targetDihedrals[edgeIndex];
                const dihedral = _dihedrals[edgeIndex];
                if (targetDihedral !== undefined && dihedral !== undefined) {
                    // TODO: we can play with this scale factor.
                    angleStrain = 20 * Math.abs(targetDihedral - dihedral) * targetLength / (2 * Math.PI);
                }
                vertexStrain[edgeVertex1] += axialStrain + angleStrain;
                vertexStrain[edgeVertex2] += axialStrain + angleStrain;
                numEdgesPerVertex[edgeVertex1]++;
                numEdgesPerVertex[edgeVertex2]++;
            });
            vertexStrain.forEach((strain, index) => vertexStrain[index] = strain / numEdgesPerVertex[index]);

            const clipVal = 0.1;
            for (let i = 0; i < alphaArray.length; i++) {
                let strain = vertexStrain[i];
                if (strain > clipVal) {
                    strain = clipVal;
                }
                let alpha = strain / clipVal;
                if (alpha > 0.75) alpha = 0.75;
                alphaArray[i] = alpha * this.heatMapOpacity;
            }
            // Increment heat map overall opacity.
            if (this.heatMapOpacity < 1) {
                this.heatMapOpacity += .02;
            }

            // Update attribute
            this.heatMapAlphaAttribute.needsUpdate = true;
        }
    }

    @boundMethod
    private showHeatMap() {
        this.timer = null;
        // Fade in heat map in animation loop (updatePositions()).
        this.heatMapOpacity = 0;
    }
}
