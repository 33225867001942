import { Crease } from 'crease';
import * as THREE from 'three';

export class utils {
    static getFilenameFromFullPath(fullPath: string) {
        return (fullPath.split('/')).pop() as string;
    }

    static getExtension(fullPath: string) {
        const parts = utils.getFilenameFromFullPath(fullPath).split('.');
        if (parts.length < 2) {
            return null;
        }
        return (parts.pop() as string).toLowerCase();
    }

    static getFilenameNoExtension(fullPath: string) {
        const filename = utils.getFilenameFromFullPath(fullPath);
        const parts = filename.split('.');
        if (parts.length > 1) {
            parts.pop();
        }
        return parts.join('.');
    }

    static convertToDegrees(rad: number) {
        return (rad * 180) / Math.PI;
    }

    static convertToRadians(deg: number) {
        return (deg * Math.PI) / 180;
    }

    static arraysHaveSameElements(array1: any[], array2: any[]) {
        if (array1.length !== array2.length) {
            return false;
        }
        for (let i = 0; i< array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }
        return true;
    }

    static linesCrossIn2D(v1: THREE.Vector2, v2: THREE.Vector2, v3: THREE.Vector2, v4: THREE.Vector2) {
        const det = (v2.x - v1.x) * (v4.y - v3.y) - (v4.x - v3.x) * (v2.y - v1.y);
        if (det === 0) {
            return false;
        }
        const lambda = ((v4.y - v3.y) * (v4.x - v1.x) + (v3.x - v4.x) * (v4.y - v1.y)) / det;
        if (lambda < 0 || lambda > 1) {
            return false;
        }
        const gamma = ((v1.y - v2.y) * (v4.x - v1.x) + (v2.x - v1.x) * (v4.y - v1.y)) / det;
        return !(gamma < 0 || gamma > 1)
    }

    static getChainedEdges(edgeIndex: number, crease: Crease) {
        // Find all collinear "chained" edges that share same faces and select those too.
        const { leftFace, rightFace, vertex1, vertex2 } = crease.edges[edgeIndex];
        let chainedEdges: number[] = [edgeIndex];
        crease.edges.forEach(edge => {
            if (edge.index === edgeIndex) {
                return;
            }
            if ((edge.leftFace === leftFace && edge.rightFace === rightFace) ||
                (edge.rightFace === leftFace && edge.leftFace === rightFace)) {
                    chainedEdges.push(edge.index);
                }
        });
        // Test collinearity in flat state with selected edge.
        const COLLINEAR_TOL = 0.99;
        const selectedEdgeVec = (new THREE.Vector2(vertex1.position[0], vertex1.position[1]))
            .sub(new THREE.Vector2(vertex2.position[0], vertex2.position[1])).normalize();
        chainedEdges = chainedEdges.filter(edgeIndex => {
            const { vertex1, vertex2 } = crease.edges[edgeIndex];
            const edgeVec = (new THREE.Vector2(vertex1.position[0], vertex1.position[1]))
                .sub(new THREE.Vector2(vertex2.position[0], vertex2.position[1])).normalize();
            return Math.abs(edgeVec.dot(selectedEdgeVec)) >= COLLINEAR_TOL;
        });

        // Find center-most of chained edges and put it on end of array.
        // This causes the fold ange widget to show up centered on this edge.
        // We may want a better longer-term solution for positioning the fold angle widget
        // when multiple edges are selected, but I think this is ok for now.
        const allEdgePoints: THREE.Vector3[] = [];
        chainedEdges.forEach(edgeIndex => {
            const { vertex1, vertex2 } = crease.edges[edgeIndex];
            allEdgePoints.push(
                new THREE.Vector3(vertex1.position[0], vertex1.position[1], 0),
                new THREE.Vector3(vertex2.position[0], vertex2.position[1], 0),
            );
        });
        const sphere = (new THREE.Sphere()).setFromPoints(allEdgePoints);
        let centerMostEdge = chainedEdges[0];
        let bestDiffSq = Infinity;
        chainedEdges.forEach(edgeIndex => {
            const { vertex1, vertex2 } = crease.edges[edgeIndex];
            const v1 = new THREE.Vector3(vertex1.position[0], vertex1.position[1], 0);
            const v2 = new THREE.Vector3(vertex2.position[0], vertex2.position[1], 0);
            const edgeCenter = v1.add(v2).divideScalar(2);
            const diffSq = edgeCenter.sub(sphere.center).lengthSq();
            if (diffSq < bestDiffSq) {
                bestDiffSq = diffSq;
                centerMostEdge = edgeIndex;
            }
        });
        chainedEdges.splice(chainedEdges.indexOf(centerMostEdge), 1);
        chainedEdges.push(centerMostEdge);
        return chainedEdges;
    }

    static getIntersectionOfThreePlanes(plane1: THREE.Plane, plane2: THREE.Plane, plane3: THREE.Plane) {
        // Assumes the three planes intersect in a single point (i.e., none of the planes are
        // parallel or coplanar).
        const m = new THREE.Matrix3().set(
            plane1.normal.x, plane1.normal.y, plane1.normal.z,
            plane2.normal.x, plane2.normal.y, plane2.normal.z,
            plane3.normal.x, plane3.normal.y, plane3.normal.z
        );
        const mInverse = new THREE.Matrix3().getInverse(m);
        const rhs = new THREE.Vector3(-plane1.constant, -plane2.constant, -plane3.constant);
        return rhs.applyMatrix3(mInverse);
    }
}