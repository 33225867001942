import { action, computed, observable } from 'mobx';
import { AppMode } from '../common/AppMode';
import { ToolId } from '../common/ToolId';

export class UIStore {
    @observable
    private _appMode: AppMode = AppMode.Home;

    @observable
    private _activeToolId: ToolId = ToolId.FoldAngleTool;

    private _lastActiveToolId: ToolId = ToolId.FoldAngleTool;

    /** The application mode. */
    @computed
    get appMode(): AppMode {
        return this._appMode;
    }

    set appMode(appMode: AppMode) {
        this._appMode = appMode;
        if (appMode === AppMode.Preview) {
            // Ground face selection tool is transient, so don't use it as last active tool.
            if (this._activeToolId !== ToolId.GroundFaceSelectionTool) {
                this._lastActiveToolId = this._activeToolId;
            }
            this._activeToolId = ToolId.OrbitTool;
        } else {
            this._activeToolId = this._lastActiveToolId;
        }
    }

    /** The active tool. */
    @computed
    get activeToolId(): ToolId {
        return this._activeToolId;
    }

    set activeToolId(activeToolId: ToolId) {
        // Ground face selection tool is transient, so don't use it as last active tool.
        if (this._activeToolId !== activeToolId &&
            this._activeToolId !== ToolId.GroundFaceSelectionTool) {
            this._lastActiveToolId = this._activeToolId;
        }
        this._activeToolId = activeToolId;
    }

    /** Whether the fold angle tool is being dragged by the user. */
    @observable
    foldAngleToolDragState: boolean = false;

    /** Revert to the previous active tool. */
    @action.bound
    resumePreviousTool() {
        this._activeToolId = this._lastActiveToolId;
    }

    /** Show in dieline mode. */
    @observable
    private _showFlat: boolean = false;

    @computed
    get showFlat(): boolean {
        return this._showFlat;
    }

    set showFlat(state: boolean) {
        this._showFlat = state;
    }

    // ===== Animation =====

    /** Show animation keyframes. */
    @observable
    showKeyframes: boolean = false;

    /** Transition duration for animations. */
    @observable
    transitionDuration: number = 0.6;

    /** Hold duration for animations. */
    @observable
    holdDuration: number = 0.4;

    @observable
    animationProgress: number = 0;
}
