import { CreaseJSON, Vector2, CreaseVertex } from 'crease';

export type ImportDielineUIMessage = ImportDielineStartMessage | ImportDielineCancelMessage;

export type ImportDielineStartMessage = {
    type: 'start';
    svg: string;
};

export type ImportDielineCancelMessage = {
    type: 'cancel';
};

export type ImportDielineWorkerMessage = ImportDielineProgressMessage | ImportDielineDoneMessage;

export type ImportDielineProgressMessage = {
    type: 'progress';
    percentProgress: number;
};

export type ImportDielineDoneMessage = {
    type: 'done';
    parseError: boolean;
    creaseJSONs: CreaseJSON[];
    problemVertices: ProblemVertex[][];
};

export type ProblemKind = 't-junction' | 'gap' | 'kink';
export type AlertLevel = 'error' | 'warning';

export type ProblemVertex = {
    alertLevel: AlertLevel,
    kind: ProblemKind;
    index: number;
};

export type ProblemFace = {
    faceIndex: number;
    description: string;
};

export type ProblemArea = {
    center: Vector2;
    problemVertices: ProblemVertex[];
    vertices: CreaseVertex[];
}

export type ProblemMessage = {
    kind: ProblemKind;
    alertLevel: AlertLevel;
    title: string;
    description: string;
};

export const problemMessages: ProblemMessage[] = [
    {
        kind: 'gap',
        alertLevel: 'error',
        title: 'Crease not connected',
        description: 'Creases should connect to other creases or cut lines.',
    },
    {
        kind: 't-junction',
        alertLevel: 'warning',
        title: 'Non-folding configuration',
        description: 'A crease that ends in the middle of another crease cannot be folded.',
    },
    {
        kind: 'kink',
        alertLevel: 'warning',
        title: 'Crease is kinked',
        description: 'Two creases should not meet at a sharp corner.',
    }
];
