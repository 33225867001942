import Avatar from '@react/react-spectrum/Avatar';
import DropdownButton from '@react/react-spectrum/DropdownButton';
import Link from '@react/react-spectrum/Link';
import { MenuItem } from '@react/react-spectrum/Menu';
import Rule from '@react/react-spectrum/Rule';
import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import '../css/UserProfileMenu.css';
import { UserProfile } from '../services/AccountService';
import { Analytics, EventName } from '../services/Analytics';
import { StoreComponent } from './StoreComponent';

const VIEW_ACCOUNT_URL = 'https://account.adobe.com/';

@inject('store')
@observer
export class UserProfileMenu extends StoreComponent {
    @boundMethod
    private viewAccount() {
        Analytics.event(EventName.AccountView);
        window.open(VIEW_ACCOUNT_URL, '_blank');
    }

    @boundMethod
    private signOut() {
        Analytics.event(EventName.AccountSignOut);
        this.props.store.account.shouldSignOut = true;
    }

    private renderProfile(userProfile: UserProfile) {
        return (
            <Link className="UserProfileMenu__profile" href={VIEW_ACCOUNT_URL}>
                <img className="UserProfileMenu__profilePicture" src={userProfile.pictureUrl} alt="" />
                <div className="UserProfileMenu__profileData">
                    <div className="UserProfileMenu__profileName">
                        {userProfile.displayName}
                    </div>
                    <div className="UserProfileMenu__profileEmail">
                        {userProfile.email}
                    </div>
                    <div className="UserProfileMenu__profileLink">
                        View Account
                    </div>
                </div>
            </Link>
        );
    }

    render() {
        const { userProfile } = this.props.store.account;
        if (!userProfile) {
            return null;
        }

        const icon = <Avatar className="UserProfileMenu__avatar" src={userProfile.pictureUrl} />;
        return (
            <DropdownButton icon={icon}>
                <MenuItem onClick={this.viewAccount}>
                    {this.renderProfile(userProfile)}
                </MenuItem>
                <Rule variant="small" />
                <MenuItem onClick={this.signOut}>
                    Sign Out
                </MenuItem>
            </DropdownButton>
        );
    }
};
