import Button from '@react/react-spectrum/Button';
import Heading from '@react/react-spectrum/Heading';
import React, { Component } from 'react';
import animation from '../assets/examples/food-carton.gif';
import '../css/SignUp.css';
import { Analytics, EventName } from '../services/Analytics';
import { AppHeader } from './AppHeader';
import { ExternalLinks } from './ExternalLinks';
import { Footer } from './Footer';

export class SignUp extends Component {
    componentDidMount() {
        Analytics.event(EventName.ShowSignUp);
    }

    render() {
        return (
            <div className="SignUp">
                <AppHeader minimal={true}/>
                <div className="SignUp__center">
                    <div className="SignUp__card">
                        <div>
                            <Heading variant="pageTitle">
                                Ready to fold?
                            </Heading>
                        </div>
                        <img className="SignUp__image" alt="" src={animation} />
                        <p>
                            Project Fantastic Fold is currently in a private beta.
                        </p>
                        <Button variant="cta" onClick={ExternalLinks.launchPrereleaseSignup}>
                            Join the beta program
                        </Button>
                    </div>
                    <Footer overlay/>
                </div>
            </div>
        );
    }
}
