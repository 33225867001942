import SpectrumProvider from '@react/react-spectrum/Provider';
import Wait from '@react/react-spectrum/Wait';
import { boundMethod } from 'autobind-decorator';
import md5 from 'crypto-js/md5';
import { when } from 'mobx';
import { observer, Provider as MobXProvider } from 'mobx-react';
import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { version } from '../../package.json';
import '../css/App.css';
import { IMSEnvironment, UserProfile } from '../services/AccountService';
import { Analytics, EventName } from '../services/Analytics';
import { IMSAccountService } from '../services/IMSAccountService';
import { MockAccountService } from '../services/MockAccountService';
import { NewRelicAnalyticsProvider } from '../services/NewRelicAnalyticsProvider';
import { Store } from '../store/Store';
import { AppContent } from './AppContent';
import { LearnContent } from './LearnContent';
import { SignUp } from './SignUp';

// This Typekit ID is specifically for Fantastic Fold. Get your own ID for other projects by
// following the instructions here:
// https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=devrel&title=Using+Adobe+Fonts+at+Adobe
const TYPEKIT_ID = 'ajc3hzz';

@observer
export class App extends Component {
    /** The single instance of the MobX store. */
    private store = new Store();

    componentDidMount() {
        // Initialize account service.
        const AccountServiceClass = process.env.REACT_APP_SKIP_SIGN_IN === 'true'
            ? MockAccountService : IMSAccountService;
        const accountService = new AccountServiceClass();
        accountService.initialize('fantastic-fold', IMSEnvironment.PRODUCTION)
            .then((userProfile?: UserProfile) => {
                if (userProfile) {
                    // We hash the user ID before sending it to analytics in order to avoid storing
                    // any personally identifiable information.
                    const hashedUserId = md5(userProfile.userId).toString();
                    Analytics.setContext({ userId: hashedUserId });
                    Analytics.event(EventName.AccountSignedIn);
                    this.store.account.setAccountSignedIn(userProfile);
                } else {
                    Analytics.setContext({});
                    Analytics.event(EventName.AccountSignedOut);
                    this.store.account.setAccountSignedOut();
                }
            });

        // Watch for the signal to sign out.
        when(() => this.store.account.shouldSignOut,
            () => {
                accountService.signOut();
            },
        );

        // Initialize analytics after getting consent to use cookies.
        when(() => this.store.account.hasCookieConsent,
            () => {
                Analytics.setProvider(new NewRelicAnalyticsProvider());
                Analytics.initialize('Fantastic Fold', version);
            },
        );
    }

    @boundMethod
    private noDragAndDrop(e: React.DragEvent<HTMLElement>) {
        // Reject drag and drop anywhere in the app.
        if (e.dataTransfer) {
            e.dataTransfer.effectAllowed = 'none';
        }
        e.preventDefault();
        e.stopPropagation();
    }

    private renderContent() {
        if (this.store.account.isSignedIn) {
            if (this.store.account.userProfile!.featureFlags.includes('prerelease-member')) {
                return (
                    <HashRouter hashType='noslash'>
                        <Switch>
                            <Route path='/learn' component={LearnContent}/>
                            <Route path='/' component={AppContent} exact/>
                            <Redirect to={`${process.env.PUBLIC_URL}/`} />
                        </Switch> 
                    </HashRouter>
                );
            } else {
                return <SignUp />;
            }
        } else {
            return <Wait centered />;
        }
    }

    render() {
        return (
            <MobXProvider store={this.store}>
                <SpectrumProvider className="App" theme={this.store.preferences.theme}
                    typekitId={TYPEKIT_ID}
                    onDragStart={this.noDragAndDrop}
                    onDragEnter={this.noDragAndDrop}
                    onDragOver={this.noDragAndDrop}
                    onDrop={this.noDragAndDrop}>
                    {this.renderContent()}
                </SpectrumProvider>
            </MobXProvider>
        );
    }
}
