import Textfield, { TextfieldProps } from '@react/react-spectrum/Textfield';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

/**
 * Properties of the NumberInput component.
 */
interface NumberInputProps extends Omit<TextfieldProps, 'onChange'> {
    /** The current value to display in the NumberInput component. */
    value?: number;

    /** The minimum acceptable number, or undefined. */
    min?: number;

    /** The maximum acceptable number, or undefined. */
    max?: number;

    /** The amount to increment/decrement by when the user presses up or down arrow keys. */
    step?: number;

    /** The amount to increment/decrement by when the user presses shift+up or shift+down. */
    largeStep?: number;

    /** The number of digits to display after the decimal point, or undefined. */
    decimalPlaces?: number;

    /** A suffix to display after the number, such as a physical unit. */
    suffix?: string;

    /** The event handler to call when the numeric value is changed. */
    onChange?: (value: number | undefined) => void;

    /** The event handler to call when the user presses the Escape key to cancel changes. */
    onCancel?: () => void;

    /** The event handler to call when the user presses the Enter key to commit changes. */
    onEnter?: () => void;
}

/**
 * A text field that displays and accepts a number with an optional suffix for units.
 * 
 * Example:
 * 
 *      <NumberInput
 *          value={this.width}
 *          min={0}
 *          max={100}
 *          decimalPlaces={1}
 *          suffix=" pt"
 *          onChange={this.setWidth} />
 */
@observer
export class NumberInput extends Component<NumberInputProps> {
    static defaultProps: NumberInputProps = {
        step: 1,
        largeStep: 10,
    };

    private _isMounted: boolean = false;

    /** Text content of the NumberInput when focused; undefined when not focused. */
    @observable
    private _userText?: string = undefined;

    /** True if the last entry made by the user was not a valid number. */
    @observable
    private _isInvalid: boolean = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { value, min, max, decimalPlaces, suffix, onChange, ref, ...otherProps } = this.props;
        let text = '';
        if (this._userText !== undefined) {
            // When focused, show whatever the user has typed.
            text = this._userText;
        } else if (value !== undefined) {
            // When not focused, show the current value followed by the optional suffix.
            if (decimalPlaces !== undefined) {
                const factor = Math.pow(10, decimalPlaces);
                text = (Math.round(value * factor) / factor).toString(10);
            } else {
                text = value.toString(10);
            }
            if (suffix !== undefined) {
                text += suffix;
            }
        }
        const inputMode = decimalPlaces === 0 ? 'numeric' : 'decimal';
        return <Textfield
            {...otherProps}
            value={text}
            inputMode={inputMode}
            autoComplete="off"
            spellCheck={false}
            validationState={this._isInvalid ? 'invalid' : undefined}
            onFocus={this._handleFocus}
            onChange={this._handleChange}
            onKeyDown={this._handleKeyDown}
            onBlur={this._handleBlur} />;
    }

    private _getInputElement(): HTMLInputElement | null {
        return this._isMounted ? ReactDOM.findDOMNode(this) as HTMLInputElement | null : null;
    }

    private _selectAll() {
        const inputElement = this._getInputElement();
        if (inputElement) {
            inputElement.select();
        }
    }

    @action.bound
    private _handleFocus() {
        // When we get focus, select all text and revert to the unchanged state.
        // Use a timeout to make sure text gets selected after a click in Safari.
        setTimeout(() => {
            this._selectAll();
            this._revertChange();
        }, 0);
    }

    @action.bound
    private _handleChange(text: string) {
        // Whenever the user edits the text, store it in local state.
        this._userText = text;
    }

    @action.bound
    private _handleKeyDown(e: React.KeyboardEvent) {
        // Revert changes when the user types Escape. Apply changes when the user types Enter.
        let handled = true;
        switch (e.key) {
            case 'Escape':
                this._revertChange();
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
                break;
            case 'Enter':
                this._applyChange(true);
                if (this.props.onEnter) {
                    this.props.onEnter();
                }
                break;
            case 'ArrowUp': {
                const { max, step, largeStep, value, onChange } = this.props;
                const increment = e.shiftKey ? largeStep : step;
                if (increment !== undefined && value !== undefined &&
                    (max === undefined || value < max)) {
                    let newValue = increment * (Math.floor(value / increment + 0.01) + 1);
                    if (max !== undefined) {
                        newValue = Math.min(newValue, max);
                    }
                    this._userText = undefined;
                    if (onChange) {
                        onChange(newValue);
                    }
                }
                break;
            }
            case 'ArrowDown': {
                const { min, step, largeStep, value, onChange } = this.props;
                const increment = e.shiftKey ? largeStep : step;
                if (increment !== undefined && value !== undefined &&
                    (min === undefined || value > min)) {
                    let newValue = increment * (Math.ceil(value / increment - 0.01) - 1);
                    if (min !== undefined) {
                        newValue = Math.max(newValue, min);
                    }
                    this._userText = undefined;
                    if (onChange) {
                        onChange(newValue);
                    }
                }
                break;
            }
            default:
                handled = false;
                break;
        }

        // Select all text and prevent the default behavior if we handled the keypress.
        if (handled) {
            setTimeout(() => {
                this._selectAll();
            }, 0);
            e.preventDefault();
        }
    }

    @action.bound
    private _handleBlur() {
        this._applyChange();
    }

    private _revertChange() {
        this._userText = undefined;
        this._isInvalid = false;
    }

    private _applyChange(forceApply: boolean = false) {
        // If the user has made changes, validate and commit them now.
        let isInvalid = false;
        const inputElement = this._getInputElement();
        const text = (forceApply && inputElement) ? inputElement.value : this._userText;
        if (text !== undefined) {
            // Remove white space.
            let trimmedText = text.trim();

            // Strip off the optional suffix.
            const { suffix, min, max, onChange } = this.props;
            if (suffix && trimmedText.endsWith(suffix)) {
                trimmedText = trimmedText.substring(0, trimmedText.length - suffix.length)
            }

            // If there's nothing left, commit the undefined value.
            if (trimmedText.length === 0) {
                if (onChange) {
                    onChange(undefined);
                }
            } else {
                // See if we can convert what remains into a number.
                try {
                    let value = Number(trimmedText);
                    if (isNaN(value)) {
                        isInvalid = true;
                    } else {
                        // Clamp the value between min and max, if provided.
                        if (min !== undefined) {
                            value = Math.max(value, min);
                        }
                        if (max !== undefined) {
                            value = Math.min(value, max);
                        }

                        // Commit the value.
                        if (onChange) {
                            onChange(value);
                        }
                    }
                } catch (error) {
                    isInvalid = true;
                }
            }
        }

        // Update our local state to contain no text edits, and indicate if the last entry
        // was invalid.
        this._userText = undefined;
        this._isInvalid = isInvalid;
    }
}
