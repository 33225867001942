import { Bounds2, vec } from 'crease';
import * as THREE from 'three';
import { MILLIMETERS_PER_POINT } from '../common/constants';
import { loadTexture } from '../common/TextureLoader';

export type TextureSpec = {
    filePath: string;
    width: number; // in millimeters
    height: number; // in millimeters
};

export type SubstrateSpec = {
    name: string;
    label: string,
    thickness: number; // in millimeters
    side1: TextureSpec;
    side2?: TextureSpec; // if side2 is absent, use side1
    edge: TextureSpec;
};

export class Substrate {
    private _side1Texture?: THREE.Texture;
    private _side2Texture?: THREE.Texture;
    private _edgeTexture?: THREE.Texture;

    constructor(public readonly spec: SubstrateSpec) {
        this.loadAllTextures = this.loadAllTextures.bind(this);
    }

    get name(): string {
        return this.spec.name;
    }

    get label(): string {
        return this.spec.label;
    }

    get thickness(): number {
        return this.spec.thickness;
    }

    getSide1Texture(creaseBounds: Bounds2, rotate90: boolean): THREE.Texture {
        if (!this._side1Texture) {
            throw new Error('Side 1 texture not preloaded.');
        }
        // Crease bounds min and max are specified in points.
        // Substrate spec's width and height are in millimeters.
        // Texture coordinates go from [0, 0] at crease bounds min to [1, 1] at crease bounds max.
        const repeatX = (creaseBounds.max[0] - creaseBounds.min[0]) / this.spec.side1.width *
            MILLIMETERS_PER_POINT;
        const repeatY = (creaseBounds.max[1] - creaseBounds.min[1]) / this.spec.side1.height *
            MILLIMETERS_PER_POINT;
        this._side1Texture.rotation = rotate90 ? Math.PI / 2 : 0;
        this._side1Texture.repeat.set(rotate90 ? repeatY : repeatX, rotate90 ? repeatX : repeatY);
        return this._side1Texture;
    }

    getSide2Texture(creaseBounds: Bounds2, rotate90: boolean): THREE.Texture {
        if (!this._side2Texture) {
            throw new Error('Side 2 texture not preloaded.');
        }
        const specSide = this.spec.side2 ? this.spec.side2 : this.spec.side1;
        const repeatX = (creaseBounds.max[0] - creaseBounds.min[0]) / specSide.width *
            MILLIMETERS_PER_POINT;
        const repeatY = (creaseBounds.max[1] - creaseBounds.min[1]) / specSide.height *
            MILLIMETERS_PER_POINT;
        this._side2Texture.rotation = rotate90 ? Math.PI / 2 : 0;
        this._side2Texture.repeat.set(rotate90 ? repeatY : repeatX, rotate90 ? repeatX : repeatY);
        return this._side2Texture;
    }

    getEdgeTexture(creaseBounds: Bounds2): THREE.Texture {
        if (!this._edgeTexture) {
            throw new Error('Edge texture not preloaded.');
        }
        // The first texture coordinate on an edge mesh uses the same coordinates as the 2D points
        // in a triangle mesh: the original dieline coordinates divided by the max of the crease
        // bounds width and height.
        // The second texture coordinate always goes from 0 to 1 as it spans the thickness of the
        // substrate.
        const size = vec.subtract(creaseBounds.max, creaseBounds.min);
        const scale = Math.max(size[0], size[1]);
        const repeatX = scale / this.spec.edge.width * MILLIMETERS_PER_POINT;
        this._edgeTexture.repeat.set(repeatX, 1);
        return this._edgeTexture;
    }

    // Preload all textures so that we can set it in store with texures loaded.
    async loadAllTextures() {
        if (this._side1Texture && this._side2Texture && this._edgeTexture) {
            return;
        }

        const promises = [
            Substrate.loadTexture(this.spec.side1.filePath),
            Substrate.loadTexture(this.spec.edge.filePath),
        ];
        if (this.spec.side2) {
            promises.push(Substrate.loadTexture(this.spec.side2.filePath));
        }
        const [side1Texture, edgeTexture, side2Texture] = await Promise.all(promises);
        this._side1Texture = side1Texture;
        this._edgeTexture = edgeTexture;
        this._side2Texture = this.spec.side2 ? side2Texture : side1Texture;
    }

    private static async loadTexture(filePath: string) {
        try {
            const texture = await loadTexture(filePath);
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
            texture.anisotropy = 16;
            return texture;
        } catch (error) {
            console.log(`'Error loading texture "${filePath}": ${error.message}`);
        }
        // Return an empty texture in case there is a problem with loader.
        return new THREE.Texture();
    }

    dispose() {
        if (this._side1Texture) {
            this._side1Texture.dispose();
            this._side2Texture = undefined;
        }
        if (this._side2Texture) {
            this._side2Texture.dispose();
            this._side2Texture = undefined;
        }
        if (this._edgeTexture) {
            this._edgeTexture.dispose();
            this._edgeTexture = undefined;
        }
    }
}