import LockOpen from '@react/react-spectrum/Icon/LockOpen';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import { CreaseJSONEdge } from 'crease';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as THREE from 'three';
import { MAX_ANGLE_DECIMAL_PLACES } from '../common/constants';
import { utils } from '../common/utils';
import { Overlay } from '../components/Overlay';
import '../css/EdgeLabelTool.css';
import { StoreComponent } from '../UI/StoreComponent';
import { FoldAnglePopover } from './FoldAnglePopover';
import { Tool } from './Tool';

// TODO:
// - bug: select unconstrained edge, click on label, type angle, click on another label (angle is
//   not set and popover doesn't appear)
// - bug: click on constrained edge label, type angle, click on another constrained edge label
//   (angle gets assigned to second edge but not first)
// - highlight labels on hover only when the fold angle tool is active (maybe hide labels in other tools?)
//    - add a disabled property to EdgeLabel?
// - hide label when it doesn't fit on edge (improve distance threshold)
// - separate overlapping labels

// === EdgeLabelTool ===

@inject('store')
@observer
export class EdgeLabelTool extends Tool {
    @observable
    private renderCounter: number = 0;

    @observable
    private showPopoverOnEdgeIndex: number | undefined = undefined;

    render() {
        // Update the camera so that the first time we render, projected positions are correct.
        const { render } = this.props.store;
        render.controls.update();

        const { creaseJSON } = this.props.store.geometry;
        const edgeLabels = (
            <div>
                {creaseJSON.edges.map((edge, i) =>
                    <EdgeLabel
                        key={i}
                        edgeIndex={i}
                        edge={edge}
                        renderCounter={this.renderCounter}
                        showPopoverOnEdgeIndex={this.showPopoverOnEdgeIndex}
                        onShowPopover={this.onShowPopover} />
                )}
            </div>
        );
        return edgeLabels;
    }

    @boundMethod
    protected cameraControlsChanged() {
        this.triggerRender();
    }

    @boundMethod
    protected updateScale() {
        this.triggerRender();
    }

    private triggerRender() {
        this.renderCounter++;
    }

    @boundMethod
    private onShowPopover(edgeIndex: number | undefined) {
        this.showPopoverOnEdgeIndex = edgeIndex;
    };
}

// === EdgeLabel ===

interface EdgeLabelProps {
    edgeIndex: number;
    edge: CreaseJSONEdge;
    renderCounter: number;
    showPopoverOnEdgeIndex: number | undefined;
    onShowPopover: (edgeIndex: number | undefined) => void;
}

@inject('store')
@observer
class EdgeLabel extends StoreComponent<EdgeLabelProps> {
    private isLeftButtonDown: boolean = false;

    render() {
        // We'll try to label any edge that is selected or has a fold angle.
        const { render } = this.props.store;
        const isSelected = this.isSelected;
        const { foldAngle, assignment, vertex1, vertex2 } = this.props.edge;
        const hasFoldAngle = foldAngle !== undefined;
        if (isSelected || hasFoldAngle) {
            // Get the projected positions of the two edge endpoints.
            const [s1, s2] = [vertex1, vertex2].map(vertex => {
                const v = render.solver.triangleMesh.verticesForwardMapping[vertex][0];
                const pos = new THREE.Vector3().fromArray(render.solver.flatPositions[v]);
                return render.projectPointToContainer(pos);
            });

            // See if the edge endpoints are far enough apart to fit a label between them.
            const d = s1.distanceTo(s2);
            if (d > 50) {
                // If so, position the label at the edge midpoint.
                s1.lerp(s2, 0.5);
                let content: JSX.Element;
                let roundedAngle: number | undefined = undefined;
                if (hasFoldAngle) {
                    const signedAngle = foldAngle! * (assignment === 'V' ? -1 : 1);
                    const factor = Math.pow(10, MAX_ANGLE_DECIMAL_PLACES);
                    roundedAngle = Math.round(signedAngle * factor) / factor;
                    content = <span>{roundedAngle}°</span>;
                } else {
                    content = <LockOpen size='XS' />;
                }
                return (
                    <div
                        className={classNames('EdgeLabel', { 'EdgeLabel--selected': isSelected })}
                        style={{ left: s1.x, top: s1.y }}
                        onContextMenu={this.noContextMenu}
                        onPointerDown={this.onPointerDown}
                        onPointerUp={this.onPointerUp}
                        onClick={this.onClick}>
                        {content}
                        {this.renderPopover(s1, roundedAngle)}
                    </div>
                );
            }
        }
        return null;
    }

    private renderPopover(position: THREE.Vector2, angleInDegrees?: number) {
        const isShowing = this.props.showPopoverOnEdgeIndex === this.props.edgeIndex &&
            this.isSelected;
        return (
            <Overlay
                show={isShowing}
                contentClassName='FoldAnglePopover'
                onClose={this.hidePopover}>
                <FoldAnglePopover
                    left={isShowing ? position.x - 77 : -10000}
                    top={isShowing ? position.y - 43 : -10000}
                    foldAngle={angleInDegrees}
                    onChange={this.setFoldAngle}
                    onDismiss={this.hidePopover}
                />
            </Overlay>
        );
    }

    private get isSelected() {
        return this.props.store.selection.selectedEdges.includes(this.props.edgeIndex);
    }

    @boundMethod
    private hidePopover() {
        this.props.onShowPopover(undefined);
    }

    @boundMethod
    private showPopover() {
        this.props.onShowPopover(this.props.edgeIndex);
    }

    @boundMethod
    private setFoldAngle(angle: number | undefined) {
        this.props.store.geometry.setFoldAngles(angle === undefined ? null : angle,
            this.props.store.selection.selectedEdges);
    }

    @boundMethod
    private noContextMenu(e: React.MouseEvent) {
        e.preventDefault();
    }

    @boundMethod
    private onPointerDown(e: React.PointerEvent) {
        // Prevent left button down events from escaping the label.
        if (e.button === 0) {
            this.isLeftButtonDown = true;
            e.preventDefault();
            e.stopPropagation();
        }
    }

    @boundMethod
    private onPointerUp(e: React.PointerEvent) {
        // Prevent left button up events from escaping the label.
        if (e.button === 0 && this.isLeftButtonDown) {
            this.isLeftButtonDown = false;
            e.preventDefault();
            e.stopPropagation();
        }
    }

    @boundMethod
    private onClick(e: React.MouseEvent) {
        // Update store after a brief timeout -- needed in case we go from showing the fold angle
        // popover on one edge to showing it on another edge.
        const isShiftDown = e.shiftKey;
        setTimeout(() => {
            const { render, selection } = this.props.store;
            const chainedEdges = utils.getChainedEdges(this.props.edgeIndex, render.crease);
            const { selectedEdges } = selection;
            if (!this.isSelected) {
                // If shift is held down, add to previous selection.
                if (isShiftDown) {
                    selection.selectEdges(chainedEdges);
                } else {
                    selection.setSelectedEdges(chainedEdges);
                }
                this.showPopover();
            } else {
                // If shift is held down, remove from previous selection.
                if (isShiftDown) {
                    selection.deselectEdges(chainedEdges);
                    this.hidePopover();
                } else {
                    // If this crease is already selected, then set this edge as the last seleted edge.
                    const nextSelectedEdges = selectedEdges.slice();
                    chainedEdges.forEach(edgeIndex => {
                        const index = nextSelectedEdges.indexOf(edgeIndex);
                        if (index >= 0) {
                            nextSelectedEdges.splice(index, 1);
                        }
                    });
                    nextSelectedEdges.push(...chainedEdges);
                    selection.setSelectedEdges(nextSelectedEdges);
                    this.showPopover();
                }
            }
        }, 0);
        e.preventDefault();
        e.stopPropagation();
    }
}
