// Note: The four images used as paperboard and corrugated textures were made by Eric Stollnitz
// by modifying the following four Adobe Stock images:
// - https://stock.adobe.com/images/cardboard-stack-texture/179054889
// - https://stock.adobe.com/images/seamless-cardboard-texture/40914492
// - https://stock.adobe.com/images/white-cardboard-rough-texture-background/114751121
// - https://stock.adobe.com/images/white-paper-texture-background-or-cardboard-surface-from-a-paper-box-for-packing-and-for-the-designs-decoration-and-nature-background-concept/292485678
// Guillaume Le Bleis acquired Extended Licenses for these images on May 20, 2020.

import { Substrate, SubstrateSpec } from './Substrate';
import paperboardSide1 from '../assets/substrates/paperboard-side1.jpg';
import paperboardSide2 from '../assets/substrates/paperboard-side2.jpg';
import corrugatedFace from '../assets/substrates/corrugated-face.jpg';
import corrugatedEdge from '../assets/substrates/corrugated-edge.jpg';

const PAPERBOARD_320 = 'Paperboard 320 µm';
const PAPERBOARD_425 = 'Paperboard 425 µm';
const PAPERBOARD_520 = 'Paperboard 520 µm';
const PAPERBOARD_620 = 'Paperboard 620 µm';
const CORRUGATED_F = 'Corrugated F';
const CORRUGATED_E = 'Corrugated E';
const CORRUGATED_B = 'Corrugated B';
const CORRUGATED_C = 'Corrugated C';
const CORRUGATED_A = 'Corrugated A';

export type SubstrateName =
    typeof PAPERBOARD_320 |
    typeof PAPERBOARD_425 |
    typeof PAPERBOARD_520 |
    typeof PAPERBOARD_620 |
    typeof CORRUGATED_F |
    typeof CORRUGATED_E |
    typeof CORRUGATED_B |
    typeof CORRUGATED_C |
    typeof CORRUGATED_A;

// Creates a Substrate with the given name using paperboard textures.
function makePaperboardSubstrate(name: SubstrateName, thickness: number): Substrate {
    const substrateSpec: SubstrateSpec = {
        name,
        label: name,
        thickness,
        side1: {
            // The side1 texture is square and looks like it's about 100 mm wide.
            filePath: paperboardSide1,
            width: 100,
            height: 100,
        },
        side2: {
            filePath: paperboardSide2,
            width: 100,
            height: 100,
        },
        edge: {
            // For now, use the same texture for the edge.
            filePath: paperboardSide2,
            width: 100,
            height: 100,
        },
    };
    return new Substrate(substrateSpec);
}

// Creates a Substrate with the given name using corrugated fiberboard textures.
function makeCorrugatedSubstrate(name: SubstrateName, thickness: number, flutesPerMeter: number): Substrate {
    const substrateSpec: SubstrateSpec = {
        name,
        label: `${name} (${thickness.toFixed(1)} mm)`,
        thickness,
        side1: {
            // The side1 texture is square and contains 36 flutes.
            filePath: corrugatedFace,
            width: 36 / flutesPerMeter * 1000,
            height: 36 / flutesPerMeter * 1000,
        },
        edge: {
            // The edge texture contains 18 flutes.
            filePath: corrugatedEdge,
            width: 18 / flutesPerMeter * 1000,
            height: 1,
        },
    };
    return new Substrate(substrateSpec);
}

// Paperboard thicknesses are sampled from http://www.internationalpaper.com/docs/default-source/english/products/europe-middle-east-and-africa/paperboard/emeatdalaskapen.pdf.
// Corrugated fiberboard dimensions come from https://en.wikipedia.org/wiki/Corrugated_fiberboard.
export const allSubstrates: { [key in SubstrateName]: Substrate; } = {
    'Paperboard 320 µm': makePaperboardSubstrate(PAPERBOARD_320, 0.320),
    'Paperboard 425 µm': makePaperboardSubstrate(PAPERBOARD_425, 0.425),
    'Paperboard 520 µm': makePaperboardSubstrate(PAPERBOARD_520, 0.520),
    'Paperboard 620 µm': makePaperboardSubstrate(PAPERBOARD_620, 0.620),
    'Corrugated F': makeCorrugatedSubstrate(CORRUGATED_F, 0.8, 420),
    'Corrugated E': makeCorrugatedSubstrate(CORRUGATED_E, 1.6, 295),
    'Corrugated B': makeCorrugatedSubstrate(CORRUGATED_B, 3.2, 154),
    'Corrugated C': makeCorrugatedSubstrate(CORRUGATED_C, 4.0, 128),
    'Corrugated A': makeCorrugatedSubstrate(CORRUGATED_A, 4.8, 108),
};
