import { action, computed, observable } from 'mobx';
import { SafeLocalStorage } from '../common/SafeLocalStorage';

export enum Theme {
    Dark = 'dark',
    Light = 'light',
}

export class PreferencesStore {
    // Theme. Defaults to light.
    @observable
    private _theme: Theme =
        SafeLocalStorage.getItem('theme') === Theme.Dark ? Theme.Dark : Theme.Light;

    @computed
    get theme(): Theme {
        return this._theme;
    }

    set theme(value: Theme) {
        this._theme = value;
        SafeLocalStorage.setItem('theme', value.toString());
    }

    @action.bound
    toggleTheme() {
        this.theme = this.theme === Theme.Dark ? Theme.Light : Theme.Dark;
    }

    // Automatically center on selected creases. Defaults to true.
    @observable
    private _enableAutoCentering =
        SafeLocalStorage.getItem('enableAutoCentering') === 'false' ? false : true;

    @computed
    get enableAutoCentering(): boolean {
        return this._enableAutoCentering;
    }

    set enableAutoCentering(value: boolean) {
        this._enableAutoCentering = value;
        SafeLocalStorage.setItem('enableAutoCentering', value.toString());
    }
}