import { TriangleMesh } from 'crease';
import * as THREE from 'three';
import { WireframeShader } from '../shaders/Shaders';

// Create a cylinder.
const radius = 1 / 2;
const height = 1;
const radialSegments = 32;
const heightSegments = 1;
const openEnded = true;
const cylinderBufferGeo = new THREE.CylinderBufferGeometry(radius, radius, height,
    radialSegments, heightSegments, openEnded);
cylinderBufferGeo.translate(0, 0.5, 0);

// Create the hemispherical cap.
const phiStart = 0;
const phiLength = 2 * Math.PI;
const topThetaStart = 0;
const thetaLength = Math.PI / 2;
const hemisphereBufferGeo = new THREE.SphereBufferGeometry(radius, radialSegments,
    radialSegments / 4, phiStart, phiLength, topThetaStart, thetaLength);

let cylinderGeometry = new THREE.InstancedBufferGeometry();
let capGeometry = new THREE.InstancedBufferGeometry();
// Lookup for updating positions.
let cylinderGeometryLookup: {edgeVertex1: number, edgeVertex2: number}[] = [];


export class EdgeTubes {
    private group = new THREE.Group();
    private capMaterial: THREE.RawShaderMaterial;
    private cylinderMaterial: THREE.RawShaderMaterial;
    private cylinderGeometry?: THREE.InstancedBufferGeometry;
    private capGeometry?: THREE.InstancedBufferGeometry;
    private static edgesForwardMapping: number[][] = [];

    constructor(options:
        {
            thickness: number,
            color?: THREE.Color,
            depthWrite?: boolean,
            vertexShader?:string,
            fragmentShader?: string,
            uniforms?: {},
        }) {
        let capUniforms = {
            ...options.uniforms,
            baseColor: {
                value: options.color ? options.color.toArray() :
                WireframeShader.uniforms.baseColor.value.slice(),
            },
            thickness: {value: [options.thickness, options.thickness, options.thickness]},
        };
        let cylinderUniforms = {
            ...options.uniforms,
            baseColor: {
                value: options.color ? options.color.toArray() :
                WireframeShader.uniforms.baseColor.value.slice(),
            },
            thickness: {value: [options.thickness, 1, options.thickness]},
        };
        this.capMaterial = new THREE.RawShaderMaterial( {
            uniforms: capUniforms,
            vertexShader: options.vertexShader || WireframeShader.vertexShader,
            fragmentShader: options.fragmentShader || WireframeShader.fragmentShader,
            fog: false,
            depthWrite: options.depthWrite ? true : false,
        });
        this.cylinderMaterial = new THREE.RawShaderMaterial( {
            uniforms: cylinderUniforms,
            vertexShader: options.vertexShader || WireframeShader.vertexShader,
            fragmentShader: options.fragmentShader || WireframeShader.fragmentShader,
            fog: false,
            depthWrite: options.depthWrite ? true : false,
        });
    }

    static setTriangleMesh(triangleMesh: TriangleMesh, scale: number) {
        const { edges, edgesForwardMapping, faces } = triangleMesh;
        EdgeTubes.edgesForwardMapping = edgesForwardMapping;

        cylinderGeometryLookup = [];

        // Make new copies of instanced geometry - changing count dynamicaly was creating issues.
        // Create a cylinder instance geometry.
        cylinderGeometry.dispose();
        cylinderGeometry = new THREE.InstancedBufferGeometry();
        cylinderGeometry.copy(cylinderBufferGeo);
        // Create a cap instance geometry.
        capGeometry.dispose();
        capGeometry = new THREE.InstancedBufferGeometry();
        capGeometry.copy(hemisphereBufferGeo);

        // Create a new edge capsule for each crease edge.
        let numCapsules = 0;
        const leftFacesCyl: number[] = [];
        const rightFacesCyl: number[] = [];
        const leftFacesCap: number[] = [];
        const rightFacesCap: number[] = [];
        edgesForwardMapping.forEach((edgeIndices) => {
            // Create a capsule group for each triangle edge within this crease edge.
            edgeIndices.forEach((edgeIndex) => {
                const { edgeVertex1, edgeVertex2, leftFace, rightFace } = edges[edgeIndex];
                cylinderGeometryLookup.push({edgeVertex1, edgeVertex2});
                leftFacesCyl.push(leftFace === undefined ? -1 : faces[leftFace].parent);
                rightFacesCyl.push(rightFace === undefined ? -1 : faces[rightFace].parent);
                for (let j=0; j<2; j++) {
                    leftFacesCap.push(leftFacesCyl[leftFacesCyl.length - 1]);
                    rightFacesCap.push(rightFacesCyl[rightFacesCyl.length - 1]);
                }
                numCapsules++;
            });
        });

        cylinderGeometry.setAttribute('iOffset',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*3), 3));
        cylinderGeometry.setAttribute('iRotation',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*4), 4));
        cylinderGeometry.setAttribute('iScale',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*3), 3));
        cylinderGeometry.setAttribute('iVisible',
            new THREE.InstancedBufferAttribute((new Float32Array(numCapsules)).fill(1), 1));
        cylinderGeometry.setAttribute('iRightFace',
            new THREE.InstancedBufferAttribute(Float32Array.from(rightFacesCyl), 1));
        cylinderGeometry.setAttribute('iLeftFace',
            new THREE.InstancedBufferAttribute(Float32Array.from(leftFacesCyl), 1));

        capGeometry.setAttribute('iOffset',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*2*3), 3));
        capGeometry.setAttribute('iRotation',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*2*4), 4));
        capGeometry.setAttribute('iScale',
            new THREE.InstancedBufferAttribute(new Float32Array(numCapsules*2*3), 3));
        capGeometry.setAttribute('iVisible',
            new THREE.InstancedBufferAttribute((new Float32Array(numCapsules*2)).fill(1), 1));
        capGeometry.setAttribute('iRightFace',
            new THREE.InstancedBufferAttribute(Float32Array.from(rightFacesCap), 1));
        capGeometry.setAttribute('iLeftFace',
            new THREE.InstancedBufferAttribute(Float32Array.from(leftFacesCap), 1));

        // Update normals and bounds.
        cylinderGeometry.computeVertexNormals();
        cylinderGeometry.computeBoundingBox();
        cylinderGeometry.computeBoundingSphere();
        capGeometry.computeVertexNormals();
        capGeometry.computeBoundingBox();
        capGeometry.computeBoundingSphere();

        EdgeTubes.updateScale(scale);
    }

    static updateScale(scale: number) {
        // No triangle mesh set yet.
        if (cylinderGeometry.getAttribute('iScale') === undefined) {
            return;
        }
        const scaleArrayCylinder = cylinderGeometry.getAttribute('iScale').array as Float32Array;
        const scaleArrayCaps = capGeometry.getAttribute('iScale').array as Float32Array;
        for (let i = 0; i < scaleArrayCylinder.length; i+=3) {
            scaleArrayCylinder[i] = scale;
            scaleArrayCylinder[i+2] = scale;
        }
        scaleArrayCaps.fill(scale);
        (cylinderGeometry.getAttribute('iScale') as THREE.BufferAttribute).needsUpdate = true;
        (capGeometry.getAttribute('iScale') as THREE.BufferAttribute).needsUpdate = true;
    }

    set color(color: THREE.Color) {
        this.capMaterial.uniforms.baseColor.value = color.toArray();
        this.capMaterial.uniforms.baseColor.value.needsUpdate = true;
        this.cylinderMaterial.uniforms.baseColor.value = color.toArray();
        this.cylinderMaterial.uniforms.baseColor.value.needsUpdate = true;
    }

    updateGeometry() {
        // Clear the group.
        this.group.children = [];

        // Copy static geometry, with shallow copy of all attributes except iVisible.
        if (this.cylinderGeometry) this.cylinderGeometry.dispose();
        this.cylinderGeometry = cylinderGeometry.clone();
        Object.keys(cylinderGeometry.attributes).forEach((attribute) => {
            if (attribute === 'iVisible') {
                return;
            }
            this.cylinderGeometry!.setAttribute(attribute, cylinderGeometry.getAttribute(attribute));
        });
        if (this.capGeometry) this.capGeometry.dispose();
        this.capGeometry = capGeometry.clone();
        Object.keys(capGeometry.attributes).forEach((attribute) => {
            if (attribute === 'iVisible') {
                return;
            }
            this.capGeometry!.setAttribute(attribute, capGeometry.getAttribute(attribute));
        });

        // Copy bounds so we can recompute once.
        this.cylinderGeometry.boundingBox = cylinderGeometry.boundingBox;
        this.cylinderGeometry.boundingSphere = cylinderGeometry.boundingSphere;
        this.capGeometry.boundingBox = capGeometry.boundingBox;
        this.capGeometry.boundingSphere = capGeometry.boundingSphere;

        // Init meshes.
        const cylinderMesh = new THREE.Mesh(this.cylinderGeometry, this.cylinderMaterial);
        this.group.add(cylinderMesh);
        const capMesh = new THREE.Mesh(this.capGeometry, this.capMaterial);
        this.group.add(capMesh);
    }

    getObject3D() {
        return this.group;
    }

    getMaterials() {
        return (this.group.children as THREE.Mesh[]).map(child => child.material as THREE.ShaderMaterial);
    }

    showEdges(edgeIndices: number[]) {
        // No triangle mesh set yet.
        if (!this.cylinderGeometry || !this.capGeometry) {
            return;
        }
        const visibleArrayCylinder = this.cylinderGeometry.getAttribute('iVisible').array as Float32Array;
        const visibleArrayCaps = this.capGeometry.getAttribute('iVisible').array as Float32Array;
        let i = 0;
        EdgeTubes.edgesForwardMapping.forEach((segmentIndices, edgeIndex) => {
            const visible = edgeIndices.indexOf(edgeIndex) >= 0 ? 1 : 0;
            segmentIndices.forEach(() => {
                visibleArrayCylinder[i] = visible;
                visibleArrayCaps[2*i] = visible;
                visibleArrayCaps[2*i+1] = visible;
                i++;
            });
        });
        (this.cylinderGeometry.getAttribute('iVisible') as THREE.BufferAttribute).needsUpdate = true;
        (this.capGeometry.getAttribute('iVisible') as THREE.BufferAttribute).needsUpdate = true;
    }

    static updatePositions(positions: [number, number, number][]) {
        // No triangle mesh set yet.
        if (cylinderGeometry.getAttribute('iScale') === undefined) {
            return;
        }

        // Create all three.js objects outside of the loop.
        const p1 = new THREE.Vector3();
        const p2 = new THREE.Vector3();
        const y = new THREE.Vector3(0, 1, 0);
        const v = new THREE.Vector3();
        const q1 = new THREE.Quaternion();
        const q2 = new THREE.Quaternion();

        const cylScaleArray = cylinderGeometry.getAttribute('iScale').array as Float32Array;
        const cylOffsetArray = cylinderGeometry.getAttribute('iOffset').array as Float32Array;
        const cylRotationArray = cylinderGeometry.getAttribute('iRotation').array as Float32Array;
        const capOffsetGeometry = capGeometry.getAttribute('iOffset').array as Float32Array;
        const capRotationArray = capGeometry.getAttribute('iRotation').array as Float32Array;

        // Create a new edge group for each crease edge.
        cylinderGeometryLookup.forEach(({ edgeVertex1, edgeVertex2 }, i) => {
            // Calculate the length and orientation of the edge.
            p1.fromArray(positions[edgeVertex1]);
            p2.fromArray(positions[edgeVertex2]);
            v.subVectors(p2, p1);
            const length = v.length();
            v.normalize();
            q1.setFromUnitVectors(y, v);
            q2.setFromUnitVectors(y, v.clone().multiplyScalar(-1));

            // Update the instance buffers.
            cylScaleArray[3*i + 1] = length;
            cylOffsetArray[3*i] = p1.x;
            cylOffsetArray[3*i + 1] = p1.y;
            cylOffsetArray[3*i + 2] = p1.z;
            cylRotationArray[4*i] = q1.x;
            cylRotationArray[4*i + 1] = q1.y;
            cylRotationArray[4*i + 2] = q1.z;
            cylRotationArray[4*i + 3] = q1.w;
            capOffsetGeometry[6*i] = p1.x;
            capOffsetGeometry[6*i + 1] = p1.y;
            capOffsetGeometry[6*i + 2] = p1.z;
            capOffsetGeometry[6*i + 3] = p2.x;
            capOffsetGeometry[6*i + 4] = p2.y;
            capOffsetGeometry[6*i + 5] = p2.z;
            for (let j = 0; j<2; j++) {
                const q = j === 0 ? q2 : q1;
                capRotationArray[8*i + 4*j] = q.x;
                capRotationArray[8*i + 4*j + 1] = q.y;
                capRotationArray[8*i + 4*j + 2] = q.z;
                capRotationArray[8*i + 4*j + 3] = q.w;
            }
        });

        (cylinderGeometry.getAttribute('iScale') as THREE.BufferAttribute).needsUpdate = true;
        (cylinderGeometry.getAttribute('iOffset') as THREE.BufferAttribute).needsUpdate = true;
        (cylinderGeometry.getAttribute('iRotation') as THREE.BufferAttribute).needsUpdate = true;
        (capGeometry.getAttribute('iOffset') as THREE.BufferAttribute).needsUpdate = true;
        (capGeometry.getAttribute('iRotation') as THREE.BufferAttribute).needsUpdate = true;

        // Update normals and bounds.
        cylinderGeometry.computeVertexNormals();
        cylinderGeometry.computeBoundingSphere();
        capGeometry.computeVertexNormals();
        capGeometry.computeBoundingSphere();
    }

    dispose() {
        this.capMaterial.dispose();
        this.cylinderMaterial.dispose();
        if (this.cylinderGeometry) this.cylinderGeometry.dispose();
        if (this.capGeometry) this.capGeometry.dispose();
    }
}