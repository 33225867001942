import { SVGToCreaseOptions } from 'crease';
import * as THREE from 'three';

const EDGE_CUT_COLOR = new THREE.Color('rgb(110, 110, 110)');
const EDGE_CREASE_COLOR = new THREE.Color('rgb(236, 91, 98)');

const supportedTags = ['circle', 'line', 'path', 'polygon', 'polyline', 'rect'];
const supportedAttributes: Record<string, string[]> = {
    svg: ['viewBox'],
    g: [],
    line: ['x1', 'y1', 'x2', 'y2'],
    circle: ['cx', 'cy', 'r'],
    path: ['d'],
    polygon: ['points'],
    polyline: ['points'],
    rect: ['x', 'y', 'width', 'height', 'rx', 'ry'],
    all: ['transform'],
};
const cutRegExps = ['cuts?', 'boundary', 'boundaries', 'boundary-edges'];
const cutExcludeRegExps = ['boundary-edge-labels'];
const creaseRegExps = ['folds?', 'creases?', 'mountains?', 'valleys?',
    'mountain-creases', 'valley-creases', 'facet-creases'];
const creaseExcludeRegExps = ['crease-labels'];

export class SVGImporter {
    static filterNode(node: Node) {
        const numChildren = node.childNodes.length;
        for (let i = numChildren - 1; i >= 0; i--) {
            const child = node.childNodes[i];
            if (child.nodeName === 'g') {
                SVGImporter.filterNode(child);
                if (child.childNodes.length === 0) {
                    node.removeChild(child);
                }
            } else if (supportedTags.indexOf(child.nodeName) < 0) {
                node.removeChild(child);
            }
        }
    }

    static stripStyle(element: Element, recurse: boolean = true) {
        const allowedAttributes = supportedAttributes.all.concat(supportedAttributes[element.nodeName]);
        const attributes = element.attributes;
        for (let i = attributes.length - 1; i >= 0; i--) {
            if (allowedAttributes.indexOf(attributes[i].name) < 0) {
                element.removeAttribute(attributes[i].name);
            }
        }
        if (recurse) {
            for (let i = 0; i < element.children.length; i++) {
                const child = element.children[i];
                SVGImporter.stripStyle(child);
            }
        }
    }

    static findDielineLayers(baseElement: SVGElement) {
        // Build regular expressions from the acceptable names for cut and crease layers.
        // Permit an optional number and an optional suffix consisting of a hyphen or underscore
        // followed by anything else.
        const optionalSuffixRegExp = '\\d*([-_].*)?';
        const cutIdRegExp = new RegExp(`(${cutRegExps.join('|')})${optionalSuffixRegExp}`, 'i');
        const cutIdExcludeRegExp = new RegExp(cutExcludeRegExps.join('|'), 'i');
        const creaseIdRegExp = new RegExp(`(${creaseRegExps.join('|')})${optionalSuffixRegExp}`,
            'i');
        const creaseIdExcludeRegExp = new RegExp(creaseExcludeRegExps.join('|'), 'i');

        const layers = {cutLayers: [] as SVGElement[], creaseLayers: [] as SVGElement[]};
        for (let childIndex = baseElement.children.length - 1; childIndex >= 0; childIndex--) {
            const child = baseElement.children[childIndex] as SVGElement;
            const childID = child.id.toLowerCase();
            const isCutLayer = cutIdRegExp.test(childID) && !cutIdExcludeRegExp.test(childID);
            if (isCutLayer) {
                layers.cutLayers.push(child);
                continue;
            }
            const isCreaseLayer = creaseIdRegExp.test(childID) && !creaseIdExcludeRegExp.test(childID);
            if (isCreaseLayer) {
                layers.creaseLayers.push(child);
                continue;
            }
        }
        return layers;
    }

    parseSVG(text: string): SVGSVGElement | null {
        const parser = new DOMParser();
        const document = parser.parseFromString(text, 'image/svg+xml');

        // If there isn't an <svg> element at the root, something went wrong.
        if (document.children.length < 0 || document.children[0].nodeName !== 'svg') {
            return null;
        }

        const svg = document.children[0] as SVGSVGElement;
        const {cutLayers, creaseLayers} = SVGImporter.findDielineLayers(svg);
        if (cutLayers.length === 0 && creaseLayers.length === 0) {
            // Check if any top-level group layer that contains cuts/creases.
            // This happens when exporting from XD.
            const groupChildren = Array.from(svg.children).filter(child => child.nodeName === 'g');
            for (let i = 0; i < groupChildren.length; i++) {
                const baseElement = groupChildren[0] as SVGElement; 
                const groupLayers = SVGImporter.findDielineLayers(baseElement);
                groupLayers.cutLayers.forEach(child => {
                    svg.appendChild(child);
                    cutLayers.push(child);
                });
                groupLayers.creaseLayers.forEach(child => {
                    svg.appendChild(child);
                    creaseLayers.push(child);
                });
            }
        }

        // Remove all children that are not cut/crease layers.
        for (let i = svg.children.length - 1; i >= 0; i--) {
            const child = svg.children[i] as SVGElement;
            if (cutLayers.indexOf(child) < 0 && creaseLayers.indexOf(child) < 0) {
                svg.removeChild(child);
            }
        }

        cutLayers.forEach(child => {
            SVGImporter.filterNode(child);
            SVGImporter.stripStyle(child);
            child.classList.add('cut');
            child.style.stroke = EDGE_CUT_COLOR.getStyle();
            child.style.strokeWidth = '1px';
            child.style.fill = 'none';
        });

        creaseLayers.forEach(child => {
            SVGImporter.filterNode(child);
            SVGImporter.stripStyle(child);
            child.classList.add('crease');
            child.style.stroke = EDGE_CREASE_COLOR.getStyle();
            child.style.strokeWidth = '1px';
            child.style.fill = 'none';
        });

        // Populate the viewBox from width and height, then remove extraneous attributes.
        const viewBox = svg.viewBox.baseVal;
        if (viewBox.width === 0 && svg.width.baseVal.value > 0) {
            viewBox.width = svg.width.baseVal.value;
        }
        if (viewBox.height === 0 && svg.height.baseVal.value > 0) {
            viewBox.height = svg.height.baseVal.value;
        }
        SVGImporter.stripStyle(svg, false);

        return svg;
    }

    static getSVGToCreaseOptions(): SVGToCreaseOptions {
        const toByteArray = (c: THREE.Color): [number, number, number] => {
            return c.toArray().map(x => (x * 255) | 0) as [number, number, number];
        }
        return {
            edgeAssignments: {
                creases: [{
                    type: 'color',
                    value: toByteArray(EDGE_CREASE_COLOR),
                    tolerance: 1
                }],
                cuts: [{
                    type: 'color',
                    value: toByteArray(EDGE_CUT_COLOR),
                    tolerance: 1
                }],
            },
        };
    }
}