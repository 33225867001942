import { Vector3, Crease, Solver, triangulatePolygon, Vector2, TriangleMesh, CreaseEdgeRef, geom } from "crease";
import { BufferGeometry, BufferAttribute, Mesh, Material, DoubleSide, MeshBasicMaterial,
    MeshStandardMaterial, Texture } from "three";

const raycastingMaterial = new MeshBasicMaterial({ side: DoubleSide, fog: false, transparent: true, opacity: 0 });
const plasticMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    side: DoubleSide,
    fog: false,
    metalness: 1,
    roughness: 0,
    envMapIntensity: 2,
    opacity: 0.5,
    transparent: true,
});
const plasticExportMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    roughness: 0,
    transparent: true,
    opacity: 0.1,
    metalness: 1,
});
plasticExportMaterial.name = 'window';

export class PlasticWindows {
    private static meshGeometry = new BufferGeometry();
    private static exportGeometry = new BufferGeometry();
    private mesh = new Mesh();
    private material: Material;
    private static triangulatedHoles: Vector3[][] = [];
    static holeBoundaryEdges: number[][] = [];

    constructor(material: Material = plasticMaterial) {
        this.material = material;
    }

    static setEnvMap(texture: Texture) {
        plasticMaterial.envMap = texture;
    }

    static setTriangleMesh(
        triangleMesh: TriangleMesh,
        crease: Crease,
        solver: Solver,
    ) {
        // Calc all window geometry and add to scene.

       // First find all holes in faces.
       this.triangulatedHoles = [];
       this.holeBoundaryEdges = [];
       crease.faces.forEach(face => {
           if (face.edgeLoops.length === 1) {
               return;
           }
           for (let loopIndex = 1; loopIndex < face.edgeLoops.length; loopIndex++) {
                const edgeLoop = face.edgeLoops[loopIndex];
                const vertexIndices: number[] = [];
                const boundaryEdgeIndices: number[] = [];
                edgeLoop.edgeRefs.forEach(edgeRef => {
                    const { index } = edgeRef.edge;
                    boundaryEdgeIndices.push(index);
                    // Get all segments that make up edge in triangleMesh.
                    // These segments are already ordered for us in triangleMesh.
                    triangleMesh.edgesForwardMapping[index].forEach(segmentIndex => {
                        const segment = triangleMesh.edges[segmentIndex];
                        vertexIndices.push(segment.edgeVertex2);
                    });
                });
                // Triangulate each hole.
                // Flip handedness of hole.
                vertexIndices.reverse();
                // Get 2D positions from solver.
                const polygon = vertexIndices.map(vertexIndex => solver.flatPositions[vertexIndex].slice(0, 2) as Vector2);
                // Check if loop has correct handedness and positive area.
                if(geom.getSignedArea(polygon) <= 0) {
                    return;
                }
                try {
                    const triangulation = triangulatePolygon([polygon]).map(triangle => triangle.map(polygonIndex => vertexIndices[polygonIndex]) as Vector3);
                    this.triangulatedHoles.push(triangulation);
                    // Save boundary edges.
                    this.holeBoundaryEdges.push(boundaryEdgeIndices);
                } catch {
                    // Triangulation sometimes fails. Forget about those holes.
                }
           }
       });

       // Then look for holes between multiple faces.
       const visitedEdges = crease.edges.map(() => false);
       function isValidEdge(edgeRef: CreaseEdgeRef) {
            const { edge } = edgeRef;
            return edge.isBoundary && !visitedEdges[edge.index];
       }
       crease.faces.forEach(face => {
           const faceBoundary = face.edgeLoops[0];
           faceBoundary.edgeRefs.forEach(boundaryEdgeRef => {
                if (!isValidEdge(boundaryEdgeRef)) {
                   return;
                }
                const holeLoop = [boundaryEdgeRef];
                let currentEdgeRef = boundaryEdgeRef;
                while (true) {
                    // Get adjacent boundary edges at this vertex.
                    const { vertex2 } = currentEdgeRef;
                    const adjacentEdge = vertex2.getNextEdgeRef(currentEdgeRef);
                    if (adjacentEdge.edge === holeLoop[0].edge) {
                        // We've closed the loop.
                        break;
                    }
                    if (!isValidEdge(adjacentEdge)) {
                        // Some kind of problem.
                        throw new Error('Unable to close loop.');
                    } else {
                        holeLoop.push(adjacentEdge);
                        currentEdgeRef = adjacentEdge;
                    }
                }

                // Get vertices and segments for this loop.
                const vertexIndices: { [key: string]: number[]; } = {};
                const allVertexIndices: number[] = [];
                const boundaryEdgeIndices: number[] = [];
                holeLoop.forEach(edgeRef => {
                    const { index, leftFace } = edgeRef.edge;
                    if (!leftFace) {
                        throw new Error(`No valid left face for edge: ${edgeRef.edge.index}`);
                    }
                    boundaryEdgeIndices.push(index);

                    // We want to split vertices into groups according to their adjacent face.
                    // Add a new array corresponding to a specific adjacent face.
                    if (!vertexIndices[leftFace.index]) {
                        vertexIndices[leftFace.index] = [];
                    }
                    // Get all segments that make up edge in triangleMesh.
                    // These segments are already ordered for us in triangleMesh.
                    vertexIndices[leftFace.index].push(
                        triangleMesh.edges[triangleMesh.edgesForwardMapping[index][0]].edgeVertex1);
                    triangleMesh.edgesForwardMapping[index].forEach(segmentIndex => {
                        const segment = triangleMesh.edges[segmentIndex];
                        vertexIndices[leftFace.index].push(segment.edgeVertex2);
                        allVertexIndices.push(segment.edgeVertex2);
                    });
                });

                // Mark as visited.
                holeLoop.forEach(edgeRef => {
                    visitedEdges[edgeRef.edge.index] = true;
                });

                // Check handedness of entire loop first, to make sure we aren't handing the outer boundary.
                allVertexIndices.reverse();
                const polygon = allVertexIndices.map(vertexIndex => solver.flatPositions[vertexIndex].slice(0, 2) as Vector2);
                // Check if loop has correct handedness and positive area.
                if(geom.getSignedArea(polygon) <= 0) {
                    return;
                }

                const validTriangulation: Vector3[] = [];
                Object.keys(vertexIndices).forEach(faceIndex => {
                    // Triangulate hole based on 2D positions.
                    // Check if this group of vertices is long enough to triangulate.
                    const faceVertexIndices = vertexIndices[faceIndex];
                    // It's possible that we may have some adjacent duplicate indices in this array,
                    // clean this up first.
                    for (let i = faceVertexIndices.length; i >= 0; i--) {
                        if (
                            (i > 0 && faceVertexIndices[i-1] === faceVertexIndices[i]) ||
                            (i === 0 && faceVertexIndices[faceVertexIndices.length - 1] === faceVertexIndices[i])
                        ) {
                            faceVertexIndices.splice(i, 1);
                        }
                    }
                    if (faceVertexIndices.length < 3) {
                        return;
                    }
                    // Flip handedness of hole.
                    faceVertexIndices.reverse();
                    const polygon = faceVertexIndices.map(vertexIndex => solver.flatPositions[vertexIndex].slice(0, 2) as Vector2);
                    // Check if loop has correct handedness and positive area.
                    if(geom.getSignedArea(polygon) <= 0) {
                        return;
                    }
                    const triangulation = triangulatePolygon([polygon]).map(triangle => triangle.map(polygonIndex => faceVertexIndices[polygonIndex]) as Vector3);
                    validTriangulation.push(...triangulation);
                });
                if (validTriangulation.length === 0) {
                    return;
                }
                this.triangulatedHoles.push(validTriangulation);
                this.holeBoundaryEdges.push(boundaryEdgeIndices);
            });
        });

        // Update geometry.
        this.meshGeometry.dispose();

        // Make mesh geometry.
        const geometry = new BufferGeometry();
        const positions = new Float32Array(PlasticWindows.triangulatedHoles.reduce((sum, hole) => hole.length + sum, 0) * 9);
        const positionAttribute = new BufferAttribute(positions, 3);
        geometry.setAttribute('position', positionAttribute);
        this.meshGeometry = geometry;

        // Add material groups to geometry.
        let index = 0;
        PlasticWindows.triangulatedHoles.forEach((hole, materialIndex) => {
            geometry.addGroup(3 * index, 3 * hole.length, materialIndex);
            index += hole.length;
        });

        // Return current visible windows.
        return this.triangulatedHoles.map(() => false);
    }

    setTriangleMesh() {
        // Add materials, init all as transparent.
        this.mesh.material = PlasticWindows.triangulatedHoles.map(() => raycastingMaterial);
        this.mesh.geometry = PlasticWindows.meshGeometry;
    }

    static updatePositions(positions: [number, number, number][]) {
        // Callback from solver.
        const positionAttribute = this.meshGeometry.getAttribute('position') as BufferAttribute;
        const positionArray = positionAttribute.array as Float32Array;
        let index = 0;
        this.triangulatedHoles.forEach(hole => {
            hole.forEach(triangle => {
                triangle.forEach(vertexIndex => {
                    const position = positions[vertexIndex];
                    positionArray[3 * index] = position[0];
                    positionArray[3 * index + 1] = position[1];
                    positionArray[3 * index + 2] = position[2];
                    index += 1;
                });
            });
        });

        positionAttribute.needsUpdate = true;

        // Update normals and bounds.
        this.meshGeometry.computeBoundingSphere();
        this.meshGeometry.computeVertexNormals();
    }

    showWindows(visibleWindows: number[]) {
        const material = this.mesh.material as Material[];
        material.forEach((el, i) => material[i] = raycastingMaterial);
        visibleWindows.forEach(windowIndex => {
            material[windowIndex] = this.material;
        });
    }

    getObject3D() {
        return this.mesh;
    }

    getMeshForExport() {
        const visibleWindows: number[] = [];
        (this.mesh.material as Material[]).forEach((material, i) => {
            if (material === plasticMaterial) {
                visibleWindows.push(i);
            }
        });
        if (visibleWindows.length === 0) {
            return null;
        }
        // GLTF exporter doesn't support multiple materials,
        // so we need to preprocess visible portions of mesh.
        PlasticWindows.exportGeometry.dispose();
        PlasticWindows.exportGeometry = new BufferGeometry();

        // Filter positionArray to elements that are currently visible.
        const positionArray = (PlasticWindows.meshGeometry.getAttribute('position') as BufferAttribute).array as Float32Array;
        const visiblePositions = new Float32Array(visibleWindows.reduce((sum, windowIndex) => PlasticWindows.triangulatedHoles[windowIndex].length + sum, 0) * 9);
        const positionAttribute = new BufferAttribute(visiblePositions, 3);

        let positionIndex = 0;
        let visiblePositionIndex = 0;
        PlasticWindows.triangulatedHoles.forEach((hole, holeIndex) => {
            if (visibleWindows.indexOf(holeIndex) < 0) {
                positionIndex += hole.length * 3;
                return;
            }
            hole.forEach(triangle => {
                triangle.forEach(() => {
                    visiblePositions[3 * visiblePositionIndex] = positionArray[3 * positionIndex];
                    visiblePositions[3 * visiblePositionIndex + 1] = positionArray[3 * positionIndex + 1];
                    visiblePositions[3 * visiblePositionIndex + 2] = positionArray[3 * positionIndex + 2];
                    positionIndex += 1;
                    visiblePositionIndex += 1;
                });
            });
        });
        PlasticWindows.exportGeometry.setAttribute('position', positionAttribute);
        PlasticWindows.exportGeometry.computeBoundingSphere();
        PlasticWindows.exportGeometry.computeVertexNormals();
        return new Mesh(PlasticWindows.exportGeometry, plasticExportMaterial);
    }

    dispose() {
        // Don't dispose this.mesh.geometry because it is PlasticWindows.meshGeometry, which is persistent.
    }
}