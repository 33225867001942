import { boundMethod } from 'autobind-decorator';
import { Crease, Solver, TriangleMesh, Vector3 } from 'crease';
import { action, computed, observable } from 'mobx';
import * as THREE from 'three';
import { ClearPass } from 'three/examples/jsm/postprocessing/ClearPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SAOPass } from 'three/examples/jsm/postprocessing/SAOPass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import { ColorBinder } from '../common/ColorBinder';
import { utils } from '../common/utils';
import { ShaderPassCustom } from '../shaders/ShaderPassCustom';
import { Controls3D } from '../view3D/Controls3D';
import { EdgeTubes } from '../view3D/EdgeTubes';
import { FaceOffsetter, OffsetTriangleMesh } from '../view3D/FaceOffsetter';
import { MeshSurface } from '../view3D/MeshSurface';
import { ThickenedMesh } from '../view3D/ThickenedMesh';
import { ThinMesh } from '../view3D/ThinMesh';
import { PlasticWindows } from '../view3D/PlasticWindows';
import { sRGBEncoding, PMREMGenerator } from 'three';
import { loadTexture } from '../common/TextureLoader';
import LIGHTING_TEXTURE from '../assets/envmap/3_Point_Soft_Overhead.png';

const SNAPSHOT_WIDTH = 120;
const SNAPSHOT_HEIGHT = 90;
const SNAPSHOT_EDGE_SCALE = 0.5;

// Basic material used for scene override in rendering loop.
const basicMaterial = new THREE.MeshBasicMaterial();

export class RenderStore {
    solver = new Solver();
    faceOffsetter = new FaceOffsetter();
    colorBinder = new ColorBinder();

    // Rendering.
    renderer = RenderStore.initRenderer();
    snapshotRenderer = RenderStore.initRenderer({preserveDrawingBuffer: true});
    // Multipass rendering composer for mesh SAO.
    private meshEffectComposer = new EffectComposer(this.renderer);
    private fxaaPass = new ShaderPassCustom(FXAAShader);
    // Multipass rendering composer for transparent tools pass.
    private toolsEffectComposer = new EffectComposer(this.renderer);
    // Secondary render targets.
    // Special render target for masking edge tubes visiblity.
    private edgeMaskRenderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.NearestFilter,
        format: THREE.RedFormat, // Only using red channel.
        stencilBuffer: false,
    });
    // General RGBA target for multipass rendering.
    private alternateRGBARenderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {
        stencilBuffer: false,
        format: THREE.RGBAFormat,
    });
    // Environment map for image-based lighting.
    private envMapGenerator: PMREMGenerator;

    /** Snapshot image of the 2D dieline. */
    @observable
    snapshot2DDataUrl: string = '';

    camera3D = new THREE.PerspectiveCamera();
    controls3D = new Controls3D(this.camera3D, document.createElement('div'));
    cameraFlat = new THREE.PerspectiveCamera();
    controlsFlat = new Controls3D(this.cameraFlat, document.createElement('div'));
    cameraSnapshot = new THREE.PerspectiveCamera();

    /** A counter that increments each time the camera controls are changed by the user. */
    @observable
    cameraControlsCounter: number = 0;

    // Flag when tools update to force view3D to render.
    forceRenderFlag = false;
    // Flag to force recompute ofoffset positions from solver.
    forceUpdatePositions = false;

    private _controls!: Controls3D;
    private _camera!: THREE.PerspectiveCamera;
    private _container?: HTMLElement;
    @observable
    private _unitsPerPixel = 1; // World units per pixel at the current camera's target.
    thinMesh = new ThinMesh(this.colorBinder);
    thickenedMesh = new ThickenedMesh();
    plasticWindows = new PlasticWindows();
    crease = new Crease([], [], []);

    // Scenes.
    backgroundScene = new THREE.Scene(); // Background scene.
    scene = new THREE.Scene(); // Primary scene.
    maskScene = new THREE.Scene(); // For masking edge tube visibility.
    edgesScene = new THREE.Scene(); // For special rendering of edges with masking.
    highlighterScene = new THREE.Scene(); // Second pass rendered scene with highlighters.
    toolScene = new THREE.Scene(); // Third pass rendered scene with tool UI.
    overlayScene = new THREE.Scene(); // Fourth pass rendered scene with anything that must be on top.
    // wireframeScene = new THREE.Scene(); // For rendering of all edges in black.

    constructor() {
        // Initialize cameras.
        this.resetCamera(this.camera3D);
        this.resetCamera(this.cameraFlat);

        // Controls setter also sets camera.
        this.controls = this.controls3D;

        // Post processing.
        this.addMeshEffectComposerPasses();
        this.addToolsEffectComposerPasses();

        // Set snapshot render size.
        this.snapshotRenderer.setSize(SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);

        // Connect texture to material uniform.
        this.thinMesh.setMaskTexture(this.edgeMaskRenderTarget.texture);

        // Add obects to scenes.
        this.addObject3Ds(this.thinMesh.getRenderedEdgeTubesObject3Ds(), this.edgesScene);
        this.addObject3Ds(this.thinMesh.getMaskingObject3Ds(), this.maskScene);
        this.addObject3Ds(this.thickenedMesh.getObject3Ds(), this.scene);
        this.addObject3Ds([this.plasticWindows.getObject3D()], this.scene);
        // this.addObject3Ds(this.thinMesh.getWireframeObject3Ds(), this.wireframeScene);

        this.envMapGenerator = new PMREMGenerator(this.renderer);
        this.envMapGenerator.compileEquirectangularShader();
        this.loadImageBasedLighting();
    }

    private async loadImageBasedLighting() {
        const texture = await loadTexture(LIGHTING_TEXTURE);
        texture.encoding = sRGBEncoding;
        const renderTarget = this.envMapGenerator.fromEquirectangular(texture);
        PlasticWindows.setEnvMap(renderTarget.texture);
    }

    setTriangleMesh(
        crease: Crease,
        triangleMesh: TriangleMesh,
        offsetTriangleMesh: OffsetTriangleMesh,
        bottomFaceIndex: number,
    ) {
        this.crease = crease;

        // Init solver.
        this.solver.initialize(this.crease, triangleMesh);
        this.solver.setFixedFace(bottomFaceIndex);

        // Update geometry attributes.
        EdgeTubes.setTriangleMesh(offsetTriangleMesh, this._unitsPerPixel);
        MeshSurface.setTriangleMesh(offsetTriangleMesh, this.crease);
        // TODO: pass in offset mesh here.
        PlasticWindows.setTriangleMesh(triangleMesh, this.crease, this.solver);
        // Update mesh geometry.
        this.thinMesh.setTriangleMesh(offsetTriangleMesh, this.crease);
        this.thickenedMesh.setTriangleMesh(offsetTriangleMesh, this.crease, this.faceOffsetter,
            this.solver);
        this.plasticWindows.setTriangleMesh();

        // Immediately set controls target to 0, 0, 0.
        if (this.controls) {
            this.controls.target.copy(MeshSurface.getCenter());
            this.controls3D.animateToTarget(this.controls3D.target);
        }

        // Set intial positions.
        const positions = this.faceOffsetter.positions(this.solver.positions);
        EdgeTubes.updatePositions(positions);
        MeshSurface.updatePositions(positions);
        // TODO: use face offsetter with PlasticWindows.
        PlasticWindows.updatePositions(this.solver.positions);

        this.snapshot2DDataUrl = this.renderSnapshot(true);
    }

    private static initControls(controls: Controls3D, el: HTMLElement) {
        // Init Controls3D for user interaction.
        // Allows for full rotation, doesn't lock along vertical axis.
        controls.setDomElement(el);
        controls.rotateSpeed = 1.0;
        controls.zoomSpeed = 12;
        controls.targetAnimationSpeed = 0.05;
        // controls.noPan = true;
        controls.staticMoving = true;
        controls.dynamicDampingFactor = 0.3;
        controls.minDistance = 0.01;
        controls.maxDistance = 5;
        // How far you can orbit vertically, upper and lower limits.
        controls.maxOrbitPolarAngle = 9 * Math.PI/20;
        controls.minOrbitPolarAngle = Math.PI/4;
    }

    // Create a WebGL renderer that uses WebGL 2 if possible, otherwise fall back to WebGL 1.
    private static initRenderer(_options?: WebGLContextAttributes ): THREE.WebGLRenderer {
        const canvas = document.createElement('canvas');
        let context: WebGLRenderingContext | null = null;
        const options: WebGLContextAttributes = { antialias: true, alpha: true, stencil: true,
            ..._options };
        try {
            context = canvas.getContext('webgl2', options) as WebGL2RenderingContext;
        } catch (e) {
        }
        if (!context) {
            context = canvas.getContext('webgl', options) as WebGLRenderingContext;
        }
        const renderer = new THREE.WebGLRenderer({ canvas, context });
        renderer.shadowMap.enabled = true;
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0x000000, 0);
        renderer.autoClear = false;
        // this.renderer.autoClearColor = false;
        renderer.autoClearDepth = false;
        return renderer;
    }

    private addMeshEffectComposerPasses() {
        // Post processing.
        const composer = this.meshEffectComposer;
        composer.renderToScreen = true;

        // Clear everything.
        const clearPass = new ClearPass();
        composer.addPass(clearPass);

        // Render scene.
        const renderPass = new RenderPass(this.scene, this.camera);
        renderPass.clear = false;
        composer.addPass(renderPass);

        // // Add mask.
        // const renderMaskPass = new MaskPass(this.scene, this.camera);
        // composer.addPass(renderMaskPass);

        // TODO: ambient occlusion not working with mask pass?
        // Ambient occlusion.
        const saoPass = new SAOPass(this.scene, this.camera, false, true);
        saoPass.params.saoIntensity = 0.01;
        saoPass.clear = false;
        // saoPass.params.saoBlurStdDev = 100;
        saoPass.params.saoBlurRadius = 1;
        composer.addPass(saoPass);

        // // Ambient occlusion.
        // const ssaoPass = new SSAOPass(this.scene, this.camera);
        // ssaoPass.kernelRadius = 0.01;
        // composer.addPass(ssaoPass);
        // const copyPass = new ShaderPass(CopyShader);
        // composer.addPass(copyPass);
    }

    private addToolsEffectComposerPasses() {
        // Post processing.
        const composer = this.toolsEffectComposer;
        composer.renderToScreen = true;

        // Clear everything.
        const clearPass = new ClearPass();
        composer.addPass(clearPass);

        // Render scene.
        const renderPass = new RenderPass(this.toolScene, this.camera);
        // renderPass.clear = true;
        composer.addPass(renderPass);

        // // Anti-aliasing.
        // composer.addPass(this.fxaaPass);

        const copyPass = new ShaderPassCustom(CopyShader);
        copyPass.uniforms.opacity.value = 0.2;
        (copyPass.material as THREE.ShaderMaterial).uniforms.opacity.value = 0.2;
        copyPass.material.premultipliedAlpha = true;
        // Need to set transparent = true to enable blend mode.
        copyPass.material.transparent = true;
        composer.addPass(copyPass);
    }

    resetCamera(camera: THREE.PerspectiveCamera = this.camera) {
        // Init the camera.
        camera.fov = 45; // Field of view.
        camera.near = 0.008; // Near plane.
        camera.far = 25; // Far plane.
        camera.zoom = 2;

        if (camera === this.cameraFlat) {
            camera.position.x = 0;
            camera.position.y = 0;
            camera.position.z = -2.5;
            // Set z to "up" position (default is y).
            camera.up.set(0, -1, 0);
        } else {
            camera.position.x = 1.5;
            camera.position.y = 1.5;
            camera.position.z = 1.5;
            // Set z to "up" position (default is y).
            camera.up.set(0, 0, 1);
        }
        camera.updateProjectionMatrix();
    }

    resetView() {
        this.controls = this.controls3D;
        this.solver.unshowFlatState();
        this.resetCamera(this.camera3D);
        this.resetCamera(this.cameraFlat);
        this.controls3D.target.set(0, 0, 0);
        this.controls3D.animateToTarget(this.controls3D.target);
        this.controlsFlat.target.set(0, 0, 0);
    }

    resetDieline() {
        this.solver.reset();
        this.forceUpdatePositions = true;

        // Immediately set controls target to center.
        this.controls3D.target.set(0, 0, 0);
        this.controls3D.animateToTarget(this.controls3D.target);
    }

    orientModelOnGroundPlane(faceIndex: number, triangleMesh: TriangleMesh) {
        // const faceIndex = this.props.selectedFaces[0];
        const triangleIndices = triangleMesh.facesForwardMapping[faceIndex];

        const normals = this.faceOffsetter.normals(this.solver.normals);
        // MUST use unoffset positions here bc we are feeding the transformed data back to the solver.
        const positionsNoOffset = this.faceOffsetter.positions(this.solver.positions);

        const normal = new THREE.Vector3();
        triangleIndices.forEach(triangleIndex => {
            normal.add((new THREE.Vector3()).fromArray(normals[triangleIndex]));
        });
        normal.normalize();
        const zVec = new THREE.Vector3(0, 0, 1);
        const quaternion = new THREE.Quaternion().setFromUnitVectors(normal, zVec);
        // Update vertex positions directly so we don't get into trouble later.
        // Orient normal with z axis.
        const rotatedPositionsNoOffset = positionsNoOffset.map((position: [number, number, number], i: number) => {
            return new THREE.Vector3().fromArray(position).applyQuaternion(quaternion);
        });
        // Offset along z to lie flat on ground.
        const zOffset = triangleIndices.reduce((sum, triangleIndex) =>
            rotatedPositionsNoOffset[triangleMesh.faces[triangleIndex].vertices[0]].z + sum, 0) / triangleIndices.length;
        zVec.multiplyScalar(-zOffset);

        rotatedPositionsNoOffset.forEach((position: THREE.Vector3, i: number) => {
            position.add(zVec);
        });

        // Compute bounding sphere.
        const boundingSphere = new THREE.Sphere();
        boundingSphere.setFromPoints( rotatedPositionsNoOffset );

        // Center around x = 0, y = 0;
        const offsetXY = new THREE.Vector3(-boundingSphere.center.x, -boundingSphere.center.y, 0);
        rotatedPositionsNoOffset.forEach((position: THREE.Vector3, i: number) => {
            position.add(offsetXY);
        });

        // Check that we haven't put most of the box under the ground plane.
        if (boundingSphere.center.z < 0) {
            rotatedPositionsNoOffset.forEach((position: THREE.Vector3, i: number) => {
                position.applyAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI);
            });
        }

        // Orient along XY plane.
        // For now, let's find largest edge segment on the "bottom" and line that up with x axis.
        // This will work for lining up non-rectangular shapes as well.
        // If we want to get fancy later, we could try an SVD or something on the boundary points.
        // Find all straight boundary edges.
        const edges = (this.crease.faces[faceIndex]).edgeLoops[0].edgeRefs.map((edgeRef) => edgeRef.edge)
            .filter((edge) => edge.controlPoints.length === 0);
        const longestEdge = edges.reduce((currentLongestEdge, edge) => 
            edge.length > currentLongestEdge.length ? edge : currentLongestEdge, edges[0]);
        const v1 = rotatedPositionsNoOffset[triangleMesh.verticesForwardMapping[longestEdge.vertex1.index][0]];
        const v2 = rotatedPositionsNoOffset[triangleMesh.verticesForwardMapping[longestEdge.vertex2.index][0]];
        const vec = v2.clone().sub(v1).normalize();
        // Rotate so vec is oriented along x axis.
        const angle = Math.atan2(vec.y, vec.x);
        const zAxis = new THREE.Vector3(0, 0, 1);
        const quaternionXY = (new THREE.Quaternion()).setFromAxisAngle(zAxis, -angle);
        rotatedPositionsNoOffset.forEach((position: THREE.Vector3, i: number) => {
            position.applyQuaternion(quaternionXY);
        });

        this.solver.positions = this.faceOffsetter.removeDuplicates(rotatedPositionsNoOffset.map((position: THREE.Vector3) =>
            position.toArray() as Vector3));
        this.forceUpdatePositions = true;

        // Recalc offset positions.
        const positions = this.faceOffsetter.positions(this.solver.positions);
        EdgeTubes.updatePositions(positions);
        MeshSurface.updatePositions(positions);
        PlasticWindows.updatePositions(this.solver.positions);

        // Immediately set controls target to center.
        this.controls.target.copy(MeshSurface.getCenter());
    }

    setContainer(el: HTMLElement | null) {
        if (!el) {
            return;
        }
        this._container = el;
        RenderStore.initControls(this.controls3D, el);
        RenderStore.initControls(this.controlsFlat, el);
        this.controls3D.maxPanDistance = 1.75;
        this.controlsFlat.noRotate = true;
        this.controlsFlat.maxPanDistance = Math.sqrt(2)/2;
        this.controlsFlat.disableAnimatedTarget();
    }

    projectPointToContainer(point: THREE.Vector3): THREE.Vector2 {
        // Project using the current camera.
        const projection = point.clone().project(this.camera);

        // Convert to pixel coordinates within the container.
        const halfWidth = this._container ? this._container.clientWidth / 2 : 0;
        const halfHeight = this._container ? this._container.clientHeight / 2 : 0;
        return new THREE.Vector2((1 + projection.x) * halfWidth, (1 - projection.y) * halfHeight);
    }

    addObject3Ds(objects: THREE.Object3D[], parent: THREE.Object3D) {
        if (objects.length === 0) {
            return;
        }
        parent.add(...objects);
    }

    removeObject3Ds(objects: THREE.Object3D[], parent: THREE.Object3D) {
        if (objects.length === 0) {
            return;
        }
        parent.remove(...objects);
    }

    @boundMethod
    private onControlsChange() {
        this.forceRenderFlag = true;

        // Increment the observable camera controls counter.
        this.cameraControlsCounter++;
    }

    set controls(controls: Controls3D) {
        // Stop listening to previous controls.
        if (this._controls) {
            this._controls.removeEventListener('zoom', this.updateUnitsPerPixel);
            this._controls.removeEventListener('change', this.onControlsChange);
        }

        // Enable/disable controls.
        this.controls3D.enabled = controls === this.controls3D;
        this.controlsFlat.enabled = controls === this.controlsFlat;
        this._controls = controls;
        this._camera = controls.object as THREE.PerspectiveCamera;

        // Start listening to current controls.
        if (this._controls) {
            this._controls.addEventListener('zoom', this.updateUnitsPerPixel);
            this._controls.addEventListener('change', this.onControlsChange);
        }

        // Update units per pixel.
        this.updateUnitsPerPixel();
    }

    get controls() {
        return this._controls;
    }

    get camera() {
        return this._camera;
    }

    handleWindowResize(width: number, height: number, shouldUpdateUnitsPerPixel: boolean = true) {
        const dpr = window.devicePixelRatio || 1;
        this.renderer.setSize(width, height);
        this.meshEffectComposer.setSize(width, height);
        this.toolsEffectComposer.setSize(width, height);
        this.thinMesh.handleWindowResize([width * dpr, height * dpr]);
        this.edgeMaskRenderTarget.setSize(width, height);
        this.alternateRGBARenderTarget.setSize(width, height);
        // Update antializasing shader.
        const pixelRatio = this.renderer.getPixelRatio();
        const shaderMaterial = this.fxaaPass.material as THREE.ShaderMaterial;
        shaderMaterial.uniforms['resolution'].value.x = 1/(width * pixelRatio);
        shaderMaterial.uniforms['resolution'].value.y = 1/(height * pixelRatio);
        [this.camera3D, this.cameraFlat].forEach(camera => {
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
        });

        // Update the units per pixel only after the camera aspect ratio has been updated.
        if (shouldUpdateUnitsPerPixel) {
            this.updateUnitsPerPixel();
        }

        // Force render on next animation cycle
        this.forceRenderFlag = true;
    }

    private calcUnitsPerPixel(camera = this.camera, pixelWidth = window.innerWidth) {
        // Calculate the number of world units per pixel as follows: First, get the extent of the
        // world space spanned by the camera's vertical field of view at the depth of the target
        // point. Then convert to width in world space by multiplying by the camera's aspect ratio,
        // and divide by the container width in pixels to arrive at units per pixel.
        const offset = camera.position.clone().sub(this.controls.target as THREE.Vector3);
        const distance = offset.length() / camera.zoom;
        const worldHeight = 2 * Math.tan(utils.convertToRadians(camera.fov) / 2) * distance;
        return worldHeight * camera.aspect / pixelWidth;
    }

    @boundMethod
    updateUnitsPerPixel() {
        this._unitsPerPixel = this.calcUnitsPerPixel();

        EdgeTubes.updateScale(this._unitsPerPixel);

        this.forceRenderFlag = true;
    }

    @computed
    get unitsPerPixel(): number {
        return this._unitsPerPixel;
    }

    @action
    setShowFlat(showFlat: boolean) {
        if (showFlat) {
            this.controls = this.controlsFlat;
            this.solver.showFlatState();
        } else {
            this.controls = this.controls3D;
            this.solver.unshowFlatState();
        }
        if (this.solver.isInitialized) {
            this.forceUpdatePositions = true;
            this.forceRenderFlag = true;
        }
    }

    private fitContentWithinView(
        camera: THREE.PerspectiveCamera,
        positions: [number, number, number][],
        margin: number,
    ) {
        // Define the size of the viewport that we want the animation to fit within.
        const viewportWidth = SNAPSHOT_WIDTH - 2 * margin;
        const viewportHeight = SNAPSHOT_HEIGHT - 2 * margin;

        // Determine the horizontal and vertical field of view that lies within the viewport.
        const twiceRendererHeight = 2 * SNAPSHOT_HEIGHT;
        const rendererHalfFovy = THREE.MathUtils.degToRad(camera.fov / 2);
        const twiceFrustumDepth = twiceRendererHeight / Math.tan(rendererHalfFovy);
        const viewportHalfFovX = Math.atan(viewportWidth / twiceFrustumDepth);
        const viewportHalfFovY = Math.atan(viewportHeight / twiceFrustumDepth);

        // Create planes parallel to the frustum's left, right, bottom and top sides.
        const forward = MeshSurface.getCenter().sub(camera.position).normalize();
        const right = forward.clone().cross(camera.up).normalize();
        const up = right.clone().cross(forward).normalize();
        const leftPlaneNormal = right.clone().negate().applyAxisAngle(up, viewportHalfFovX);
        const rightPlaneNormal = right.clone().applyAxisAngle(up, -viewportHalfFovX);
        const bottomPlaneNormal = up.clone().negate().applyAxisAngle(right, -viewportHalfFovY);
        const topPlaneNormal = up.clone().applyAxisAngle(right, viewportHalfFovY);
        const leftPlane = new THREE.Plane(leftPlaneNormal, Infinity);
        const rightPlane = new THREE.Plane(rightPlaneNormal, Infinity);
        const bottomPlane = new THREE.Plane(bottomPlaneNormal, Infinity);
        const topPlane = new THREE.Plane(topPlaneNormal, Infinity);
        const frustumPlanes = [leftPlane, rightPlane, bottomPlane, topPlane];

        // Adjust the planes to just barely touch the given points.
        const p = new THREE.Vector3();
        positions.forEach(point => {
            p.fromArray(point);
            frustumPlanes.forEach(plane => {
                plane.constant = Math.min(plane.constant, -plane.normal.dot(p));
            });
        });

        // Calculate two possible camera locations: a horizontal center, where the frustum's left
        // and right planes just barely touch points (and the up component is zero); and a vertical
        // center, where the frustum's bottom and top planes just barely touch points (and the
        // right component is zero).
        const horizontalPlane = new THREE.Plane(up, 0);
        const horizontalCenter = utils.getIntersectionOfThreePlanes(leftPlane, rightPlane,
            horizontalPlane);
        const verticalPlane = new THREE.Plane(right, 0);
        const verticalCenter = utils.getIntersectionOfThreePlanes(bottomPlane, topPlane,
            verticalPlane);

        // Calculate the camera position from the further back of the two candidate positions.
        // Adjust the perpendicular component.
        const position = new THREE.Vector3();
        if (forward.dot(horizontalCenter) < forward.dot(verticalCenter)) {
            position.copy(horizontalCenter).addScaledVector(up, up.dot(verticalCenter));
        } else {
            position.copy(verticalCenter).addScaledVector(right, right.dot(horizontalCenter));
        }

        // Set the camera position and view direction.
        camera.position.copy(position);
        camera.lookAt(position.clone().add(forward));
        camera.updateProjectionMatrix();
    }

    renderSnapshot(showFlat: boolean, margin: number = 1) {
        this.cameraSnapshot.copy(showFlat ? this.cameraFlat : this.camera3D);

        // Save size.
        const size = new THREE.Vector2();
        this.renderer.getSize(size);

        // Temporarily change renderer for EffectComposer.
        this.meshEffectComposer.renderer = this.snapshotRenderer;
        this.toolsEffectComposer.renderer = this.snapshotRenderer;
        this.meshEffectComposer.setSize(SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        this.toolsEffectComposer.setSize(SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        this.edgeMaskRenderTarget.setSize(SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        this.alternateRGBARenderTarget.setSize(SNAPSHOT_WIDTH, SNAPSHOT_HEIGHT);
        // Update antializasing shader.
        const pixelRatio = this.snapshotRenderer.getPixelRatio();
        const shaderMaterial = this.fxaaPass.material as THREE.ShaderMaterial;
        shaderMaterial.uniforms['resolution'].value.x = 1/(SNAPSHOT_WIDTH * pixelRatio);
        shaderMaterial.uniforms['resolution'].value.y = 1/(SNAPSHOT_HEIGHT * pixelRatio);
        
        this.cameraSnapshot.aspect = SNAPSHOT_WIDTH / SNAPSHOT_HEIGHT;
        this.cameraSnapshot.updateProjectionMatrix();
        const positions = showFlat ? this.solver.flatPositions : this.solver.positions;
        this.fitContentWithinView(this.cameraSnapshot, positions, margin);

        // Update edge thickness.
        const unitsPerPixel = this.calcUnitsPerPixel(this.cameraSnapshot, SNAPSHOT_WIDTH) * SNAPSHOT_EDGE_SCALE;
        EdgeTubes.updateScale(unitsPerPixel);

        if (showFlat) {
            this.renderWireFrame(this.cameraSnapshot, this.snapshotRenderer, true);
        } else {
            this.render3D(this.cameraSnapshot, this.snapshotRenderer, true);
        }

        // Reset edge thickness.
        EdgeTubes.updateScale(this._unitsPerPixel);

        // Reset all effects passes, uniforms, and render targets back to previous state.
        this.meshEffectComposer.renderer = this.renderer;
        this.toolsEffectComposer.renderer = this.renderer;
        this.handleWindowResize(size.x, size.y, false);

        return this.snapshotRenderer.domElement.toDataURL();
    }

    renderWireFrame(camera = this.camera, renderer = this.renderer, isSnapshot?: boolean) {
        this.updateNearPlane(camera);

        const context = renderer.getContext();

        // renderer.render(this.backgroundScene, this.camera);

        // Clear everything - depth, color, stencil.
        renderer.clear(true, true, true);

        // Render edges.
        renderer.render(this.edgesScene, camera);

        // Don't need to render anything else for snapshot.
        if (isSnapshot) {
            return;
        }

        // Render highlighter without depth write.
        context.depthMask(false); // Lock depth buffer.
        renderer.render(this.highlighterScene, camera);
        context.depthMask(true); // Unlock depth buffer.

        // Render tools at full opacity with dieline mesh + edges depth test.
        renderer.render(this.toolScene, camera);

        // Render anything that should go on top with depth test/write disabled.
        context.disable(context.DEPTH_TEST);
        context.depthMask(false); // Lock depth buffer.
        renderer.render(this.overlayScene, camera);
        context.enable(context.DEPTH_TEST);
        context.depthMask(true); // Unlock depth buffer.
    }

    render3D(camera = this.camera, renderer = this.renderer, isSnapshot?: boolean) {
        this.updateNearPlane(camera);

        const context = renderer.getContext();

        // Clear everything - depth, color, stencil.
        renderer.clear(true, true, true);
        // renderer.render(this.backgroundScene, this.camera);

        // Render dieline mesh.
        // Update to correct camera first.
        this.meshEffectComposer.passes.forEach(pass => {
            const renderPass = pass as RenderPass;
            if (renderPass.camera !== undefined) {
                renderPass.camera = camera;
            }
        });
        this.meshEffectComposer.render();

        // Render to secondary target
        renderer.setRenderTarget(this.edgeMaskRenderTarget);
        // Clear everything - depth, color, stencil.
        renderer.clear(true, true, true);
        // Render edges visibility mask.
        renderer.render(this.maskScene, camera);

        // Switch to rendering to canvas.
        renderer.setRenderTarget(null);

        // Lock color buffer.
        context.colorMask( false, false, false, false );
        // Override mask mesh material to one without polygon offsets.
        this.maskScene.overrideMaterial = basicMaterial;
        // Render masking mesh to depth buffer.
        renderer.render(this.maskScene, camera);
        // Remove material override.
        this.maskScene.overrideMaterial = null;
        // Unlock color buffer.
        context.colorMask( true, true, true, true );

        // Render edges with depth test and masking.
        renderer.render(this.edgesScene, camera);

        // Don't need to render anything else for snapshot.
        if (isSnapshot) {
            return;
        }

        // Render highlighter with depth testing, without depth write.
        context.depthMask(false); // Lock depth buffer.
        renderer.render(this.highlighterScene, camera);
        context.depthMask(true); // Unlock depth buffer.

        // Turn off sort objects for tools layer - this is used to control rendering order of fold angle tool.
        renderer.sortObjects = false;
        // Render tools to secondary render target with cleared depth buffer, then blend with canvas with transparency.
        // Update to correct camera first.
        this.toolsEffectComposer.passes.forEach(pass => {
            const renderPass = pass as RenderPass;
            if (renderPass.camera !== undefined) {
                renderPass.camera = camera;
            }
        });
        this.toolsEffectComposer.render();
        // Render tools at full opacity with dieline mesh + edges depth test.
        renderer.render(this.toolScene, camera);
        // Turn sort objects back on.
        renderer.sortObjects = true;

        // Render anything that should go on top with depth test/write disabled.
        context.disable(context.DEPTH_TEST);
        context.depthMask(false); // Lock depth buffer.
        renderer.render(this.overlayScene, camera);
        context.enable(context.DEPTH_TEST);
        context.depthMask(true); // Unlock depth buffer.
    }

    private updateNearPlane(camera: THREE.PerspectiveCamera) {
        const center = MeshSurface.getCenter();
        const radius = MeshSurface.getRadius();
        const viewDir = this.controls.target.clone().sub(camera.position).normalize();
        const offset = center.sub(camera.position);
        const distanceToCenter = offset.dot(viewDir);
        camera.near = Math.max(0.008, distanceToCenter - 2 * radius);
        camera.updateProjectionMatrix();
    }

    enablePan(state: boolean) {
        this.controls3D.noPan = !state;
    }
}
