import { Bezier, CreaseEdge, geom, TriangleEdge, vec, Vector3, Crease, Solver } from 'crease';
import * as THREE from 'three';
import { OffsetTriangleMesh, FaceOffsetter } from './FaceOffsetter';
import { MeshSurface } from './MeshSurface';

const ROUNDED_EDGE_SEGMENTS = 10;

const ARTWORK_MATERIAL = new THREE.MeshPhongMaterial({
    color: 0xffffff,
    flatShading: true,
    fog: false,
    alphaTest: 0.5,
    transparent: false,
});

type EdgeInfo = {
    leftEdge: TriangleEdge;
    rightEdge: TriangleEdge;
    isStartVertex: boolean;
};

export class ThickenedMesh {
    private triangleMesh?: OffsetTriangleMesh;
    private crease?: Crease;
    private faceOffsetter?: FaceOffsetter;
    private solver?: Solver;
    private side1Material = ARTWORK_MATERIAL.clone();
    private side2Material = ARTWORK_MATERIAL.clone();
    private rawEdgeMaterial = new THREE.MeshPhongMaterial({ name: 'edge', flatShading: true, fog: false });
    private roundedEdgeInfos: EdgeInfo[] = [];
    private rawEdgeVertexIndices: number[] = [];
    private rawEdgeFanInfos: EdgeInfo[] = [];

    // Init a separate mesh for each side of the dieline, so they can be rendered with 
    // different textures.
    side1 = new MeshSurface(this.side1Material, true);
    side2 = new MeshSurface(this.side2Material);
    roundMesh1 = new THREE.Mesh(new THREE.BufferGeometry(), this.side1Material);
    roundMesh2 = new THREE.Mesh(new THREE.BufferGeometry(), this.side2Material);
    rawEdgeMesh = new THREE.Mesh(new THREE.BufferGeometry(), this.rawEdgeMaterial);

    constructor() {
        this.side1Material.name = 'side1';
        this.side2Material.name = 'side2';
    }

    setTriangleMesh(
        triangleMesh: OffsetTriangleMesh,
        crease: Crease,
        faceOffsetter: FaceOffsetter,
        solver: Solver,
    ) {
        this.triangleMesh = triangleMesh;
        this.crease = crease;
        this.faceOffsetter = faceOffsetter;
        this.solver = solver;

        this.side1.updateGeometry();
        this.side2.updateGeometry();

        // Update position attribute length.
        const positionAttribute1 = this.side1.getPositionAttribute().clone();
        this.side1.setPositionAttribute(positionAttribute1);
        const positionAttribute2 = this.side2.getPositionAttribute().clone();
        this.side2.setPositionAttribute(positionAttribute2);

        // Initialize the rounded edge meshes.
        this.initializeRoundedEdges();

        // Initialize the raw edge mesh.
        this.initializeRawEdges();
    }

    private initializeRoundedEdges() {
        const indices: number[] = [];
        const uvs: number[] = [];
        this.initializeRoundedEdgeStrips(indices, uvs);

        // Create position, normal, uv, and index buffers for each round mesh.
        const vertexCount = uvs.length / 2;
        [this.roundMesh1, this.roundMesh2].forEach(mesh => {
            const geometry = mesh.geometry as THREE.BufferGeometry;
            const positionBuffer = new THREE.Float32BufferAttribute(vertexCount * 3, 3);
            geometry.setAttribute('position', positionBuffer);
            const normalBuffer = new THREE.Float32BufferAttribute(vertexCount * 3, 3);
            geometry.setAttribute('normal', normalBuffer);
            const uvBuffer = new THREE.Float32BufferAttribute(uvs, 2);
            geometry.setAttribute('uv', uvBuffer);
            geometry.setIndex(indices);
            indices.reverse();
        });
    }

    private initializeRoundedEdgeStrips(indices: number[], uvs: number[]) {
        const offsetMesh = this.triangleMesh!;
        const uvBuffer = this.side1.getUVAttribute();
        const pointCount = ROUNDED_EDGE_SEGMENTS + 1;
        this.roundedEdgeInfos = [];
        this.crease!.edges.filter(creaseEdge => creaseEdge.isCrease).forEach(creaseEdge => {
            let index = uvs.length / 2;
            // Get all the duplicated triangle edges that make up this crease edge.
            const duplicatedEdges = offsetMesh.duplicatedEdgesForwardMapping[creaseEdge.index];

            // Iterate over triangle edges along the crease edge.
            duplicatedEdges.forEach((duplicatedEdge, i) => {
                const leftEdge = offsetMesh.edges[duplicatedEdge.forwardEdgeIndex];
                const rightEdge = offsetMesh.edges[duplicatedEdge.reverseEdgeIndex];

                // At the start of the crease edge, add a row of vertices connecting the start of
                // the left triangle edge to the end of the right triangle edge.
                if (i === 0) {
                    this.roundedEdgeInfos.push({ leftEdge, rightEdge, isStartVertex: true });
                    const u = uvBuffer.getX(leftEdge.edgeVertex1);
                    const v = uvBuffer.getY(leftEdge.edgeVertex1);
                    for (let j = 0; j < pointCount; j++) {
                        uvs.push(u, v);
                    }
                }

                // Add a row of vertices connecting the end of the left edge to the start of the
                // right edge, as well as a strip of triangles.
                this.roundedEdgeInfos.push({ leftEdge, rightEdge, isStartVertex: false });
                const u = uvBuffer.getX(leftEdge.edgeVertex2);
                const v = uvBuffer.getY(leftEdge.edgeVertex2);
                for (let j = 0; j < pointCount; j++) {
                    uvs.push(u, v);
                    if (j > 0) {
                        indices.push(index, index + 1, index + pointCount + 1);
                        indices.push(index, index + pointCount + 1, index + pointCount);
                        index++;
                    }
                }
                index++;
            });
        });
    }

    private initializeRawEdges() {
        const indices: number[] = [];
        const uvs: number[] = [];
        this.initializeRawEdgeStrips(indices, uvs);
        this.initializeRawEdgeFans(indices, uvs);

        // Create position, normal, uv, and index buffers.
        const vertexCount = uvs.length / 2;
        const geometry = this.rawEdgeMesh.geometry as THREE.BufferGeometry;
        const positionBuffer = new THREE.Float32BufferAttribute(vertexCount * 3, 3);
        geometry.setAttribute('position', positionBuffer);
        const normalBuffer = new THREE.Float32BufferAttribute(vertexCount * 3, 3);
        geometry.setAttribute('normal', normalBuffer);
        const uvBuffer = new THREE.Float32BufferAttribute(uvs, 2);
        geometry.setAttribute('uv', uvBuffer);
        geometry.setIndex(indices);
    }

    private initializeRawEdgeStrips(indices: number[], uvs: number[]) {
        const offsetMesh = this.triangleMesh!;
        this.rawEdgeVertexIndices = [];
        const boundaryEdgeLoops = this.getBoundaryEdgeLoops();
        boundaryEdgeLoops.forEach(boundaryEdgeLoop => {
            boundaryEdgeLoop.forEach(creaseEdge => {
                // Get all the mesh triangle edges that make up this crease edge.
                const edgeIndices = offsetMesh.edgesForwardMapping[creaseEdge.index];
                const orderedEdges = edgeIndices.map(edgeIndex => offsetMesh.edges[edgeIndex]);
        
                // Slit edges may have no corresponding triangle edges (at least for now).
                if (orderedEdges.length > 0) {
                    // Store the thickened mesh vertex indices corresponding to all the mesh
                    // vertices along this chain of triangle edges.
                    let index = uvs.length / 2;
                    const newVertexIndex1 = orderedEdges[0].edgeVertex1;
                    this.rawEdgeVertexIndices.push(newVertexIndex1);
                    // These placeholder texture coordinates will get updated later.
                    uvs.push(0, 0, 0, 1);
                    orderedEdges.forEach(oldEdge => {
                        const newVertexIndex2 = oldEdge.edgeVertex2;
                        this.rawEdgeVertexIndices.push(newVertexIndex2);
                        uvs.push(0, 0, 0, 1);
                        indices.push(index, index + 3, index + 1);
                        indices.push(index, index + 2, index + 3);
                        index += 2;
                    });
                }
            });
        });
    }

    private initializeRawEdgeFans(indices: number[], uvs: number[]) {
        this.rawEdgeFanInfos = [];
        this.crease!.edges.filter(creaseEdge => creaseEdge.isCrease).forEach(creaseEdge => {
            if (creaseEdge.vertex1.incidentEdges.some(edge => edge.isBoundary)) {
                this.createRawEdgeFan(creaseEdge, true, indices, uvs);
            }
            if (creaseEdge.vertex2.incidentEdges.some(edge => edge.isBoundary)) {
                this.createRawEdgeFan(creaseEdge, false, indices, uvs);
            }
        });
    }

    private createRawEdgeFan(
        creaseEdge: CreaseEdge,
        isStartVertex: boolean,
        indices: number[],
        uvs: number[],
    ) {
        this.addRawEdgeFan(indices, uvs);
        const offsetMesh = this.triangleMesh!;
        const duplicatedEdges = offsetMesh.duplicatedEdgesForwardMapping[creaseEdge.index];
        const whichEdge = isStartVertex ? 0 : duplicatedEdges.length - 1;
        const duplicatedEdge = duplicatedEdges[whichEdge];
        const leftEdge = offsetMesh.edges[duplicatedEdge.forwardEdgeIndex];
        const rightEdge = offsetMesh.edges[duplicatedEdge.reverseEdgeIndex]
        this.rawEdgeFanInfos.push({ leftEdge, rightEdge, isStartVertex });
    }

    private addRawEdgeFan(indices: number[], uvs: number[]) {
        const index = uvs.length / 2;
        uvs.push(0, 0);
        for (let i = 0; i < ROUNDED_EDGE_SEGMENTS; i++) {
            // These placeholder texture coordinates will get updated later.
            uvs.push(0, 0);
            uvs.push(0, 0);
            indices.push(index + ROUNDED_EDGE_SEGMENTS + i + 1, index + i + 1, index + i);
        }
    }

    updatePositions(
        thickness: number,
        isSubstrateHorizontal: boolean,
        showFlat: boolean,
        sortedOverlappingGroups: number[][],
    ) {
        if (!this.triangleMesh) {
            return;
        }

        // Get the offsets for each side.
        const side1Offset = thickness / 2;
        const side2Offset = thickness / 2;

        // Offset side1 outward and side2 inward. Note that side1 normals are up-to-date because
        // View3D calls MeshSurface.updatePositions before this.
        const positions = this.faceOffsetter!.offsetPositions(this.triangleMesh!, showFlat,
            this.solver!, sortedOverlappingGroups, thickness / 4);
        const normals = this.side1.getNormalAttribute().array as Float32Array;
        const positionBuffer1 = this.side1.getPositionAttribute().array as Float32Array;
        const positionBuffer2 = this.side2.getPositionAttribute().array as Float32Array;
        this.offsetFaces(positions, normals, positionBuffer1, side1Offset, positionBuffer2,
             side2Offset);
        
        // Set buffer update flags.
        this.side1.getPositionAttribute().needsUpdate = true;
        this.side2.getPositionAttribute().needsUpdate = true;
        this.side1.getObject3D().geometry.computeVertexNormals();
        this.side2.getObject3D().geometry.computeVertexNormals();
        this.side1.getObject3D().geometry.computeBoundingSphere();
        this.side2.getObject3D().geometry.computeBoundingSphere();

        // Update the rounded edges.
        this.updateRoundedEdges();

        // Update the raw edges.
        this.updateRawEdges(isSubstrateHorizontal);
    }

    private updateRoundedEdges() {
        // Get position buffers of side1, side2, roundMesh1, and roundMesh2.
        const side1PositionBuffer = this.side1.getPositionAttribute();
        const side1NormalBuffer = this.side1.getNormalAttribute();
        const side2PositionBuffer = this.side2.getPositionAttribute();
        const roundMesh1Geometry = this.roundMesh1.geometry as THREE.BufferGeometry;
        const roundMesh1PositionBuffer = roundMesh1Geometry.attributes.position as
            THREE.BufferAttribute;
        const roundMesh2Geometry = this.roundMesh2.geometry as THREE.BufferGeometry;
        const roundMesh2PositionBuffer = roundMesh2Geometry.attributes.position as
            THREE.BufferAttribute;

        // Update positions of rounded edges.
        let index = 0;
        this.roundedEdgeInfos.forEach(roundedEdgeInfo => {
            index = this.updateRoundedEdge(index, roundedEdgeInfo,
                side1PositionBuffer, side2PositionBuffer, side1NormalBuffer,
                roundMesh1PositionBuffer, roundMesh2PositionBuffer);
        });

        // Update normals and bounds.
        roundMesh1PositionBuffer.needsUpdate = true;
        roundMesh2PositionBuffer.needsUpdate = true;
        roundMesh1Geometry.computeVertexNormals();
        roundMesh1Geometry.computeBoundingBox();
        roundMesh1Geometry.computeBoundingSphere();
        roundMesh2Geometry.computeVertexNormals();
        roundMesh2Geometry.computeBoundingBox();
        roundMesh2Geometry.computeBoundingSphere();
    }

    private updateRoundedEdge(
        index: number,
        roundedEdgeInfo: EdgeInfo,
        side1PositionBuffer: THREE.BufferAttribute,
        side2PositionBuffer: THREE.BufferAttribute,
        side1NormalBuffer: THREE.BufferAttribute,
        roundMesh1PositionBuffer: THREE.BufferAttribute,
        roundMesh2PositionBuffer: THREE.BufferAttribute,
    ): number {
        const { leftEdge, rightEdge, isStartVertex } = roundedEdgeInfo;
        const side1Bezier: Bezier = this.getBezier(side1PositionBuffer, side1NormalBuffer,
            leftEdge, rightEdge, isStartVertex);
        const side2Bezier: Bezier = this.getBezier(side2PositionBuffer, side1NormalBuffer,
            leftEdge, rightEdge, isStartVertex);

        // Update points on curve connecting left and right faces.
        for (let i = 0; i < ROUNDED_EDGE_SEGMENTS + 1; i++) {
            // Update positions.
            const fraction = i / ROUNDED_EDGE_SEGMENTS;
            const p1 = geom.evaluateBezier(side1Bezier, fraction);
            roundMesh1PositionBuffer.setXYZ(index, p1[0], p1[1], p1[2]);
            const p2 = geom.evaluateBezier(side2Bezier, fraction);
            roundMesh2PositionBuffer.setXYZ(index, p2[0], p2[1], p2[2]);
            index++;
        }
        return index;
    }

    private updateRawEdges(isSubstrateHorizontal: boolean) {
        // Get position buffers of side1, side2, and raw edge.
        const side1PositionBuffer = this.side1.getPositionAttribute();
        const side1NormalBuffer = this.side1.getNormalAttribute();
        const side2PositionBuffer = this.side2.getPositionAttribute();
        const rawEdgeGeometry = this.rawEdgeMesh.geometry as THREE.BufferGeometry;
        const rawEdgePositionBuffer = rawEdgeGeometry.attributes.position as
            THREE.BufferAttribute;
        const rawEdgeUvBuffer = rawEdgeGeometry.attributes.uv as THREE.BufferAttribute;
        const offsetMesh = this.triangleMesh!;

        // Update positions of raw edge strips.
        let index = 0;
        this.rawEdgeVertexIndices.forEach(vertexIndex => {
            // Use the 2D mesh's X or Y position as the U coordinate.
            const p = offsetMesh.vertices[vertexIndex].position2D;
            const u = p[isSubstrateHorizontal ? 1 : 0];

            // Copy one position from side1 and one from side2.
            rawEdgeUvBuffer.setX(index, u);
            rawEdgePositionBuffer.copyAt(index++, side1PositionBuffer, vertexIndex);
            rawEdgeUvBuffer.setX(index, u);
            rawEdgePositionBuffer.copyAt(index++, side2PositionBuffer, vertexIndex);

        });

        // Update positions of raw edge fans.
        this.rawEdgeFanInfos.forEach(rawEdgeFanInfo => {
            index = this.updateRawEdgeFan(index, rawEdgeFanInfo, side1PositionBuffer,
                side2PositionBuffer, side1NormalBuffer, rawEdgePositionBuffer, rawEdgeUvBuffer,
                isSubstrateHorizontal);
        });

        // Update normals and bounds.
        rawEdgePositionBuffer.needsUpdate = true;
        rawEdgeUvBuffer.needsUpdate = true;
        rawEdgeGeometry.computeVertexNormals();
        rawEdgeGeometry.computeBoundingBox();
        rawEdgeGeometry.computeBoundingSphere();
    }

    private updateRawEdgeFan(
        index: number,
        rawEdgeFanInfo: EdgeInfo,
        side1PositionBuffer: THREE.BufferAttribute,
        side2PositionBuffer: THREE.BufferAttribute,
        side1NormalBuffer: THREE.BufferAttribute,
        rawEdgePositionBuffer: THREE.BufferAttribute,
        rawEdgeUvBuffer: THREE.BufferAttribute,
        isSubstrateHorizontal: boolean,
    ) {
        
        const { leftEdge, rightEdge, isStartVertex } = rawEdgeFanInfo;
        const dihedrals = this.faceOffsetter!.dihedrals(this.solver!.dihedrals);
        const dihedral = dihedrals[this.triangleMesh!.edges.indexOf(leftEdge)];
        const isMountainFold = dihedral === undefined || dihedral >= 0;
        const positionBuffer = isMountainFold ? side1PositionBuffer : side2PositionBuffer;
        let bezier: Bezier = this.getBezier(positionBuffer, side1NormalBuffer,
            leftEdge, rightEdge, isStartVertex);
        if (isMountainFold !== isStartVertex) {
            bezier = bezier.reverse() as Bezier;
        }

        // Use the 2D mesh's X or Y position as the U coordinate.
        const offsetMesh = this.triangleMesh!;
        const vertexIndex = isStartVertex ? leftEdge.edgeVertex1 : leftEdge.edgeVertex2;
        const pos = offsetMesh.vertices[vertexIndex].position2D;
        const u = pos[isSubstrateHorizontal ? 1 : 0];
        const v = isMountainFold ? 0 : 1;
        
        // Update points on curve connecting left and right faces.
        for (let i = 0; i < ROUNDED_EDGE_SEGMENTS + 1; i++) {
            // Update positions.
            const fraction = i / ROUNDED_EDGE_SEGMENTS;
            const p = geom.evaluateBezier(bezier, fraction);
            rawEdgePositionBuffer.setXYZ(index, p[0], p[1], p[2]);
            rawEdgeUvBuffer.setXY(index, u, v);
            index++;
        }

        // Update points at fan apex (at start or end of crease edge).
        const thinMeshPositions = this.faceOffsetter!.positions(this.solver!.positions);
        const vertex1 = thinMeshPositions[leftEdge.edgeVertex1];
        const vertex2 = thinMeshPositions[leftEdge.edgeVertex2];
        const apex = isStartVertex ? vertex1 : vertex2;
        for (let i = 0; i < ROUNDED_EDGE_SEGMENTS; i++) {
            rawEdgePositionBuffer.setXYZ(index, apex[0], apex[1], apex[2]);
            rawEdgeUvBuffer.setXY(index, u, 0.5);
            index++;
        }

        return index;
    }

    /**
     * Returns the controls points of a 3D Bezier curve connecting the start (or end) of the
     * specified left edge to the end (or start) of the specified right edge.
     * @param positionBuffer The positions of triangle vertices. May be side 1 or side 2.
     * @param normalBuffer The normals of triangle vertices. Should always be side 1.
     * @param leftEdge The edge on the left side of the gap.
     * @param rightEdge The edge on the right side of the gap.
     * @param isStartVertex True if connecting the start of leftEdge to the end of rightEdge.
     */
    private getBezier(
        positionBuffer: THREE.BufferAttribute,
        normalBuffer: THREE.BufferAttribute,
        leftEdge: TriangleEdge,
        rightEdge: TriangleEdge,
        isStartVertex: boolean,
    ): Bezier {
        // A helper function to extract a vector from a buffer.
        const vecFromBuffer = (buffer: THREE.BufferAttribute, index: number): Vector3 =>
            [buffer.getX(index), buffer.getY(index), buffer.getZ(index)];

        // A helper function to get the position and tangent at one end of an edge.
        const getVertexAndTangent = (positionBuffer: THREE.BufferAttribute,
            normalBuffer: THREE.BufferAttribute, edge: TriangleEdge, isStartVertex: boolean) => {
            const v1 = vecFromBuffer(positionBuffer, edge.edgeVertex1);
            const v2 = vecFromBuffer(positionBuffer, edge.edgeVertex2);
            const edgeDir = vec.subtract(v2, v1);
            const vertex = isStartVertex ? v1 : v2;
            const vertexIndex = isStartVertex ? edge.edgeVertex1 : edge.edgeVertex2;
            const normal = vecFromBuffer(normalBuffer, vertexIndex);
            const tangent = vec.normalize(vec.cross(normal, edgeDir));
            return { vertex, tangent };
        };

        // Get the position and tangent at the left and right vertices.
        const { vertex: leftVertex, tangent: leftTangent } =
            getVertexAndTangent(positionBuffer, normalBuffer, leftEdge, isStartVertex);
        const { vertex: rightVertex, tangent: rightTangent } =
            getVertexAndTangent(positionBuffer, normalBuffer, rightEdge, !isStartVertex);

        // Check to see if the Bezier would form a loop.
        if (vec.dot(vec.subtract(rightVertex, leftVertex), leftTangent) < 0 &&
            vec.dot(vec.subtract(leftVertex, rightVertex), rightTangent) < 0) {
            // If so, just connect the vertices with a straight line.
            return [rightVertex, leftVertex];
        }

        // Construct the tangent handles of the Bezier curve. Scale the tangents so that the
        // midpoint of the Bezier curve interpolates the center of a circular arc with the same
        // endpoints and tangents. This closely matches a circular arc whenever there are no extra
        // offsets introduced by FaceOffsetter.
        const cosineFullAngle = Math.max(-1, Math.min(-vec.dot(leftTangent, rightTangent), 1));
        const cosineHalfAngle = Math.sqrt((1 + cosineFullAngle) / 2);
        const lengthOfTangentSum = vec.length(vec.add(leftTangent, rightTangent));
        const scale = lengthOfTangentSum === 0 ? 1 / 3 :
            4 * (1 - cosineHalfAngle) / (3 * lengthOfTangentSum);
        const tangentLength = scale * vec.distance(leftVertex, rightVertex);
        const leftHandle = vec.add(leftVertex, vec.multiply(leftTangent, tangentLength));
        const rightHandle = vec.add(rightVertex, vec.multiply(rightTangent, tangentLength));

        // Return the Bezier control points.
        return [rightVertex, rightHandle, leftHandle, leftVertex];
    }

    private getBoundaryEdgeLoops() {
        const hasVisitedEdge: boolean[] = [];
        const boundaryEdgeLoops: CreaseEdge[][] = [];
        this.crease!.edges.forEach((creaseEdge) => {
            if (!hasVisitedEdge[creaseEdge.index] && creaseEdge.isBoundary) {
                const boundaryEdgeLoop: CreaseEdge[] = [];
                const startVertex = creaseEdge.vertex1;
                let currentEdge = creaseEdge;
                do {
                    hasVisitedEdge[currentEdge.index] = true;
                    boundaryEdgeLoop.push(currentEdge);
                    currentEdge = ThickenedMesh.getNextBoundaryEdge(currentEdge);
                } while (currentEdge.vertex1 !== startVertex);
                boundaryEdgeLoops.push(boundaryEdgeLoop);
            }
        });
        return boundaryEdgeLoops;
    }

    private static getNextBoundaryEdge(edge: CreaseEdge): CreaseEdge {
        if (!edge.isBoundary) {
            throw new Error('Only pass boundary edges to getNextBoundaryEdge.');
        }
        const vertex = edge.vertex2;
        const index = vertex.incidentEdges.findIndex(edgeRef => edgeRef.edge === edge);
        if (index < 0) {
            throw new Error('Inconsistent vertex/edge information.');
        }
        const nextEdgeRef = vertex.getNextEdgeRef(vertex.incidentEdges[index]);
        const nextEdge = nextEdgeRef.edge;
        if (!nextEdge.isBoundary) {
            throw new Error('Next edge after a boundary edge should also be a boundary edge.');
        }
        return nextEdge;
    }

    private offsetFaces(positions: Vector3[], normals: Float32Array,
        positionBuffer1: Float32Array, side1Offset: number,
        positionBuffer2: Float32Array, side2Offset: number) {
        // Offset side1 outward and side2 inward.
        positions.forEach((position, i) => {
            const offsetX = normals[3 * i];
            const offsetY = normals[3 * i + 1];
            const offsetZ = normals[3 * i + 2];
            positionBuffer1[3 * i] = position[0] + side1Offset * offsetX;
            positionBuffer1[3 * i + 1] = position[1] + side1Offset * offsetY;
            positionBuffer1[3 * i + 2] = position[2] + side1Offset * offsetZ;
            positionBuffer2[3 * i] = position[0] - side2Offset * offsetX;
            positionBuffer2[3 * i + 1] = position[1] - side2Offset * offsetY;
            positionBuffer2[3 * i + 2] = position[2] - side2Offset * offsetZ;
        });
    }

    setArtwork(exteriorArtwork: THREE.Texture | null, interiorArtwork: THREE.Texture | null) {
        this.side1Material.map = exteriorArtwork;
        this.side2Material.map = interiorArtwork;
        this.side1Material.needsUpdate = true;
        this.side2Material.needsUpdate = true;
    }

    updateSubstrateTexture(substrateTexture: THREE.Texture | null) {
        this.rawEdgeMaterial.map = substrateTexture;
        this.rawEdgeMaterial.needsUpdate = true;
    }

    getObject3Ds() {
        return [
            this.side1.getObject3D(),
            this.side2.getObject3D(),
            this.roundMesh1,
            this.roundMesh2,
            this.rawEdgeMesh,
        ];
    }

    setPreviewMode(previewMode: boolean) {
        this.getObject3Ds().forEach(object3D => {
            object3D.castShadow = previewMode;
        });
    }

    show() {
        this.getObject3Ds().forEach((object3D) => {
            object3D.visible = true;
        });
    }

    hide() {
        this.getObject3Ds().forEach((object3D) => {
            object3D.visible = false;
        });
    }
};