import { action } from 'mobx';
import React, { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Platform } from './Platform';

/** Properties of the KeyboardShortcut component. */
interface KeyboardShortcutProps {
    /** A comma-separated list of shortcut keys. */
    keys: string;

    /** The event handler to call when any of the shortcut keys is pressed. */
    onPressed: (arg?: any) => void;

    /** The event handler to call when any of the shortcut keys is released. */
    onReleased?: (arg?: any) => void;

    /** An optional argument that is passed to the event handler. */
    arg?: any;
}

/**
 * A component that renders nothing, but executes an event handler when a particular keyboard
 * shortcut is pressed.
 * 
 * Note that the `keys` property can be set to a comma-separated list of shortcut keys, as in the
 * following example:
 * 
 *      <KeyboardShortcut keys="a, alt+shift+d, cmdOrCtrl+e" onPressed={this.selectAll} />
 * 
 * Valid modifiers include "alt" (or "option"), "ctrl", and "shift", as well as "meta" (or "cmd"),
 * which refers to the Command key on macOS and the Windows key on Windows. The special modifier
 * "cmdOrCtrl" is replaced by "cmd" on macOS and "ctrl" on Windows.
 * 
 * See https://www.npmjs.com/package/react-keyboard-event-handler for more details.
 */
export class KeyboardShortcut extends Component<KeyboardShortcutProps> {
    render() {
        const allKeys = this.props.keys.split(',').map(key => key.trim());
        const allModifiedKeys = allKeys.map(key => this._getModifiedKey(key));
        return (
            <>
                <KeyboardEventHandler handleKeys={allModifiedKeys} onKeyEvent={this._onKeyDown}
                    handleFocusableElements={true} />
                {this.props.onReleased &&
                    <KeyboardEventHandler handleKeys={allModifiedKeys} handleEventType="keyup"
                        onKeyEvent={this._onKeyUp} handleFocusableElements={true} />
                }
            </>
        );
    }

    private _getModifiedKey(key: string): string {
        // Replace 'cmdOrCtrl' with 'meta' or 'ctrl', depending on the user's operating system.
        const cmdOrCtrl = Platform.IS_MAC ? 'meta' : 'ctrl';
        return key.replace(/cmdOrCtrl/i, cmdOrCtrl);
    }

    @action.bound
    private _onKeyDown(key: string, event: KeyboardEvent) {
        // Ignore events when an editable element has keyboard focus.
        const editableElements = ['INPUT', 'SELECT', 'TEXTAREA'];
        if (event.target && ('nodeName' in event.target)
            && editableElements.includes(event.target['nodeName'])) {
            return;
        }

        // If it's not a repeat key event, call the onPressed handler.
        if (!event.repeat) {
            this.props.onPressed(this.props.arg);
        }

        // Mark the event as handled.
        event.preventDefault();
        event.stopPropagation();
    }

    @action.bound
    private _onKeyUp(key: string, event: KeyboardEvent) {
        // Ignore events when an editable element has keyboard focus.
        const editableElements = ['INPUT', 'SELECT', 'TEXTAREA'];
        if (event.target && ('nodeName' in event.target)
            && editableElements.includes(event.target['nodeName'])) {
            return;
        }

        // If it's not a repeat key event, call the onReleased handler.
        if (!event.repeat && this.props.onReleased) {
            this.props.onReleased(this.props.arg);
        }

        // Mark the event as handled.
        event.preventDefault();
        event.stopPropagation();
    }
}
