/**
 * Adapted from the three.js GLTF exporter:
 * https://github.com/mrdoob/three.js/blob/master/examples/js/exporters/GLTFExporter.js
 */

import * as THREE from 'three';
import PromiseFileReader from 'promise-file-reader';

// Silence warnings in production builds.
let warn = console.warn;
if (process.env.NODE_ENV === 'production') {
    warn = (...args: any[]) => {};
}

interface GLTFSpecularGlossinessMaterial extends THREE.MeshBasicMaterial {
    specular: THREE.Color;
    specularMap: THREE.Texture;
    glossiness: number;
}

//------------------------------------------------------------------------------
// Constants
//------------------------------------------------------------------------------
const WEBGL_CONSTANTS = {
    POINTS: 0x0000,
    LINES: 0x0001,
    LINE_LOOP: 0x0002,
    LINE_STRIP: 0x0003,
    TRIANGLES: 0x0004,
    TRIANGLE_STRIP: 0x0005,
    TRIANGLE_FAN: 0x0006,

    UNSIGNED_BYTE: 0x1401,
    UNSIGNED_SHORT: 0x1403,
    FLOAT: 0x1406,
    UNSIGNED_INT: 0x1405,
    ARRAY_BUFFER: 0x8892,
    ELEMENT_ARRAY_BUFFER: 0x8893,

    NEAREST: 0x2600,
    LINEAR: 0x2601,
    NEAREST_MIPMAP_NEAREST: 0x2700,
    LINEAR_MIPMAP_NEAREST: 0x2701,
    NEAREST_MIPMAP_LINEAR: 0x2702,
    LINEAR_MIPMAP_LINEAR: 0x2703,

    CLAMP_TO_EDGE: 33071,
    MIRRORED_REPEAT: 33648,
    REPEAT: 10497
};

function getWebGLFilter(filter: THREE.TextureFilter): number {
    switch (filter) {
        case THREE.NearestFilter: return WEBGL_CONSTANTS.NEAREST;
        case THREE.NearestMipmapNearestFilter: return WEBGL_CONSTANTS.NEAREST_MIPMAP_NEAREST;
        case THREE.NearestMipmapLinearFilter: return WEBGL_CONSTANTS.NEAREST_MIPMAP_LINEAR;
        case THREE.LinearFilter: return WEBGL_CONSTANTS.LINEAR;
        case THREE.LinearMipmapNearestFilter: return WEBGL_CONSTANTS.LINEAR_MIPMAP_NEAREST;
        case THREE.LinearMipmapLinearFilter: return WEBGL_CONSTANTS.LINEAR_MIPMAP_LINEAR;
        default: throw new Error(`Unknown texture filter ${filter}.`);
    }
}

function getWebGLWrapping(wrapping: THREE.Wrapping): number {
    switch (wrapping) {
        case THREE.ClampToEdgeWrapping: return WEBGL_CONSTANTS.CLAMP_TO_EDGE;
        case THREE.RepeatWrapping: return WEBGL_CONSTANTS.REPEAT;
        case THREE.MirroredRepeatWrapping: return WEBGL_CONSTANTS.MIRRORED_REPEAT;
        default: throw new Error(`Unknown wrapping ${wrapping}.`);
    }
}

export type GLTFExporterOptions = {
    binary?: boolean;
    trs?: boolean;
    onlyVisible?: boolean;
    truncateDrawRange?: boolean;
    embedImages?: boolean;
    maxTextureSize?: number;
    forceIndices?: boolean;
    forcePowerOfTwoTextures?: boolean;
    includeCustomExtensions?: boolean;
    objectTransform?: THREE.Matrix4;
};

const DEFAULT_OPTIONS = {
    binary: false,
    trs: false,
    onlyVisible: true,
    truncateDrawRange: true,
    embedImages: true,
    maxTextureSize: Infinity,
    forceIndices: false,
    forcePowerOfTwoTextures: false,
    includeCustomExtensions: false,
    objectTransform: new THREE.Matrix4()
};

type MeshLike = THREE.LineSegments | THREE.LineLoop | THREE.Line | THREE.Points | THREE.Mesh;

//------------------------------------------------------------------------------
// GLTF Exporter
//------------------------------------------------------------------------------
export class GLTFExporter {
    private options!: typeof DEFAULT_OPTIONS;
    private outputJSON: any;
    private byteOffset = 0;
    private buffers: ArrayBuffer[] = [];
    private pending: Promise<void>[] = [];
    private nodeMap = new Map<THREE.Object3D, number>();
    private extensionsUsed: Record<string, boolean> = {};
    private cachedData = {
        meshes: new Map<string, number>(),
        attributes: new Map<string, number>(),
        attributesNormalized: new Map<THREE.BufferAttribute, THREE.BufferAttribute>(),
        materials: new Map<THREE.Material, number>(),
        textures: new Map<THREE.Texture, number>(),
        images: new Map<HTMLImageElement, any>()
    };
    private cachedCanvas!: HTMLCanvasElement;
    private uids = new Map<object, string>();
    private uid = 0;

    /**
     * Convert meshes to GLTF output.
     * @param  {THREE.Mesh[]]} meshes An array of THREE.Mesh.
     * @param  {GLTFExporterOptions} options Export options.
     */
    async export(meshes: THREE.Mesh[], options?: GLTFExporterOptions): Promise<Blob> {
        this.options = Object.assign({}, DEFAULT_OPTIONS, options);
        this.outputJSON = {
            asset: {
                version: '2.0',
                generator: 'Fantastic Fold'
            }
        };

        this.processInput(meshes);

        await Promise.all(this.pending);

        // Merge buffers.
        const blob = new Blob(this.buffers, { type: 'application/octet-stream' });

        // Declare extensions.
        const extensionsUsedList = Object.keys(this.extensionsUsed);
        if (extensionsUsedList.length > 0) {
            this.outputJSON.extensionsUsed = extensionsUsedList;
        }

        if (this.outputJSON.buffers && this.outputJSON.buffers.length > 0) {
            // Update bytelength of the single buffer.
            this.outputJSON.buffers[0].byteLength = blob.size;

            if (this.options.binary === true) {
                // https://github.com/KhronosGroup/glTF/blob/master/specification/2.0/README.md#glb-file-format-specification
                const GLB_HEADER_BYTES = 12;
                const GLB_HEADER_MAGIC = 0x46546C67;
                const GLB_VERSION = 2;
                const GLB_CHUNK_PREFIX_BYTES = 8;
                const GLB_CHUNK_TYPE_JSON = 0x4E4F534A;
                const GLB_CHUNK_TYPE_BIN = 0x004E4942;

                // Binary chunk.
                const arrayBuffer = await PromiseFileReader.readAsArrayBuffer(blob);
                const binaryChunk = this.getPaddedArrayBuffer(arrayBuffer);
                const binaryChunkPrefix = new DataView(new ArrayBuffer(GLB_CHUNK_PREFIX_BYTES));
                binaryChunkPrefix.setUint32(0, binaryChunk.byteLength, true);
                binaryChunkPrefix.setUint32(4, GLB_CHUNK_TYPE_BIN, true);

                // JSON chunk.
                const jsonChunk = this.getPaddedArrayBuffer(this.stringToArrayBuffer(JSON.stringify(this.outputJSON)), 0x20);
                const jsonChunkPrefix = new DataView(new ArrayBuffer(GLB_CHUNK_PREFIX_BYTES));
                jsonChunkPrefix.setUint32(0, jsonChunk.byteLength, true);
                jsonChunkPrefix.setUint32(4, GLB_CHUNK_TYPE_JSON, true);

                // GLB header.
                const header = new ArrayBuffer(GLB_HEADER_BYTES);
                const headerView = new DataView(header);
                headerView.setUint32(0, GLB_HEADER_MAGIC, true);
                headerView.setUint32(4, GLB_VERSION, true);
                const totalByteLength = GLB_HEADER_BYTES
                    + jsonChunkPrefix.byteLength + jsonChunk.byteLength
                    + binaryChunkPrefix.byteLength + binaryChunk.byteLength;
                headerView.setUint32(8, totalByteLength, true);

                const glbBlob = new Blob([
                    header,
                    jsonChunkPrefix,
                    jsonChunk,
                    binaryChunkPrefix,
                    binaryChunk
                ], { type: 'application/octet-stream' });
                return glbBlob;
            } else {
                const base64data = await PromiseFileReader.readAsDataURL(blob);
                this.outputJSON.buffers[0].uri = base64data;
                return new Blob([JSON.stringify(this.outputJSON)]);
            }
        } else {
            return new Blob([JSON.stringify(this.outputJSON)]);
        }
    }

    /**
     * Assign and return a temporal unique id for an object
     * especially which doesn't have .uuid
     * @param  {Object} object
     * @return {string}
     */
    getUID(object: object): string {
        if (!this.uids.has(object)) {
            this.uids.set(object, this.uid.toString());
            this.uid++;
        }
        return this.uids.get(object) as string;
    }

    /**
     * Compare two arrays
     * @param  {Array} array1 Array 1 to compare
     * @param  {Array} array2 Array 2 to compare
     * @return {Boolean}        Returns true if both arrays are equal
     */
    equalArray(array1: any[], array2: any[]): boolean {
        return (array1.length === array2.length) && array1.every((element, index) => {
            return element === array2[index];
        });
    }

    /**
     * Converts a string to an ArrayBuffer.
     * @param  {string} text
     * @return {ArrayBuffer}
     */
    stringToArrayBuffer(text: string): ArrayBuffer {
        if (window.TextEncoder !== undefined) {
            return new TextEncoder().encode(text).buffer;
        }

        const array = new Uint8Array(new ArrayBuffer(text.length));
        for (let i = 0, il = text.length; i < il; i++) {
            const value = text.charCodeAt(i);

            // Replacing multi-byte character with space(0x20).
            array[i] = value > 0xFF ? 0x20 : value;
        }
        return array.buffer;
    }

    /**
     * Get the min and max vectors from the given attribute
     * @param  {THREE.BufferAttribute} attribute Attribute to find the min/max in range from start to start + count
     * @param  {Integer} start
     * @param  {Integer} count
     * @return {Object} Object containing the `min` and `max` values (As an array of attribute.itemSize components)
     */
    getMinMax(attribute: THREE.BufferAttribute, start: number, count: number): { min: number[], max: number[] } {
        const output = {
            min: new Array<number>(attribute.itemSize).fill(Number.POSITIVE_INFINITY),
            max: new Array<number>(attribute.itemSize).fill(Number.NEGATIVE_INFINITY)
        };

        for (let i = start; i < start + count; i++) {
            for (let a = 0; a < attribute.itemSize; a++) {
                const value = attribute.array[i * attribute.itemSize + a];
                output.min[a] = Math.min(output.min[a], value);
                output.max[a] = Math.max(output.max[a], value);
            }
        }
        return output;
    }

    /**
     * Checks if image size is POT.
     *
     * @param {Image} image The image to be checked.
     * @returns {Boolean} Returns true if image size is POT.
     *
     */
    isPowerOfTwo(image: HTMLImageElement | HTMLCanvasElement): boolean {
        return THREE.MathUtils.isPowerOfTwo(image.width) && THREE.MathUtils.isPowerOfTwo(image.height);
    }

    /**
     * Checks if normal attribute values are normalized.
     *
     * @param {THREE.BufferAttribute} normal
     * @returns {Boolean}
     *
     */
    isNormalizedNormalAttribute(normal: THREE.BufferAttribute): boolean {
        if (this.cachedData.attributesNormalized.has(normal)) {
            return false;
        }

        const v = new THREE.Vector3();
        for (let i = 0, il = normal.count; i < il; i++) {
            // 0.0005 is from glTF-validator
            if (Math.abs(v.fromArray(normal.array, i * 3).length() - 1.0) > 0.0005) {
                return false;
            }
        }
        return true;
    }

    /**
     * Creates normalized normal buffer attribute.
     *
     * @param {THREE.BufferAttribute} normal
     * @returns {THREE.BufferAttribute}
     *
     */
    createNormalizedNormalAttribute(normal: THREE.BufferAttribute): THREE.BufferAttribute {
        if (this.cachedData.attributesNormalized.has(normal)) {
            return this.cachedData.attributesNormalized.get(normal) as THREE.BufferAttribute;
        }

        const attribute = normal.clone();
        const v = new THREE.Vector3();
        for (let i = 0, il = attribute.count; i < il; i++) {
            v.fromArray(attribute.array, i * 3);
            if (v.x === 0 && v.y === 0 && v.z === 0) {
                // if values can't be normalized set (1, 0, 0)
                v.setX(1.0);
            } else {
                v.normalize();
            }
            v.toArray(attribute.array, i * 3);
        }
        this.cachedData.attributesNormalized.set(normal, attribute);
        return attribute;
    }

    /**
     * Get the required size + padding for a buffer, rounded to the next 4-byte boundary.
     * https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#data-alignment
     *
     * @param {Integer} bufferSize The size the original buffer.
     * @returns {Integer} new buffer size with required padding.
     *
     */
    getPaddedBufferSize(bufferSize: number): number {
        return Math.ceil(bufferSize / 4) * 4;
    }

    /**
     * Returns a buffer aligned to 4-byte boundary.
     *
     * @param {ArrayBuffer} arrayBuffer Buffer to pad
     * @param {Integer} paddingByte (Optional)
     * @returns {ArrayBuffer} The same buffer if it's already aligned to 4-byte boundary or a new buffer
     */
    getPaddedArrayBuffer(arrayBuffer: ArrayBuffer, paddingByte: number = 0): ArrayBuffer {
        const paddedLength = this.getPaddedBufferSize(arrayBuffer.byteLength);
        if (paddedLength !== arrayBuffer.byteLength) {
            const array = new Uint8Array(paddedLength);
            array.set(new Uint8Array(arrayBuffer));
            if (paddingByte !== 0) {
                for (let i = arrayBuffer.byteLength; i < paddedLength; i++) {
                    array[i] = paddingByte;
                }
            }
            return array.buffer;
        }
        return arrayBuffer;
    }

    /**
     * Serializes a userData.
     *
     * @param {THREE.Object3D|THREE.Material} object
     * @param {Object} gltfProperty
     */
    serializeUserData(object: THREE.Object3D | THREE.Material | THREE.BufferGeometry, gltfProperty: any): void {
        if (Object.keys(object.userData).length === 0) {
            return;
        }

        try {
            const json = JSON.parse(JSON.stringify(object.userData));
            if (this.options.includeCustomExtensions && json.gltfExtensions) {
                if (gltfProperty.extensions === undefined) {
                    gltfProperty.extensions = {};
                }
                for (const extensionName in json.gltfExtensions) {
                    gltfProperty.extensions[extensionName] = json.gltfExtensions[extensionName];
                    this.extensionsUsed[extensionName] = true;
                }
                delete json.gltfExtensions;
            }

            if (Object.keys(json).length > 0) {
                gltfProperty.extras = json;
            }
        } catch (error) {
            warn(`GLTFExporter: userData of "${object.name}" ` +
                `won't be serialized because of JSON.stringify error - ${error.message}`);
        }
    }

    /**
     * Applies a texture transform, if present, to the map definition. Requires
     * the KHR_texture_transform extension.
     */
    applyTextureTransform(mapDef: any, texture: THREE.Texture): void {
        // TODO: Re-enable texture transforms once Dimension supports negative texture scaling.
        /*
        let didTransform = false;
        const transformDef: any = {};
        if (texture.offset.x !== 0 || texture.offset.y !== 0) {
            transformDef.offset = texture.offset.toArray();
            didTransform = true;
        }
        if (texture.rotation !== 0) {
            transformDef.rotation = texture.rotation;
            didTransform = true;
        }
        if (texture.repeat.x !== 1 || texture.repeat.y !== 1) {
            transformDef.scale = texture.repeat.toArray();
            transformDef.scale = transformDef.scale.map((s: number) => Math.max(s, 0));
            didTransform = true;
        }
        if (didTransform) {
            mapDef.extensions = mapDef.extensions || {};
            mapDef.extensions['KHR_texture_transform'] = transformDef;
            this.extensionsUsed['KHR_texture_transform'] = true;
        }
        */
    }

    /**
     * Process a buffer to append to the default one.
     * @param  {ArrayBuffer} buffer
     * @return {Integer}
     */
    processBuffer(buffer: ArrayBuffer): number {
        if (!this.outputJSON.buffers) {
            this.outputJSON.buffers = [{ byteLength: 0 }];
        }

        // All buffers are merged before export.
        this.buffers.push(buffer);
        return 0;
    }

    /**
     * Process and generate a BufferView
     * @param  {THREE.BufferAttribute} attribute
     * @param  {number} componentType
     * @param  {number} start
     * @param  {number} count
     * @param  {number} target (Optional) Target usage of the BufferView
     * @return {Object}
     */
    processBufferView(
        attribute: THREE.BufferAttribute,
        componentType: number,
        start: number,
        count: number,
        target?: number
    ): { id: number, byteOffset: number } {
        if (!this.outputJSON.bufferViews) {
            this.outputJSON.bufferViews = [];
        }

        // Create a new dataview and dump the attribute's array into it
        let componentSize: number;
        if (componentType === WEBGL_CONSTANTS.UNSIGNED_BYTE) {
            componentSize = 1;
        } else if (componentType === WEBGL_CONSTANTS.UNSIGNED_SHORT) {
            componentSize = 2;
        } else {
            componentSize = 4;
        }

        const byteLength = this.getPaddedBufferSize(count * attribute.itemSize * componentSize);
        const dataView = new DataView(new ArrayBuffer(byteLength));
        let offset = 0;
        for (let i = start; i < start + count; i++) {
            for (let a = 0; a < attribute.itemSize; a++) {
                // @TODO Fails on InterleavedBufferAttribute, and could probably be
                // optimized for normal BufferAttribute.
                const value = attribute.array[i * attribute.itemSize + a];
                if (componentType === WEBGL_CONSTANTS.FLOAT) {
                    dataView.setFloat32(offset, value, true);
                } else if (componentType === WEBGL_CONSTANTS.UNSIGNED_INT) {
                    dataView.setUint32(offset, value, true);
                } else if (componentType === WEBGL_CONSTANTS.UNSIGNED_SHORT) {
                    dataView.setUint16(offset, value, true);
                } else if (componentType === WEBGL_CONSTANTS.UNSIGNED_BYTE) {
                    dataView.setUint8(offset, value);
                }
                offset += componentSize;
            }
        }

        const gltfBufferView: any = {
            buffer: this.processBuffer(dataView.buffer),
            byteOffset: this.byteOffset,
            byteLength: byteLength
        };
        if (target !== undefined) {
            gltfBufferView.target = target;
        }
        if (target === WEBGL_CONSTANTS.ARRAY_BUFFER) {
            // Only define byteStride for vertex attributes.
            gltfBufferView.byteStride = attribute.itemSize * componentSize;
        }
        this.byteOffset += byteLength;
        this.outputJSON.bufferViews.push(gltfBufferView);

        // @TODO Merge bufferViews where possible.
        const output = {
            id: this.outputJSON.bufferViews.length - 1,
            byteOffset: 0
        };
        return output;
    }

    /**
     * Process and generate a BufferView from an image Blob.
     * @param {Blob} blob
     * @return {Promise<Integer>}
     */
    async processBufferViewImage(blob: Blob): Promise<number> {
        if (!this.outputJSON.bufferViews) {
            this.outputJSON.bufferViews = [];
        }
        const result = await PromiseFileReader.readAsArrayBuffer(blob);
        const buffer = this.getPaddedArrayBuffer(result);
        const bufferView = {
            buffer: this.processBuffer(buffer),
            byteOffset: this.byteOffset,
            byteLength: buffer.byteLength
        };
        this.byteOffset += buffer.byteLength;
        this.outputJSON.bufferViews.push(bufferView);
        return this.outputJSON.bufferViews.length - 1;
    }

    /**
     * Process attribute to generate an accessor
     * @param  {THREE.BufferAttribute} attribute Attribute to process
     * @param  {THREE.BufferGeometry} geometry (Optional) Geometry used for truncated draw range
     * @param  {Integer} start (Optional)
     * @param  {Integer} count (Optional)
     * @return {Integer | undefined} Index of the processed accessor on the "accessors" array
     */
    processAccessor(
        attribute: THREE.BufferAttribute,
        geometry?: THREE.BufferGeometry,
        start?: number,
        count?: number
    ): number | undefined {
        const types: Record<number, string> = {
            1: 'SCALAR',
            2: 'VEC2',
            3: 'VEC3',
            4: 'VEC4',
            16: 'MAT4'
        };

        // Detect the component type of the attribute array (float, uint or ushort)
        let componentType;
        if (attribute.array.constructor === Float32Array) {
            componentType = WEBGL_CONSTANTS.FLOAT;
        } else if (attribute.array.constructor === Uint32Array) {
            componentType = WEBGL_CONSTANTS.UNSIGNED_INT;
        } else if (attribute.array.constructor === Uint16Array) {
            componentType = WEBGL_CONSTANTS.UNSIGNED_SHORT;
        } else if (attribute.array.constructor === Uint8Array) {
            componentType = WEBGL_CONSTANTS.UNSIGNED_BYTE;
        } else {
            throw new Error('GLTFExporter: Unsupported bufferAttribute component type.');
        }

        if (start === undefined) {
            start = 0;
        }
        if (count === undefined)
        {
            count = attribute.count;
        }

        // @TODO Indexed buffer geometry with drawRange not supported yet
        if (this.options.truncateDrawRange && geometry !== undefined && geometry.index === null) {
            const end = start + count;
            const end2 = geometry.drawRange.count === Infinity
                ? attribute.count
                : geometry.drawRange.start + geometry.drawRange.count;
            start = Math.max(start, geometry.drawRange.start);
            count = Math.min(end, end2) - start;
            if (count < 0) {
                count = 0;
            }
        }

        // Skip creating an accessor if the attribute doesn't have data to export
        if (count === 0) {
            return undefined;
        }

        const minMax = this.getMinMax(attribute, start, count);

        // If geometry isn't provided, don't infer the target usage of the bufferView. For
        // animation samplers, target must not be set.
        let bufferViewTarget: number | undefined;
        if (geometry !== undefined) {
            bufferViewTarget = attribute === geometry.index ? WEBGL_CONSTANTS.ELEMENT_ARRAY_BUFFER : WEBGL_CONSTANTS.ARRAY_BUFFER;
        }

        const bufferView = this.processBufferView(attribute, componentType, start, count, bufferViewTarget);
        const gltfAccessor = {
            bufferView: bufferView.id,
            byteOffset: bufferView.byteOffset, // TODO
            componentType: componentType,
            count: count,
            max: minMax.max,
            min: minMax.min,
            type: types[attribute.itemSize]
        };

        if (!this.outputJSON.accessors) {
            this.outputJSON.accessors = [];
        }
        this.outputJSON.accessors.push(gltfAccessor);
        return this.outputJSON.accessors.length - 1;
    }

    /**
     * Process image
     * @param  {Image} image to process
     * @param  {Integer} format of the image (e.g. THREE.RGBFormat, THREE.RGBAFormat etc)
     * @param  {Boolean} flipY before writing out the image
     * @return {Integer}     Index of the processed texture in the "images" array
     */
    processImage(image: HTMLImageElement, format: number, flipY: boolean): number {
        if (!this.cachedData.images.has(image)) {
            this.cachedData.images.set(image, {});
        }

        const cachedImages = this.cachedData.images.get(image);
        const mimeType = format === THREE.RGBAFormat ? 'image/png' : 'image/jpeg';
        const key = `${mimeType}:flipY/${flipY.toString()}`;

        if (cachedImages[key] !== undefined) {
            return cachedImages[key];
        }

        if (!this.outputJSON.images) {
            this.outputJSON.images = [];
        }

        const gltfImage: any = { mimeType };
        if (this.options.embedImages) {
            const canvas = this.cachedCanvas = this.cachedCanvas || document.createElement('canvas');
            canvas.width = Math.min(image.width, this.options.maxTextureSize);
            canvas.height = Math.min(image.height, this.options.maxTextureSize);
            if (this.options.forcePowerOfTwoTextures && !this.isPowerOfTwo(canvas)) {
                warn('GLTFExporter: Resized non-power-of-two image.', image);
                canvas.width = THREE.MathUtils.floorPowerOfTwo(canvas.width);
                canvas.height = THREE.MathUtils.floorPowerOfTwo(canvas.height);
            }

            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            if (flipY === true) {
                ctx.translate(0, canvas.height);
                ctx.scale(1, - 1);
            }
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            if (this.options.binary === true) {
                this.pending.push(new Promise(resolve => {
                    // #215: IE and older Edge versions use msToBlob instead of toBlob.
                    // @ts-ignore
                    const toBlobMethod = canvas.toBlob || canvas.msToBlob;
                    toBlobMethod.call(canvas, blob => {
                        if (!blob) {
                            throw new Error('Failed to convert image to blob.');
                        }
                        this.processBufferViewImage(blob).then(bufferViewIndex => {
                            gltfImage.bufferView = bufferViewIndex;
                            resolve();
                        });
                    }, mimeType);
                }));
            } else {
                gltfImage.uri = canvas.toDataURL(mimeType);
            }
        } else {
            gltfImage.uri = image.src;
        }

        this.outputJSON.images.push(gltfImage);
        const index = this.outputJSON.images.length - 1;
        cachedImages[key] = index;
        return index;
    }

    /**
     * Process sampler
     * @param  {Texture} map Texture to process
     * @return {Integer}     Index of the processed texture in the "samplers" array
     */
    processSampler(map: THREE.Texture): number {
        if (!this.outputJSON.samplers) {
            this.outputJSON.samplers = [];
        }

        const gltfSampler = {
            magFilter: getWebGLFilter(map.magFilter),
            minFilter: getWebGLFilter(map.minFilter),
            wrapS: getWebGLWrapping(map.wrapS),
            wrapT: getWebGLWrapping(map.wrapT)

        };
        this.outputJSON.samplers.push(gltfSampler);
        return this.outputJSON.samplers.length - 1;
    }

    /**
     * Process texture
     * @param  {Texture} map Map to process
     * @return {Integer}     Index of the processed texture in the "textures" array
     */
    processTexture(map: THREE.Texture): number {
        if (this.cachedData.textures.has(map)) {
            return this.cachedData.textures.get(map) as number;
        }

        if (!this.outputJSON.textures) {
            this.outputJSON.textures = [];
        }

        const gltfTexture: any = {
            sampler: this.processSampler(map),
            source: this.processImage(map.image, map.format, map.flipY)
        };
        if (map.name) {
            gltfTexture.name = map.name;
        }
        this.outputJSON.textures.push(gltfTexture);
        const index = this.outputJSON.textures.length - 1;
        this.cachedData.textures.set(map, index);
        return index;
    }

    /**
     * Process material
     * @param  {THREE.Material} material Material to process
     * @return {Integer | undefined} Index of the processed material in the "materials" array
     */
    processMaterial(material: THREE.Material): number | undefined {
        if (this.cachedData.materials.has(material)) {
            return this.cachedData.materials.get(material) as number;
        }

        if (!this.outputJSON.materials) {
            this.outputJSON.materials = [];
        }
        if ((material instanceof THREE.ShaderMaterial) && material.type !== 'GLTFSpecularGlossinessMaterial') {
            warn('GLTFExporter: THREE.ShaderMaterial not supported.');
            return undefined;
        }
        const specularGlossinessMaterial = material as GLTFSpecularGlossinessMaterial;

        // @QUESTION Should we avoid including any attribute that has the default value?
        const gltfMaterial: any = {
            pbrMetallicRoughness: {}
        };

        if (material instanceof THREE.MeshBasicMaterial) {
            gltfMaterial.extensions = { KHR_materials_unlit: {} };
            this.extensionsUsed['KHR_materials_unlit'] = true;
        } else if (material.type === 'GLTFSpecularGlossinessMaterial') {
            gltfMaterial.extensions = { KHR_materials_pbrSpecularGlossiness: {} };
            this.extensionsUsed['KHR_materials_pbrSpecularGlossiness'] = true;
        } else if (material instanceof THREE.MeshStandardMaterial) {
            warn('GLTFExporter: Use MeshStandardMaterial or MeshBasicMaterial for best results.');
        }

        // pbrMetallicRoughness.baseColorFactor
        const color = (material as THREE.MeshBasicMaterial).color.toArray().concat([material.opacity]);
        if (!this.equalArray(color, [1, 1, 1, 1])) {
            gltfMaterial.pbrMetallicRoughness.baseColorFactor = color;
        }

        if (material instanceof THREE.MeshStandardMaterial) {
            gltfMaterial.pbrMetallicRoughness.metallicFactor = material.metalness;
            gltfMaterial.pbrMetallicRoughness.roughnessFactor = material.roughness;
        } else {
            gltfMaterial.pbrMetallicRoughness.metallicFactor = 0.0;
            gltfMaterial.pbrMetallicRoughness.roughnessFactor = 0.6;
        }

        // pbrSpecularGlossiness diffuse, specular and glossiness factor
        if (material.type === 'GLTFSpecularGlossinessMaterial') {
            if (gltfMaterial.pbrMetallicRoughness.baseColorFactor) {
                gltfMaterial.extensions.KHR_materials_pbrSpecularGlossiness.diffuseFactor = gltfMaterial.pbrMetallicRoughness.baseColorFactor;
            }
            const specularFactor = [1, 1, 1];
            specularGlossinessMaterial.specular.toArray(specularFactor, 0);
            gltfMaterial.extensions.KHR_materials_pbrSpecularGlossiness.specularFactor = specularFactor;
            gltfMaterial.extensions.KHR_materials_pbrSpecularGlossiness.glossinessFactor = specularGlossinessMaterial.glossiness;
        }

        // pbrMetallicRoughness.metallicRoughnessTexture
        const standardMaterial = material as THREE.MeshStandardMaterial;
        if (standardMaterial.metalnessMap || standardMaterial.roughnessMap) {
            if (standardMaterial.metalnessMap === standardMaterial.roughnessMap) {
                const metalRoughMapDef = { index: this.processTexture(standardMaterial.metalnessMap as THREE.Texture) };
                this.applyTextureTransform(metalRoughMapDef, standardMaterial.metalnessMap as THREE.Texture);
                gltfMaterial.pbrMetallicRoughness.metallicRoughnessTexture = metalRoughMapDef;
            } else {
                warn('GLTFExporter: Ignoring metalnessMap and roughnessMap because they are not the same Texture.');
            }

        }

        // pbrMetallicRoughness.baseColorTexture or pbrSpecularGlossiness diffuseTexture
        if (standardMaterial.map) {
            const baseColorMapDef = { index: this.processTexture(standardMaterial.map) };
            this.applyTextureTransform(baseColorMapDef, standardMaterial.map);
            if (material.type === 'GLTFSpecularGlossinessMaterial') {
                gltfMaterial.extensions.KHR_materials_pbrSpecularGlossiness.diffuseTexture = baseColorMapDef;
            }
            gltfMaterial.pbrMetallicRoughness.baseColorTexture = baseColorMapDef;
        }

        // pbrSpecularGlossiness specular map
        if (material.type === 'GLTFSpecularGlossinessMaterial' && specularGlossinessMaterial.specularMap) {
            const specularMapDef = { index: this.processTexture(specularGlossinessMaterial.specularMap) };
            this.applyTextureTransform(specularMapDef, specularGlossinessMaterial.specularMap);
            gltfMaterial.extensions.KHR_materials_pbrSpecularGlossiness.specularGlossinessTexture = specularMapDef;
        }

        if (material instanceof THREE.MeshBasicMaterial ||
            material instanceof THREE.LineBasicMaterial ||
            material instanceof THREE.PointsMaterial) {
            // Do nothing.
        } else {
            // emissiveFactor
            const emissive = standardMaterial.emissive.clone().multiplyScalar(standardMaterial.emissiveIntensity).toArray();
            if (!this.equalArray(emissive, [0, 0, 0])) {
                gltfMaterial.emissiveFactor = emissive;
            }

            // emissiveTexture
            if (standardMaterial.emissiveMap) {
                const emissiveMapDef = { index: this.processTexture(standardMaterial.emissiveMap) };
                this.applyTextureTransform(emissiveMapDef, standardMaterial.emissiveMap);
                gltfMaterial.emissiveTexture = emissiveMapDef;
            }
        }

        // normalTexture
        if (standardMaterial.normalMap) {
            const normalMapDef: any = { index: this.processTexture(standardMaterial.normalMap) };
            if (standardMaterial.normalScale && standardMaterial.normalScale.x !== -1) {
                if (standardMaterial.normalScale.x !== standardMaterial.normalScale.y) {
                    warn('GLTFExporter: Normal scale components are different, ignoring Y and exporting X.');
                }
                normalMapDef.scale = standardMaterial.normalScale.x;
            }

            this.applyTextureTransform(normalMapDef, standardMaterial.normalMap);
            gltfMaterial.normalTexture = normalMapDef;
        }

        // occlusionTexture
        if (standardMaterial.aoMap) {
            const occlusionMapDef: any = {
                index: this.processTexture(standardMaterial.aoMap),
                texCoord: 1
            };
            if (standardMaterial.aoMapIntensity !== 1.0) {
                occlusionMapDef.strength = standardMaterial.aoMapIntensity;
            }
            this.applyTextureTransform(occlusionMapDef, standardMaterial.aoMap);
            gltfMaterial.occlusionTexture = occlusionMapDef;
        }

        // alphaMode
        if (material.transparent) {
            gltfMaterial.alphaMode = 'BLEND';
        } else {
            if (material.alphaTest > 0.0) {
                gltfMaterial.alphaMode = 'MASK';
                gltfMaterial.alphaCutoff = material.alphaTest;
            }
        }

        // doubleSided
        if (material.side === THREE.DoubleSide) {
            gltfMaterial.doubleSided = true;
        }

        if (material.name !== '') {
            gltfMaterial.name = material.name;
        }

        this.serializeUserData(material, gltfMaterial);

        this.outputJSON.materials.push(gltfMaterial);
        const index = this.outputJSON.materials.length - 1;
        this.cachedData.materials.set(material, index);
        return index;
    }

    /**
     * Process mesh
     * @param  {THREE.LineSegments | THREE.THREE.Mesh} mesh Mesh to process
     * @return {Integer | undefined} Index of the processed mesh in the "meshes" array
     */
    processMesh(mesh: MeshLike): number | undefined {
        const cacheKey = mesh.uuid;
        if (this.cachedData.meshes.has(cacheKey)) {
            return this.cachedData.meshes.get(cacheKey) as number;
        }

        // Make sure we have a BufferGeometry
        let geometry: THREE.BufferGeometry;
        if (mesh.geometry instanceof THREE.BufferGeometry) {
            geometry = mesh.geometry;
        } else {
            warn('GLTFExporter: Exporting THREE.Geometry will increase file size. Use THREE.BufferGeometry instead.');
            geometry = new THREE.BufferGeometry();
            geometry.fromGeometry(mesh.geometry);
        }

        const isMultiMaterial = Array.isArray(mesh.material);
        if (isMultiMaterial && geometry.groups.length === 0) {
            // Dispose of geometry if needed.
            if (geometry !== mesh.geometry) {
                geometry.dispose();
            }
            return undefined;
        }

        // Use the correct mode
        const materials = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
        let mode;
        if (mesh instanceof THREE.LineSegments) {
            mode = WEBGL_CONSTANTS.LINES;
        } else if (mesh instanceof THREE.LineLoop) {
            mode = WEBGL_CONSTANTS.LINE_LOOP;
        } else if (mesh instanceof THREE.Line) {
            mode = WEBGL_CONSTANTS.LINE_STRIP;
        } else if (mesh instanceof THREE.Points) {
            mode = WEBGL_CONSTANTS.POINTS;
        } else {
            const wireframe = (materials[0] as THREE.MeshBasicMaterial).wireframe;
            mode = wireframe ? WEBGL_CONSTANTS.LINES : WEBGL_CONSTANTS.TRIANGLES;
        }

        const gltfMesh: any = {};
        const attributes: Record<string, number> = {};
        const primitives: any[] = [];
        const targets: Record<string, number>[] = [];

        // Conversion between attributes names in threejs and gltf spec
        const nameConversion: Record<string, string> = {
            uv: 'TEXCOORD_0',
            uv2: 'TEXCOORD_1',
            color: 'COLOR_0',
            skinWeight: 'WEIGHTS_0',
            skinIndex: 'JOINTS_0'
        };

        const originalNormal = geometry.getAttribute('normal');
        if (originalNormal instanceof THREE.InterleavedBufferAttribute) {
            throw new Error('InterleavedBufferAttribute is not supported.');
        }
        if (originalNormal !== undefined && !this.isNormalizedNormalAttribute(originalNormal)) {
            warn('GLTFExporter: Creating normalized normal attribute from the non-normalized one.');
            geometry.setAttribute('normal', this.createNormalizedNormalAttribute(originalNormal));
        }

        // @QUESTION Detect if .vertexColors = THREE.VertexColors?
        // For every attribute create an accessor
        let modifiedAttribute: THREE.BufferAttribute | undefined;
        for (let attributeName in geometry.attributes) {

            // Ignore morph target attributes, which are exported later.
            if (attributeName.substr(0, 5) === 'morph') {
                continue;
            }

            const attribute = geometry.attributes[attributeName];
            if (attribute instanceof THREE.InterleavedBufferAttribute) {
                throw new Error('InterleavedBufferAttribute is not supported.');
            }
            attributeName = nameConversion[attributeName] || attributeName.toUpperCase();

            // Prefix all geometry attributes except the ones specifically
            // listed in the spec; non-spec attributes are considered custom.
            var validVertexAttributes =
                /^(POSITION|NORMAL|TANGENT|TEXCOORD_\d+|COLOR_\d+|JOINTS_\d+|WEIGHTS_\d+)$/;
            if (!validVertexAttributes.test(attributeName)) {
                attributeName = '_' + attributeName;
            }

            if (this.cachedData.attributes.has(this.getUID(attribute))) {
                attributes[attributeName] = this.cachedData.attributes.get(this.getUID(attribute)) as number;
                continue;
            }

            // JOINTS_0 must be UNSIGNED_BYTE or UNSIGNED_SHORT.
            modifiedAttribute = undefined;
            const array = attribute.array;
            if (attributeName === 'JOINTS_0' &&
                !(array instanceof Uint16Array) &&
                !(array instanceof Uint8Array)) {
                warn('GLTFExporter: Attribute "skinIndex" converted to type UNSIGNED_SHORT.');
                modifiedAttribute = new THREE.BufferAttribute(new Uint16Array(array), attribute.itemSize, attribute.normalized);
            }

            const accessor = this.processAccessor(modifiedAttribute || attribute, geometry);
            if (accessor !== undefined) {
                attributes[attributeName] = accessor;
                this.cachedData.attributes.set(this.getUID(attribute), accessor);
            }
        }

        if (originalNormal !== undefined) {
            geometry.setAttribute('normal', originalNormal);
        }

        // Skip if no exportable attributes found
        if (Object.keys(attributes).length === 0) {
            // Dispose of geometry if needed.
            if (geometry !== mesh.geometry) {
                geometry.dispose();
            }
            return undefined;
        }

        // Morph targets
        if (mesh instanceof THREE.Mesh && mesh.morphTargetInfluences !== undefined &&
            mesh.morphTargetInfluences.length > 0) {
            const weights: number[] = [];
            const targetNames: string[] = [];
            const reverseDictionary: Record<number, string> = {};
            if (mesh.morphTargetDictionary !== undefined) {
                for (let key in mesh.morphTargetDictionary) {
                    reverseDictionary[mesh.morphTargetDictionary[key]] = key;
                }
            }

            for (let i = 0; i < mesh.morphTargetInfluences.length; ++i) {
                const target: Record<string, number> = {};
                let warned = false;
                for (let attributeName in geometry.morphAttributes) {
                    // glTF 2.0 morph supports only POSITION/NORMAL/TANGENT.
                    // Three.js doesn't support TANGENT yet.
                    if (attributeName !== 'position' && attributeName !== 'normal') {
                        if (!warned) {
                            warn('GLTFExporter: Only POSITION and NORMAL morph are supported.');
                            warned = true;
                        }
                        continue;
                    }

                    const attribute = geometry.morphAttributes[attributeName][i];
                    if (attribute instanceof THREE.InterleavedBufferAttribute) {
                        throw new Error('InterleavedBufferAttribute is not supported.');
                    }
                    if (attribute.count === 0) {
                        continue;
                    }
                    const gltfAttributeName = attributeName.toUpperCase();

                    // Three.js morph attribute has absolute values while the one of glTF has relative values.
                    //
                    // glTF 2.0 Specification:
                    // https://github.com/KhronosGroup/glTF/tree/master/specification/2.0#morph-targets
                    const baseAttribute = geometry.attributes[attributeName];
                    if (this.cachedData.attributes.has(this.getUID(attribute))) {
                        target[gltfAttributeName] = this.cachedData.attributes.get(this.getUID(attribute)) as number;
                        continue;
                    }

                    // Clones attribute not to override
                    const relativeAttribute = attribute.clone();
                    if (!geometry.morphTargetsRelative) {
                        for (let j = 0, jl = attribute.count; j < jl; j++) {
                            relativeAttribute.setXYZ(
                                j,
                                attribute.getX(j) - baseAttribute.getX(j),
                                attribute.getY(j) - baseAttribute.getY(j),
                                attribute.getZ(j) - baseAttribute.getZ(j)
                            );
                        }
                    }

                    target[gltfAttributeName] = this.processAccessor(relativeAttribute, geometry) as number;
                    this.cachedData.attributes.set(this.getUID(baseAttribute), target[gltfAttributeName]);
                }
                targets.push(target);
                weights.push(mesh.morphTargetInfluences[i]);
                if (mesh.morphTargetDictionary !== undefined) {
                    targetNames.push(reverseDictionary[i]);
                }
            }

            gltfMesh.weights = weights;
            if (targetNames.length > 0) {
                gltfMesh.extras = { targetNames };
            }
        }

        let forceIndices = this.options.forceIndices;
        if (!forceIndices && geometry.index === null && isMultiMaterial) {
            // temporal workaround.
            warn('GLTFExporter: Creating index for non-indexed multi-material mesh.');
            forceIndices = true;
        }

        let didForceIndices = false;
        if (geometry.index === null && forceIndices) {
            const indices: number[] = [];
            for (let i = 0, il = geometry.attributes.position.count; i < il; i++) {
                indices[i] = i;
            }
            geometry.setIndex(indices);
            didForceIndices = true;
        }

        const groups = isMultiMaterial ? geometry.groups : [{ materialIndex: 0, start: undefined, count: undefined }];
        for (let i = 0, il = groups.length; i < il; i++) {
            const primitive: any = {
                mode: mode,
                attributes: attributes,
            };
            this.serializeUserData(geometry, primitive);
            if (targets.length > 0) {
                primitive.targets = targets;
            }
            if (geometry.index !== null) {
                let cacheKey = this.getUID(geometry.index);
                if (groups[i].start !== undefined || groups[i].count !== undefined) {
                    cacheKey += ':' + groups[i].start + ':' + groups[i].count;
                }
                if (this.cachedData.attributes.has(cacheKey)) {
                    primitive.indices = this.cachedData.attributes.get(cacheKey);
                } else {
                    primitive.indices = this.processAccessor(geometry.index, geometry, groups[i].start, groups[i].count);
                    this.cachedData.attributes.set(cacheKey, primitive.indices);
                }
                if (primitive.indices === undefined) {
                    delete primitive.indices;
                }
            }

            const materialIndex = groups[i].materialIndex;
            if (materialIndex === undefined) {
                throw new Error('Undefined material index.');
            }
            const material = this.processMaterial(materials[materialIndex]);
            if (material !== undefined) {
                primitive.material = material;
            }
            primitives.push(primitive);
        }

        if (didForceIndices) {
            geometry.setIndex(null);
        }

        gltfMesh.primitives = primitives;
        if (mesh.name !== '') {
            gltfMesh.name = `${mesh.name} mesh`;
        }
        if (!this.outputJSON.meshes) {
            this.outputJSON.meshes = [];
        }
        this.outputJSON.meshes.push(gltfMesh);
        const index = this.outputJSON.meshes.length - 1;
        this.cachedData.meshes.set(cacheKey, index);

        // Dispose of geometry if needed.
        if (geometry !== mesh.geometry) {
            geometry.dispose();
        }
        return index;
    }

    /**
     * Process camera
     * @param  {THREE.Camera} camera Camera to process
     * @return {Integer}      Index of the processed mesh in the "camera" array
     */
    processCamera(camera: THREE.Camera): number {
        if (!this.outputJSON.cameras) {
            this.outputJSON.cameras = [];
        }

        let gltfCamera: any;
        if (camera instanceof THREE.OrthographicCamera) {
            gltfCamera = {
                type: 'orthographic',
                orthographic: {
                    xmag: camera.right * 2,
                    ymag: camera.top * 2,
                    zfar: camera.far <= 0 ? 0.001 : camera.far,
                    znear: camera.near < 0 ? 0 : camera.near
                }
            };
        } else if (camera instanceof THREE.PerspectiveCamera) {
            gltfCamera = {
                type: 'perspective',
                perspective: {
                    aspectRatio: camera.aspect,
                    yfov: THREE.MathUtils.degToRad(camera.fov),
                    zfar: camera.far <= 0 ? 0.001 : camera.far,
                    znear: camera.near < 0 ? 0 : camera.near
                }
            };
        } else {
            throw new Error(`Unsupported camera type "${camera.type}".`);
        }

        if (camera.name !== '') {
            gltfCamera.name = camera.name;
        }

        this.outputJSON.cameras.push(gltfCamera);
        return this.outputJSON.cameras.length - 1;
    }

    /**
     * Process Object3D node
     * @param  {THREE.Object3D} node Object3D to processNode
     * @return {Integer | undefined}      Index of the node in the nodes list
     */
    processNode(object: THREE.Object3D): number | undefined {
        if (!this.outputJSON.nodes) {
            this.outputJSON.nodes = [];
        }
        const gltfNode: any = {};
        if (this.options.trs) {
            const rotation = object.quaternion.toArray();
            if (!this.equalArray(rotation, [0, 0, 0, 1])) {
                gltfNode.rotation = rotation;
            }

            const position = object.position.toArray();
            if (!this.equalArray(position, [0, 0, 0])) {
                gltfNode.translation = position;
            }

            const scale = object.scale.toArray();
            if (!this.equalArray(scale, [1, 1, 1])) {
                gltfNode.scale = scale;
            }
        } else {
            if (object.matrixAutoUpdate) {
                object.updateMatrix();
            }
            const matrix = object.matrix.clone();
            // matrix.multiply(this.options.objectTransform);
            if (!this.equalArray(matrix.elements, [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1])) {
                gltfNode.matrix = matrix.elements;
            }
        }

        // We don't export empty strings name because it represents no-name in Three.js.
        if (object.name !== '') {
            gltfNode.name = object.name;
        }

        this.serializeUserData(object, gltfNode);

        let hasContent = false;
        if (object instanceof THREE.Mesh ||
            object instanceof THREE.Line ||
            object instanceof THREE.LineLoop ||
            object instanceof THREE.Points) {
            const mesh = this.processMesh(object);
            if (mesh !== undefined) {
                gltfNode.mesh = mesh;
                hasContent = true;
            }
        } else if (object instanceof THREE.Camera) {
            gltfNode.camera = this.processCamera(object);
            hasContent = true;
        } else if (object instanceof THREE.Light) {
            warn('GLTFExporter: Ignoring light.', object);
            return undefined;
        }

        if (object instanceof THREE.SkinnedMesh) {
            warn('GLTFExporter: Ignoring skinned mesh.', object);
        }

        if (object.children.length > 0) {
            const children: number[] = [];
            for (let i = 0, l = object.children.length; i < l; i++) {
                const child = object.children[i];
                if (child.visible || this.options.onlyVisible === false) {
                    const node = this.processNode(child);
                    if (node !== undefined) {
                        children.push(node);
                    }
                }
            }

            if (children.length > 0) {
                gltfNode.children = children;
                hasContent = true;
            }
        }

        if (hasContent) {
            this.outputJSON.nodes.push(gltfNode);
            const nodeIndex = this.outputJSON.nodes.length - 1;
            this.nodeMap.set(object, nodeIndex);
            return nodeIndex;
        }
        return undefined;
    }

    /**
     * Process Scene
     * @param  {THREE.Scene} node Scene to process
     */
    processScene(scene: THREE.Scene): void {
        if (!this.outputJSON.scenes) {
            this.outputJSON.scenes = [];
            this.outputJSON.scene = 0;
        }

        const gltfScene: any = {
            nodes: []
        };
        if (scene.name !== '') {
            gltfScene.name = scene.name;
        }

        this.outputJSON.scenes.push(gltfScene);

        const nodes: number[] = [];
        for (let i = 0, l = scene.children.length; i < l; i++) {
            const child = scene.children[i];
            if (child.visible || this.options.onlyVisible === false) {
                const node = this.processNode(child);
                if (node !== undefined) {
                    nodes.push(node);
                }
            }
        }

        if (nodes.length > 0) {
            gltfScene.nodes = nodes;
        }

        this.serializeUserData(scene, gltfScene);
    }

    /**
     * Creates a THREE.Scene to hold a list of objects and parse it
     * @param  {THREE.Object3D[]} objects List of objects to process
     */
    processObjects(objects: THREE.Object3D[]): void {
        const scene = new THREE.Scene();
        scene.name = 'scene';
        for (let i = 0; i < objects.length; i++) {
            // We push directly to children instead of calling `add` to prevent
            // modify the .parent and break its original scene and hierarchy
            scene.children.push(objects[i]);
        }
        this.processScene(scene);
        // Dispose of scene inited during this operation.
        scene.dispose();
    }

    applyTextureTransformToUVs(material: THREE.Material, uvs: number[]) {
        const basicMaterial = material as THREE.MeshBasicMaterial;
        if (basicMaterial.map) {
            const matrix = (basicMaterial.map as any).matrix; // three.js declarations omit matrix
            const newUVs = uvs.slice();
            const uv = new THREE.Vector2();
            for (let i = 0; i < newUVs.length; i += 2) {
                uv.x = uvs[i];
                uv.y = uvs[i + 1];
                uv.applyMatrix3(matrix);
                newUVs[i] = uv.x;
                newUVs[i + 1] = uv.y;
            }
            return newUVs;
        }
        return uvs;
    }

    processInput(meshes: THREE.Mesh[]): void {
        // Get all the materials used by the meshes.
        // Only one material per mesh is supported for now.
        const allMaterials = meshes.map(mesh => {
            if (Array.isArray(mesh.material)) {
                throw new Error('Multiple materials are not supported.');
            }
            return mesh.material;
        });

        // Extract the unique materials.
        const uniqueMaterials = Array.from(new Set(allMaterials));

        // Create a single geometry that combines the attributes of all meshes, with one group per
        // material.
        const newGeometry = new THREE.BufferGeometry();
        let positions: number[] = [];
        let normals: number[] = [];
        let uvs: number[] = [];
        let indices: number[] = [];
        uniqueMaterials.forEach((material, materialIndex) => {
            const groupStart = indices.length;
            meshes.filter(mesh => mesh.material === material).forEach(mesh => {
                const geometry = mesh.geometry as THREE.BufferGeometry;
                const startIndex = positions.length / 3;
                positions = positions.concat(Array.from(geometry.getAttribute('position').array));
                normals = normals.concat(Array.from(geometry.getAttribute('normal').array));
                // Crete default uvs if needed.
                const oldUVs = geometry.getAttribute('uv') ?
                    Array.from(geometry.getAttribute('uv').array) :
                    new Array(geometry.getAttribute('position').array.length / 3 * 2).fill(0);
                uvs = uvs.concat(this.applyTextureTransformToUVs(material, oldUVs));
                // Create default indices if needed.
                const newIndices = geometry.index ?
                    Array.from(geometry.index.array).map(i => i + startIndex) :
                    (new Array(geometry.getAttribute('position').array.length / 3)).fill(0).map((el, i) => i + startIndex);
                indices = indices.concat(newIndices);
            });
            const groupCount = indices.length - groupStart;
            newGeometry.addGroup(groupStart, groupCount, materialIndex);
        });
        newGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
        newGeometry.setAttribute('normal', new THREE.Float32BufferAttribute(normals, 3));
        newGeometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
        newGeometry.setIndex(indices);
        newGeometry.applyMatrix4(this.options.objectTransform);

        // Create a new mesh.
        const newMesh = new THREE.Mesh(newGeometry, uniqueMaterials);
        newMesh.name = 'model';

        // Process the mesh.
        this.processObjects([newMesh]);

        // Dispose of geometry inited during this operation.
        newGeometry.dispose();
    }
}
