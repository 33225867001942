import * as THREE from 'three';
import { MeshSurface } from '../view3D/MeshSurface';
import { PartialMeshSurface } from '../view3D/PartialMeshSurface';
import { Modifiers, Tool } from './Tool';

const faceHoverMaterial = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.6,
    side: THREE.DoubleSide,
    fog: false,
    polygonOffset: true,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -1,
    depthWrite: false,
});

const raycastingMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide });

export class FaceSelectionTool extends Tool {
    private faceHoverObject = new PartialMeshSurface(faceHoverMaterial);
    private raycastingObject = new MeshSurface(raycastingMaterial);

    toolOnActivate() {
        const { render } = this.props.store;
        render.addObject3Ds([
            this.faceHoverObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.bind(faceHoverMaterial, 'color', '--color-face-hover');

        this.addAutorun(() => {
            this.faceHoverObject.updateGeometry();
            this.raycastingObject.updateGeometry();
            this.raycastingTargets = [this.raycastingObject.getObject3D()];
            // Set not visible by default.
            this.hoverFaces([]);
        })
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.faceHoverObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.unbind(faceHoverMaterial, 'color');

        this.faceHoverObject.dispose();
        this.raycastingObject.dispose();
    }

    getIntersectionUserData(intersections: THREE.Intersection[]) {
        // Handle no intersection case.
        if (intersections.length === 0) {
            return null;
        }

        const { selectableFaceIndices } = this.props.store.geometry;
        const intersectedFaces: number[] = [];
        intersections.forEach((intersection) => {
            const triangleIndex = intersection.faceIndex;
            if (triangleIndex === undefined) {
                return;
            }
            // Traverse all ancestors of intersected object.
            let userData = null;
            // See if userData contains a numeric creaseFaceIndex.
            if (typeof intersection.object.userData.creaseFaceIndices === 'object') {
                userData = intersection.object.userData.creaseFaceIndices[triangleIndex];
            }
            if (userData !== null && !selectableFaceIndices.includes(userData)) {
                return;
            }
            intersectedFaces.push(userData);
        });

        if (intersectedFaces.length === 0) {
            return null;
        }
        if (intersectedFaces.length === 1) {
            return intersectedFaces[0];
        }

        // Return topmost face.
        const { overlappingGroups, panelOrder } = this.props.store.geometry;
        if (overlappingGroups.length === 0) {
            return intersectedFaces[0];
        }
        
        // Filter to faces in same group.
        const currentGroup = overlappingGroups.find(group => {
            return group.indexOf(intersectedFaces[0]) >= 0;
        });
        if (currentGroup === undefined) {
            return intersectedFaces[0];
        }
        // If no group return first intersection.
        if (currentGroup.length === 0) {
            return intersectedFaces[0];
        }
        // Calc top most face in group.
        let topMostFace = intersectedFaces[0];
        intersectedFaces.filter((el) => currentGroup.indexOf(el) >= 0).forEach(face => {
            if (panelOrder.indexOf(face) < panelOrder.indexOf(topMostFace)) {
                topMostFace = face;
            }
        });

        return topMostFace;
    }

    onNullEvent() {
        this.hoverFaces([]);
    }

    onHoverMove(intersections: THREE.Intersection[]) {
        // For now, leave hover out of store.
        const faceIndex = this.getIntersectionUserData(intersections);
        if (faceIndex === null) {
            this.hoverFaces([]);
            return false;
        }
        this.hoverFaces([faceIndex]);
        return true;
    }

    onLeftClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers) {
        this.hoverFaces([]);
        const faceIndex = this.getIntersectionUserData(intersections);
        if (faceIndex === null) {
            this.props.store.selection.deselectAll();
            return false;
        }

        // Update store.
        // Don't allow more than 1 face to be selected at a time.
        this.props.store.selection.setSelectedFaces([faceIndex]);
        return true;
    }

    onLeftDragStart() {
        // Unhover any faces.
        this.hoverFaces([]);
        return false;
    }

    onRightDragStart() {
        // Unhover any faces.
        this.hoverFaces([]);
        return false;
    }

    onScrollDragStart() {
        // Unhover any faces.
        this.hoverFaces([]);
        return false;
    }

    hoverFaces(faceIndices: number[]) {
        this.faceHoverObject.showFaces(faceIndices);
    }
}
