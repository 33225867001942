import { Component } from 'react';
import { Store } from '../store/Store';

/**
 * Properties of a StoreComponent.
 */
export interface StoreProps {
    store: Store;
}

/**
 * The base class for a component that can access the store.
 */
export class StoreComponent<Props = {}, State = {}> extends Component<StoreProps & Props, State> {
    static defaultProps = {} as StoreProps;
}
