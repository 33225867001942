import { CreaseEdge, TriangleMesh } from 'crease';
import { inject, observer } from 'mobx-react';
import * as THREE from 'three';
import { Vector2 } from 'three';
import { utils } from '../common/utils';
import { StoreProps } from '../UI/StoreComponent';
import { Modifiers, Tool } from './Tool';

@inject('store')
@observer
export class MarqueeSelectionTool extends Tool {
    private raycastingPlane = new THREE.Plane();
    private selectionRect = new THREE.Mesh(
        new THREE.PlaneGeometry(),
        new THREE.MeshBasicMaterial({ fog: false, side: THREE.DoubleSide, transparent: true, opacity: 0.2 }),
    );
    private startPosition: THREE.Vector3 | null = null;
    private startScreenCoords = new THREE.Vector2();
    // Keep track of all edges selected before a drag operation.
    private previousSelection: number[] = [];
    private currentSelection: number[] = [];

    constructor(props: StoreProps) {
        super(props);
        this.selectionRect.visible = false;
    }

    toolOnActivate() {
        const { render, ui } = this.props.store;
        render.addObject3Ds([
            this.selectionRect,
        ], render.overlayScene);
        render.colorBinder.bind(this.selectionRect.material, 'color', '--color-marquee-selection');

        // Clear when showFlat changes.
        this.addReaction(() => ui.showFlat, () => {
            this.clear();
        });
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.selectionRect,
        ], render.overlayScene);
        render.colorBinder.unbind(this.selectionRect.material, 'color');

        this.selectionRect.geometry.dispose();
        (this.selectionRect.material as THREE.Material).dispose();
    }

    onLeftDragStart(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers, raycaster: THREE.Raycaster) {
        // If shift is not held down, delete previous selections.
        const { render, selection } = this.props.store;
        if (!modifiers.shiftKey) {
            selection.deselectAll();
        }
        this.clear();
        // Orient raycating plane.
        const cameraVector = new THREE.Vector3(0,0, -1);
        cameraVector.applyQuaternion(render.camera.quaternion);
        this.raycastingPlane.setFromNormalAndCoplanarPoint(cameraVector, new THREE.Vector3());
        // Orient selection rect.
        this.selectionRect.quaternion.copy(render.camera.quaternion);
        // Get initial intersection.
        this.startPosition = new THREE.Vector3();
        raycaster.ray.intersectPlane(this.raycastingPlane, this.startPosition);
        this.startScreenCoords.copy(screenCoords);
        // Disable animation of trackball controls to new centerpoint.
        render.controls3D.disableAnimatedTarget();
        return true;
    }

    onLeftDragMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers, raycaster: THREE.Raycaster) {

        const scenePosition = new THREE.Vector3();
        raycaster.ray.intersectPlane(this.raycastingPlane, scenePosition);

        if (this.startPosition === null) {
            this.startPosition = scenePosition.clone();
            this.startScreenCoords.copy(screenCoords);
        } else {
            // Intersect rect with edges.
            this.testRectIntersection(screenCoords, this.startScreenCoords, !modifiers.altKey);
        }

        // Update geometry.
        const center = this.startPosition.clone().add(scenePosition).divideScalar(2);
        // Re-orient to xy plane.
        const { render } = this.props.store;
        const quaternion = render.camera.quaternion.clone().inverse();
        const startPositionXY = this.startPosition.clone().applyQuaternion(quaternion);
        const scenePositionXY = scenePosition.clone().applyQuaternion(quaternion);
        const scale = startPositionXY.clone().max(scenePositionXY).sub(startPositionXY.clone().min(scenePositionXY));

        // Update geometry.
        this.selectionRect.position.copy(center);
        this.selectionRect.scale.copy(scale);
        this.selectionRect.visible = true;
    
        return true;
    }

    private isEdgeIntersectingRect(edge: CreaseEdge, min: THREE.Vector2, max: THREE.Vector2,
        positions: [number, number, number][], triangleMesh: TriangleMesh, partialIntersectionOK: boolean) {
        const { vertex1, vertex2 } = edge;
        // Get 3D positions of vertices from solver.
        const meshVertex1 = triangleMesh.verticesForwardMapping[vertex1.index][0];
        const meshVertex2 = triangleMesh.verticesForwardMapping[vertex2.index][0];
        const position1_3D = new THREE.Vector3().fromArray(positions[meshVertex1]);
        const position2_3D = new THREE.Vector3().fromArray(positions[meshVertex2]);
        // Project vertices into screen space.
        const { render } = this.props.store;
        position1_3D.project(render.camera);
        position2_3D.project(render.camera);
        // Remove z component.
        const position1_2D = new THREE.Vector2(position1_3D.x, position1_3D.y);
        const position2_2D = new THREE.Vector2(position2_3D.x, position2_3D.y);
        // Check bounds.
        const edgeMin = position1_2D.clone().min(position2_2D);
        if (max.x < edgeMin.x || max.y < edgeMin.y) {
            return false;
        }
        const edgeMax = position1_2D.clone().max(position2_2D);
        if (min.x > edgeMax.x || min.y > edgeMax.y) {
            return false;
        }

        // Check if edge is completely contained in rect.
        let inside = true;
        if (max.x < position1_2D.x || max.y < position1_2D.y) {
            inside = false;
        }
        if (min.x > position1_2D.x || min.y > position1_2D.y) {
            inside = false;
        }
        if (max.x < position2_2D.x || max.y < position2_2D.y) {
            inside = false;
        }
        if (min.x > position2_2D.x || min.y > position2_2D.y) {
            inside = false;
        }
        if (inside) {
            return true;
        }
        if (!partialIntersectionOK) {
            return false;
        }

        // Check intersections of edge with rect edges.
        if (utils.linesCrossIn2D(min, new Vector2(min.x, max.y), position1_2D, position2_2D)) {
            return true;
        }
        if (utils.linesCrossIn2D(min, new Vector2(max.x, min.y), position1_2D, position2_2D)) {
            return true;
        }
        if (utils.linesCrossIn2D(new Vector2(min.x, max.y), max, position1_2D, position2_2D)) {
            return true;
        }
        if (utils.linesCrossIn2D(new Vector2(max.x, min.y), max, position1_2D, position2_2D)) {
            return true;
        }
        return false;
    }

    private testRectIntersection(currentScreenCoords: THREE.Vector2, startScreenCoords: THREE.Vector2, partialIntersectionOK: boolean) {
        // Calc bounds of current line segment.
        const min = currentScreenCoords.clone().min(startScreenCoords);
        const max = currentScreenCoords.clone().max(startScreenCoords);

        const { geometry, render, selection, ui } = this.props.store;
        const { triangleMesh, selectableEdgeIndices } = geometry;

        // Compare with edges in crease.
        const { crease, faceOffsetter } = render;
        const positions = faceOffsetter.positions(ui.showFlat ? render.solver.flatPositions
            : render.solver.positions);

        const currentSelection: number[] = [];
        selectableEdgeIndices.forEach((edgeIndex) => {
            if (currentSelection.indexOf(edgeIndex) >= 0) {
                return;
            }
            if (!this.isEdgeIntersectingRect(crease.edges[edgeIndex], min, max, positions, triangleMesh, partialIntersectionOK)) {
                return;
            }
            // Get edge selection chain.
            const chainedEdges = utils.getChainedEdges(edgeIndex, crease);
            if (!partialIntersectionOK && chainedEdges.length > 1) {
                // Check that entire chain is inside box.
                for (let i=0; i<chainedEdges.length; i++) {
                    const chainedEdgeIndex = chainedEdges[i];
                    if (chainedEdgeIndex === edgeIndex) {
                        continue;
                    }
                    if (!this.isEdgeIntersectingRect(crease.edges[chainedEdgeIndex], min, max, positions, triangleMesh, partialIntersectionOK)) {
                        return;
                    }
                }
            }
            currentSelection.push(...chainedEdges);
        });
        // Make a copy before doing this sort operation.
        // Otherwise the order of the chained edge selection will not be correct.
        // And the fold angle tool will end up in the wrong place.
        const sortedSelection = currentSelection.slice().sort();

        // Check if anything has changed.
        let changed = false;
        if (this.currentSelection.length !== sortedSelection.length) {
            changed = true;
        } else {
            for (let i=0; i< this.currentSelection.length; i++) {
                if (this.currentSelection[i] !== sortedSelection[i]) {
                    changed = true;
                    break;
                }
            }
        }

        this.currentSelection = sortedSelection;

        if (!changed) {
            return;
        }

        // Merge selectedEdges with currentSelection and dispatch action.
        const nextSelectedEdges = this.previousSelection.slice();
        currentSelection.forEach(edgeIndex => {
            const index = nextSelectedEdges.indexOf(edgeIndex);
            if (index >=0 ) {
                // If we already selected and this edge is part of new selection, unselect the edge.
                nextSelectedEdges.splice(index, 1);
            } else {
                // If this edge was not previously selected, select it.
                nextSelectedEdges.push(edgeIndex);
            }
        });

        selection.setSelectedEdges(nextSelectedEdges);
    }

    onLeftDragEnd() {
        this.clear();
        // Enable animation of trackball controls to new centerpoint.
        this.props.store.render.controls3D.enableAnimatedTarget();
        return true;
    }

    onRightDragMove() {
        // Remove rect visibility.
        this.selectionRect.visible = false;
        return false;
    }

    onScrollDragMove() {
        // Remove rect visibility.
        this.selectionRect.visible = false;
        return false;
    }

    private clear() {
        this.startPosition = null;
        this.selectionRect.visible = false;
        // Store current selected edges before drag.
        this.previousSelection = this.props.store.selection.selectedEdges.slice();
        this.currentSelection = [];
    }
}
