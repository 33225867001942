import { Crease, TriangleMesh } from 'crease';
import * as THREE from 'three';
import { ColorBinder } from '../common/ColorBinder';
import { MaskShader, WireframeShaderOccluded } from '../shaders/Shaders';
import { EdgeTubes } from './EdgeTubes';
import { MeshSurface } from './MeshSurface';

const EDGE_THICKNESS = 2; // pixels
const EDGE_CONSTRAINT_THICKNESS = 3.5; // pixels

const maskMaterial = new THREE.RawShaderMaterial( {
    uniforms: MaskShader.uniforms,
    vertexShader: MaskShader.vertexShader,
    fragmentShader: MaskShader.fragmentShader,
    fog: false,
    polygonOffset: true,
});

const whiteMaterial = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    polygonOffset: true,
    fog: false,
});

export class ThinMesh {
    
    // Store face materials and artwork texture materials.
    private side1Material = whiteMaterial.clone();
    private side2Material = whiteMaterial.clone();
    private side1MaskMaterials: THREE.RawShaderMaterial[] = [];
    private side2MaskMaterials: THREE.RawShaderMaterial[] = [];

    // Edge tubes.
    private edgeTubesCreases = new EdgeTubes(
        {
            thickness: EDGE_THICKNESS,
            vertexShader: WireframeShaderOccluded.vertexShader,
            fragmentShader: WireframeShaderOccluded.fragmentShader,
            uniforms: WireframeShaderOccluded.uniforms,
        });
    private edgeTubesCuts = new EdgeTubes(
        {
            thickness: EDGE_THICKNESS,
            vertexShader: WireframeShaderOccluded.vertexShader,
            fragmentShader: WireframeShaderOccluded.fragmentShader,
            uniforms: WireframeShaderOccluded.uniforms,
        });
    private edgeTubesConstraints = new EdgeTubes(
        {
            thickness: EDGE_CONSTRAINT_THICKNESS,
            vertexShader: WireframeShaderOccluded.vertexShader,
            fragmentShader: WireframeShaderOccluded.fragmentShader,
            uniforms: WireframeShaderOccluded.uniforms,
        });

    // Init a separate mesh for each side of the dieline, so they can be rendered with 
    // different textures.
    private side1 = new MeshSurface(this.side1Material, true);
    private side2 = new MeshSurface(this.side2Material);

    // Masking mesh.
    private maskMeshSide1 = new MeshSurface(this.side1MaskMaterials, true);
    private maskMeshSide2 = new MeshSurface(this.side2MaskMaterials);

    // Data for masking edges.
    panelOrderData = new Uint8Array();

    constructor(colorBinder: ColorBinder) {
        colorBinder.bind(this.edgeTubesCreases, 'color', '--color-edge-crease');
        colorBinder.bind(this.edgeTubesCuts, 'color', '--color-edge-cut');
        colorBinder.bind(this.edgeTubesConstraints, 'color', '--color-edge-constraint');
    }

    setMaskTexture(texture: THREE.Texture) {
        WireframeShaderOccluded.uniforms.tMask.value = texture;
        this.getAllEdgeTubes().forEach(edgeTube => {
            edgeTube.getMaterials().forEach((material) => {
                material.uniforms.tMask.value = texture;
                material.uniforms.tMask.value.needsUpdate = true;
            });
        });
    }

    setPanelOrderTexture(panelOrderData: Uint8Array, triangleMesh: TriangleMesh) {
        const texture = new THREE.DataTexture(panelOrderData, 100, 2*triangleMesh.facesForwardMapping.length,
            THREE.RGBFormat, THREE.UnsignedByteType, undefined, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping,
            THREE.NearestFilter, THREE.NearestFilter);
        const oldTexture = WireframeShaderOccluded.uniforms.tPanelOrder.value;
        if (oldTexture) {
            oldTexture.dispose();
        }
        WireframeShaderOccluded.uniforms.tPanelOrder.value = texture;
        WireframeShaderOccluded.uniforms.numRows.value = texture.image.height;
        this.getAllEdgeTubes().forEach(edgeTube => {
            edgeTube.getMaterials().forEach((material) => {
                material.uniforms.tPanelOrder.value = texture;
                material.uniforms.tPanelOrder.value.needsUpdate = true;
                material.uniforms.numRows.value = texture.image.height;
            });
        });
    }

    handleWindowResize(size: [number, number]) {
        WireframeShaderOccluded.uniforms.screenSize.value = size;
        this.getAllEdgeTubes().forEach(edgeTube => {
            edgeTube.getMaterials().forEach((material) => {
                material.uniforms.screenSize.value = size;
                material.uniforms.screenSize.value.needsUpdate = true;
            });
        });
    }

    updateActiveConstraintVisibility(constrainedEdges: number[]) {
        this.edgeTubesConstraints.showEdges(constrainedEdges);
    }

    setTriangleMesh(triangleMesh: TriangleMesh, crease: Crease) {
        // Init new DataTexture for masking edges, and set uniforms on masking mesh.
        this.panelOrderData = new Uint8Array(2 * 100 * triangleMesh.facesForwardMapping.length * 3);
        this.setPanelOrderTexture(this.panelOrderData, triangleMesh);

        // Update face meshes.
        [this.side1, this.side2, this.maskMeshSide1, this.maskMeshSide2].forEach(meshSurface => {
            meshSurface.updateGeometry();
        });

        const { facesForwardMapping } = triangleMesh;

        // Create arrayed materials.
        this.side1MaskMaterials.forEach(material => material.dispose());
        this.side2MaskMaterials.forEach(material => material.dispose());
        this.side1MaskMaterials.length = 0;
        this.side2MaskMaterials.length = 0;
        facesForwardMapping.forEach((triangleIndices, i: number) => {
            // Init new mask material with face index reference.
            this.side1MaskMaterials.push(maskMaterial.clone());
            this.side1MaskMaterials[i].uniforms.faceIndex.value = i;
            this.side2MaskMaterials.push(maskMaterial.clone());
            this.side2MaskMaterials[i].uniforms.faceIndex.value = i;
        });

        // Update edge tubes.
        this.getAllEdgeTubes().forEach(edgeTube => {
            edgeTube.updateGeometry();
        });
        this.edgeTubesCuts.showEdges(crease.edges.filter(edge => !edge.isCrease).map(edge => edge.index));
        this.edgeTubesCreases.showEdges(crease.edges.filter(edge => edge.isCrease).map(edge => edge.index));
        this.edgeTubesConstraints.showEdges([]);
    }

    panelOrderDidChange() {
        // Update material uniforms.
        this.getAllEdgeTubes().forEach(edgeTube => {
            edgeTube.getMaterials().forEach((material) => {
                if (material.uniforms.tPanelOrder && material.uniforms.tPanelOrder.value) {
                    material.uniforms.tPanelOrder.value.needsUpdate = true;
                }
            });
        });
    }

    setPreviewMode(previewMode: boolean) {
        [this.edgeTubesCreases, this.edgeTubesCuts, this.edgeTubesConstraints].forEach(edgeTube => {
            edgeTube.getObject3D().visible = !previewMode;
        });
    }

    private getAllEdgeTubes() {
        return [this.edgeTubesConstraints, this.edgeTubesCuts, this.edgeTubesCreases];
    }

    getRenderedEdgeTubesObject3Ds() {
        return [this.edgeTubesConstraints.getObject3D(), this.edgeTubesCuts.getObject3D(), this.edgeTubesCreases.getObject3D()];
    }

    getMaskingObject3Ds() {
        return [this.maskMeshSide1.getObject3D(), this.maskMeshSide2.getObject3D()];
    }

    getMeshObject3Ds() {
        return [this.side1.getObject3D(), this.side2.getObject3D()];
    }
};