import DropZone from '@react/react-spectrum/DropZone';
import Heading from '@react/react-spectrum/Heading';
import LinkOutLight from '@react/react-spectrum/Icon/LinkOutLight';
import IllustratedMessage from '@react/react-spectrum/IllustratedMessage';
import Link from '@react/react-spectrum/Link';
import { boundMethod } from 'autobind-decorator';
import { SVGToCrease } from 'crease';
import { saveAs } from 'file-saver';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import PromiseFileReader from 'promise-file-reader';
import React, { Component } from 'react';
import { AppMode } from '../common/AppMode';
import { SVGImporter } from '../common/SVGImporter';
import { loadTexture } from '../common/TextureLoader';
import { FileOpener } from '../components/FileOpener';
import { MessageDialog } from '../components/MessageDialog';
import '../css/Home.css';
import { Analytics, EventName } from '../services/Analytics';
import { Side } from '../store/InfoStore';
import { allSubstrates } from '../substrates/allSubstrates';
import { Example } from './Example';
import { EXAMPLES } from './Examples-prod'; // Use './Examples-dev' for more examples.
import { ExternalLinks } from './ExternalLinks';
import { ImportDielineModal, ImportFileSource } from './ImportDielineModal';
import { StoreComponent } from './StoreComponent';

const SVG_MIME_TYPE = 'image/svg+xml';

@inject('store')
@observer
export class Home extends StoreComponent {
    private async openExample(example: Example) {
        await this.loadCreaseFromExample(example);
        this.props.store.ui.appMode = AppMode.Edit;
        Analytics.event(EventName.ExampleImport, { exampleName: example.name });

        const promises = [
            this.loadSubstrateFromExample(example),
            this.loadArtworkFromExample(example, Side.Exterior),
            this.loadArtworkFromExample(example, Side.Interior),
        ];
        await Promise.all(promises);
    }

    private getExampleFilename(example: Example, extension: string) {
        return example.name.toLocaleLowerCase().replace(' ', '-') + extension;
    }

    private async loadCreaseFromExample(example: Example) {
        // TODO: This whole process seems a bit convoluted. But it works for now.
        const svgResponse = await fetch(example.dieline);
        const svgText = await svgResponse.text();
        const svgImporter = new SVGImporter();
        const dieline = svgImporter.parseSVG(svgText) as SVGSVGElement;
        const options = SVGImporter.getSVGToCreaseOptions();
        const svgToCreaseOutput = SVGToCrease.parseSVG(dieline.outerHTML, options);
        const crease = svgToCreaseOutput.creases[0];
        const filename = this.getExampleFilename(example, '.svg');
        this.props.store.loadCrease(filename, crease, dieline);
    }

    private async loadSubstrateFromExample(example: Example) {
        await allSubstrates[example.substrateName].loadAllTextures();
        this.props.store.info.substrate = allSubstrates[example.substrateName];
        this.props.store.info.isSubstrateHorizontal = example.isSubstrateHorizontal;
    }

    private async loadArtworkFromExample(
        example: Example,
        side: Side,
    ) {
        const artworkFilePath = side === Side.Exterior ? example.exteriorArtwork
            : example.interiorArtwork;
        if (!artworkFilePath) {
            return;
        }
        const artworkResponse = await fetch(artworkFilePath);
        const artworkBlob = await artworkResponse.blob();
        const dataURL = await PromiseFileReader.readAsDataURL(artworkBlob);
        const texture = await loadTexture(dataURL);
        this.props.store.info.loadArtwork(dataURL, texture, side);
    }

    private async downloadExample(example: Example) {
        if (example.illustratorFile) {
            const illustratorResponse = await fetch(example.illustratorFile);
            const illustratorBlob = await illustratorResponse.blob();
            saveAs(illustratorBlob, this.getExampleFilename(example, '.ai'));
            Analytics.event(EventName.ExampleDownload, { exampleName: example.name });
        }
    }

    @boundMethod
    private onDragOver(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            // Data transfer must contain exactly one item, and it must be an SVG file.
            const isDropAllowed = e.dataTransfer.items.length === 1 &&
                e.dataTransfer.items[0].kind === 'file' &&
                e.dataTransfer.items[0].type.toLowerCase() === SVG_MIME_TYPE;
            e.dataTransfer.effectAllowed = isDropAllowed ? 'copy' : 'none';
        }
    }

    @boundMethod
    private onDrop(e: React.DragEvent) {
        e.preventDefault();
        if (e.dataTransfer) {
            const files = e.dataTransfer.files;
            let svgFileCount = 0;
            for (let i = 0; i < files.length; i++) {
                if (files[i].type.toLowerCase() === SVG_MIME_TYPE) {
                    svgFileCount++;
                }
            }
            const title = 'SVG File Required';
            if (svgFileCount === 0) {
                MessageDialog.show(title, 'Please drag and drop an SVG file.');
            } else if (svgFileCount > 1 || files.length > svgFileCount) {
                MessageDialog.show(title, 'Please drag and drop just one SVG file.');
            } else {
                this.openFile(files[0], 'dragAndDrop');
            }
        }
    }

    @boundMethod
    private onFileSelected(file: File) {
        this.openFile(file, 'select');
    }

    private async openFile(file: File, fileSource: ImportFileSource) {
        const result = await ImportDielineModal.openFile(file, fileSource);
        if (result) {
            const { filename, crease, dieline, triangleMesh } = result;
            this.props.store.loadCrease(filename, crease, dieline, triangleMesh);
            this.props.store.ui.appMode = AppMode.Edit;

            // Load default substrate and set.
            const defaultSubstrate = 'Paperboard 320 µm';
            await allSubstrates[defaultSubstrate].loadAllTextures();
            this.props.store.info.substrate = allSubstrates[defaultSubstrate];
            this.props.store.info.isSubstrateHorizontal = false;
        }
    }

    private renderExample(example: Example) {
        const staticSrc = example.image || example.dieline;
        const animatedSrc = example.animation;
        const openExample = () => { this.openExample(example); };
        const downloadExample = () => { this.downloadExample(example); };
        return (
            <div key={example.name} className="Home__example">
                <AnimatedImage staticSrc={staticSrc} animatedSrc={animatedSrc}
                    onClick={openExample} />
                <div>{example.name}</div>
                <div>
                    <Link onClick={openExample}>Try</Link>
                    {example.illustratorFile &&
                        <>
                            &nbsp;|&nbsp;
                            <Link onClick={downloadExample}>Download Ai File</Link>
                        </>
                    }
                </div>
            </div>
        );
    }

    render() {
        // TODO: Get a better illustration. This one comes from the React-Spectrum storybook.
        const illustration = (
            <svg viewBox="0 0 199 97.7" height="110" aria-hidden="true"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="3px"><path d="M110.53,85.66,100.26,95.89a1.09,1.09,0,0,1-1.52,0L88.47,85.66"></path><line x1="99.5" y1="95.5" x2="99.5" y2="58.5"></line><path d="M105.5,73.5h19a2,2,0,0,0,2-2v-43"></path><path d="M126.5,22.5h-19a2,2,0,0,1-2-2V1.5h-31a2,2,0,0,0-2,2v68a2,2,0,0,0,2,2h19"></path><line x1="105.5" y1="1.5" x2="126.5" y2="22.5"></line><path d="M139.5,36.5H196A1.49,1.49,0,0,1,197.5,38V72A1.49,1.49,0,0,1,196,73.5H141A1.49,1.49,0,0,1,139.5,72V32A1.49,1.49,0,0,1,141,30.5H154a2.43,2.43,0,0,1,1.67.66l6,5.66"></path><rect x="1.5" y="34.5" width="58" height="39" rx="2" ry="2"></rect><path strokeWidth="2px" d="M47.93,50.49a5,5,0,1,0-4.83-5A4.93,4.93,0,0,0,47.93,50.49Z"></path><path strokeWidth="2px" d="M36.6,65.93,42.05,60A2.06,2.06,0,0,1,45,60l12.68,13.2"></path><path strokeWidth="2px" d="M3.14,73.23,22.42,53.76a1.65,1.65,0,0,1,2.38,0l19.05,19.7"></path></g></svg>
        );
        const description = (
            <>
                Or&nbsp;<FileOpener accept=".svg,.crease" onFileSelected={this.onFileSelected}>
                    <Link>select a file from your computer</Link>
                </FileOpener>
            </>
        );

        return (
            <div className="Home">
                <div className="Home__welcome">
                    <div className="Home__intro">
                        <Heading variant="pageTitle">
                            Start folding!
                        </Heading>
                        <p>
                            Start creating 3D models from your Adobe Illustrator package dieline
                            files. Try folding a sample package or download an Illustrator file to
                            get started.
                        </p>
                    </div>
                    <div className="Home__examples">
                        <div className="Home__examplesRow">
                            { this.renderExample(EXAMPLES[0]) }
                            { this.renderExample(EXAMPLES[1]) }
                        </div>
                        <div className="Home__examplesRow">
                            { this.renderExample(EXAMPLES[2]) }
                            { this.renderExample(EXAMPLES[3]) }
                        </div>
                    </div>
                </div>
                <div className="Home__bottomRow">
                    <div className="Home__learn">
                        <h3>Learn how to get started</h3>
                        <ul className="Home__learnBullets">
                            <li><div className="Home__numberBullet">1</div>Prep your artwork</li>
                            <li><div className="Home__numberBullet">2</div>Import into Fantastic Fold</li>
                            <li><div className="Home__numberBullet">3</div>Fold your dieline</li>
                            <li><div className="Home__numberBullet">4</div>Export and keep creating</li>
                        </ul>
                        <Link onClick={ExternalLinks.launchHelp}>
                            View the tutorial <LinkOutLight className="Home__icon" size="S" />
                        </Link>
                    </div>
                    <DropZone className="Home__dropZone" onDragEnter={this.onDragOver}
                        onDragOver={this.onDragOver} onDrop={this.onDrop}>
                        <IllustratedMessage illustration={illustration}
                            heading="Drag and Drop Your SVG Dieline" description={description} />
                    </DropZone>
                </div>
            </div>
        );
    }
}

// --- AnimatedImage helper component ---

interface AnimatedImageProps {
    staticSrc: string;
    animatedSrc?: string;
    onClick?: () => void;
}

@observer
class AnimatedImage extends Component<AnimatedImageProps> {
    @observable
    private isHovered: boolean = false;

    @boundMethod
    private onMouseEnter() {
        this.isHovered = true;
    }

    @boundMethod
    private onMouseLeave() {
        this.isHovered = false;
    }

    render() {
        const src = this.isHovered && this.props.animatedSrc ? this.props.animatedSrc
            : this.props.staticSrc;
        return <img src={src} alt="" onClick={this.props.onClick} onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave} />;
    }
}
