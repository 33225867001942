/**
 * @author alteredq / http://alteredqualia.com/
 */

 // All that is changed from original source is locking the depth buffer when rendering the full screen quad.

import {
	ShaderMaterial,
    UniformsUtils,
    Material,
    WebGLRenderer,
    WebGLRenderTarget,
} from 'three';
import { Pass } from 'three/examples/jsm/postprocessing/Pass.js';

export class ShaderPassCustom extends Pass {
    textureID: string;
    uniforms!: { [uniform: string]: THREE.IUniform };
    material!: Material;
    fsQuad: Pass.FullScreenQuad;
    constructor(shader: THREE.ShaderMaterialParameters, textureID?: string) {
        super();

        this.textureID = ( textureID !== undefined ) ? textureID : "tDiffuse";
    
        if ( shader instanceof ShaderMaterial ) {
    
            this.uniforms = shader.uniforms;
    
            this.material = shader;
    
        } else if ( shader ) {
    
            this.uniforms = UniformsUtils.clone( shader.uniforms );
    
            this.material = new ShaderMaterial( {
    
                defines: Object.assign( {}, shader.defines ),
                uniforms: this.uniforms,
                vertexShader: shader.vertexShader,
                fragmentShader: shader.fragmentShader
    
            } );
        }
        this.fsQuad = new Pass.FullScreenQuad( this.material );
    }
    
    render( renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget /*, deltaTime, maskActive */ ) {

		if ( this.uniforms[ this.textureID ] ) {
			this.uniforms[ this.textureID ].value = readBuffer.texture;
        }

		this.fsQuad.material = this.material;

		if ( this.renderToScreen ) {

            renderer.setRenderTarget( null );
            const context = renderer.getContext();
            context.depthMask(false); // Lock depth buffer.
            this.fsQuad.render( renderer );
            context.depthMask(true); // Unlock depth buffer.

		} else {

			renderer.setRenderTarget( writeBuffer );
			// TODO: Avoid using autoClear properties, see https://github.com/mrdoob/three.js/pull/15571#issuecomment-465669600
			if ( this.clear ) renderer.clear( renderer.autoClearColor, renderer.autoClearDepth, renderer.autoClearStencil );
			this.fsQuad.render( renderer );

		}

	}
  }

