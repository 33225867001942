import React, { Component, ReactElement } from 'react';
import './KeyboardShortcutLabel.css';
import { Platform } from './Platform';

/** Properties of the KeyboardShortcutLabel component. */
interface KeyboardShortcutLabelProps {
    /** An optional comma-separated list of shortcut keys. */
    shortcut?: string;
}

/**
 * Provides a label indicating the shortcut keys that can be used to activate some functionality.
 * 
 * A shortcut label is usually displayed within a tooltip, as in this example:
 * 
 *      <OverlayTrigger>
 *          <Button label="Export Model" />
 *          <Tooltip variant="info">
 *              <b>Export Model File</b> <KeyboardShortcutLabel shortcut="cmdOrCtrl+e" /> <br />
 *              Download a model file for use in other applications.
 *          </Tooltip>
 *      </OverlayTrigger>
 * 
 * Also see the `TooltipContent` component, which formats content for a tooltip.
 */
export class KeyboardShortcutLabel extends Component<KeyboardShortcutLabelProps> {
    render() {
        const { shortcut } = this.props;
        if (shortcut) {
            const keyCombos = shortcut.split(',').map(key => key.trim());
            return keyCombos.map((keyCombo, index) => [
                this._renderKeyCombo(keyCombo),
                <span key={`or${index}`}> or</span>
            ]).flat().slice(0, -1);
        }
        return null;
    }

    private _renderKeyCombo(keyCombo: string): ReactElement {
        const keys = keyCombo.split('+');
        const joiner = Platform.IS_MAC ? '' : ' ';
        const renderedKeys = keys.map(key => this._renderKey(key)).join(joiner);
        return <kbd className="KeyboardShortcutLabel--key" key={renderedKeys}>{renderedKeys}</kbd>;
    }

    private _renderKey(key: string): string {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        const replacements: Record<string, string> = {
            'Left': '←',
            'Right': '→',
            'Up': '↑',
            'Down': '↓',
        };
        if (Platform.IS_MAC) {
            Object.assign(replacements, {
                'CmdOrCtrl': '⌘',
                'Cmd': '⌘',
                'Meta': '⌘',
                'Alt': '⌥',
                'Option': '⌥',
                'Ctrl': '⌃',
                'Shift': '⇧',
            });
        } else {
            Object.assign(replacements, {
                'CmdOrCtrl': 'Ctrl',
                'Cmd': 'Win',
                'Meta': 'Win',
                'Option': 'Alt',
            });
        }
        let newKey = capitalizedKey;
        Object.keys(replacements).forEach(oldKey => {
            newKey = newKey.replace(oldKey, replacements[oldKey]);
        });
        return newKey;
    }
}
