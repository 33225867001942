import { action, computed, IObservableArray, observable } from 'mobx';

export class SelectionStore {
    @observable
    private _selectedFaces: IObservableArray<number> = observable<number>([]);

    @observable
    private _selectedEdges: IObservableArray<number> = observable<number>([]);

    @computed
    get selectedFaces(): IObservableArray<number> {
        return this._selectedFaces;
    }

    @action
    setSelectedFaces(faceIndices: number[]) {
        this._selectedFaces.replace(faceIndices);
        this.deselectEdgesIfFacesSelected();
    }

    @action
    selectFace(faceIndex: number) {
        this._selectedFaces.push(faceIndex);
        this.deselectEdgesIfFacesSelected();
    }

    @action
    deselectFace(faceIndex: number) {
        this._selectedFaces.remove(faceIndex);
    }

    private deselectEdgesIfFacesSelected() {
        if (this._selectedFaces.length && this._selectedEdges.length) {
            this._selectedEdges.clear();
        }
    }

    @computed
    get selectedEdges(): IObservableArray<number> {
        return this._selectedEdges;
    }

    @action
    setSelectedEdges(edgeIndices: number[]) {
        this._selectedEdges.replace(edgeIndices);
        this.deselectFacesIfEdgesSelected();
    }

    @action
    selectEdges(edgeIndices: number[]) {
        this._selectedEdges.push(...edgeIndices);
        this.deselectFacesIfEdgesSelected();
    }

    @action
    deselectEdges(edgeIndices: number[]) {
        edgeIndices.forEach(edgeIndex => {
            this._selectedEdges.remove(edgeIndex);
        });
    }

    private deselectFacesIfEdgesSelected() {
        if (this._selectedEdges.length && this._selectedFaces.length) {
            this._selectedFaces.clear();
        }
    }

    @action
    deselectAll() {
        this._selectedFaces.clear();
        this._selectedEdges.clear();
    }
}
