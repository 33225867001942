import { TriangleMesh } from 'crease';
import * as THREE from 'three';

const raycastingMaterial = new THREE.MeshBasicMaterial({
    depthWrite: false,
    fog: false,
});

// We still need this version for raycasting - Instanced geometry will not work with THREE.Raycaster.
// We could change this to InstancedMesh, but not a performance hangup currently.
// Unfortunately, InstancedMesh does not support adding custom attributes (beyond position/orientation/scale transfomations)
// so we cannot use it for rendering the edge tubes.
export class EdgeTubesRaycasting {
    private group = new THREE.Group();
    private capsuleGeometry: THREE.BufferGeometry;

    constructor(thickness: number) {
        // Create a cylinder.
        const radius = thickness / 2;
        const height = 1;
        const radialSegments = 8;
        const heightSegments = 1;
        const openEnded = true;
        const cylinderGeom = new THREE.CylinderBufferGeometry(radius, radius, height,
            radialSegments, heightSegments, openEnded);
        cylinderGeom.translate(0, 0.5, 0);

        // Store all three geometries.
        this.capsuleGeometry = cylinderGeom;
    }

    setTriangleMesh(triangleMesh: TriangleMesh, scale: number) {
        // Organize this.group into subgroups corresponding to each Crease edge.

        // Clear the group.
        this.group.children = [];

        // Init tubes for all crease edges.
        const { edges, edgesForwardMapping } = triangleMesh;
        const creaseEdgeIndices = Object.keys(edgesForwardMapping).map(key => parseInt(key, 10));

        // Create a new edge group for each crease edge.
        creaseEdgeIndices.forEach(creaseEdgeIndex => {
            const edgeIndices = edgesForwardMapping[creaseEdgeIndex];
            const edgeGroup = new THREE.Group();
            edgeGroup.userData = { creaseEdgeIndex };
            this.group.add(edgeGroup);

            // Create a capsule group for each triangle edge within this crease edge.
            edgeIndices.forEach((edgeIndex) => {
                const { edgeVertex1, edgeVertex2 } = edges[edgeIndex];
                
                const mesh = new THREE.Mesh(this.capsuleGeometry, raycastingMaterial)
                mesh.userData = { edgeVertex1, edgeVertex2 };
                edgeGroup.add(mesh);
            });
        });

        this.updateScale(scale);
    }

    getObject3Ds(indices: number[]) {
        return indices.map(i => this.group.children[i]);
    }

    updateScale(scale: number) {
        // Update the transform of each mesh.
        this.group.children.forEach(edgeGroup => {
            edgeGroup.children.forEach(cylinder => {
                const { edgeVertex1, edgeVertex2 } = cylinder.userData;
                if (typeof edgeVertex1 === 'number' && typeof edgeVertex2 === 'number') {
                
                    // Update the three parts of the current capsule group.
                    cylinder.scale.x = scale;
                    cylinder.scale.z = scale;
                }
            });
        });

        this.group.updateMatrixWorld();
    }

    updatePositions(positions: [number, number, number][]) {
        // Create all three.js objects outside of the loop.
        const p1 = new THREE.Vector3();
        const p2 = new THREE.Vector3();
        const y = new THREE.Vector3(0, 1, 0);
        const v = new THREE.Vector3();
        const q = new THREE.Quaternion();

        // Update the transform of each mesh from the vertex positions.
        this.group.children.forEach(edgeGroup => {
            edgeGroup.children.forEach(cylinder => {
                const { edgeVertex1, edgeVertex2 } = cylinder.userData;
                if (typeof edgeVertex1 === 'number' && typeof edgeVertex2 === 'number') {
                    // Calculate the length and orientation of the edge.
                    p1.fromArray(positions[edgeVertex1]);
                    p2.fromArray(positions[edgeVertex2]);
                    v.subVectors(p2, p1);
                    const length = v.length();
                    v.normalize();
                    q.setFromUnitVectors(y, v);

                    // Update the three parts of the current capsule group.
                    cylinder.position.copy(p1);
                    cylinder.scale.y = length;
                    cylinder.quaternion.copy(q);
                }
            });
        });

        this.group.updateMatrixWorld();
    }

    dispose() {
        this.capsuleGeometry.dispose();
    }
}