import { Crease, TriangleEdge, TriangleFace, TriangleMesh, TriangleVertex, vec, Vector2, Solver } from 'crease';

const MIN_OFFSET = 0.0001;
const MAX_OFFSET = 0.005;

type Listener = () => void;

export interface DuplicatedEdge {
    forwardEdgeIndex: number;
    reverseEdgeIndex: number;
}

export type DuplicatedEdgesForwardMapping = DuplicatedEdge[][];

export interface OffsetTriangleMesh extends TriangleMesh {
    duplicatedEdgesForwardMapping: DuplicatedEdgesForwardMapping;
}

export class FaceOffsetter {
    private eventListeners: Listener[] = [];
    // Mapping from new triangle mesh to old mesh.
    private verticesLookup: number[] = [];
    private verticesReverseLookup: number[][] = [];
    private edgesLookup: number[] = [];

    offsetTriangleMesh(triangleMesh: TriangleMesh, crease: Crease): OffsetTriangleMesh {
        // Start with empty vertices arrays.
        const vertices: TriangleVertex[] = [];
        const verticesForwardMapping: number[][] = crease.vertices.map(() => []);
        // Keep track of the mapping from each old vertex index to (possibly multiple) new vertex
        // indices, and from each old edge index to a new edge index.
        const verticesMapping: { creaseFaceIndex: number, vertexIndex: number }[][]
            = triangleMesh.vertices.map(() => []);
        const edgesMapping: number[][] = triangleMesh.edges.map(() => []);

        // Process each crease face separately.
        triangleMesh.facesForwardMapping.forEach((triangles, creaseFaceIndex) => {
            const faceVertexMapping: number[] = [];
            triangles.forEach(triangle => {
                triangleMesh.faces[triangle].vertices.forEach((oldVertexIndex) => {
                    let newVertexIndex = faceVertexMapping[oldVertexIndex];
                    if (newVertexIndex === undefined) {
                        const oldVertex = triangleMesh.vertices[oldVertexIndex];
                        const newVertex: TriangleVertex = {
                            parent: oldVertex.parent,
                            position2D: oldVertex.position2D.slice() as Vector2,
                            index: vertices.length
                        };
                        newVertexIndex = vertices.length;
                        vertices.push(newVertex);
                        if (newVertex.parent !== undefined) {
                            verticesForwardMapping[newVertex.parent].push(newVertexIndex);
                        }
                        faceVertexMapping[oldVertexIndex] = newVertexIndex;
                        verticesMapping[oldVertexIndex].push({
                            creaseFaceIndex: creaseFaceIndex,
                            vertexIndex: newVertexIndex,
                        });
                    }
                });
            });
        });

        function vertexIndexFromMapping(oldVertexIndex: number, creaseFaceIndex: number) {
            const childVertices = verticesMapping[oldVertexIndex];
            const vertexInfo = childVertices.find(child =>
                child.creaseFaceIndex === creaseFaceIndex);
            if (vertexInfo === undefined) {
                throw new Error(`Unable to find child vertex for vertex: ${oldVertexIndex} and face: ${creaseFaceIndex}`);
            }
            return vertexInfo.vertexIndex;
        }

        const faces = triangleMesh.faces.map((face) => {
            return {
                vertices: face.vertices.slice(),
                parent: face.parent,
            } as TriangleFace;
        });
        const facesForwardMapping = triangleMesh.facesForwardMapping.map(el => el.slice());
        faces.forEach(face => {
            const { vertices, parent } = face;
            const v0 = vertexIndexFromMapping(vertices[0], parent);
            const v1 = vertexIndexFromMapping(vertices[1], parent);
            const v2 = vertexIndexFromMapping(vertices[2], parent);
            face.vertices = [
                v0, v1, v2,
            ];
        });

        const edges: TriangleEdge[] = triangleMesh.edges.map(edge => JSON.parse(JSON.stringify(edge)) as TriangleEdge);
        const edgesForwardMapping = triangleMesh.edgesForwardMapping.map(el => el.slice());
        const duplicatedEdgesForwardMapping: DuplicatedEdge[][] =
            triangleMesh.edgesForwardMapping.map(() => []);
        edges.forEach((edge, edgeIndex) => {
            const { edgeVertex1, edgeVertex2, leftFace, leftVertex, rightFace, rightVertex, parent } = edge;
            edgesMapping[edgeIndex] = [edgeIndex];
            // Check if this edge should be duplicated.
            // Only crease edges should be duplicated, they will have faces to both sides, and a valid parent.
            if ((leftFace !== undefined && rightFace !== undefined) && parent !== undefined) {
                // Construct a reversed copy of edge, bordering just the right face.
                const reverseEdge: TriangleEdge = {
                    edgeVertex1: vertexIndexFromMapping(edgeVertex2, triangleMesh.faces[rightFace].parent),
                    edgeVertex2: vertexIndexFromMapping(edgeVertex1, triangleMesh.faces[rightFace].parent),
                    leftVertex: vertexIndexFromMapping(rightVertex!, triangleMesh.faces[rightFace].parent),
                    leftFace: edge.rightFace!,
                    parent: edge.parent,
                    targetLength: edge.targetLength,
                };
                const reverseEdgeIndex = edges.length;
                edges.push(reverseEdge);
                edgesForwardMapping[parent].push(reverseEdgeIndex);
                edgesMapping[edgeIndex].push(reverseEdgeIndex);

                // Clear edge's right vertex and face -- it's no longer connected.
                edge.rightVertex = undefined;
                edge.rightFace = undefined;

                // Keep track of duplicated edges.
                duplicatedEdgesForwardMapping[parent].push({
                    forwardEdgeIndex: edgeIndex,
                    reverseEdgeIndex,
                });
            }

            // Always ues left face as ref for edge vertices.
            const parentFace = triangleMesh.faces[leftFace].parent;
            edge.edgeVertex1 = vertexIndexFromMapping(edge.edgeVertex1, parentFace);
            edge.edgeVertex2 = vertexIndexFromMapping(edge.edgeVertex2, parentFace);
            edge.leftVertex = vertexIndexFromMapping(leftVertex, parentFace);
            if (rightFace !== undefined && rightVertex !== undefined) {
                edge.rightVertex = vertexIndexFromMapping(rightVertex, triangleMesh.faces[rightFace].parent);
            }
        });

        // Arrange duplicated edges in topological order.
        this.orderDuplicatedEdges(crease, vertices, edges, duplicatedEdgesForwardMapping);

        // Keep track of mapping from one tri mesh to another.
        this.verticesLookup = vertices.map(() => -1);
        this.verticesReverseLookup = Object.keys(verticesMapping).map(() => []);
        this.edgesLookup = edges.map(() => -1);

        verticesMapping.forEach((children, parentIndex) => {
            children.forEach(child => {
                this.verticesLookup[child.vertexIndex] = parentIndex;
            });
            this.verticesReverseLookup[parentIndex] = children.map(child => child.vertexIndex);
        });
        edgesMapping.forEach((children, parentIndex) => {
            children.forEach(child => {
                this.edgesLookup[child] = parentIndex;
            });
        });

        return {
            vertices,
            verticesForwardMapping,
            faces,
            facesForwardMapping,
            edges,
            edgesForwardMapping,
            duplicatedEdgesForwardMapping,
        };
    }

    private orderDuplicatedEdges(
        crease: Crease,
        vertices: TriangleVertex[],
        edges: TriangleEdge[],
        duplicatedEdgesForwardMapping: DuplicatedEdgesForwardMapping,
    ) {
        crease.edges.forEach(creaseEdge => {
            const duplicatedEdges = duplicatedEdgesForwardMapping[creaseEdge.index].slice();
            if (duplicatedEdges.length > 0) {
                const creaseEdge = crease.edges[edges[duplicatedEdges[0].forwardEdgeIndex].parent!];
                const firstCreaseVertexIndex = creaseEdge.vertex1.index;
                const firstDuplicatedEdge = duplicatedEdges.find(d =>
                    vertices[edges[d.forwardEdgeIndex].edgeVertex1].parent === firstCreaseVertexIndex);
                if (!firstDuplicatedEdge) {
                    throw new Error('Cannot find first edge in chain of triangle edges.');
                }
                let prevVertexIndex = edges[firstDuplicatedEdge.forwardEdgeIndex].edgeVertex1;
                const edgeMatches = (duplicatedEdge: DuplicatedEdge) =>
                    edges[duplicatedEdge.forwardEdgeIndex].edgeVertex1 === prevVertexIndex;
                const orderedDuplicatedEdges: DuplicatedEdge[] = [];
                while (duplicatedEdges.length) {
                    const arrayIndex = duplicatedEdges.findIndex(edgeMatches);
                    if (arrayIndex < 0) {
                        throw new Error('Cannot complete chain of triangle edges.');
                    }
                    let nextDuplicatedEdge = duplicatedEdges[arrayIndex];
                    let nextEdge = edges[nextDuplicatedEdge.forwardEdgeIndex];
                    duplicatedEdges.splice(arrayIndex, 1);
                    prevVertexIndex = nextEdge.edgeVertex2;
                    orderedDuplicatedEdges.push(nextDuplicatedEdge);
                }
                duplicatedEdgesForwardMapping[creaseEdge.index] = orderedDuplicatedEdges;
            }
        });
    }

    positions(positions: [number, number, number][]) {
        return this.verticesLookup.map((index) => positions[index]);
    }

    offsetPositions(
        triangleMesh: TriangleMesh,
        showFlat: boolean,
        solver: Solver,
        sortedOverlappingGroups: number[][],
        offsetIncr?: number,
    ) {

        const positions = showFlat ? solver.flatPositions : solver.positions;

        // Ignore offsetting if we are showing flat state.
        if (showFlat) {
            return this.verticesLookup.map((index) => positions![index]);
        }

        // No overlapping groups.
        if (sortedOverlappingGroups.length === 0) {
            return this.verticesLookup.map((index) => positions![index]);
        }

        const normals = showFlat ? solver.flatNormals : solver.normals;

        // Set some bounds on offset size - it starts to look ridiculous.
        if (offsetIncr === undefined) {
            offsetIncr = MIN_OFFSET;
        } else {
            offsetIncr = Math.min(offsetIncr, MAX_OFFSET);
        }

        // Calculate vertex normals.
        const vertexNormals = this.verticesLookup.map(() => [0, 0, 0]);
        const numFacesPerVertex = this.verticesLookup.map(() => 0);
        triangleMesh.faces.forEach((face, faceIndex) => {
            const { vertices } = face;
            const normal = normals![faceIndex];
            vertices.forEach(vertex => {
                vertexNormals[vertex] = vec.add(vertexNormals[vertex], normal);
                numFacesPerVertex[vertex]++;
            });
        });
        vertexNormals.forEach((normal, index) => {
            vertexNormals[index] = vec.divide(vertexNormals[index], numFacesPerVertex[index]);
        });

        const offsetPositions = this.verticesLookup.map((index) => positions![index]);
        const visitedVertices = this.verticesLookup.map(() => false);
        sortedOverlappingGroups.forEach(group => {
            group.forEach((creaseFaceIndex, i) => {
                // Always offset toward the exterior normal.
                const offsetAmount = -i * offsetIncr!;
                triangleMesh.facesForwardMapping[creaseFaceIndex].forEach(faceIndex => {
                    const face = triangleMesh.faces[faceIndex];
                    face.vertices.forEach(vertexIndex => {
                        if (visitedVertices[vertexIndex]) {
                            return;
                        }
                        visitedVertices[vertexIndex] = true;
                        offsetPositions[vertexIndex] = vec.add(offsetPositions[vertexIndex], vec.multiply(vertexNormals[vertexIndex], offsetAmount)) as [number, number, number];
                    });
                });
            })
        });
        return offsetPositions;
    }

    removeDuplicates(positions: [number, number, number][]) {
        return this.verticesReverseLookup.map((children) => positions[children[0]]);
    }

    dihedrals(dihedrals: (number | undefined)[]) {
        return this.edgesLookup.map((index) => dihedrals![index]);
    }

    normals(normals: [number, number, number][]) {
        // We don't currently change the triangle count or order, but if we add that in the future,
        // we would need to add some code here.
        return normals;
    }

    addEventListener(listener: Listener) {
        this.eventListeners.push(listener);
    }

    removeEventListener(listener: Listener) {
        this.eventListeners.splice(this.eventListeners.indexOf(listener), 1);
    }

    updatePositions() {
        this.eventListeners.forEach(listener => {
            listener();
        });
    }
}