import { inject, observer } from 'mobx-react';
import { Tool } from './Tool';

@inject('store')
@observer
export class DollyTool extends Tool {
    toolOnActivate() {
        const { render } = this.props.store;
        render.controlsFlat.setLeftDragZoom();
        render.controls3D.setLeftDragZoom();
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.controlsFlat.disableLeftDrag();
        render.controls3D.disableLeftDrag();
    }
}
