/**
 * Adapted from Three.js TrackballControls:
 * https://github.com/mrdoob/three.js/blob/master/examples/js/controls/TrackballControls.js
 */
import * as THREE from 'three';

const STATE = { NONE: - 1, ROTATE: 0, ZOOM: 1, PAN: 2, TOUCH_ROTATE: 3, TOUCH_ZOOM_PAN: 4 };
const CHANGE_EVENT = { type: 'change' };
const START_EVENT = { type: 'start' };
const END_EVENT = { type: 'end' };
const TOUCH_END_EVENT = { type: 'touchend' };
const ZOOM_EVENT = { type: 'zoom' };

interface RotationStart {
    moveStart: THREE.Vector2;
    eyeStart: THREE.Vector3;
    rightDirection: THREE.Vector3;
    upDirection: THREE.Vector3;
}

export class Controls3D extends THREE.EventDispatcher {
    object: THREE.Camera;

    // API
    enabled: boolean;
    screen: { left: number, top: number, width: number, height: number };

    constrainOrbit: boolean;
    spherical: THREE.Spherical;
    sphericalDelta: THREE.Spherical;
    minOrbitPolarAngle: number;
    maxOrbitPolarAngle: number;

    rotateSpeed: number;
    zoomSpeed: number;
    panSpeed: number;
    targetAnimationSpeed: number;

    noRotate: boolean;
    noZoom: boolean;
    noPan: boolean;
    state: number;

    staticMoving: boolean;
    dynamicDampingFactor: number;

    minDistance: number;
    maxDistance: number;
    maxPanDistance: number;

    target: THREE.Vector3;

    private domElement!: HTMLElement;
    private window: Window;
    private leftDragMode: number;
    private shouldLockTarget: boolean;
    private eye: THREE.Vector3;
    private movePrev: THREE.Vector2;
    private moveCurr: THREE.Vector2;
    private zoomStart: THREE.Vector2;
    private zoomEnd: THREE.Vector2;
    private touchZoomDistanceStart: number;
    private touchZoomDistanceEnd: number;
    private panStart: THREE.Vector2;
    private panEnd: THREE.Vector2;
    private rotationStart?: RotationStart;
    private target0: THREE.Vector3;
    private position0: THREE.Vector3;
    private up0: THREE.Vector3;
    private nextTarget: THREE.Vector3;

    constructor(object: THREE.Camera, domElement: HTMLElement, domWindow?: Window) {
        super();
        this.object = object;

        this.window = (domWindow !== undefined) ? domWindow : window;

        // Set to false to disable this control
        this.enabled = true;
        this.screen = { left: 0, top: 0, width: 0, height: 0 };

        // Variables for orbit controls.
        this.constrainOrbit = false;
        // current position in spherical coordinates
        this.spherical = new THREE.Spherical();
        this.sphericalDelta = new THREE.Spherical();
        // How far you can orbit vertically, upper and lower limits.
        // Range is 0 to Math.PI radians.
        this.minOrbitPolarAngle = 0; // radians
        this.maxOrbitPolarAngle = Math.PI; // radians

        this.rotateSpeed = 1.0;
        this.zoomSpeed = 1.2;
        this.panSpeed = 1;
        this.targetAnimationSpeed = 1;

        this.noRotate = false;
        this.noZoom = false;
        this.noPan = false;

        this.staticMoving = false;
        this.dynamicDampingFactor = 0.2;

        // How far you can dolly in and out ( PerspectiveCamera only )
        this.minDistance = 0;
        this.maxDistance = Infinity;
        // How far from target you can pan.
        this.maxPanDistance = Infinity;

        // "target" sets the location of focus, where the object orbits around
        this.target = new THREE.Vector3();
        // nextTarget sets to the location to animate target to next.
        this.nextTarget = this.target.clone();
        this.shouldLockTarget = false;// Temp disable target animations.

        this.state = STATE.NONE;
        this.leftDragMode = STATE.NONE;

        this.eye = new THREE.Vector3();

        this.movePrev = new THREE.Vector2();
        this.moveCurr = new THREE.Vector2();

        this.zoomStart = new THREE.Vector2();
        this.zoomEnd = new THREE.Vector2();

        this.touchZoomDistanceStart = 0;
        this.touchZoomDistanceEnd = 0;

        this.panStart = new THREE.Vector2();
        this.panEnd = new THREE.Vector2();

        this.target0 = this.target.clone();
        this.position0 = this.object.position.clone();
        this.up0 = this.object.up.clone();

        // Bind event handlers to this object, so they can be passed as listeners.
        this.contextMenu = this.contextMenu.bind(this);
        this.pointerDown = this.pointerDown.bind(this);
        this.pointerMove = this.pointerMove.bind(this);
        this.pointerUp = this.pointerUp.bind(this);
        this.lostPointerCapture = this.lostPointerCapture.bind(this);
        this.mouseWheel = this.mouseWheel.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
        this.handleResize = this.handleResize.bind(this);

        // Bind events.
        this.window.addEventListener('resize', this.handleResize, false);
        this.setDomElement(domElement);

        // force an update at start
        this.update();
    }

    // ------------------------------------------------

    // Right drag is always orbit.
    // Scroll wheel/drag is always zoom.
    // Left drag depends on active tool.
    private pointerDown(event: PointerEvent): void {
        if (this.enabled === false) { return; }
        if (this.state === STATE.NONE) {
            switch (event.button) {
                case 0: // Left click
                    if (this.leftDragMode === STATE.PAN && !this.noPan) {
                        this.state = STATE.PAN;
                        this.panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
                        this.panEnd.copy(this.panStart);
                    } else if (this.leftDragMode === STATE.ZOOM) {
                        this.state = STATE.ZOOM;
                        this.zoomStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
                        this.zoomEnd.copy(this.zoomStart);
                    } else if (this.leftDragMode === STATE.ROTATE && !this.noRotate) {
                        this.state = STATE.ROTATE;
                        this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
                        this.movePrev.copy(this.moveCurr);
                        this.startRotation();
                    }
                    break;
                case 1: // Scroll click
                    if (!this.noPan) {
                        this.state = STATE.PAN;
                        this.panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
                        this.panEnd.copy(this.panStart);
                    }
                    break;
                case 2: // Right click
                    if (!this.noRotate) {
                        this.state = STATE.ROTATE;
                        this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
                        this.movePrev.copy(this.moveCurr);
                        this.startRotation();
                    } else if (!this.noPan) {
                        // If we are in dieline view, let right drag default to pan.
                        this.state = STATE.PAN;
                        this.panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
                        this.panEnd.copy(this.panStart);
                    }
                    break;
            }
        }

        if (this.state !== STATE.NONE) {
            this.domElement.addEventListener('pointermove', this.pointerMove, false);
            this.domElement.addEventListener('pointerup', this.pointerUp, false);
            this.domElement.addEventListener('lostpointercapture', this.lostPointerCapture, false);
            this.domElement.setPointerCapture(event.pointerId);
            event.preventDefault();
            event.stopPropagation();
            this.dispatchEvent(START_EVENT);
        }
    }

    private pointerMove(event: PointerEvent): void {
        if (this.enabled === false) { return; }
        if (this.state === STATE.ROTATE) {
            this.moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
        } else if (this.state === STATE.ZOOM) {
            this.zoomEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        } else if (this.state === STATE.PAN) {
            this.panEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }

        if (this.state !== STATE.NONE) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private pointerUp(event: PointerEvent): void {
        if (this.enabled === false) { return; }

        if (this.state !== STATE.NONE) {
            this.domElement.releasePointerCapture(event.pointerId);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private lostPointerCapture(): void {
        this.endMouseEvent();
    }

    private endMouseEvent() {
        if (this.state !== STATE.NONE) {
            this.state = STATE.NONE;
            this.rotationStart = undefined;
            this.domElement.removeEventListener('pointermove', this.pointerMove);
            this.domElement.removeEventListener('pointerup', this.pointerUp);
            this.domElement.removeEventListener('lostpointercapture', this.lostPointerCapture);
            this.dispatchEvent(END_EVENT);
        }
    }

    private mouseWheel(event: WheelEvent): void {
        if (this.enabled === false) { return; }
        switch (event.deltaMode) {
            case 2:
                // Zoom in pages
                this.zoomStart.y -= event.deltaY * 0.025;
                break;

            case 1:
                // Zoom in lines
                this.zoomStart.y -= event.deltaY * 0.01;
                break;

            default:
                // undefined, 0, assume pixels
                this.zoomStart.y -= event.deltaY * 0.00025;
                break;

        }
        this.dispatchEvent(START_EVENT);
        this.dispatchEvent(END_EVENT);
        event.preventDefault();
        event.stopPropagation();
    }

    private touchStart(event: TouchEvent): void {
        if (this.enabled === false) { return; }
        switch (event.touches.length) {
            case 1:
                this.state = STATE.TOUCH_ROTATE;
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                this.movePrev.copy(this.moveCurr);
                this.startRotation();
                break;
            default: // 2 or more
                this.state = STATE.TOUCH_ZOOM_PAN;
                const dx = event.touches[0].pageX - event.touches[1].pageX;
                const dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = this.touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);
                const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panStart.copy(this.getMouseOnScreen(x, y));
                this.panEnd.copy(this.panStart);
                break;
        }
        this.dispatchEvent(START_EVENT);
    }

    private touchMove(event: TouchEvent): void {
        if (this.enabled === false) { return; }
        if (event.cancelable) {
            event.preventDefault();
            event.stopPropagation();
         }
        
        switch (event.touches.length) {
            case 1:
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                break;
            default: // 2 or more
                const dx = event.touches[0].pageX - event.touches[1].pageX;
                const dy = event.touches[0].pageY - event.touches[1].pageY;
                this.touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);
                const x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
                const y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
                this.panEnd.copy(this.getMouseOnScreen(x, y));
                break;
        }
    }

    private touchEnd(event: TouchEvent): void {
        if (this.enabled === false) { return; }
        switch (event.touches.length) {
            case 0:
                this.state = STATE.NONE;
                return;
            case 1:
                this.moveCurr.copy(this.getMouseOnCircle(event.touches[0].pageX, event.touches[0].pageY));
                break;
        }
        this.endTouchEvent();
    }

    private endTouchEvent() {
        this.state = STATE.NONE;
        this.rotationStart = undefined;
        this.dispatchEvent(END_EVENT);
        this.dispatchEvent(TOUCH_END_EVENT);
    }

    private contextMenu(event: Event): void {
        event.preventDefault();
    }

    // ------------------------------------------------

    private handleResize(): void {
        const box = this.domElement.getBoundingClientRect();
        // adjustments come from similar code in the jquery offset() function
        const d = this.domElement.ownerDocument!.documentElement;
        this.screen.left = box.left + this.window.pageXOffset - d.clientLeft;
        this.screen.top = box.top + this.window.pageYOffset - d.clientTop;
        this.screen.width = box.width;
        this.screen.height = box.height;
    }

    private getMouseOnScreen(pageX: number, pageY: number): THREE.Vector2 {
        const vector = new THREE.Vector2();
        return vector.set(
            (pageX - this.screen.left) / this.screen.width,
            (pageY - this.screen.top) / this.screen.height
        );
    }

    private getMouseOnCircle(pageX: number, pageY: number): THREE.Vector2 {
        const vector = new THREE.Vector2();
        return vector.set(
            ((pageX - this.screen.width * 0.5 - this.screen.left) / (this.screen.width * 0.5)),
            ((this.screen.height + 2 * (this.screen.top - pageY)) / this.screen.width)
        );
    }

    private getArcballPoint(p: THREE.Vector2): THREE.Vector3 {
        // Bell's trackball (see for example
        // https://www.researchgate.net/publication/8329656_Virtual_Trackballs_Revisited).
        const r = 0.5;
        const len = p.length();
        let z: number;
        if (len <= r / Math.SQRT2) {
            z = Math.sqrt(r * r - len * len);
        } else {
            z = r * r / (2 * len);
        }
        return new THREE.Vector3(p.x, p.y, z);
    }

    private startRotation() {
        const moveStart = this.moveCurr.clone();
        const eyeStart = this.eye.clone();
        const rightDirection = this.object.up.clone().cross(eyeStart).normalize();
        const upDirection = this.object.up.clone().normalize();
        this.rotationStart = { moveStart, eyeStart, rightDirection, upDirection };
    }

    private rotateCamera(): void {
        if (this.constrainOrbit) {
            const delta = this.moveCurr.clone().sub(this.movePrev);
            // Convert moves to spherical delta
            this.sphericalDelta.theta = this.rotateSpeed * -delta.x * Math.PI;
            this.sphericalDelta.phi = this.rotateSpeed * delta.y * Math.PI;
            // so camera.up is the orbit axis
            const updateQuat = new THREE.Quaternion().setFromUnitVectors( this.object.up, new THREE.Vector3( 0, 1, 0 ) );
            const updateQuatInverse = updateQuat.clone().inverse();
            // rotate offset to "y-axis-is-up" space
            this.eye.applyQuaternion( updateQuat );
            // angle from z-axis around y-axis
            this.spherical.setFromVector3( this.eye );
            this.spherical.theta += this.sphericalDelta.theta;
            this.spherical.phi += this.sphericalDelta.phi;// restrict theta to be between desired limits
            // restrict phi to be between desired limits
            this.spherical.phi = Math.max( this.minOrbitPolarAngle, Math.min( this.maxOrbitPolarAngle, this.spherical.phi ) );
            this.spherical.makeSafe();
            this.eye.setFromSpherical( this.spherical );
            this.eye.applyQuaternion(updateQuatInverse);
        } else {
            // Commented out code is for arcball controls.
            // const p1 = this.getArcballPoint(this.movePrev);
            // const p2 = this.getArcballPoint(this.moveCurr);

            // const eyeDirection = new THREE.Vector3().copy(this.eye).normalize();
            // const rightDirection = new THREE.Vector3().crossVectors(this.object.up,
            //     eyeDirection).normalize();
            // const upDirection = new THREE.Vector3().crossVectors(eyeDirection,
            //     rightDirection).normalize();

            // const v1 = new THREE.Vector3()
            //     .addScaledVector(rightDirection, p1.x)
            //     .addScaledVector(upDirection, p1.y)
            //     .addScaledVector(eyeDirection, p1.z)
            //     .normalize();

            // const v2 = new THREE.Vector3()
            //     .addScaledVector(rightDirection, p2.x)
            //     .addScaledVector(upDirection, p2.y)
            //     .addScaledVector(eyeDirection, p2.z)
            //     .normalize();

            // const axis = new THREE.Vector3().crossVectors(v2, v1).normalize();
            // const dotProduct = THREE.MathUtils.clamp(v2.dot(v1), -1, 1);
            // const angle = this.rotateSpeed * Math.acos(dotProduct);

            if (this.rotationStart) {
                const { moveStart, eyeStart, rightDirection, upDirection } = this.rotationStart;

                const diff = this.moveCurr.clone().sub(moveStart);
                const up = upDirection.clone().setLength(diff.y);
                const right = rightDirection.clone().setLength(diff.x);

                const axis = up.clone().add(right).cross(eyeStart).normalize();
                const angle = this.rotateSpeed * diff.length() * Math.PI;

                const q = new THREE.Quaternion().setFromAxisAngle(axis, angle);

                this.eye.copy(eyeStart).applyQuaternion(q);
                this.object.up.copy(upDirection).applyQuaternion(q);
            }
        }
        this.movePrev.copy(this.moveCurr);
    }

    private zoomCamera(): void {
        let factor = 0;
        if (this.state === STATE.TOUCH_ZOOM_PAN) {
            factor = this.touchZoomDistanceStart / this.touchZoomDistanceEnd;
            this.touchZoomDistanceStart = this.touchZoomDistanceEnd;
            this.eye.multiplyScalar(factor);
        } else {
            factor = 1.0 + (this.zoomEnd.y - this.zoomStart.y) * this.zoomSpeed;

            if (factor !== 1.0 && factor > 0.0) {
                this.eye.multiplyScalar(factor);
            }
            if (this.staticMoving) {
                this.zoomStart.copy(this.zoomEnd);
            } else {
                this.zoomStart.y += (this.zoomEnd.y - this.zoomStart.y) * this.dynamicDampingFactor;
            }
        }
    }

    private panCamera(): void {
        const mouseChange: THREE.Vector2 = new THREE.Vector2();
        const objectUp: THREE.Vector3 = new THREE.Vector3();
        const pan: THREE.Vector3 = new THREE.Vector3();

        mouseChange.copy(this.panEnd).sub(this.panStart);

        // Correct for the aspect ratio of the view.
        mouseChange.x *= this.screen.width / this.screen.height;

        if (mouseChange.lengthSq()) {

            let scale = this.eye.length() * this.panSpeed;
            if ('isPerspectiveCamera' in this.object && this.object['isPerspectiveCamera']) {
                const perspectiveCamera = this.object as THREE.PerspectiveCamera;
                const tanHalfFov = Math.tan(THREE.MathUtils.degToRad(perspectiveCamera.fov / 2));
                scale *= tanHalfFov;
            }
            mouseChange.multiplyScalar(scale);

            pan.copy(this.eye).cross(this.object.up).setLength(mouseChange.x);
            pan.add(objectUp.copy(this.object.up).setLength(mouseChange.y));

            this.target.add(pan);

            // Check against max pan distance.
            if (this.target.lengthSq() > this.maxPanDistance * this.maxPanDistance) {
                this.target.sub(pan);
                this.panStart.copy(this.panEnd);
                return;
            }

            //
            this.nextTarget.copy(this.target);

            this.object.position.add(pan);

            if (this.staticMoving) {
                this.panStart.copy(this.panEnd);
            } else {
                this.panStart.add(mouseChange.subVectors(this.panEnd, this.panStart).multiplyScalar(this.dynamicDampingFactor));
            }
        }
    }

    private checkDistances(): void {
        if (!this.noZoom || !this.noPan) {
            if (this.eye.lengthSq() > this.maxDistance * this.maxDistance) {
                this.object.position.addVectors(this.target, this.eye.setLength(this.maxDistance));
                this.zoomStart.copy(this.zoomEnd);
            }
            if (this.eye.lengthSq() < this.minDistance * this.minDistance) {
                this.object.position.addVectors(this.target, this.eye.setLength(this.minDistance));
                this.zoomStart.copy(this.zoomEnd);
            }
        }
    }

    update(): void {
        // Flag to return, telling outside if we need a render.
        let changed = !this.moveCurr.equals(this.movePrev);
        changed = changed || this.touchZoomDistanceStart !== this.touchZoomDistanceEnd;
        changed = changed || !this.zoomStart.equals(this.zoomEnd);
        changed = changed || !this.panStart.equals(this.panEnd);
        const shouldAnimateToTarget = !this.shouldLockTarget && !this.target.equals(this.nextTarget);
        changed = changed || shouldAnimateToTarget;

        // animate target if needed.
        if (shouldAnimateToTarget) {
            const targetDiff = this.nextTarget.clone().sub(this.target);
            if (targetDiff.length() < this.targetAnimationSpeed) {
                this.target = this.nextTarget.clone();
            } else {
                this.target.add(targetDiff.normalize().multiplyScalar(this.targetAnimationSpeed));
            }
        }

        this.eye.subVectors(this.object.position, this.target);
        if (!this.noRotate) {
            this.rotateCamera();
        }
        let zoomed = false;
        if (!this.noZoom) {
            if (!this.zoomStart.equals(this.zoomEnd) || this.touchZoomDistanceStart !== this.touchZoomDistanceEnd) {
                zoomed = true;
            }
            this.zoomCamera();
        }
        if (!this.noPan) {
            this.panCamera();
        }
        this.object.position.addVectors(this.target, this.eye);
        this.checkDistances();
        this.object.lookAt(this.target);
        if (zoomed) {
            this.dispatchEvent(ZOOM_EVENT);
        }
        if (changed) {
            this.dispatchEvent(CHANGE_EVENT);
        }
    }

    reset(): void {
        this.state = STATE.NONE;
        this.target.copy(this.target0);
        this.object.position.copy(this.position0);
        this.object.up.copy(this.up0);
        this.eye.subVectors(this.object.position, this.target);
        this.object.lookAt(this.target);
        this.dispatchEvent(CHANGE_EVENT);
    }

    setLeftDragOrbit(): void {
        this.leftDragMode = STATE.ROTATE;
    }

    setLeftDragPan(): void {
        this.leftDragMode = STATE.PAN;
    }

    setLeftDragZoom(): void {
        this.leftDragMode = STATE.ZOOM;
    }

    disableLeftDrag(): void {
        this.leftDragMode = STATE.NONE;
    }

    animateToTarget(nextTarget: THREE.Vector3): void {
        this.nextTarget.copy(nextTarget);
    }

    enableAnimatedTarget(): void {
        this.shouldLockTarget = false;
    }

    disableAnimatedTarget(): void {
        this.shouldLockTarget = true;
    }

    setDomElement(domElement: HTMLElement): void {
        if (this.domElement) {
            this.removeListeners();
            this.endMouseEvent();
        }

        this.domElement = domElement;

        this.domElement.addEventListener('contextmenu', this.contextMenu, false);
        this.domElement.addEventListener('pointerdown', this.pointerDown, false);
        this.domElement.addEventListener('wheel', this.mouseWheel, false);

        this.domElement.addEventListener('touchstart', this.touchStart, false);
        this.domElement.addEventListener('touchend', this.touchEnd, false);
        this.domElement.addEventListener('touchmove', this.touchMove, false);
        this.handleResize();
    }

    dispose(): void {
        this.removeListeners();

        if (this.domElement) {
            this.domElement.removeEventListener('pointermove', this.pointerMove, false);
            this.domElement.removeEventListener('pointerup', this.pointerUp, false);
            this.domElement.removeEventListener('lostpointercapture', this.lostPointerCapture);
        }

        this.window.removeEventListener('resize', this.handleResize, false);
    }

    private removeListeners() {
        this.domElement.removeEventListener('contextmenu', this.contextMenu, false);
        this.domElement.removeEventListener('pointerdown', this.pointerDown, false);
        this.domElement.removeEventListener('wheel', this.mouseWheel, false);

        this.domElement.removeEventListener('touchstart', this.touchStart, false);
        this.domElement.removeEventListener('touchend', this.touchEnd, false);
        this.domElement.removeEventListener('touchmove', this.touchMove, false);
    }
}
