import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import '../css/Footer.css';
import { CookieConsent } from '../services/CookieConsent';
import { StoreComponent } from './StoreComponent';

interface FooterProps {
    overlay?: boolean;
}

@inject('store')
@observer
export class Footer extends StoreComponent<FooterProps> {
    render() {
        const className = classNames('Footer', {
            'Footer--overlay': this.props.overlay,
            'Footer--no-overlay': !this.props.overlay,
        });
        return (
            <div className={className}>
                <CookieConsent onConsent={this.props.store.account.setCookieConsent} />
            </div>
        );
    }
}
