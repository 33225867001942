import Tooltip from '@react/react-spectrum/Tooltip';
import { boundMethod } from 'autobind-decorator';
import { utils as creaseUtils } from 'crease';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import { utils } from '../common/utils';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { Overlay } from '../components/Overlay';
import { Platform } from '../components/Platform';
import '../css/FoldAngleTool.css';
import { Analytics, EventName } from '../services/Analytics';
import { StoreProps } from '../UI/StoreComponent';
import { FoldAnglePopover } from './FoldAnglePopover';
import { Modifiers, Tool } from './Tool';

/*
AdobePatentID="P9702-US"
*/

const RING_RADIUS = 75; // pixels
const RING_THICKNESS = 3; // pixels
const OUTER_SPHERE_RADIUS = 14; // pixels
const INNER_SPHERE_RADIUS = 12; // pixels
const HIT_TARGET_RADIUS = 20; // pixels
const RADIAL_LINE_THICKNESS = 3; // pixels
const RIGHT_ANGLE_LENGTH = RING_RADIUS / 4; // pixels
const SMALL_SNAPPING_INCREMENT = creaseUtils.convertToRadians(0.1); // degrees
const NORMAL_SNAPPING_INCREMENT = creaseUtils.convertToRadians(1); // degrees
const LARGE_SNAPPING_INCREMENT = creaseUtils.convertToRadians(45); // degrees

@inject('store')
@observer
export class FoldAngleTool extends Tool {
    // 3D objects.
    private wrapper = new THREE.Group();
    private leftFaceSelectionTool = new THREE.Group();
    private rightFaceSelectionTool = new THREE.Group();
    // Materials used in selection handles.
    private ringMaterial = new THREE.MeshBasicMaterial({ fog: false });
    private faceOutlineMaterial = new THREE.MeshBasicMaterial({ fog: false, depthWrite: false });
    private faceSelectionMaterial = new THREE.MeshBasicMaterial({ fog: false });
    private faceHoverMaterial = new THREE.MeshBasicMaterial({ fog: false });
    private wedgeMaterial = new THREE.MeshBasicMaterial({ fog: false, opacity: 0.3, transparent: true, side: THREE.DoubleSide });
    private rightAngleMaterial = new THREE.MeshBasicMaterial({ fog: false, opacity: 0.7, transparent: true, side: THREE.DoubleSide });
    private hitTargetMaterial = new THREE.MeshBasicMaterial({ depthWrite: false, colorWrite: false });
    // Highlighters draw a border around the selection tools
    private leftFaceSelectionHighlighter: THREE.Mesh;
    private rightFaceSelectionHighlighter: THREE.Mesh;
    // Pie wedges from unfolded direction to selection tools.
    private leftWedge: THREE.Mesh;
    private rightWedge: THREE.Mesh;
    // Lines from center of widget out to selection tools.
    private leftLine: THREE.Mesh;
    private rightLine: THREE.Mesh;
    // Right-angle indicator.
    private rightAngleIndicator: THREE.Mesh;
    // Dragging plane.
    private _plane = new THREE.Plane();

    // Which face selection tool is currently being dragged.
    @observable
    private activeFaceSelectionTool: THREE.Object3D | null = null;
    // Flag if we need to reverse angle calcs.
    // This depends on this face is leftFace/rightFace on the edge.
    @observable
    private reversed: boolean = false;
    // Keep track of angle state (always use radians).
    @observable
    private angle: number | null = null;
    @observable
    private dragStartAngle: number = 0;
    @observable
    private creaseStartAngle: number = 0;
    @observable
    private tooltipText?: string = undefined;
    @observable
    private showPopover: boolean = false;
    
    constructor(props: StoreProps) {
        super(props);

        // Style widget and add geometry.
        const torus = new THREE.Mesh(
            new THREE.TorusBufferGeometry(RING_RADIUS, RING_THICKNESS / 2, 10, 100),
            this.ringMaterial,
        );
        this.wrapper.add(torus);

        // Highlighter draws a circular boundary around selection handle.
        // For now, using an inside out sphere to render this.
        const outlineGeometry = new THREE.SphereBufferGeometry(OUTER_SPHERE_RADIUS, 32, 32);
        this.leftFaceSelectionHighlighter = new THREE.Mesh(
            outlineGeometry,
            this.faceOutlineMaterial,
        );
        this.rightFaceSelectionHighlighter = new THREE.Mesh(
            outlineGeometry,
            this.faceOutlineMaterial,
        );
        this.wrapper.add(this.leftFaceSelectionHighlighter);
        this.wrapper.add(this.rightFaceSelectionHighlighter);

        // Selection handles are made from two half spheres, so we can hide part of them when they
        // overlap each other at +/- PI.
        const hemisphereGeometry = new THREE.SphereBufferGeometry(INNER_SPHERE_RADIUS, 32, 32, 0, 2*Math.PI, 0, Math.PI/2);
        // Add additional hemispheres for hit targets,
        // decoupled from the visulization so we can change the size of the targets.
        const hemisphereGeometryHitTarget = new THREE.SphereBufferGeometry(HIT_TARGET_RADIUS, 32, 32, 0, 2*Math.PI, 0, Math.PI/2);
        const leftFaceHitTargets = [
            this.makeHemisphereWithHitTarget(
                hemisphereGeometry, this.faceSelectionMaterial,
                hemisphereGeometryHitTarget, this.hitTargetMaterial),
            this.makeHemisphereWithHitTarget(
                hemisphereGeometry, this.faceSelectionMaterial,
                hemisphereGeometryHitTarget, this.hitTargetMaterial).rotateX(Math.PI),
        ];
        const rightFaceHitTargets = [
            this.makeHemisphereWithHitTarget(
                hemisphereGeometry, this.faceSelectionMaterial,
                hemisphereGeometryHitTarget, this.hitTargetMaterial),
            this.makeHemisphereWithHitTarget(
                hemisphereGeometry, this.faceSelectionMaterial,
                hemisphereGeometryHitTarget, this.hitTargetMaterial).rotateX(Math.PI),
        ];
        this.leftFaceSelectionTool.add(...leftFaceHitTargets);
        this.rightFaceSelectionTool.add(...rightFaceHitTargets);

        this.wrapper.add(this.leftFaceSelectionTool);
        this.wrapper.add(this.rightFaceSelectionTool);

        // Pie wedges.
        this.leftWedge = new THREE.Mesh(undefined, this.wedgeMaterial);
        this.rightWedge = new THREE.Mesh(undefined, this.wedgeMaterial);
        this.wrapper.add(this.leftWedge);
        this.wrapper.add(this.rightWedge);

        // Radial lines along faces.
        const lineGeometry = new THREE.CylinderBufferGeometry(RADIAL_LINE_THICKNESS / 2,
            RADIAL_LINE_THICKNESS / 2, RING_RADIUS);
        lineGeometry.translate(0, RING_RADIUS / 2, 0);
        this.leftLine = new THREE.Mesh(lineGeometry, this.faceOutlineMaterial);
        this.rightLine = new THREE.Mesh(lineGeometry, this.faceOutlineMaterial);
        this.wrapper.add(this.leftLine);
        this.wrapper.add(this.rightLine);

        // Right angle indicator.
        const rightAngleGeometry = new THREE.PlaneBufferGeometry(RIGHT_ANGLE_LENGTH,
            RIGHT_ANGLE_LENGTH, 1, 1);
        rightAngleGeometry.translate(RIGHT_ANGLE_LENGTH / 2, RIGHT_ANGLE_LENGTH / 2, 0);
        this.rightAngleIndicator = new THREE.Mesh(rightAngleGeometry, this.rightAngleMaterial);
        this.rightAngleIndicator.visible = false;
        this.wrapper.add(this.rightAngleIndicator);
    }

    makeHemisphereWithHitTarget(visGeo: THREE.BufferGeometry, visMaterial: THREE.Material,
        hitGeo: THREE.BufferGeometry, hitMaterial: THREE.Material) {
        const hitMesh = new THREE.Mesh(hitGeo, hitMaterial);
        const visMesh = new THREE.Mesh(visGeo, visMaterial);
        // Set raycasting target - this never changes for this tool.
        this.raycastingTargets.push(hitMesh);
        return (new THREE.Group()).add(hitMesh, visMesh);
    }

    toolOnActivate() {
        const { render } = this.props.store;
        render.addObject3Ds([
            this.wrapper,
        ], render.toolScene);
        render.colorBinder.bind(this.ringMaterial, 'color', '--color-gizmo-ring');
        render.colorBinder.bind(this.faceOutlineMaterial, 'color', '--color-gizmo-handle-outline');
        render.colorBinder.bind(this.faceSelectionMaterial, 'color', '--color-gizmo-handle-fill');
        render.colorBinder.bind(this.faceHoverMaterial, 'color', '--color-gizmo-handle-hover');
        render.colorBinder.bind(this.rightAngleMaterial, 'color', '--color-gizmo-right-angle');
        render.colorBinder.bind(this.wedgeMaterial, 'color', '--color-gizmo-handle-hover');

        this.addAutorun(() => {
            const centerEdge = this.props.store.lastSelectedEdge;
            if (!this.props.store.ui.foldAngleToolDragState) {
                let angle: number | null = null;
                if (centerEdge && centerEdge.foldAngle !== undefined) {
                    angle = utils.convertToRadians(centerEdge.foldAngle);
                    if (centerEdge.assignment === 'V') {
                        angle *= -1;
                    }
                }
                this.angle = angle;
            }
            this.wrapper.visible = centerEdge !== null && !this.props.store.ui.showFlat;
        });
    }

    toolOnDeactivate() {
        const { geometry, render } = this.props.store;
        render.removeObject3Ds([
            this.wrapper,
        ], render.toolScene);
        render.colorBinder.unbind(this.ringMaterial, 'color');
        render.colorBinder.unbind(this.faceOutlineMaterial, 'color');
        render.colorBinder.unbind(this.faceSelectionMaterial, 'color');
        render.colorBinder.unbind(this.faceHoverMaterial, 'color');
        render.colorBinder.unbind(this.rightAngleMaterial, 'color');
        render.colorBinder.unbind(this.wedgeMaterial, 'color');

        // Dispose all children of wrapper.
        this.wrapper.traverse(child => {
            if (child instanceof THREE.Mesh) {
                if (Array.isArray(child.material)) {
                    child.material.forEach(material => {
                        material.dispose();
                    });
                } else {
                    child.material.dispose();
                }
                child.geometry.dispose();
            }
        });

        // Set bottom face as fixed.
        render.solver.setFixedFace(geometry.bottomFaceIndex);
    }

    private _getIntersectedHandle(intersections: THREE.Intersection[]) {
        // Handle no intersection case.
        if (intersections.length === 0) {
            return null;
        }
        for (let i = 0; i < intersections.length; i++) {
            if (!(intersections[i].object.parent?.visible)) {
                continue;
            }
            return intersections[i].object.parent!.parent;
        }
        return null;
    }

    // Returns the angle of the mouse raycast onto the dragging plane.
    private _getAngle(center: THREE.Vector3, reversed: boolean) {
        const vector = center.clone().sub(this.wrapper.position);
        const quaternion = new THREE.Quaternion().setFromUnitVectors(new THREE.Vector3(0, 0, 1), this._plane.normal);
        vector.applyQuaternion(quaternion.conjugate());
        const multiplier = reversed ? -1 : 1;
        return multiplier * Math.atan2(vector.y, vector.x);
    }

    onHoverMove(intersections: THREE.Intersection[]) {
        if (this.props.store.ui.foldAngleToolDragState) {
            return true;
        }
        return this._updateHoverColors(intersections);
    }

    onLeftDown(intersections: THREE.Intersection[]) {
        const centerEdge = this.props.store.lastSelectedEdge;
        if (centerEdge) {
            const activeFaceSelectionTool = this._getIntersectedHandle(intersections);
            if (activeFaceSelectionTool) {
                const fixedFaceIndex = activeFaceSelectionTool === this.rightFaceSelectionTool ?
                    centerEdge.leftFace : centerEdge.rightFace;
                this.reversed = centerEdge.leftFace === fixedFaceIndex;
                this.dragStartAngle = this._getAngle(activeFaceSelectionTool.children[0].getWorldPosition(new THREE.Vector3()), this.reversed);
                this.creaseStartAngle = this.angle === null ? this._getDihedralAngle() : this.angle;
                // Store initial state of user interaction.
                this.activeFaceSelectionTool = activeFaceSelectionTool;
                this.showPopover =false;
                const { render, ui } = this.props.store;
                ui.foldAngleToolDragState = true;
                // Fix face corresponding to unselected handle.
                render.solver.setFixedFace(fixedFaceIndex);
                return true;
            }
            this.activeFaceSelectionTool = null;
            return false;
        }
        return false;
    }

    onLeftClick(intersections: THREE.Intersection[]) {
        this.activeFaceSelectionTool = this._getIntersectedHandle(intersections);
        this.showPopover = this.activeFaceSelectionTool !== null;
        this.props.store.ui.foldAngleToolDragState = false;
        return intersections.length > 0;
    }

    onLeftDragStart() {
        if (!this.props.store.ui.foldAngleToolDragState) {
            return false;
        }
        return true;
    }

    onLeftDragMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers, raycaster: THREE.Raycaster) {
        if (!this.props.store.ui.foldAngleToolDragState) {
            return false;
        }

        // Calculate intersection with plane.
        const dragPosition = new THREE.Vector3();
        raycaster.ray.intersectPlane(this._plane, dragPosition);
        // Get angle change from drag.
        const delta = this._getAngle(dragPosition, this.reversed) - this.dragStartAngle;
        let angle = this.creaseStartAngle + delta;
        if (angle > Math.PI) {
            angle -= 2 * Math.PI;
        }
        if (angle < -Math.PI) {
            angle += 2 * Math.PI;
        }
        // Don't allow drag past PI/-PI.
        if (this.angle !== null && angle * this.angle < 0 && Math.abs(this.angle) > 2) {
            angle = this.angle > 0 ? Math.PI : -Math.PI;
        }

        // Snap angle to large, small, or normal increment, depending on modifier keys.
        let snappingIncrement = modifiers.shiftKey ? LARGE_SNAPPING_INCREMENT :
            NORMAL_SNAPPING_INCREMENT;
        if ((Platform.IS_MAC && modifiers.metaKey) || (!Platform.IS_MAC && modifiers.ctrlKey)) {
            snappingIncrement = SMALL_SNAPPING_INCREMENT;
        }
        angle = Math.round(angle / snappingIncrement) * snappingIncrement;

        // Update tooltip.
        const digits = snappingIncrement < NORMAL_SNAPPING_INCREMENT ? 1 : 0;
        const angleInDegrees = utils.convertToDegrees(angle);
        const tooltipText = angleInDegrees.toFixed(digits) + '°';
        
        // Update local state.
        this.angle = angle;
        this.tooltipText = tooltipText;

        // Set new target angle for all selected edges.
        // Do this directly to solver for now, without updating store.
        // Update to store happens on drag end.
        const { render, selection } = this.props.store;
        selection.selectedEdges.forEach(edgeIndex => {
            render.solver.setTargetCreaseAngleForEdgeIndex(angleInDegrees, edgeIndex)
        });

        return true;
    }

    onLeftDragEnd(intersections: THREE.Intersection[]) {
        const { geometry, selection, ui } = this.props.store;
        if (!ui.foldAngleToolDragState) {
            return false;
        }
        this._updateHoverColors(intersections);
        // Clip angle to +/-180.
        let angleInDeg = utils.convertToDegrees(this.angle as number);
        if (angleInDeg > 180) angleInDeg = 180;
        else if (angleInDeg < -180) angleInDeg = -180;
        // Set fold angles in store.
        geometry.setFoldAngles(angleInDeg, selection.selectedEdges);
        ui.foldAngleToolDragState = false;
        this.tooltipText = undefined;
        Analytics.event(EventName.EdgeAngleDrag, { edgeCount: selection.selectedEdges.length });
        return true;
    }

    private _updateHoverColors(intersections: THREE.Intersection[]) {
        const intersectedHandle = this._getIntersectedHandle(intersections);
        const isHoveringLeft = intersectedHandle === this.leftFaceSelectionTool;
        const isHoveringRight = intersectedHandle === this.rightFaceSelectionTool;
        this.leftFaceSelectionTool.traverse(obj => {
            if (obj instanceof THREE.Mesh && obj.material !== this.hitTargetMaterial) {
                obj.material = isHoveringLeft ? this.faceHoverMaterial : this.faceSelectionMaterial;
            }
        });
        this.rightFaceSelectionTool.traverse(obj => {
            if (obj instanceof THREE.Mesh && obj.material !== this.hitTargetMaterial) {
                obj.material = isHoveringRight ? this.faceHoverMaterial : this.faceSelectionMaterial;
            }
        });
        return isHoveringLeft || isHoveringRight;
    }

    @boundMethod
    protected updateScale() {
        const { render } = this.props.store;
        const scale = render.unitsPerPixel;
        this.wrapper.scale.set(scale, scale, scale);
    }

    @boundMethod
    protected updatePositions() {
        // Callback from solver.
        this._renderWidget();
    }

    private _getTooltipPosition(): THREE.Vector2 {
        // Get the 3D position at the center of the fold angle widget.
        let centerPoint = this.wrapper.position.clone();

        // Offset along a left or right radial vector.
        const sign = this.activeFaceSelectionTool === this.rightFaceSelectionTool ? 1 : -1;
        const cos = this.angle ? Math.cos(this.angle) : 1;
        const sin = this.angle ? Math.sin(this.angle) : 0;
        const offset = new THREE.Vector3(sign * RING_RADIUS * cos, -RING_RADIUS * sin, 0);
        let handlePoint = centerPoint.clone().add(offset);

        // Apply the fold angle widget's transform.
        handlePoint.applyMatrix4(this.wrapper.matrixWorld);

        // Project to 2D pixel coordinates.
        const { render } = this.props.store;
        const handlePoint2D = render.projectPointToContainer(handlePoint);

        // Offset down and to the right.
        handlePoint2D.x += 30;
        handlePoint2D.y += 30;
        return handlePoint2D;
    }

    @boundMethod
    private _setFoldAngle(angle: number | undefined) {
        this.angle = angle === undefined ? null : utils.convertToRadians(angle);
        const { selectedEdges } = this.props.store.selection;
        this.props.store.geometry.setFoldAngles(angle === undefined ? null : angle, selectedEdges);
        Analytics.event(EventName.EdgeAngleType, { edgeCount: selectedEdges.length });
    }

    @boundMethod
    private _hidePopover() {
        this.showPopover = false;
    }

    private _getDihedralAngle() {
        const centerEdge = this.props.store.lastSelectedEdge;
        if (centerEdge === null) {
            return 0;
        }
        const { geometry, render } = this.props.store;
        const dihedrals = render.faceOffsetter.dihedrals(render.solver.dihedrals);
        // Get average dihedral of all segments in edge.
        const segmentIndices = geometry.triangleMesh.edgesForwardMapping[centerEdge.index];
        return segmentIndices.map(index => dihedrals[index] as number)
            .reduce((sum, val) => sum + val, 0) / segmentIndices.length;
    }

    private _renderWidget() {
        const centerEdge = this.props.store.lastSelectedEdge;
        if (!centerEdge || this.props.store.ui.showFlat) {
            return;
        }
        // Update widget position/orientation.
        const { geometry, render } = this.props.store;
        const normals = render.faceOffsetter.normals(render.solver.normals);
        // Use un-offset positions so position of widget isn't moving as folding occurs.
        const positions = render.faceOffsetter.positions(render.solver.positions);

        const { vertex1, vertex2, leftFace, rightFace } = centerEdge;
        // Calculate vector for edge.
        const { triangleMesh } = geometry;
        const v1 = new THREE.Vector3().fromArray(positions[triangleMesh.verticesForwardMapping[vertex1][0]]);
        const v2 = new THREE.Vector3().fromArray(positions[triangleMesh.verticesForwardMapping[vertex2][0]]);
        const normal = v2.clone().sub(v1).normalize();
        const position = v2.clone().add(v1).divideScalar(2);
        // Update plane and widget position.
        this._plane.setFromNormalAndCoplanarPoint(normal, position);
        // this.wrapper.quaternion.setFromUnitVectors(new THREE.Vector3(0, 0, 1), normal);
        this.wrapper.position.copy(position);

        // Pin axes to fixed face.
        // Get free face's projection on plane and rotate widget so that this face is on the widget's x axis.
        const fixedFace = this.activeFaceSelectionTool === this.rightFaceSelectionTool ? leftFace : rightFace;
        const faceNormal = new THREE.Vector3().fromArray(normals[triangleMesh.facesForwardMapping[fixedFace][0]]);
        const planeZAxis = new THREE.Vector3(0, 0, 1);
        
        const quaternion1 = new THREE.Quaternion().setFromUnitVectors(planeZAxis, normal);
        this.wrapper.quaternion.copy(quaternion1);
        // Rotate on z axis.
        const fixedAxis = (normal.clone().cross(faceNormal)).applyQuaternion(quaternion1.conjugate());
        const zAngle = Math.atan2(fixedAxis.y, fixedAxis.x);
        this.wrapper.rotateZ(zAngle);

        let angle = this.angle === null ? this._getDihedralAngle() : this.angle;

        // Update selection tool positions and orientations.
        if (this.activeFaceSelectionTool === this.rightFaceSelectionTool) {
            this.rightFaceSelectionTool.position.x = RING_RADIUS * Math.cos(angle);
            this.rightFaceSelectionTool.position.y = -RING_RADIUS * Math.sin(angle);
            this.rightFaceSelectionTool.rotation.z = -angle;
            this.rightLine.rotation.z = -(Math.PI / 2 + angle);
            this.leftFaceSelectionTool.position.x = -RING_RADIUS;
            this.leftFaceSelectionTool.position.y = 0;
            this.leftFaceSelectionTool.rotation.z = 0;
            this.leftLine.rotation.z = Math.PI / 2;
            this.rightAngleIndicator.rotation.z = angle < 0 ? Math.PI / 2 : Math.PI;
        } else {
            this.leftFaceSelectionTool.position.x = RING_RADIUS * Math.cos(angle + Math.PI);
            this.leftFaceSelectionTool.position.y = RING_RADIUS * Math.sin(angle + Math.PI);
            this.leftFaceSelectionTool.rotation.z = angle;
            this.leftLine.rotation.z = Math.PI / 2 + angle;
            this.rightFaceSelectionTool.position.x = RING_RADIUS;
            this.rightFaceSelectionTool.position.y = 0;
            this.rightFaceSelectionTool.rotation.z = 0;
            this.rightLine.rotation.z = -Math.PI / 2;
            this.rightAngleIndicator.rotation.z = angle < 0 ? 0 : 3 * Math.PI / 2;
        }
        this.leftFaceSelectionHighlighter.position.copy(this.leftFaceSelectionTool.position);
        this.rightFaceSelectionHighlighter.position.copy(this.rightFaceSelectionTool.position);

        // Set selection tool visibility in cases where we are near +/-PI.
        const tol = 0.05;
        if (Math.abs(angle) > Math.PI - tol) {
            this.leftFaceSelectionTool.children[1].visible = angle < 0;
            this.rightFaceSelectionTool.children[1].visible = angle < 0;
            this.leftFaceSelectionTool.children[0].visible = angle > 0;
            this.rightFaceSelectionTool.children[0].visible = angle > 0;
        } else {
            this.leftFaceSelectionTool.children.forEach(child => {
                child.visible = true;
            });
            this.rightFaceSelectionTool.children.forEach(child => {
                child.visible = true;
            });
        }

        // Draw pie wedge for selected handle while dragging.
        if (this.props.store.ui.foldAngleToolDragState) {
            if (this.activeFaceSelectionTool === this.rightFaceSelectionTool) {
                if (this.rightWedge.geometry) this.rightWedge.geometry.dispose();
                this.rightWedge.geometry = new THREE.CircleBufferGeometry(RING_RADIUS, 16,
                    Math.min(0, -angle), Math.abs(angle));
                this.rightWedge.visible = true;
                this.leftWedge.visible = false;
            } else {
                this.leftWedge.geometry.dispose();
                this.leftWedge.geometry = new THREE.CircleBufferGeometry(RING_RADIUS, 16,
                    Math.PI + Math.min(0, angle), Math.abs(angle));
                this.leftWedge.visible = true;
                this.rightWedge.visible = false;
            }
        } else {
            this.leftWedge.visible = this.rightWedge.visible = false;
        }

        // Show the right angle indicator when the angle is +/-90 degrees (within 0.05 degrees).
        const rightAngleTolerance = utils.convertToRadians(0.05);
        this.rightAngleIndicator.visible = Math.abs(Math.abs(angle) - Math.PI / 2) <
            rightAngleTolerance;
    }

    @boundMethod
    private _selectAll() {
        this.props.store.selectAllEdges();
        Analytics.event(EventName.EdgeSelectAll);
    }

    @boundMethod
    private _deselectAll() {
        this.props.store.selection.deselectAll();
        Analytics.event(EventName.EdgeSelectNone);
    }

    render() {
        this._renderWidget();
        // Update camera.
        const centerEdge = this.props.store.lastSelectedEdge;
        if (centerEdge && this.props.store.preferences.enableAutoCentering) {
            // Center camera on widget
            this.props.store.render.controls3D.animateToTarget(this.wrapper.position.clone());
        }

        const selectAllShortcut = (
            <KeyboardShortcut keys="cmdOrCtrl+a" onPressed={this._selectAll} />
        );

        if (centerEdge) {
            const angleInDegrees = this.angle === null ? undefined : utils.convertToDegrees(this.angle);
            const position = this._getTooltipPosition();
            return (
                <>
                    {this._renderTooltip(position)}
                    {this._renderPopover(position, angleInDegrees)}
                    {selectAllShortcut}
                    <KeyboardShortcut keys="esc" onPressed={this._deselectAll} />
                </>
            );
        }
        return selectAllShortcut;
    }

    private _renderTooltip(position: THREE.Vector2) {
        const container = document.getElementById('View3DContainer');
        if (container && this.tooltipText) {
            const tooltipStyle = {
                left: position.x,
                top: position.y,
            };
            const tooltip = (
                <Tooltip className="FoldAngleTool__tooltip" open={true} style={tooltipStyle}>
                    {this.tooltipText}
                </Tooltip>
            );
            return ReactDOM.createPortal(tooltip, container);
        }
        return null;
    }

    private _renderPopover(position: THREE.Vector2, angleInDegrees?: number) {
        return (
            <Overlay
                show={this.showPopover}
                contentClassName='FoldAnglePopover'
                onClose={this._hidePopover}>
                <FoldAnglePopover
                    left={this.showPopover ? position.x : -10000}
                    top={this.showPopover ? position.y : -10000}
                    foldAngle={angleInDegrees}
                    onChange={this._setFoldAngle}
                    onDismiss={this._hidePopover}
                />
            </Overlay>
        );
    }
}
