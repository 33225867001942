import React, { Component } from 'react';

declare global {
    interface Window {
        fedsConfig?: {
            privacy?: {
                otDomainId?: string;
                footerLinkSelector?: string;
            };
        };
    }
}

interface CookieConsentProps {
    onConsent: () => void;
}

/**
 * Displays the cookie consent notice and calls the onConsent callback when consent is granted.
 */
export class CookieConsent extends Component<CookieConsentProps> {
    componentDidMount() {
        // Listen for the privacy consent event.
        window.addEventListener('adobePrivacy:PrivacyConsent', this.props.onConsent);

        // Load Adobe's privacy consent management code.
        this.loadConsentManagement();
    }

    componentWillUnmount() {
        // Stop listening for the privacy consent event.
        window.removeEventListener('adobePrivacy:PrivacyConsent', this.props.onConsent);
    }

    render() {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a className="privacy-consent-link" tabIndex={0}>Cookie preferences</a>;
    }

    /**
     * Loads the cookie consent script. Note that the document should contain an element with
     * class "privacy-consent-link" before this function is called.
     */
    private loadConsentManagement() {
        // See Adobe's consent management integration documentation here:
        // https://wiki.corp.adobe.com/display/privacyatadobe/2020+CMP+Engineering+Implementation

        // Use Fantastic Fold's domain ID.
        window.fedsConfig = window.fedsConfig || {};
        window.fedsConfig.privacy = window.fedsConfig.privacy || {};
        window.fedsConfig.privacy.otDomainId = 'b1008b35-939f-4aa4-b295-ee42630f56b7';
        window.fedsConfig.privacy.footerLinkSelector = '.privacy-consent-link';

        // Use www.dev02.adobe.com for testing; use www.adobe.com for production.
        // const endpoint = 'https://www.dev02.adobe.com/etc/beagle/public/globalnav';
        const endpoint = 'https://www.adobe.com/etc/beagle/public/globalnav';

        // Load the script.
        const scriptElement = document.createElement('script');
        scriptElement.src = `${endpoint}/adobe-privacy/latest/privacy.min.js`;
        document.body.appendChild(scriptElement);
    }
}
