import Dialog from '@react/react-spectrum/Dialog';
import Heading from '@react/react-spectrum/Heading';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { error, success } from '@react/react-spectrum/Toast';
import Wait from '@react/react-spectrum/Wait';
import { vec, Bounds2 } from 'crease';
import React from 'react';
import * as THREE from 'three';
import { CENTIMETERS_PER_POINT, METERS_PER_POINT } from '../common/constants';
import { GLTFExporter, GLTFExporterOptions } from '../common/GLTFExporter';
import { ObjExporter } from '../common/ObjExporter';
import { utils } from '../common/utils';
import { Analytics, EventName } from '../services/Analytics';
import { ThickenedMesh } from '../view3D/ThickenedMesh';
import { PlasticWindows } from '../view3D/PlasticWindows';

export async function export3DModel(
    dielineFilename: string | null,
    extension: 'glb' | 'gltf' | 'obj',
    thickenedMesh: ThickenedMesh,
    plasticWindows: PlasticWindows,
    creaseBounds: Bounds2,
    substrateThickness: number,
) {
    Analytics.event(EventName.ModelExportStart);
    const startTime = performance.now();

    let dialogId = ModalContainer.show(
        <Dialog disableEscKey>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Wait size="S" />
                <Heading variant="subtitle2" style={{ marginLeft: 8 }}>
                    Preparing 3D model...
                </Heading>
            </div>
        </Dialog>
    );
    try {
        // Create a list of meshes to export.
        const meshes = [
            thickenedMesh.side1.getObject3D(),
            thickenedMesh.side2.getObject3D(),
            thickenedMesh.roundMesh1,
            thickenedMesh.roundMesh2,
            thickenedMesh.rawEdgeMesh,
        ];
        // Check if there are plastic windows to export.
        const windows = plasticWindows.getMeshForExport();
        if (windows) {
            meshes.push(windows);
        }

        // Calculate the scale of tbe original dieline, converting from points to meters.
        const downloadAsOBJ = extension === 'obj';
        const basename = utils.getFilenameNoExtension(dielineFilename || 'model');
        const filename = `${basename}.${downloadAsOBJ ? 'zip' : extension}`;
        const size = vec.subtract(creaseBounds.max, creaseBounds.min);
        const units = downloadAsOBJ ? CENTIMETERS_PER_POINT : METERS_PER_POINT;
        const scale = Math.max(size[0], size[1]) * units;

        // Create a transform matrix:
        // - translate up by half the substrate thickness (so the mesh sits on the ground)
        // - scale the mesh from our [-0.5, 0.5] coordinates to centimeters
        // - rotate around X by -90 degrees to go from Z-up coordinates to Y-up coordinates
        const objectTransform = new THREE.Matrix4();
        objectTransform.scale(new THREE.Vector3(scale, scale, scale));
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationX(-Math.PI / 2);
        objectTransform.multiply(rotationMatrix);
        const translationMatrix = new THREE.Matrix4();
        translationMatrix.makeTranslation(0, 0, substrateThickness / 2);
        objectTransform.multiply(translationMatrix);

        let fileSize: number;
        if (downloadAsOBJ) {
            // Create a transform matrix that flips the V texture coordinate, since OBJ and
            // Three.js use different conventions.
            const textureTransform = new THREE.Matrix3();
            textureTransform.set(1, 0, 0, 0, -1, 1, 0, 0, 1);

            // Convert meshes to OBJ format.
            const exporter = new ObjExporter();
            const blob = await exporter.exportToZip(basename, meshes, objectTransform,
                textureTransform);

            // Download the ZIP file.
            saveAs(blob, filename);
            fileSize = blob.size;
        } else {
            // Convert meshes to GLTF or GLB format.
            const exporter = new GLTFExporter();
            const binary = extension === 'glb';
            const options: GLTFExporterOptions = { binary, objectTransform };
            const blob = await exporter.export(meshes, options);
        
            // Download the GLTF or GLB file file.
            saveAs(blob, filename);
            fileSize = blob.size;
        }
        success(`Downloaded "${filename}".`);
        const elapsedTime = (performance.now() - startTime) / 1000;
        Analytics.event(EventName.ModelExportComplete, { fileSize,  elapsedTime });
    } catch (err) {
        error('Download failed.');
        console.error(err);
        Analytics.event(EventName.ModelExportError, { error: err.toString(), stack: err.stack });
    }
    ModalContainer.hide(dialogId);
}
