import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as THREE from 'three';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { Analytics, EventName } from '../services/Analytics';
import { FaceSelectionTool } from './FaceSelectionTool';
import { Modifiers } from './Tool';

@inject('store')
@observer
export class GroundFaceSelectionTool extends FaceSelectionTool {
    toolOnActivate() {
        super.toolOnActivate();
        Analytics.event(EventName.BottomFaceSelectStart);
    }

    onLeftClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers) {
        this.hoverFaces([]);
        this.props.store.selection.deselectAll();
        const faceIndex = this.getIntersectionUserData(intersections);
        if (faceIndex === null) {
            this.cancel();
            return false;
        }

        // On first click, change active tool back to previous tool.
        this.props.store.ui.resumePreviousTool();
        this.props.store.geometry.bottomFaceIndex = faceIndex;

        // We need to call orientModelOnGroundPlane() here so that we always see the model reorient,
        // even if the bottom face selected is the same as what was previously selected.
        const { render } = this.props.store;
        render.orientModelOnGroundPlane(faceIndex, this.props.store.geometry.triangleMesh);
        // Reset camera orientation.
        render.resetCamera();
        render.updateUnitsPerPixel();

        Analytics.event(EventName.BottomFaceSelectComplete);
        return true;
    }

    @boundMethod
    private cancel() {
        this.props.store.ui.resumePreviousTool();
        Analytics.event(EventName.BottomFaceSelectCancel);
    }

    render() {
        return <KeyboardShortcut keys="esc" onPressed={this.cancel} />;
    }
}
