import { Bounds2, Crease, CreaseEdge, CreaseJSON } from 'crease';
import { action, computed, IObservableArray, observable } from 'mobx';
import { OffsetTriangleMesh } from '../view3D/FaceOffsetter';

export type FoldAngle = number | null;

export interface FoldingStep {
    foldAngles: FoldAngle[];
    dihedralAngles: number[];
    snapshotUrl: string;
}

export class GeometryStore {
    private _crease: Crease = new Crease([], [], []);

    @observable
    private _creaseJSON: CreaseJSON = {
        vertices: [],
        edges: [],
        faces: [],
    };

    @observable
    private _creaseBounds: Bounds2 = {
        min: [0, 0],
        max: [1, 1],
    };

    @observable
    private _triangleMesh: OffsetTriangleMesh = {
        vertices: [],
        edges: [],
        faces: [],
        verticesForwardMapping: [],
        edgesForwardMapping: [],
        facesForwardMapping: [],
        duplicatedEdgesForwardMapping: [],
    };

    @observable
    foldingSteps: IObservableArray<FoldingStep> = observable([]);

    @observable
    private _panelOrder: number[] = [];

    @observable
    private _overlappingGroups: number[][] = [];

    @observable
    private _visibleWindows: number[] = [];

    @computed
    get creaseJSON(): Readonly<CreaseJSON> {
        return this._creaseJSON;
    }

    @computed
    get creaseBounds(): Readonly<Bounds2> {
        return this._creaseBounds;
    }

    @computed
    get triangleMesh(): Readonly<OffsetTriangleMesh> {
        return this._triangleMesh;
    }

    @computed
    get panelOrder(): number[] {
        return this._panelOrder;
    }

    set panelOrder(panelOrder: number[]) {
        this._panelOrder = panelOrder;
        this._overlappingGroups = sortOverlappingGroups(this._overlappingGroups, panelOrder);
    }

    @computed
    get overlappingGroups(): number[][] {
        return this._overlappingGroups;
    }

    set overlappingGroups(overlappingGroups: number[][]) {
        this._overlappingGroups = sortOverlappingGroups(overlappingGroups, this._panelOrder);
    }

    @computed get visibleWindows() {
        return this._visibleWindows;
    }

    set visibleWindows(visibleWindows: number[]) {
        this._visibleWindows = visibleWindows;
    }

    @computed
    get selectableEdgeIndices(): number[] {
        return this.creaseJSON.edges.map((_, index) => index)
            .filter(index => {
                const edge = this.creaseJSON.edges[index];
                return edge.assignment !== 'B' && edge.assignment !== 'C';
            });
    }

    @computed
    get selectableFaceIndices(): number[] {
        return this.creaseJSON.faces.map((_, index) => index);
    }

    @action
    loadCrease(crease: Crease, offsetTriangleMesh: OffsetTriangleMesh) {
        this._crease = crease;
        this._creaseJSON = crease.toJSON();
        this._creaseBounds = crease.getBounds();
        this._triangleMesh = offsetTriangleMesh;
        this.foldingSteps.clear();

        // Order faces by area for initial panel order.
        let faceAreas = crease.faces.map(face => {
            return {
                index: face.index,
                area: face.area,
            };
        });
        faceAreas = faceAreas.sort((a, b) => (a.area > b.area) ? -1 : 1);
        this._panelOrder = faceAreas.map(({ index }) => index);
        this._overlappingGroups = [];

        // Set largest face as bottom by default.
        this.bottomFaceIndex = faceAreas[0].index;
    }

    @action
    setFoldAngles(angle: FoldAngle, edgeIndices: number[]) {
        edgeIndices.forEach((edgeIndex: number) => {
            const edge = this._crease.edges[edgeIndex];
            setEdgeFoldAngle(edge, angle);
        });
        this._creaseJSON = this._crease.toJSON();
    }

    @action
    setAllFoldAngles(angles: FoldAngle[]) {
        this._crease.edges.forEach((edge, i) => {
            setEdgeFoldAngle(edge, angles[i]);
        });
        this._creaseJSON = this._crease.toJSON();
    }

    @observable
    bottomFaceIndex: number = 0;
}

// Return a list of overlapping groups, sorted by panelOrder.
function sortOverlappingGroups(overlappingGroups: number[][], panelOrder: number[]) {
    const sortedGroups: number[][] = [];
    overlappingGroups.forEach(group => {
        const sortedGroup: number[] = [];
        panelOrder.forEach(faceIndex => {
            if (group.indexOf(faceIndex) >= 0) {
                sortedGroup.push(faceIndex);
            }
        });
        sortedGroups.push(sortedGroup);
    })
    return sortedGroups;
}

function setEdgeFoldAngle(edge: CreaseEdge, angle: FoldAngle) {
    if (edge.isCrease) {
        if (angle === null) {
            edge.foldAngle = null;
        } else {
            edge.foldAngle = Math.abs(angle);
            edge.assignment = angle > 0 ? 'M' : 'V';
        }
    }
}