import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { loadTexture } from '../common/TextureLoader';
import { ModalDialog } from '../components/ModalDialog';
import '../css/ImportArtworkModal.css';
import { Analytics, EventName } from '../services/Analytics';
import { Side } from '../store/InfoStore';

export class ImportArtworkModal extends ModalDialog<THREE.Texture> {
    private startTime: number = performance.now();

    static async show(artworkURL: string, dieline: Element | null, side: Side):
        Promise<THREE.Texture | void> {
        const dialog = new ImportArtworkModal(artworkURL, dieline, side);
        return dialog.show();
    }

    private constructor(
        private artworkURL: string,
        private dieline: Element | null,
        private side: Side,
    ) {
        super(`Import ${side === Side.Exterior ? 'Exterior' : 'Interior'} Artwork`);
        this.className = 'ImportArtworkModal';
        const sideName = side === Side.Exterior ? 'exterior' : 'interior';
        Analytics.event(EventName.ArtworkImportStart, { side: sideName });
    }

    private get elapsedTime(): number {
        return (performance.now() - this.startTime) / 1000;
    }

    protected async getConfirmResult(): Promise<THREE.Texture | void> {
        const side = this.side === Side.Exterior ? 'exterior' : 'interior';
        try {
            const texture = await loadTexture(this.artworkURL);
            const { width, height } = texture.image;
            const elapsedTime = this.elapsedTime;
            Analytics.event(EventName.ArtworkImportComplete, { side, width, height, elapsedTime });
            return texture;
        } catch (e) {
            Analytics.event(EventName.ArtworkImportError, { side, error: e.message });
        }
    }

    protected async getCancelResult(): Promise<void> {
        const side = this.side === Side.Exterior ? 'exterior' : 'interior';
        const elapsedTime = this.elapsedTime;
        Analytics.event(EventName.ArtworkImportCancel, { side, elapsedTime });
    }

    protected renderContent(): ReactNode {
        const sideName = this.side === Side.Exterior ? 'Exterior' : 'Interior';
        const dielineHTML = this.dieline ? this.dieline.outerHTML : '';
        const style = {
            backgroundImage: `url(${this.artworkURL})`
        };
        const className = classNames('ImportArtworkModal__svgContainer',
            `ImportArtworkModal__svgContainer--${sideName}`);
        return (
            <div className="ImportArtworkModal__column">
                <div className={className}
                    dangerouslySetInnerHTML={{__html: dielineHTML}}
                    style={style}/>
                <div className="ImportArtworkModal__instructions">
                    <p>
                        Make sure the artwork aligns with the cuts and creases of the dieline.
                        Note that the artwork is assumed to cover the same artboard area as the
                        dieline SVG file.
                    </p>
                </div>
            </div>
        );
    }
}
