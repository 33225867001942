import CloseCircle from '@react/react-spectrum/Icon/CloseCircle';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { AppMode } from '../common/AppMode';
import { ToolId } from '../common/ToolId';
import { TooltipButton } from '../components/TooltipButton';
import '../css/AppContent.css';
import { Analytics, EventName } from '../services/Analytics';
import { ToolsWrapper } from '../view3D/ToolsWrapper';
import { View3D } from '../view3D/View3D';
import { AnimationPanel } from './AnimationPanel';
import { AppHeader } from './AppHeader';
import { CreasePropertiesPanel } from './CreasePropertiesPanel';
import { Footer } from './Footer';
import { Home } from './Home';
import { Minimap } from './Minimap';
import { PackagePropertiesPanel } from './PackagePropertiesPanel';
import { StoreComponent } from './StoreComponent';
import { ToolPanel } from './ToolPanel';

@inject('store')
@observer
export class AppContent extends StoreComponent {
    componentDidMount() {
        Analytics.event(EventName.ShowAppContent);
    }

    @boundMethod
    private closePreview() {
        this.props.store.ui.appMode = AppMode.Edit;
    }

    render() {
        const { appMode } = this.props.store.ui;
        const isHome = appMode === AppMode.Home;
        return (
            <>
                <View3D />
                <div className={classNames('AppContent', { 'AppContent--home': isHome })}>
                    <AppHeader />
                    {isHome &&
                        <Home />
                    }
                    {!isHome &&
                        <>
                            <ToolsWrapper />
                            {appMode !== AppMode.Preview &&
                                <ToolPanel />
                            }
                            <div className="AppContent__rightPanel">
                                {appMode === AppMode.Preview &&
                                    <TooltipButton
                                        className="AppContent__closePreview"
                                        icon={<CloseCircle size="M" />}
                                        variant="action"
                                        quiet
                                        tooltip="Close preview"
                                        onClick={this.closePreview}
                                    />
                                }
                                {appMode !== AppMode.Preview &&
                                    <PackagePropertiesPanel />
                                }
                                {appMode !== AppMode.Preview &&
                                    this.props.store.ui.activeToolId === ToolId.FoldAngleTool &&
                                    this.props.store.selection.selectedEdges.length > 0 &&
                                    <CreasePropertiesPanel />
                                }
                            </div>
                            {appMode !== AppMode.Preview &&
                                <Minimap />
                            }
                            <AnimationPanel />
                        </>
                    }
                    <Footer overlay={!isHome} />
                </div>
            </>
        );
    }
}
