import { inject, observer } from 'mobx-react';
import * as THREE from 'three';
import { EdgeTubes } from '../view3D/EdgeTubes';
import { PartialMeshSurface } from '../view3D/PartialMeshSurface';
import { Tool } from './Tool';

const EDGE_SELECT_THICKNESS = 4; // pixels

const faceSelectMaterial = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.4,
    side: THREE.DoubleSide,
    fog: false,
    polygonOffset: true,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -1,
    depthWrite: false,
});

@inject('store')
@observer
export class SelectionHighlighterTool extends Tool {
    private edgeSelectObject = new EdgeTubes({ depthWrite: false, thickness: EDGE_SELECT_THICKNESS});
    private faceSelectObject = new PartialMeshSurface(faceSelectMaterial);

    toolOnActivate() {
        const { render, selection } = this.props.store;
        render.addObject3Ds([
            this.edgeSelectObject.getObject3D(),
            this.faceSelectObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.bind(this.faceSelectObject, 'color', '--color-face-selection');
        render.colorBinder.bind(this.edgeSelectObject, 'color', '--color-edge-selection');

        // Update geometry when the triangle mesh changes.
        this.addAutorun(() => {
            this.edgeSelectObject.updateGeometry();
            this.faceSelectObject.updateGeometry();
        });

        // Update highlighted edges when edge selection changes.
        this.addAutorun(() => {
            this.selectEdges(selection.selectedEdges);
        });

        // Update highlighted faces when face selection changes.
        this.addAutorun(() => {
            this.selectFaces(selection.selectedFaces);
        });
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.edgeSelectObject.getObject3D(),
            this.faceSelectObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.unbind(this.faceSelectObject, 'color');
        render.colorBinder.unbind(this.edgeSelectObject, 'color');

        this.edgeSelectObject.dispose();
        this.faceSelectObject.dispose();
    }

    private selectEdges(edgeIndices: number[]) {
        this.edgeSelectObject.showEdges(edgeIndices);
    }

    private selectFaces(faceIndices: number[]) {
        this.faceSelectObject.showFaces(faceIndices);
    }
}
