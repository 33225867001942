import { Analytics, EventName } from '../services/Analytics';

export class ExternalLinks {
    static launchFeedback() {
        Analytics.event(EventName.LinkLaunchFeedback);
        if (process.env.REACT_APP_USE_EMAIL_FOR_FEEDBACK === 'true') {
            const to = 'Eric Stollnitz <stollnit@adobe.com>';
            const subject = 'feedback on Fantastic Fold';
            const url = `mailto:${to}?subject=${subject}`;
            window.location.href = url;
        } else {
            window.open('https://forums.adobeprerelease.com/fantastic-fold/', '_blank');
        }
    }

    static launchHelp() {
        Analytics.event(EventName.LinkLaunchHelp);
        window.open(`${process.env.PUBLIC_URL}/#learn`, '_blank')
    }

    static launchPrereleaseSignup() {
        Analytics.event(EventName.AccountSignUp);
        window.location.href = 'https://www.adobeprerelease.com/beta/9E3E2E8B-5080-453F-A49F-66EE93C23670';
    }
}
