import { action, computed, observable } from 'mobx';
import { UserProfile } from '../services/AccountService';

export class AccountStore {
    @observable
    private _isLoading: boolean = true;

    @observable
    private _userProfile?: UserProfile = undefined;

    @observable
    private _hasCookieConsent: boolean = false;

    /** Whether or not account status is still loading. */
    @computed
    get isLoading(): boolean {
        return this._isLoading;
    }

    /** The current user profile, or undefined if the user is not signed in. */
    @computed
    get userProfile(): UserProfile | undefined {
        return this._userProfile;
    }

    /** Whether or not the user is signed in. */
    @computed
    get isSignedIn(): boolean {
        return !this._isLoading && !!this._userProfile;
    }

    /** Whether or not the user has consented to the use of cookies. */
    @computed
    get hasCookieConsent(): boolean {
        return this._hasCookieConsent;
    }

    /** Provides the profile of the currently signed-in user. */
    @action
    setAccountSignedIn(userProfile: UserProfile) {
        this._userProfile = userProfile;
        this._isLoading = false;
    }

    /** Indicates that the user is not signed in. */
    @action
    setAccountSignedOut() {
        this._userProfile = undefined;
        this._isLoading = false;
    }

    /** Whether or not the user has requested to sign out. */
    @observable
    shouldSignOut: boolean = false;

    /** Indicates that the user has consented to the use of cookies. */
    @action.bound
    setCookieConsent() {
        this._hasCookieConsent = true;
    }
}
