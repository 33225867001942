import { Tool } from './Tool';
import { inject, observer } from 'mobx-react';
import { MeshBasicMaterial, DoubleSide, Intersection } from 'three';
import { EDGE_HOVER_THICKNESS } from '../common/constants';
import { EdgeTubes } from '../view3D/EdgeTubes';
import { PlasticWindows } from '../view3D/PlasticWindows';

const hoverMaterial = new MeshBasicMaterial({
    side: DoubleSide,
    fog: false,
    transparent: true,
    opacity: 0.75,
});

@inject('store')
@observer
export class WindowTool extends Tool {
    private holeBoundaryEdgeTubes = new EdgeTubes({
        depthWrite: false,
        thickness: EDGE_HOVER_THICKNESS,
    });
    private hoverVisualization = new PlasticWindows(hoverMaterial);

    toolOnActivate() {
        // Initialize edge tubes.
        this.holeBoundaryEdgeTubes.updateGeometry();
        this.holeBoundaryEdgeTubes.showEdges([]);

        // Initialize hover visualization,
        this.hoverVisualization.setTriangleMesh();

        // Add objects to scene.
        const { render } = this.props.store;
        this.raycastingTargets = [render.plasticWindows.getObject3D()];
        render.addObject3Ds([
            this.holeBoundaryEdgeTubes.getObject3D(),
            this.hoverVisualization.getObject3D(),
        ], render.toolScene);
        render.colorBinder.bind(hoverMaterial, 'color', '--color-face-hover');
        render.colorBinder.bind(this.holeBoundaryEdgeTubes, 'color', '--color-edge-hover');

        // Deselect any current selections.
        this.props.store.selection.deselectAll();
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.holeBoundaryEdgeTubes.getObject3D(),
            this.hoverVisualization.getObject3D(),
        ], render.toolScene);
        render.colorBinder.unbind(hoverMaterial, 'color');
        render.colorBinder.unbind(this.holeBoundaryEdgeTubes, 'color');

        this.holeBoundaryEdgeTubes.dispose();
        this.hoverVisualization.dispose();
    }

    onLeftClick(intersections: Intersection[]) {
        if (intersections.length === 0) {
            return false;
        }
        const intersection = intersections[0];
        if (!intersection.face) {
            return false;
        }
        // Toggle material.
        const windowIndex = intersection.face.materialIndex;
        const visibleWindows = this.props.store.geometry.visibleWindows.slice();
        if (visibleWindows.indexOf(windowIndex) >= 0) {
            visibleWindows.splice(visibleWindows.indexOf(windowIndex), 1);
        } else {
            visibleWindows.push(windowIndex);
        }
        this.props.store.render.plasticWindows.showWindows(visibleWindows);
        this.props.store.geometry.visibleWindows = visibleWindows;
        return true;
    }

    onHoverMove(intersections: Intersection[]) {
        // Unshow hover vis by default.
        this.hoverVisualization.showWindows([]);
        this.holeBoundaryEdgeTubes.showEdges([]);
        // Check for intersections.
        if (intersections.length === 0) {
            return true;
        }
        const intersection = intersections[0];
        if (!intersection.face) {
            return true;
        }
        // Show hover visualization.
        const hoveredWindowIndex = intersection.face.materialIndex;
        this.hoverVisualization.showWindows([hoveredWindowIndex]);
        this.holeBoundaryEdgeTubes.showEdges(PlasticWindows.holeBoundaryEdges[hoveredWindowIndex]);
        return true;
    }

    onNullEvent() {
        // Unshow hover vis by default.
        this.hoverVisualization.showWindows([]);
        this.holeBoundaryEdgeTubes.showEdges([]);
    }
}
