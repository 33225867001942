import Button, { ButtonProps } from '@react/react-spectrum/Button';
import OverlayTrigger, { OverlayTriggerProps } from '@react/react-spectrum/OverlayTrigger';
import Tooltip, { TooltipProps } from '@react/react-spectrum/Tooltip';
import classNames from 'classnames';
import React, { Component } from 'react';
import { ValuesType } from 'utility-types';

/** Placement of the tooltip relative to the button. */
export type Placement = ValuesType<Pick<OverlayTriggerProps, 'placement'>>;

/** The kind of tooltip to display. */
export type TooltipVariant = ValuesType<Pick<TooltipProps, 'variant'>>;

/**
 * Properties of the TooltipButton component.
 */
export interface TooltipButtonProps extends ButtonProps {
    /** The placement of the tooltip relative to the button. */
    placement?: Placement;

    /** The kind of tooltip to display. Defaults to "info". */
    tooltipVariant?: TooltipVariant;

    /** The content of the tooltip. */
    tooltip?: React.ReactNode;
}

/**
 * Displays a button with a tooltip that appears on hover.
 * 
 * Example:
 * 
 *      <TooltipButton
 *          label="Export Model"
 *          tooltip="Download and save a model for use in other applications."
 *          placement="bottom" />
 */
export class TooltipButton extends Component<TooltipButtonProps> {
    static defaultProps: TooltipButtonProps = {
        tooltipVariant: 'info',
    };

    render() {
        const {
            placement,
            tooltipVariant,
            tooltip,
            className,
            selected,
            ref,
            ...buttonProps
        } = this.props;

        // OverlayTrigger changes the Button's selected property, so we need to add the
        // is-selected class ourselves.
        const modifiedClassName = classNames(className, { 'is-selected': selected });
        return (
            <OverlayTrigger
                delayShow={500}
                placement={placement}
                trigger={tooltip ? ['hover'] : []}>
                <Button className={modifiedClassName} {...buttonProps} />
                <Tooltip variant={tooltipVariant}>{tooltip}</Tooltip>
            </OverlayTrigger>
        );
    }
}
