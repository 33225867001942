import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import * as THREE from 'three';
import { AppMode } from '../common/AppMode';
import { ToolId } from '../common/ToolId';
import '../css/ToolsWrapper.css';
// import FaceOrderingTool from '../tools/FaceOrderingTool';
import { DollyTool } from '../tools/DollyTool';
import { EdgeLabelTool } from '../tools/EdgeLabelTool';
import { EdgeSelectionTool } from '../tools/EdgeSelectionTool';
import { FaceOrderingTool } from '../tools/FaceOrderingTool';
import { FaceSelectionTool } from '../tools/FaceSelectionTool';
import { FoldAngleTool } from '../tools/FoldAngleTool';
import { GroundFaceSelectionTool } from '../tools/GroundFaceSelectionTool';
import { HeatMapTool } from '../tools/HeatMapTool';
import { WindowTool } from '../tools/WindowTool';
import { MarqueeSelectionTool } from '../tools/MarqueeSelectionTool';
import { OrbitTool } from '../tools/OrbitTool';
import { PanTool } from '../tools/PanTool';
import { SelectionHighlighterTool } from '../tools/SelectionHighlighterTool';
import { Modifiers, Tool } from '../tools/Tool';
import { StoreComponent } from '../UI/StoreComponent';

// Num pixels to move mouse before a drag is registered.
// This prevent false drag starts when mouse moves slightly during a click.
const dragTol = 2;

type ToolEventName = 'onLeftDown' |'onScrollDown' | 'onRightDown' | 'onLeftClick' |
    'onScrollClick' | 'onRightClick' | 'onLeftDragStart' | 'onLeftDragMove' |
    'onLeftDragEnd' | 'onScrollDragStart' | 'onScrollDragMove' | 'onScrollDragEnd' | 
    'onRightDragStart' | 'onRightDragMove' | 'onRightDragEnd' | 'onHoverMove' | 'updateScale' |
    'onNullEvent';

@inject('store')
@observer
export class ToolsWrapper extends StoreComponent {
    // Container element.
    private container: HTMLElement | null = null;
    // Flags to keep track of internal state.
    private leftDown = false;
    private scrollDown = false;
    private rightDown = false;
    private leftDragging = false;
    private scrollDragging = false;
    private rightDragging = false;
    // Mouse state.
    private mousePos = new THREE.Vector2();
    private mouseDownPos = new THREE.Vector2();
    // Raycasting.
    private raycaster = new THREE.Raycaster();
    // Store raycasting priority as array of array of Tool refs.
    // Outer array gives priority, if an element in this.raycastingTargets[0] is hit,
    // then we don't need to check this.raycastingTargets[1].
    private raycastingPriority: React.RefObject<Tool>[][] = [];

    // Keep ref to tools.
    private edgeSelectionTool = React.createRef<EdgeSelectionTool>();
    private marqueeSelectionTool = React.createRef<MarqueeSelectionTool>();
    private faceOrderingTool = React.createRef<FaceOrderingTool>();
    private faceSelectionTool = React.createRef<FaceSelectionTool>();
    private groundFaceSelectionTool = React.createRef<GroundFaceSelectionTool>();
    private foldAngleTool = React.createRef<FoldAngleTool>();
    private heatMapTool = React.createRef<HeatMapTool>();
    private windowTool = React.createRef<WindowTool>();

    componentDidMount() {
        // Get the DOM element associated with this component.
        this.container = ReactDOM.findDOMNode(this) as HTMLElement | null;

        // Update controls.
        this.props.store.render.setContainer(this.container);
    }

    componentWillUnmount() {
        this.container = null;
    }

    private propagateEvent(
        eventName: ToolEventName,
        event: React.PointerEvent | React.TouchEvent,
        mousePos: THREE.Vector2 = this.mousePos
    ) {
        const screenCoords = this.getScreenSpaceCoords(mousePos);
        let stopPropagation = false;
        this.raycastingPriority.forEach(toolRefs => {
            // Filter to those that are instantiated.
            const tools: Tool[] = toolRefs
                .filter(toolRef => toolRef.current !== null)
                .map(toolRef => toolRef.current!);
            // Pass null event to tools if event has already been handled.
            if (stopPropagation) {
                tools.forEach(tool => {
                    tool.onNullEvent();
                });
                return;
            }
            // First get all intersection targets.
            const targets = tools.reduce((targets, tool) => {
                targets.push(...tool.raycastingTargets);
                return targets;
            }, [] as THREE.Object3D[]);
            // Calc intersections to pass through to event.
            const intersections = this.getIntersections(targets, screenCoords);
            // Get modifier keys.
            const modifiers = {
                shiftKey: event.shiftKey,
                ctrlKey: event.ctrlKey,
                altKey: event.altKey,
                metaKey: event.metaKey,
            };
            // Call event handlers.
            tools.forEach(tool => {
                type Handler = (intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
                    modifiers: Modifiers, raycaster: THREE.Raycaster) => boolean;
                const handler = tool[eventName] as Handler;
                stopPropagation = stopPropagation || handler.call(tool, intersections, screenCoords,
                    modifiers, this.raycaster);
            });
            // If we got a hit, force render.
            if (stopPropagation) {
                this.props.store.render.forceRenderFlag = true;
            }
        });
    }

    @boundMethod
    private pointerDown(e: React.PointerEvent) {
        if (this.leftDown || this.scrollDown || this.rightDown) {
            // Let's just handle one button down at a time.
            // If something's already pressed, ignore this event.
            return;
        }
        if (this.container) {
            this.container.setPointerCapture(e.pointerId);
        }
        // Store drag start position.
        this.mouseDownPos = this.getMouseCoordinates(e.clientX, e.clientY);
        this.mousePos = this.mouseDownPos;
        switch (e.button) {
            case 0:
                this.leftDown = true;
                this.propagateEvent('onLeftDown', e);
                break;
            case 1:
                this.scrollDown = true;
                this.propagateEvent('onScrollDown', e);
                break;
            case 2:
                this.rightDown = true;
                this.propagateEvent('onRightDown', e);
                break;
        }
    }

    @boundMethod
    private pointerMove(e: React.PointerEvent) {
        this.mousePos = this.getMouseCoordinates(e.clientX, e.clientY);
        // If we're already dragging, create a drag move event.
        if (this.rightDragging || this.scrollDragging || this.leftDragging) {
            if (this.leftDragging) {
                this.propagateEvent('onLeftDragMove', e);
            } else if (this.scrollDragging) {
                this.propagateEvent('onScrollDragMove', e);
            } else if (this.rightDragging) {
                this.propagateEvent('onRightDragMove', e);
            }
            return;
        }
        // Test if we have a drag start.
        if (this.leftDown || this.scrollDown || this.rightDown) {
            if (this.mousePos.clone().sub(this.mouseDownPos).length() > dragTol) {
                if (this.leftDown) {
                    this.leftDragging = true;
                    this.propagateEvent('onLeftDragStart', e, this.mouseDownPos);
                    this.propagateEvent('onLeftDragMove', e);
                } else if (this.scrollDown) {
                    this.scrollDragging = true;
                    this.propagateEvent('onScrollDragStart', e, this.mouseDownPos);
                    this.propagateEvent('onScrollDragMove', e);
                } else if (this.rightDown) {
                    this.rightDragging = true;
                    this.propagateEvent('onRightDragStart', e, this.mouseDownPos);
                    this.propagateEvent('onRightDragMove', e);
                }
            }
            return;
        }
        // No buttons pressed.
        this.propagateEvent('onHoverMove', e);
    }

    @boundMethod
    private pointerUp(e: React.PointerEvent) {
        // Release capture and let the lostPointerCapture handler do the work.
        if (this.container) {
            this.container.releasePointerCapture(e.pointerId);
        }
    }

    @boundMethod
    private lostPointerCapture(e: React.PointerEvent) {
        if (this.leftDown) {
            this.leftDown = false;
            if (this.leftDragging) {
                this.leftDragging = false;
                this.propagateEvent('onLeftDragEnd', e);
            } else {
                this.propagateEvent('onLeftClick', e);
            }
        } else if (this.scrollDown) {
            this.scrollDown = false;
            if (this.scrollDragging) {
                this.scrollDragging = false;
                this.propagateEvent('onScrollDragEnd', e);
            } else {
                this.propagateEvent('onScrollClick', e);
            }
        } else if (this.rightDown) {
            this.rightDown = false;
            if (this.rightDragging) {
                this.rightDragging = false;
                this.propagateEvent('onRightDragEnd', e);
            } else {
                this.propagateEvent('onRightClick', e);
            }
        }
    }

    // private touchStart(e: TouchEvent) {
    //     switch (e.touches.length) {
    //         case 1:
    //             if (this.leftDown) {
    //                 return;
    //             }
    //             this.leftDown = true;
    //             this.propagateEvent('onLeftDown', e);
    //             // Store drag start position.
    //             const rect = (e.touches[0].target as HTMLDivElement).getBoundingClientRect();
    //             const offsetX = e.targetTouches[0].pageX - rect.left;
    //             const offsetY = e.targetTouches[0].pageY - rect.top;
    //             this.mouseDownPos = this.getMouseCoordinates(offsetX, offsetY);
    //             break;
    //     }
    // }

    // private touchMove(e: TouchEvent) {
    //     switch (e.touches.length) {
    //         case 1:
    //             const rect = (e.touches[0].target as HTMLDivElement).getBoundingClientRect();
    //             const offsetX = e.targetTouches[0].pageX - rect.left;
    //             const offsetY = e.targetTouches[0].pageY - rect.top;
    //             this.mousePos = this.getMouseCoordinates(offsetX, offsetY);
    //             // If we're already dragging, create a drag move event.
    //             if (this.leftDragging){
    //                 this.propagateEvent('onLeftDragMove', e);
    //                 return;
    //             }
    //             // Test if we have a drag start.
    //             if (this.leftDown) {
    //                 if (this.mousePos.clone().sub(this.mouseDownPos).length() > dragTol) {
    //                     this.leftDragging = true;
    //                     this.propagateEvent('onLeftDragStart', e);
    //                     this.propagateEvent('onLeftDragMove', e);
    //                 }
    //                 return;
    //             }
    //             break;
    //     }
    // }

    // private touchEnd(e: TouchEvent) {
    //     switch (e.touches.length) {
    //         case 1:
    //             this.leftDown = false;
    //             if (this.leftDragging) {
    //                 this.propagateEvent('onLeftDragEnd', e);
    //                 this.leftDragging = false;
    //                 break;
    //             }
    //             this.propagateEvent('onLeftClick', e);
    //             break;
    //     }
    // }

    private getScreenSpaceCoords(mousePos: THREE.Vector2) {
        if (!this.container) {
            return new THREE.Vector2();
        }
        // Calculate mouse position in normalized device coordinates.
        // Range (-1 to +1) for both components.
        const x = (2 * mousePos.x / this.container.clientWidth) - 1;
        const y = -(2 * mousePos.y / this.container.clientHeight) + 1;
        return new THREE.Vector2(x, y);
    }

    private getMouseCoordinates(offsetX: number, offsetY: number) {
        return new THREE.Vector2(offsetX, offsetY);
    }

    private getIntersections(targets: THREE.Object3D[], screenCoords: THREE.Vector2 | null) {
        if (screenCoords === null) {
            return [];
        }
        // Update the ray with the camera and mouse position.
        this.raycaster.setFromCamera(screenCoords, this.props.store.render.camera);
        // Ignore if there are no targets.
        if (!targets.length) {
            return [];
        }
        // Calculate objects intersecting the ray.
        const recursive = true;
        return this.raycaster.intersectObjects(targets, recursive);
    }

    render() {
        const { store } = this.props;
        const { appMode, activeToolId, showFlat } = store.ui;
        switch (activeToolId) {
            case ToolId.FoldAngleTool: {
                this.raycastingPriority = [
                    [this.foldAngleTool],
                    [this.marqueeSelectionTool],
                    [this.edgeSelectionTool],
                ];
                break;
            }
            case ToolId.FaceOrderingTool: {
                this.raycastingPriority = [
                    [this.faceSelectionTool],
                ];
                break;
            }
            case ToolId.GroundFaceSelectionTool: {
                this.raycastingPriority = [
                    [this.groundFaceSelectionTool],
                ];
                break;
            }
            case ToolId.WindowTool: {
                this.raycastingPriority = [
                    [this.windowTool],
                ];
                break;
            }
            default: {
                this.raycastingPriority = [];
            }
        }

        return (
            <div className="ToolsWrapper"
                onPointerDown={this.pointerDown}
                onPointerMove={this.pointerMove}
                onPointerUp={this.pointerUp}
                onLostPointerCapture={this.lostPointerCapture}>
                {appMode !== AppMode.Preview &&
                    <HeatMapTool ref={this.heatMapTool} />
                }
                <SelectionHighlighterTool />
                {showFlat &&
                    <EdgeLabelTool />
                }
                {activeToolId === ToolId.FoldAngleTool &&
                    <>
                        <FoldAngleTool ref={this.foldAngleTool} />
                        <EdgeSelectionTool ref={this.edgeSelectionTool} />
                        <MarqueeSelectionTool ref={this.marqueeSelectionTool} />
                    </>
                }
                {activeToolId === ToolId.FaceOrderingTool &&
                    <>
                        <FaceOrderingTool ref={this.faceOrderingTool}/>
                        <FaceSelectionTool ref={this.faceSelectionTool}/>
                        <GroundFaceSelectionTool ref={this.groundFaceSelectionTool}/>
                    </>
                }
                {activeToolId === ToolId.GroundFaceSelectionTool &&
                    <GroundFaceSelectionTool ref={this.groundFaceSelectionTool} />
                }
                {activeToolId === ToolId.WindowTool &&
                    <WindowTool ref={this.windowTool} />
                }
                {activeToolId === ToolId.OrbitTool &&
                    <OrbitTool />
                }
                {activeToolId === ToolId.DollyTool &&
                    <DollyTool />
                }
                {activeToolId === ToolId.PanTool &&
                    <PanTool />
                }
            </div>
        );
    }
}
