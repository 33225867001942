import foodCartonDieline from '../assets/examples/food-carton-dieline.svg';
import foodCartonExterior from '../assets/examples/food-carton-exterior.png';
import foodCartonInterior from '../assets/examples/food-carton-interior.png';
import foodCartonImage from '../assets/examples/food-carton-render.png';
import foodCartonAnimation from '../assets/examples/food-carton.gif';
// @ts-ignore
import foodCartonIllustrator from '../assets/examples/food-carton.ai';

import displayTrayDieline from '../assets/examples/display-tray-dieline.svg';
import displayTrayExterior from '../assets/examples/display-tray-exterior.png';
import displayTrayInterior from '../assets/examples/display-tray-interior.png';
import displayTrayImage from '../assets/examples/display-tray-render.png';
import displayTrayAnimation from '../assets/examples/display-tray.gif';
// @ts-ignore
import displayTrayIllustrator from '../assets/examples/display-tray.ai';

import gussetBoxDieline from '../assets/examples/gusset-box-dieline.svg';
import gussetBoxExterior from '../assets/examples/gusset-box-exterior.png';
import gussetBoxInterior from '../assets/examples/gusset-box-interior.png';
import gussetBoxImage from '../assets/examples/gusset-box-render.png';
import gussetBoxAnimation from '../assets/examples/gusset-box.gif';
// @ts-ignore
import gussetBoxIllustrator from '../assets/examples/gusset-box.ai';

import lockMailerDieline from '../assets/examples/lock-mailer-dieline.svg';
import lockMailerExterior from '../assets/examples/lock-mailer-exterior.png';
import lockMailerInterior from '../assets/examples/lock-mailer-interior.png';
import lockMailerImage from '../assets/examples/lock-mailer-render.png';
import lockMailerAnimation from '../assets/examples/lock-mailer.gif';
// @ts-ignore
import lockMailerIllustrator from '../assets/examples/lock-mailer.ai';

import { Example } from './Example';

export const EXAMPLES: Example[] = [
    {
        name: 'Food Carton',
        dieline: foodCartonDieline,
        image: foodCartonImage,
        animation: foodCartonAnimation,
        substrateName: 'Paperboard 620 µm',
        isSubstrateHorizontal: false,
        exteriorArtwork: foodCartonExterior,
        interiorArtwork: foodCartonInterior,
        illustratorFile: foodCartonIllustrator,
    },
    {
        name: 'Display Tray',
        dieline: displayTrayDieline,
        image: displayTrayImage,
        animation: displayTrayAnimation,
        substrateName: 'Corrugated F',
        isSubstrateHorizontal: false,
        exteriorArtwork: displayTrayExterior,
        interiorArtwork: displayTrayInterior,
        illustratorFile: displayTrayIllustrator,
    },
    {
        name: 'Gusset Box',
        dieline: gussetBoxDieline,
        image: gussetBoxImage,
        animation: gussetBoxAnimation,
        substrateName: 'Paperboard 520 µm',
        isSubstrateHorizontal: false,
        exteriorArtwork: gussetBoxExterior,
        interiorArtwork: gussetBoxInterior,
        illustratorFile: gussetBoxIllustrator,
    },
    {
        name: 'Lock Mailer',
        dieline: lockMailerDieline,
        image: lockMailerImage,
        animation: lockMailerAnimation,
        substrateName: 'Corrugated E',
        isSubstrateHorizontal: false,
        exteriorArtwork: lockMailerExterior,
        interiorArtwork: lockMailerInterior,
        illustratorFile: lockMailerIllustrator,
    },
];
