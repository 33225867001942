import { Tool } from './Tool';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class PanTool extends Tool {
    toolOnActivate() {
        const { render } = this.props.store;
        render.controlsFlat.setLeftDragPan();
        render.controls3D.setLeftDragPan();
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.controlsFlat.disableLeftDrag();
        render.controls3D.disableLeftDrag();
    }
}
