import Heading from '@react/react-spectrum/Heading';
import Alert from '@react/react-spectrum/Icon/Alert';
import CheckmarkCircle from '@react/react-spectrum/Icon/CheckmarkCircle';
import CloseCircle from '@react/react-spectrum/Icon/CloseCircle';
import Link from '@react/react-spectrum/Link';
import Progress from '@react/react-spectrum/Progress';
import { Tab, TabList } from '@react/react-spectrum/TabList';
import Wait from '@react/react-spectrum/Wait';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import { Crease, creaseToTriangleMesh, TriangleMesh, TriangulationError, utils as creaseUtils } from 'crease';
import { action, observable } from 'mobx';
import PromiseFileReader from 'promise-file-reader';
import React from 'react';
import ImportDielineWorker from 'worker-loader!./ImportDielineWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import { TRIANGULATION_MINIMUM_ANGLE } from '../common/constants';
import { SVGImporter } from '../common/SVGImporter';
import { utils } from '../common/utils';
import { MessageDialog } from '../components/MessageDialog';
import { ModalDialog } from '../components/ModalDialog';
import '../css/ImportDielineModal.css';
import { Analytics, EventName } from '../services/Analytics';
import { CreaseVisualizer } from './CreaseVisualizer';
import { ExternalLinks } from './ExternalLinks';
import { ImportDielineStartMessage, ImportDielineWorkerMessage, ProblemFace, ProblemVertex } from './ImportDielineMessages';

enum OverallStatus {
    Processing,
    Processed,
    Failed,
}

enum CreaseStatus {
    Good,
    HasWarnings,
    HasErrors,
    TriangulationFailed,
}

export type ImportFileSource = 'dragAndDrop' | 'select';

export interface ImportDielineResult {
    filename: string;
    crease: Crease;
    dieline?: SVGSVGElement;
    triangleMesh?: TriangleMesh;
}

interface CreaseInfo {
    crease: Crease;
    triangleMesh?: TriangleMesh;
    problemVertices: ProblemVertex[];
    problemFaces: ProblemFace[];
    creaseStatus: CreaseStatus;
    area: number;
    hasCurvedCreases: boolean;
}

export class ImportDielineModal extends ModalDialog<ImportDielineResult> {
    private worker!: ImportDielineWorker;

    @observable
    private status: OverallStatus = OverallStatus.Processing;

    @observable
    private percentProgress: number = 0;

    @observable
    private creaseInfos: CreaseInfo[] = [];

    @observable
    private selectedCreaseIndex: number = -1;

    static async openFile(file: File, fileSource: ImportFileSource):
        Promise<ImportDielineResult | void> {
        Analytics.event(EventName.DielineAnalysisStart, { fileSource });
        const startTime = performance.now();

        // Get filename and extension.
        const filenameWithExtension = file.name;
        const filename = utils.getFilenameNoExtension(filenameWithExtension);
        const extension = utils.getExtension(filenameWithExtension);

        // Check for a supported extension.
        if (extension && !['svg', 'crease'].includes(extension)) {
            MessageDialog.show('Import Dieline', `Unsupported file extension "${extension}".`);
            return Promise.resolve();
        }

        // Read the file asynchronously.
        try {
            const text = await PromiseFileReader.readAsText(file);
            if (extension === 'svg') {
                return ImportDielineModal.importSVG(filename, text, startTime);
            } else {
                return ImportDielineModal.importCrease(filename, text, startTime);
            }
        }
        catch (error) {
            MessageDialog.show('Import Dieline', 'Unable to read file.');
            Analytics.event(EventName.DielineAnalysisError, { error: error.toString() });
        }
        return Promise.resolve();
    }

    private static importSVG(filename: string, text: string, startTime: number):
        Promise<ImportDielineResult | void> {
        const svgImporter = new SVGImporter();
        const dieline = svgImporter.parseSVG(text);
        if (dieline === null) {
            const message = 'Not a valid SVG file.';
            MessageDialog.show('Import Dieline', message);
            Analytics.event(EventName.DielineAnalysisError, { error: message });
        } else if (dieline.children.length < 2) {
            const message = 'Please ensure the SVG file contains layers named “cuts” and “creases.”';
            MessageDialog.show('Import Dieline', message);
            Analytics.event(EventName.DielineAnalysisError, { error: message });
        } else {
            return ImportDielineModal.show(filename, dieline, startTime);
        }
        return Promise.resolve();
    }

    private static importCrease(
        filename: string,
        text: string,
        startTime: number,
    ): ImportDielineResult | void {
        try {
            // Parse json and init Crease object.
            const json = JSON.parse(text);
            const crease = Crease.fromJSON(json);
            // Remove all fold angles for now.
            crease.edges.forEach((edge) => {
                edge.foldAngle = null;
            });

            const elapsedTime = (performance.now() - startTime) / 1000;
            Analytics.event(EventName.DielineImportComplete, {
                components: crease.components.length,
                vertices: crease.vertices.length,
                edges: crease.edges.length,
                faces: crease.faces.length,
                curvedCreases: creaseUtils.countIf(crease.edges, edge =>
                    edge.isCrease && edge.controlPoints.length > 0),
                elapsedTime,
            });
            return { filename, crease };
        } catch (error) {
            const message = `Error while parsing Crease JSON: ${error}`;
            MessageDialog.show('Import Dieline', message);
            Analytics.event(EventName.DielineImportError, { error: message });
        }
    }

    private static async show(
        filename: string,
        dieline: SVGSVGElement,
        startTime: number,
    ): Promise<ImportDielineResult | void> {
        // Get the bounding box of the dieline by briefly injecting it into the document.
        window.document.body.appendChild(dieline);
        let { x, y, width, height } = dieline.getBBox();
        window.document.body.removeChild(dieline);

        // Get the dieline HTML, replacing the viewBox with a slightly expanded version of the
        // bounding box.
        const margin = 0.02 * Math.max(width, height);
        x -= margin;
        y -= margin;
        width += 2 * margin;
        height += 2 * margin;
        const viewBoxAttribute = `viewBox="${x} ${y} ${width} ${height}"`
        const dielineHTML = dieline.outerHTML.replace(/viewBox="[^"]*"/, viewBoxAttribute);
        
        // Create the dialog and show it.
        const dialog = new ImportDielineModal(filename, dieline, dielineHTML, startTime);
        return dialog.show();
    }

    private constructor(
        private filename: string,
        private dieline: SVGSVGElement,
        private dielineHTML: string,
        private startTime: number,
    ) {
        super('Import Dieline');
        this.className = 'ImportDielineModal';
        this.updateButtons();
    }

    protected onOpened() {
        this.worker = new ImportDielineWorker();
        this.worker.addEventListener('message', this.handleWorkerMessage);
        const startMessage: ImportDielineStartMessage = {
            type: 'start',
            svg: this.dielineHTML
        };
        this.worker.postMessage(startMessage);
    }

    protected onClosing() {
        this.worker.terminate();
    }

    @action.bound
    private handleWorkerMessage(e: MessageEvent) {
        const message = e.data as ImportDielineWorkerMessage;
        switch (message.type)
        {
            case 'progress':
                this.percentProgress = message.percentProgress;
                break;
            case 'done':
                const { parseError, creaseJSONs, problemVertices: allProblemVertices } = message;
                const status = parseError ? OverallStatus.Failed : OverallStatus.Processed;
                const creaseInfos: CreaseInfo[] = creaseJSONs.map((creaseJSON, i) => {
                    const crease = Crease.fromJSON(creaseJSON);
                    let area = 0;
                    if (crease.vertices.length > 0) {
                        const bounds = crease.getBounds();
                        area = (bounds.max[0] - bounds.min[0]) * (bounds.max[1] - bounds.min[1]);
                    }
                    const hasCurvedCreases = crease.edges.some(edge =>
                        edge.isCrease && edge.controlPoints.length > 0);
                    const problemVertices = allProblemVertices[i];
                    const hasErrors = problemVertices.find(problemVertex => problemVertex.alertLevel === 'error');
                    let creaseStatus = problemVertices.length > 0 ? 
                        (hasErrors ? CreaseStatus.HasErrors : CreaseStatus.HasWarnings) :
                        CreaseStatus.Good;

                    // Try to triangulate.
                    const problemFaces: ProblemFace[] = [];
                    let triangleMesh: TriangleMesh | undefined = undefined;
                    try {
                        triangleMesh = creaseToTriangleMesh(crease, TRIANGULATION_MINIMUM_ANGLE);
                    } catch (e) {
                        // TODO: Is the specific error message useful?
                        if (e instanceof TriangulationError) {
                            problemFaces.push({
                                faceIndex: e.faceIndex,
                                description: 'Triangulation failed for this panel.'
                            });
                        }
                        creaseStatus = CreaseStatus.TriangulationFailed;
                    }

                    return {
                        crease,
                        triangleMesh,
                        problemVertices,
                        problemFaces,
                        creaseStatus,
                        area,
                        hasCurvedCreases,
                    };
                });

                // Sort by increasing crease status, then by decreasing area.
                creaseInfos.sort((a, b) => (a.creaseStatus - b.creaseStatus) ||
                    (b.area - a.area));

                this.status = status;
                this.creaseInfos = creaseInfos;
                this.selectedCreaseIndex = 0;
                this.updateButtons();
                Analytics.event(EventName.DielineAnalysisComplete,
                    this.getAnalyticsData(creaseInfos));
                break;
            default:
                throw new Error(`Unrecognized message: ${JSON.stringify(message)}.`);
        }
    }

    private getAnalyticsData(creaseInfos: CreaseInfo[]) {
        const components = creaseInfos.length;
        const vertices = creaseUtils.sum(creaseInfos, info => info.crease.vertices.length);
        const edges = creaseUtils.sum(creaseInfos, info => info.crease.edges.length);
        const faces = creaseUtils.sum(creaseInfos, info => info.crease.faces.length);
        const triangleVertices = creaseUtils.sum(creaseInfos, info =>
            info.triangleMesh ? info.triangleMesh.vertices.length : 0);
        const triangleEdges = creaseUtils.sum(creaseInfos, info =>
            info.triangleMesh ? info.triangleMesh.edges.length : 0);
        const triangleFaces = creaseUtils.sum(creaseInfos, info =>
            info.triangleMesh ? info.triangleMesh.faces.length : 0);
        const problemVertices = creaseUtils.sum(creaseInfos, info => info.problemVertices.length);
        const problemFaces = creaseUtils.sum(creaseInfos, info => info.problemFaces.length);
        const curvedCreases = creaseUtils.sum(creaseInfos, info =>
            creaseUtils.countIf(info.crease.edges, edge =>
                edge.isCrease && edge.controlPoints.length > 0));
        const elapsedTime = this.elapsedTime;
        return {
            components,
            vertices,
            edges,
            faces,
            triangleVertices,
            triangleEdges,
            triangleFaces,
            problemVertices,
            problemFaces,
            curvedCreases,
            elapsedTime,
        };
    }

    private get elapsedTime(): number {
        return (performance.now() - this.startTime) / 1000;
    }

    @boundMethod
    private handleTabChange(selectedCreaseIndex: number) {
        this.selectedCreaseIndex = selectedCreaseIndex;
        this.updateButtons();
    }

    protected async getConfirmResult(): Promise<ImportDielineResult | void> {
        if (this.status !== OverallStatus.Failed && this.creaseInfos.length > 0 &&
            this.creaseInfos[this.selectedCreaseIndex].creaseStatus !== CreaseStatus.TriangulationFailed &&
            this.creaseInfos[this.selectedCreaseIndex].creaseStatus !== CreaseStatus.HasErrors) {
            try {
                const creaseInfo = this.creaseInfos[this.selectedCreaseIndex];
                const { crease, triangleMesh } = creaseInfo;
                const { filename, dieline } = this;
                const result = { filename, crease, dieline, triangleMesh };
                Analytics.event(EventName.DielineImportComplete,
                    this.getAnalyticsData([creaseInfo]));
                return result;
            } catch (e) {
                MessageDialog.show('Error', 'Could not import dieline.');
                Analytics.event(EventName.DielineImportError,
                    { error: e.toString(), stack: e.stack });
                }
        } else {
            Analytics.event(EventName.DielineImportCancel,
                this.getAnalyticsData(this.creaseInfos));
        }
    }

    protected async getCancelResult(): Promise<void> {
        if (this.status === OverallStatus.Processing) {
            const progress = this.percentProgress;
            const elapsedTime = this.elapsedTime;
            Analytics.event(EventName.DielineAnalysisCancel, { progress, elapsedTime });
        } else {
            Analytics.event(EventName.DielineImportCancel,
                this.getAnalyticsData(this.creaseInfos));
        }
    }

    private renderLegend() {
        const showWarnings = this.status === OverallStatus.Processed &&
            (this.creaseInfos[this.selectedCreaseIndex].creaseStatus === CreaseStatus.HasWarnings ||
            this.creaseInfos[this.selectedCreaseIndex].problemVertices.find(problemVertex => problemVertex.alertLevel === 'warning'));
        const showErrors = this.status === OverallStatus.Processed &&
            (this.creaseInfos[this.selectedCreaseIndex].creaseStatus === CreaseStatus.HasErrors ||
            this.creaseInfos[this.selectedCreaseIndex].problemVertices.find(problemVertex => problemVertex.alertLevel === 'error'));
        
        return (
            <div className="ImportDielineModal__legend">
                <div className="ImportDielineModal__legendItem">
                    <div className="ImportDielineModal__colorSwatch ImportDielineModal__colorSwatch--cuts"></div>
                    Cuts
                </div>
                <div className="ImportDielineModal__legendItem">
                    <div className="ImportDielineModal__colorSwatch ImportDielineModal__colorSwatch--creases"></div>
                    Creases
                </div>
                <div className="ImportDielineModal__legendItem">
                    <div className="ImportDielineModal__colorSwatch ImportDielineModal__colorSwatch--panels"></div>
                    Panels
                </div>
                {showWarnings &&
                    <div className="ImportDielineModal__legendItem">
                        <div className="ImportDielineModal__colorSwatch ImportDielineModal__colorSwatch--problems">
                            <div className="ImportDielineModal__problemArea ImportDielineModal__problemArea--warning"></div>
                        </div>
                        Problems
                    </div>
                }
                {showErrors &&
                    <div className="ImportDielineModal__legendItem">
                        <div className="ImportDielineModal__colorSwatch ImportDielineModal__colorSwatch--problems">
                            <div className="ImportDielineModal__problemArea ImportDielineModal__problemArea--error"></div>
                        </div>
                        Errors
                    </div>
                }
            </div>
        );
    }

    private renderInstructions() {
        const isProcessed = this.status === OverallStatus.Processed;
        const creaseInfo = this.creaseInfos[this.selectedCreaseIndex];
        const hasTriangulation = isProcessed && creaseInfo.triangleMesh;
        const hasProblems = isProcessed && hasTriangulation &&
            (creaseInfo.problemVertices.length > 0 || creaseInfo.hasCurvedCreases);
        const triangulationFailed = isProcessed && !hasTriangulation;
        const succeeded = hasTriangulation && !hasProblems;
        return (
            <div className="ImportDielineModal__instructions">
                <Heading className="ImportDielineModal__instructionsHeading" variant="subtitle2">
                    Check For Import
                </Heading>
                {this.status === OverallStatus.Processing &&
                    <>
                        <Progress label="Checking for problems" labelPosition="top"
                            value={this.percentProgress} />
                        <p>
                            Review the cuts and creases in your dieline. If you notice any
                            issues, press <b>Cancel</b>, edit your dieline in Adobe Illustrator,
                            and import it again.
                        </p>
                    </>
                }
                {succeeded &&
                    <Heading className="ImportDielineModal__row" variant="subtitle2">
                        <CheckmarkCircle className="ImportDielineModal__checkmark" size="S" />
                        <div className="ImportDielineModal__status">No problems found</div>
                    </Heading>
                }
                {hasProblems &&
                    <>
                        <Heading className="ImportDielineModal__row" variant="subtitle2">
                            { creaseInfo.creaseStatus === CreaseStatus.HasErrors &&
                                <>
                                    <Alert className="ImportDielineModal__alert--error" size="S" />
                                    <div className="ImportDielineModal__status">Errors found</div>
                                </>
                            }
                            {creaseInfo.creaseStatus !== CreaseStatus.HasErrors &&
                             (creaseInfo.hasCurvedCreases || creaseInfo.creaseStatus === CreaseStatus.HasWarnings) &&
                                <>
                                    <Alert className="ImportDielineModal__alert--warning" size="S" />
                                    <div className="ImportDielineModal__status">Potential problems found</div>
                                </>
                            }
                        </Heading>
                        <p>
                            {creaseInfo.hasCurvedCreases &&
                                <span>
                                    Curved creases are not fully supported yet — they probably won't
                                    produce the desired effect.&nbsp;
                                </span>
                            }
                            {creaseInfo.creaseStatus === CreaseStatus.HasErrors &&
                                <span>
                                    Review errors by selecting highlighted dieline
                                    areas.&nbsp;
                                    Errors must be fixed in Adobe Illustrator before importing
                                    into Fantastic Fold.&nbsp;
                                </span>
                            }
                            {creaseInfo.creaseStatus === CreaseStatus.HasWarnings &&
                                <span>
                                    Review potential problems by selecting highlighted dieline
                                    areas.&nbsp;
                                </span>
                            }
                            {creaseInfo.creaseStatus !== CreaseStatus.HasErrors &&
                                <span>
                                    If needed, press <b>Cancel</b>, edit your dieline in Adobe Illustrator,
                                    and import it again.
                                </span>
                            }
                        </p>
                    </>
                }
                {triangulationFailed && 
                    <>
                        <Heading className="ImportDielineModal__row" variant="subtitle2">
                            <CloseCircle className="ImportDielineModal__error" size="S" />
                            <div className="ImportDielineModal__status">Critical problems found</div>
                        </Heading>
                        <p>
                            Fantastic Fold could not create a triangle mesh for this dieline.
                            Perforations, slits, or small features might be the cause.
                            Please simplify the dieline in Adobe Illustrator, then import it again.
                        </p>
                    </>
                }
                {this.status === OverallStatus.Failed && 
                    <>
                        <Heading className="ImportDielineModal__row" variant="subtitle2">
                            <CloseCircle className="ImportDielineModal__error" size="S" />
                            <div className="ImportDielineModal__status">Critical problems found</div>
                        </Heading>
                        <p>
                            Fantastic Fold could not identify the panels in this dieline.
                            Please edit the dieline in Adobe Illustrator, then import it again.
                        </p>
                    </>
                }
                <p>
                    <Link onClick={ExternalLinks.launchHelp}>Learn more</Link> about setting up
                    your dieline for Fantastic Fold.
                </p>
            </div>
        );
    }

    private renderIconForDieline(i: number) {
        if (this.status === OverallStatus.Processing) {
            return undefined;
        }
        const creaseInfo = this.creaseInfos[i];
        if (creaseInfo.triangleMesh) {
            if (creaseInfo.problemVertices.length > 0 || creaseInfo.hasCurvedCreases) {
                const className = classNames('ImportDielineModal__alert', 'ImportDielineModal__tabIcon', {
                    'ImportDielineModal__alert--error': creaseInfo.creaseStatus === CreaseStatus.HasErrors,
                    'ImportDielineModal__alert--warning': creaseInfo.creaseStatus !== CreaseStatus.HasErrors,
                });
                return <Alert className={className} size="S" />;
            }
            else {
                return <CheckmarkCircle className="ImportDielineModal__checkmark ImportDielineModal__tabIcon" size="S" />;
            }
        }
        return <CloseCircle className="ImportDielineModal__error ImportDielineModal__tabIcon" size="S" />;
    }

    @action
    private updateButtons() {
        const allFailed = this.status === OverallStatus.Failed ||
            (this.status === OverallStatus.Processed && this.creaseInfos.every(info =>
            info.triangleMesh === undefined));
        const importLabel = this.creaseInfos.length <= 1 ? 'Import'
            : `Import Dieline ${this.selectedCreaseIndex + 1}`;
        this.confirmLabel = allFailed ? 'Cancel' : importLabel;
        this.cancelLabel = allFailed ? '' : 'Cancel';
        const creaseInfo = this.creaseInfos[this.selectedCreaseIndex];
        this.confirmDisabled = this.status === OverallStatus.Processing ||
            creaseInfo?.creaseStatus === CreaseStatus.HasErrors ||
            (!allFailed && this.status === OverallStatus.Processed &&
                creaseInfo.triangleMesh === undefined);
    }

    protected renderContent() {
        const creaseInfo = this.creaseInfos[this.selectedCreaseIndex];
        const showVisualizer = this.status === OverallStatus.Processed && creaseInfo;

        let tabs;
        if (this.status === OverallStatus.Processing) {
            tabs = <Tab icon={<Wait className="ImportDielineModal__wait" size="S" />}>Analyzing...</Tab>;
        } else if (this.creaseInfos.length === 0) {
            tabs = <Tab icon={<CloseCircle className="ImportDielineModal__error ImportDielineModal__tabIcon" size="S" />}>Dieline 1</Tab>;
        } else {
            tabs = Array(this.creaseInfos.length).fill(0).map((_, i) =>
                <Tab key={i} icon={this.renderIconForDieline(i)}
                    className="ImportDielineModal__tabItem">Dieline {i + 1}</Tab>
            );
        }

        let dielineHTML = this.dielineHTML;
        if (this.status === OverallStatus.Failed) {
            dielineHTML += `
                <svg viewBox="0 0 100 100">
                    <text class="ImportDielineModal__noPanels" x="50" y="85">?</text>
                </svg>`;
        }

        return (
            <div className="ImportDielineModal__content">
                <div className="ImportDielineModal__leftColumn">
                    <TabList collapsible onChange={this.handleTabChange}>
                        {tabs}
                    </TabList>
                    {!showVisualizer &&
                        <div className="ImportDielineModal__svgContainer"
                            dangerouslySetInnerHTML={{ __html: dielineHTML }} />
                    }
                    {showVisualizer &&
                        <CreaseVisualizer crease={creaseInfo.crease}
                            problemVertices={creaseInfo.problemVertices}
                            problemFaces={creaseInfo.problemFaces} />
                    }
                    {this.renderLegend()}
                </div>
                {this.renderInstructions()}
            </div>
        );
    }
}
