import Dolly from '@react/react-spectrum/Icon/Dolly';
import Move from '@react/react-spectrum/Icon/Move';
import Orbit from '@react/react-spectrum/Icon/Orbit';
import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ReactComponent as EdgeSelect } from '../assets/icons/EdgeSelect.svg';
import { ReactComponent as FaceSelect } from '../assets/icons/FaceSelect.svg';
import { ReactComponent as MouseWheel } from '../assets/icons/MouseWheel.svg';
import { ReactComponent as RightMouseButton } from '../assets/icons/RightMouseButton.svg';
import { ReactComponent as WindowIcon } from '../assets/icons/WindowIcon.svg';
import { AppMode } from '../common/AppMode';
import { ToolId } from '../common/ToolId';
import { KeyboardShortcut } from '../components/KeyboardShortcut';
import { Toolbar, ToolbarButton, ToolbarDivider } from '../components/Toolbar';
import '../css/ToolPanel.css';
import { Analytics, EventName } from '../services/Analytics';
import { StoreComponent } from './StoreComponent';
import { KeyboardShortcutLabel } from '../components/KeyboardShortcutLabel';

const validTools: Record<AppMode, ToolId[]> = {
    edit: [
        ToolId.FoldAngleTool,
        ToolId.WindowTool,
        ToolId.OrbitTool,
        ToolId.PanTool,
        ToolId.DollyTool,
    ],
    preview: [],
    home: [],
};

@inject('store')
@observer
export class ToolPanel extends StoreComponent {
    private previousToolId: ToolId = ToolId.FoldAngleTool;

    render() {
        const { activeToolId, showFlat } = this.props.store.ui;
        return (
            <div className="ToolPanel">
                <Toolbar
                    activeToolId={activeToolId}
                    setActiveToolId={this.setActiveToolId}>
                    {this.isValidTool(ToolId.FoldAngleTool) &&
                        <ToolbarButton
                            toolId={ToolId.FoldAngleTool}
                            icon={<EdgeSelect className="spectrum-Icon spectrum-Icon--sizeS" />}
                            name="Crease Selection Tool"
                            shortcut="V"
                            description="Select crease lines of a package." />
                    }
                    {this.isValidTool(ToolId.WindowTool) &&
                        <ToolbarButton
                            toolId={ToolId.WindowTool}
                            icon={<WindowIcon className="spectrum-Icon spectrum-Icon--sizeS" />}
                            name="Window Draw Tool"
                            shortcut="W"
                            description="Fill holes with clear plastic windows." />
                    }
                    {this.isValidTool(ToolId.FaceOrderingTool) &&
                        <ToolbarButton
                            toolId={ToolId.FaceOrderingTool}
                            icon={<FaceSelect className="spectrum-Icon spectrum-Icon--sizeS" />}
                            name="Face Selection Tool"
                            shortcut="F"
                            description="Select faces or panels of a package." />
                    }
                    <ToolbarDivider />
                    {this.shouldEnableOrbit() &&
                        <ToolbarButton
                            toolId={ToolId.OrbitTool}
                            icon={<Orbit />}
                            name="Orbit Tool"
                            shortcut="1"
                            additionalAccess={<RightMouseButton style={{ verticalAlign: 'bottom', marginBottom: 1 }} height="16" />}
                            description="Click and drag to rotate your view around the scene." />
                    }
                    {this.shouldEnablePan() &&
                        <ToolbarButton
                            toolId={ToolId.PanTool}
                            icon={<Move />}
                            name="Pan Tool"
                            shortcut="2"
                            additionalAccess={<span>
                                {showFlat
                                    ? <RightMouseButton style={{ verticalAlign: 'bottom', marginBottom: 1 }} height="16" />
                                    : <MouseWheel style={{ verticalAlign: 'bottom', marginBottom: 1 }} height="16" />}
                                &nbsp;or hold<KeyboardShortcutLabel shortcut="Space" />
                                </span>}
                            description="Click and drag to pan your camera up, down, left, and right." />
                    }
                    {this.isValidTool(ToolId.DollyTool) &&
                        <ToolbarButton
                            toolId={ToolId.DollyTool}
                            icon={<Dolly />}
                            name="Dolly Tool"
                            shortcut="3"
                            additionalAccess={<MouseWheel style={{ verticalAlign: 'bottom', marginBottom: 1 }} height="16" />}
                            description="Scroll to move your camera forward and backward." />
                    }
                </Toolbar>
                {this.shouldEnablePan() &&
                    <KeyboardShortcut
                        keys="Space"
                        onPressed={this.startPan}
                        onReleased={this.endPan} />
                }
            </div>
        );
    }

    private isValidTool(toolId: ToolId) {
        const { appMode } = this.props.store.ui;
        return validTools[appMode].includes(toolId);
    }

    private shouldEnablePan() {
        return (this.props.store.ui.showFlat || !this.props.store.preferences.enableAutoCentering)
            && this.isValidTool(ToolId.PanTool);
    }

    private shouldEnableOrbit() {
        return !this.props.store.ui.showFlat
            && this.isValidTool(ToolId.OrbitTool);
    }

    @boundMethod
    private setActiveToolId(toolId: string) {
        this.props.store.ui.activeToolId = toolId as ToolId;
        Analytics.event(EventName.ToolSelect, { tool: toolId as ToolId });
    }

    @boundMethod
    private startPan() {
        this.previousToolId = this.props.store.ui.activeToolId;
        this.setActiveToolId(ToolId.PanTool);
    }

    @boundMethod
    private endPan() {
        this.setActiveToolId(this.previousToolId);
    }
}
