import * as THREE from 'three';
import JSZip from 'jszip';

export class ObjExporter {
    private _objectTransform!: THREE.Matrix4;
    private _textureTransform!: THREE.Matrix3;
    private _materialNames!: string[];
    private _obj!: string;
    private _mtl!: string;
    private _zip!: JSZip;
    private _vertexIndex!: number;
    private _uvIndex!: number;
    private _normalIndex!: number;

    async exportToZip(basename: string, meshes: THREE.Mesh[], objectTransform: THREE.Matrix4,
        textureTransform: THREE.Matrix3): Promise<Blob> {
        // Intialize class members.
        this._objectTransform = objectTransform;
        this._textureTransform = textureTransform;
        this._materialNames = [];
        this._obj = '';
        this._mtl = '';
        this._zip = new JSZip();
        this._vertexIndex = 0;
        this._uvIndex = 0;
        this._normalIndex = 0;

        // Process each mesh.
        await Promise.all(meshes.map((mesh, i) => this._processMesh(mesh, i)));

        // Write the OBJ and MTL files in the ZIP archive.
        this._zip.file(`${basename}.obj`, `mtllib ${basename}.mtl\n${this._obj}`);
        this._zip.file(`${basename}.mtl`, this._mtl);

        // Return the blob containing the ZIP archive.
        return this._zip.generateAsync({ type: 'blob' }) as Promise<Blob>;
    }

    private async _processMesh(mesh: THREE.Mesh, index: number): Promise<void> {
        let vertexCount = 0;
        let normalCount = 0;
        let uvCount = 0;

        // Make sure we have a BufferGeometry.
        let geometry = mesh.geometry;
        if (geometry instanceof THREE.Geometry) {
            geometry = new THREE.BufferGeometry().setFromObject(mesh);
        }

        if (!(geometry instanceof THREE.BufferGeometry)){
            throw new Error(`Unsupported geometry type: ${geometry}`);
        }

        // Skip this mesh if it has no triangles.
        const indices = geometry.getIndex();
        if (indices !== null && indices.count >= 3) {

            // Include a blank line before each mesh.
            if (this._obj.length) {
                this._obj += '\n';
            }

            // Write the material name.
            const material = Array.isArray(mesh.material) ? mesh.material[0] : mesh.material;
            if (material) {
                const materialName = material.name || `material${this._materialNames.length}`;
                if (!this._materialNames.includes(materialName)) {
                    await this._createMaterial(materialName, material);
                }
                this._obj += `usemtl ${materialName}\n`;
            }

            // Combine the mesh's world transform with the object transform that was passed in.
            const transformMatrix = new THREE.Matrix4();
            transformMatrix.copy(mesh.matrixWorld);
            transformMatrix.multiply(this._objectTransform);

            // Write the vertices.
            const vertices = geometry.getAttribute('position');
            if (vertices !== undefined) {
                const vertex = new THREE.Vector3();
                vertexCount = vertices.count;
                for (let i = 0; i < vertexCount; i++) {
                    // Get the vertex position.
                    vertex.x = vertices.getX(i);
                    vertex.y = vertices.getY(i);
                    vertex.z = vertices.getZ(i);

                    // Transfrom the vertex.
                    vertex.applyMatrix4(transformMatrix);

                    // Write the vertex.
                    this._obj += `v ${ObjExporter._formatVector3(vertex)}\n`;
                }
            }

            // Write the texture coordinates.
            const uvs = geometry.getAttribute('uv');
            if (uvs !== undefined) {
                const uv = new THREE.Vector2();
                uvCount = uvs.count;
                const texture = 'map' in material ? material['map'] as THREE.Texture : null;
                for (let i = 0; i < uvCount; i++) {
                    uv.x = uvs.getX(i);
                    uv.y = uvs.getY(i);
                    if (texture) {
                        uv.applyMatrix3((texture as any).matrix); // three.js declarations omit matrix
                    }
                    uv.applyMatrix3(this._textureTransform);
                    this._obj += `vt ${ObjExporter._formatVector2(uv)}\n`;
                }
            }

            // Write the normals.
            const normals = geometry.getAttribute('normal');
            if (normals !== undefined) {
                const normalMatrix = new THREE.Matrix3();
                normalMatrix.getNormalMatrix(transformMatrix);
                const normal = new THREE.Vector3();
                normalCount = normals.count;
                for (let i = 0; i < normalCount; i++) {
                    // Get the normal vector.
                    normal.x = normals.getX(i);
                    normal.y = normals.getY(i);
                    normal.z = normals.getZ(i);

                    // Transform the normal.
                    normal.applyMatrix3(normalMatrix);

                    // Write the normal.
                    this._obj += `vn ${ObjExporter._formatVector3(normal)}\n`;
                }
            }

            // Write the triangles.
            if (indices !== null) {
                const indexCount = indices.count;
                for (let i = 0; i < indexCount - 2; i += 3) {
                    this._obj += 'f';
                    for (let m = 0; m < 3; m++) {
                        const j = indices.getX(i + m) + 1;
                        this._obj += ` ${this._vertexIndex + j}`;
                        if (normals || uvs) {
                            this._obj += `/${uvs ? (this._uvIndex + j) : ''}`;
                            if (normals) {
                                this._obj += `/${this._normalIndex + j}`;
                            }
                        }
                    }
                    this._obj += '\n';
                }
            }
        }

        // Update indices.
        this._vertexIndex += vertexCount;
        this._uvIndex += uvCount;
        this._normalIndex += normalCount;

        // Dispose of geometry if needed.
        if (geometry !== mesh.geometry) {
            geometry.dispose();
        }
    }

    private async _createMaterial(materialName: string, material: THREE.Material): Promise<void> {
        // Add texture to ZIP archive.
        let filename = `${materialName}.png`;
        if ('map' in material) {
            const texture: THREE.Texture = material['map'];
            if (texture) {
                const image = texture.image;
                if (image instanceof Image) {
                    filename = await this._addFileToZip(materialName, image.src);
                }
            }
        }

        // Add to MTL output.
        if (this._mtl.length) {
            this._mtl += '\n';
        }
        this._mtl += `# ${materialName}\n`
            + `newmtl ${materialName}\n`
            + 'illum 4\n'
            + 'Kd 1 1 1\n'
            + 'Ka 0 0 0\n'
            + 'Tf 1 1 1\n'
            + `map_Kd ${filename}\n`
            + 'Ni 1\n'
            + 'Ks 0.5 0.5 0.5\n'
            + 'Ns 18\n';
        this._materialNames.push(materialName);
    }

    private async _addFileToZip(basename: string, url: string): Promise<string> {
        let filename: string;
        if (url.startsWith('data:')) {
            const [prefix, data] = url.split(',', 2);
            const base64 = prefix.endsWith(';base64');
            filename = `${basename}.png`;
            this._zip.file(filename, data, { base64 });
        } else {
            const response = await fetch(url);
            const blob = await response.blob();
            const extension = url.substr(url.lastIndexOf('.'));
            filename = `${basename}${extension}`;
            this._zip.file(filename, blob);
        }
        return filename;
    }

    private static _roundNumber(x: number) {
        return Math.round(x * 1e6) / 1e6;
    }

    private static _formatVector2(v: THREE.Vector2): string {
        return [0, 1].map(i => ObjExporter._roundNumber(v.getComponent(i))).join(' ');
    }

    private static _formatVector3(v: THREE.Vector3): string {
        return [0, 1, 2].map(i => ObjExporter._roundNumber(v.getComponent(i))).join(' ');
    }
}
