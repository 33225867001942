import React, { Component, ReactNode } from 'react';
import { KeyboardShortcutLabel } from './KeyboardShortcutLabel';
import './TooltipContent.css';

/** Properties of the TooltipContent component. */
interface TooltipContentProps {
    heading: string;
    shortcut?: string;
    additionalAccess?: ReactNode;
    description?: string;
}

/**
 * Formats the content of a tooltip.
 * 
 * Example:
 * 
 *      <OverlayTrigger>
 *          <Button label="Export Model" />
 *          <Tooltip variant="info">
 *              <TooltipContent
 *                  heading="Export Model File"
 *                  shortcut="cmdOrCtrl+e"
 *                  description="Download a model file for use in other applications." />
 *          </Tooltip>
 *      </OverlayTrigger>
 */
export class TooltipContent extends Component<TooltipContentProps> {
    render() {
        const { heading, shortcut, additionalAccess, description } = this.props;
        const modifiedHeading = description ? <b>{heading}</b> : heading;
        return (
            <span>
                {modifiedHeading} <KeyboardShortcutLabel shortcut={shortcut} />
                {additionalAccess && <span>&nbsp;or&nbsp;{additionalAccess}</span>}
                <br />
                {description}
            </span>
        );
    }
}
