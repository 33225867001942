import { ReactNode } from 'react';
import { ModalDialog } from './ModalDialog';

/** A simple dialog with a title, a message, and a confirm button. */
export class MessageDialog extends ModalDialog {
    /**
     * Displays a dialog with the given title and content, returning a promise that is resolved
     * when the user dismisses the dialog.
     * @param title The title of the dialog.
     * @param content The content of the dialog.
     * @param confirmLabel The label displayed on the confirm button.
     * @example
     *      await MessageDialog.show('Wrong droids',
     *          'These aren’t the droids you’re looking for.');
     *      moveAlong();
     */
    static show(title: string, content: ReactNode, confirmLabel = 'OK'): Promise<void> {
        const dialog = new MessageDialog(title, content, confirmLabel, '');
        return dialog.show();
    }
}
