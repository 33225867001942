import { MeshSurface } from './MeshSurface';

// Partial mesh surface extends mesh surface by allowing some faces to be shown and others hidden.
// This class expects that material passed to the constructor is THREE.Material (not THREE.Material[])

export class PartialMeshSurface extends MeshSurface {

    constructor(material: THREE.Material, isMeshBackside: boolean = false) {
        super(material, isMeshBackside);
        // Need to change mesh.material to use material array.
        this.mesh.material = [material];
    }

    showFaces(faceIndices: number[]) {
        // Set groups on geometry.
        const geometry = (this.mesh.geometry as THREE.BufferGeometry);
        geometry.clearGroups();
        let runningIndex = 0;
        MeshSurface.facesForwardMapping.forEach((triangleIndices, i: number) => {
            // Tell threejs which triangles correspond to which materials.
            if (faceIndices.indexOf(i) >= 0) {
                // All groups pointing to material index 0.
                geometry.addGroup(3 * runningIndex, 3 * triangleIndices.length, 0);
            }
            runningIndex += triangleIndices.length;
        });
    }
}
