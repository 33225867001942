import { TriangleMesh, getNormalizer, Crease } from 'crease';
import * as THREE from 'three';

// Need to tell if this is a frontside or backside mesh.
// Would use material.side here, but this is not respected in many effects shaders.
let meshGeometryFrontside = new THREE.BufferGeometry();
let meshGeometryBackside = new THREE.BufferGeometry();

// Lookup table for raycasting.
let creaseFaceIndices: number[] = [];

export class MeshSurface {
    mesh = new THREE.Mesh(new THREE.BufferGeometry());
    private isMeshBackside = false;
    static facesForwardMapping: number[][] = [];

    constructor(material: THREE.Material | THREE.Material[], isMeshBackside: boolean = false) {
        this.mesh.material = material;
        this.isMeshBackside = isMeshBackside;
    }

    static setTriangleMesh(triangleMesh: TriangleMesh, crease: Crease) {
        const { vertices, facesForwardMapping, faces } = triangleMesh;
        MeshSurface.facesForwardMapping = facesForwardMapping;

        // Frontside and backside share same position and uv buffers, but have separate normal and indices buffers.
        meshGeometryFrontside.dispose();
        meshGeometryBackside.dispose();
        meshGeometryFrontside = new THREE.BufferGeometry();
        meshGeometryBackside = new THREE.BufferGeometry();

        // Init positions, normals.
        const positions = new Float32Array(vertices.length * 3);
        const normalsFrontside = new Float32Array(vertices.length * 3);
        const normalsBackside = new Float32Array(vertices.length * 3);
        const positionAttribute = new THREE.BufferAttribute(positions, 3);
        meshGeometryFrontside.setAttribute('position', positionAttribute);
        meshGeometryBackside.setAttribute('position', positionAttribute);
        const normalAttributeFrontside = new THREE.BufferAttribute(normalsFrontside, 3);
        meshGeometryFrontside.setAttribute('normal', normalAttributeFrontside);
        const normalAttributeBackside = new THREE.BufferAttribute(normalsBackside, 3);
        meshGeometryBackside.setAttribute('normal', normalAttributeBackside);

        // Set uvs.
        const uvs = new Float32Array(vertices.length * 2);
        // Get min and max of 2D coords and scale each axis so it fills the entire 0-1 range.
        // We must use crease.getBounds() here, since we are using crease.getBounds() to calc the offset to the texture.
        // There can be some slight diffs between getBounds() and manually calculating bounds from triangleMesh vertices
        // because getBounds() includes control points in its calculation.
        const bounds = crease.getBounds();
        const normalizer = getNormalizer(crease);
        bounds.min = normalizer(bounds.min);
        bounds.max = normalizer(bounds.max);
        vertices.forEach((vertex, i: number) => {
            uvs[(2 * i)] = (vertex.position2D[0] - bounds.min[0]) / (bounds.max[0] - bounds.min[0]);
            uvs[(2 * i) + 1] = (vertex.position2D[1] - bounds.min[1]) / (bounds.max[1] - bounds.min[1]);
        });
        const uvAttribute = new THREE.BufferAttribute(uvs, 2);
        meshGeometryFrontside.setAttribute('uv', uvAttribute);
        meshGeometryBackside.setAttribute('uv', uvAttribute);

        // Set triangle indices.
        const indicesFrontside: number[] = [];
        const indicesBackside: number[] = [];
        facesForwardMapping.forEach((triangleIndices, i: number) => {
            triangleIndices.forEach((triangleIndex, j: number) => {
                const face = faces[triangleIndex];
                indicesBackside.push(face.vertices[1], face.vertices[0], face.vertices[2]);
                indicesFrontside.push(face.vertices[0], face.vertices[1], face.vertices[2]);
            });
        });
        meshGeometryFrontside.setIndex(indicesFrontside);
        meshGeometryBackside.setIndex(indicesBackside);

        // Frontside and backside share same bounds so we can recompute once.
        meshGeometryBackside.boundingBox = meshGeometryFrontside.boundingBox;
        meshGeometryBackside.boundingSphere = meshGeometryFrontside.boundingSphere;

        // Compute a lookup table for raycasting.
        creaseFaceIndices = [];
        facesForwardMapping.forEach((triangleIndices, i: number) => {
            triangleIndices.forEach(() => {
                creaseFaceIndices.push(i);
            });
        });
    }

    updateGeometry() {
        // Shallow clone of geometry, keeping refs to parent attribute buffers.
        const prototypeGeometry = this.isMeshBackside? meshGeometryBackside : meshGeometryFrontside;
        const geometry = new THREE.BufferGeometry();
        Object.keys(prototypeGeometry.attributes).forEach((attributeName) => {
            geometry.setAttribute(attributeName, prototypeGeometry.getAttribute(attributeName));
        });
        geometry.setIndex(prototypeGeometry.getIndex());
        // Copy bounds so we can recompute once.
        geometry.boundingBox = prototypeGeometry.boundingBox;
        geometry.boundingSphere = prototypeGeometry.boundingSphere;

        // If using an array of materials, add groups to geometry.
        if (Array.isArray(this.mesh.material)) {
            let runningIndex = 0;
            MeshSurface.facesForwardMapping.forEach((triangleIndices, i: number) => {
                // Tell threejs which triangles correspond to which materials.
                geometry.addGroup(3 * runningIndex, 3 * triangleIndices.length, i);
                runningIndex += triangleIndices.length;
            });
        }

        // Update geometry.
        this.mesh.geometry.dispose();
        this.mesh.geometry = geometry;

        // Add lookup table for raycasting.
        this.mesh.userData = { creaseFaceIndices };
    }

    get geometry(): THREE.BufferGeometry {
        return this.mesh.geometry as THREE.BufferGeometry;
    }

    getUVAttribute() {
        return this.geometry.getAttribute('uv') as THREE.BufferAttribute;
    }

    getPositionAttribute() {
        return this.geometry.getAttribute('position') as THREE.BufferAttribute;
    }

    getNormalAttribute() {
        return this.geometry.getAttribute('normal') as THREE.BufferAttribute;
    }

    setPositionAttribute(attribute: THREE.BufferAttribute) {
        this.geometry.setAttribute('position', attribute);
    }

    // This is used by HeatMapTool to control the per vertex color of the heat map.
    setAlphaAttribute(attribute: THREE.BufferAttribute) {
        this.geometry.setAttribute('alpha', attribute);
    }

    getObject3D() {
        return this.mesh;
    }

    static updatePositions(positions: [number, number, number][]) {
        const positionAttribute = meshGeometryFrontside.getAttribute('position') as THREE.BufferAttribute;
        const positionBuffer = positionAttribute.array as Float32Array;
        if (!positionBuffer) {
            throw new Error('updatePositions() error: positionBuffer for Model3D mesh has not been inited.')
        }
        if (positionBuffer.length / 3 !== positions.length) {
            throw new Error(`updatePositions() error: incompatible positionBuffer length ${positionBuffer.length / 3} for new position data ${positions.length}.`)
        }

        // Update position Buffer.
        for (let i = 0; i < positions.length; i++) {
            const position = positions[i];
            for (let j = 0; j < 3; j++) {
                positionBuffer[3*i + j] = position[j];
            }
        }
        positionAttribute.needsUpdate = true;

        // Update normals and bounds.
        meshGeometryFrontside.computeBoundingSphere();
        meshGeometryFrontside.computeVertexNormals();
        // MeshGeometryBackside has inverse normals of meshGeometryFrontside.
        // No need for meshGeometryBackside.computeVertexNormals();
        const frontsideNormals = meshGeometryFrontside.getAttribute('normal').array;
        const backsideNormalsAttribute = meshGeometryBackside.getAttribute('normal') as THREE.BufferAttribute;
        const backsideNormals = backsideNormalsAttribute.array as Float32Array;
        for (let i=0; i<backsideNormals.length; i++) {
            backsideNormals[i] = frontsideNormals[i] * -1;
        }
        backsideNormalsAttribute.needsUpdate = true;
    }

    static getCenter() {
        if (meshGeometryFrontside.boundingSphere === null) {
            return new THREE.Vector3();
        }
        return meshGeometryFrontside.boundingSphere.center.clone();
    }

    static getRadius() {
        if (meshGeometryFrontside.boundingSphere === null) {
            return 0;
        }
        return meshGeometryFrontside.boundingSphere.radius;
    }

    setVisibility(state: boolean) {
        this.mesh.visible = state;
    }

    dispose() {
        // Don't dispose this.mesh.geometry because it is either
        // meshGeometryFrontside or meshGeometryBackside, which are persistent.
    }
}
