import { boundMethod } from 'autobind-decorator';
import { autorun, IReactionDisposer, IReactionPublic, reaction, IReactionOptions } from 'mobx';
import React from 'react';
import * as THREE from 'three';
import { StoreComponent } from '../UI/StoreComponent';

export interface Modifiers {
    shiftKey: boolean;
    ctrlKey: boolean;
    altKey: boolean;
    metaKey: boolean;
}

export class Tool<Props = {}, State = {}> extends StoreComponent<Props, State> {
    private disposers: IReactionDisposer[] = [];

    // Raycasting.
    raycastingTargets: THREE.Object3D[] = [];

    // Override in subclasses.
    onLeftDown(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onScrollDown(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onRightDown(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onLeftClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; } // Triggered on mouseup.
    onScrollClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; } // Triggered on mouseup.
    onRightClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; } // Triggered on mouseup.
    onLeftDragStart(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers, raycaster: THREE.Raycaster): boolean { return false; }
    onLeftDragMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers, raycaster: THREE.Raycaster): boolean { return false; }
    onLeftDragEnd(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onScrollDragStart(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onScrollDragMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onScrollDragEnd(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onRightDragStart(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onRightDragMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onRightDragEnd(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }
    onHoverMove(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers): boolean { return false; }// Moving mouse with no button pressed.
    onNullEvent() {} // This gets called if another tool has captured a user event, usually this hides visibility of a tool not receiving the event.
    // Include any JSX code here.
    render(): React.ReactNode {
        return [];
    }
    @boundMethod
    protected cameraControlsChanged() {}
    @boundMethod
    protected updateScale() {}
    @boundMethod
    protected updatePositions() {}
    protected toolOnActivate() {} // Callback right when tool is selected.
    protected toolOnDeactivate() {} // Callback right when tool is deselected.
    // End override in subclasses.

    // Call from toolOnActivate in subclasses:
    protected addAutorun(view: (r: IReactionPublic) => any) {
        this.disposers.push(autorun(view));
    }

    protected addReaction<Data>(
        expression: (r: IReactionPublic) => Data,
        effect: (data: Data, r: IReactionPublic) => void,
        options?: IReactionOptions,
    ) {
        this.disposers.push(reaction(expression, effect, options));
    }

    componentDidMount() {
        // Add event listeners.
        const { render } = this.props.store;
        render.faceOffsetter.addEventListener(this.updatePositions);
        this.addReaction(() => render.cameraControlsCounter, () => this.cameraControlsChanged());
        this.addReaction(() => render.unitsPerPixel, () => this.updateScale(),
            { fireImmediately: true });
        this.toolOnActivate();
    }

    componentWillUnmount() {
        // Remove event listeners.
        const { render } = this.props.store;
        render.faceOffsetter.removeEventListener(this.updatePositions);
        this.toolOnDeactivate();
        this.disposers.forEach(disposer => {
            disposer();
        });
        this.disposers = [];
    }
}
