import Button from '@react/react-spectrum/Button';
import { boundMethod } from 'autobind-decorator';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as THREE from 'three';
import { Modifiers, Tool } from './Tool';

@inject('store')
@observer
export class FaceOrderingTool extends Tool {

    onLeftClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2, modifiers: Modifiers) {
        // Unselect all faces (no shift + multiselect)
        this.props.store.selection.deselectAll();
        return super.onLeftClick(intersections, screenCoords, modifiers);
    }

    getSortedGroup(targetFace: number) {
        const { overlappingGroups, panelOrder } = this.props.store.geometry;
        const group = overlappingGroups.find((group) => {
            return group.indexOf(targetFace) > -1;
        });

        if (group === undefined) {
            return [];
        }

        const sortedGroup: number[] = [];
        panelOrder.forEach((faceIndex) => {
            if (group.indexOf(faceIndex) > -1) {
                sortedGroup.push(faceIndex);
            }
        });
        return sortedGroup;
    }

    orderFaceRelativeToFace(faceToMove: number, targetFace: number, offset: number) {
        const panelOrder = this.props.store.geometry.panelOrder.slice();
        panelOrder.splice(panelOrder.indexOf(faceToMove), 1);
        panelOrder.splice(panelOrder.indexOf(targetFace) + offset, 0, faceToMove);

        this.props.store.geometry.panelOrder = panelOrder;
    }

    @boundMethod
    bringToFront() {
        const face = this.props.store.selection.selectedFaces[0];

        const sortedGroup = this.getSortedGroup(face);
        if (sortedGroup.length === 0) {
            return;
        }

        this.orderFaceRelativeToFace(face, sortedGroup[0], 0);
    }

    @boundMethod
    bringForward() {
        const face = this.props.store.selection.selectedFaces[0];

        const sortedGroup = this.getSortedGroup(face);
        if (sortedGroup.length === 0) {
            return;
        }
        const sortedGroupIndex = sortedGroup.indexOf(face);

        this.orderFaceRelativeToFace(face, sortedGroup[sortedGroupIndex - 1], 0);
    }

    @boundMethod
    sendBackward() {
        const face = this.props.store.selection.selectedFaces[0];

        const sortedGroup = this.getSortedGroup(face);
        if (sortedGroup.length === 0) {
            return;
        }
        const sortedGroupIndex = sortedGroup.indexOf(face);

        this.orderFaceRelativeToFace(face, sortedGroup[sortedGroupIndex + 1], 1);
    }

    @boundMethod
    sendToBack() {
        const face = this.props.store.selection.selectedFaces[0];

        const sortedGroup = this.getSortedGroup(face);
        if (sortedGroup.length === 0) {
            return;
        }

        this.orderFaceRelativeToFace(face, sortedGroup[sortedGroup.length - 1], 1);
    }

    render() {
        const selectedFaces = this.props.store.selection.selectedFaces;
        if (selectedFaces.length) {
            const targetFace = selectedFaces[0];
            // Sort group.
            const sortedGroup = this.getSortedGroup(targetFace);
            if (sortedGroup.length === 0) {
                return [];
            }

            const disabled = {
                bringToFront: false,
                bringForward: false,
                sendBackward: false,
                sendToBack: false,
            };

            // Check if we are at front or end of sorted group.
            if (sortedGroup[0] === targetFace) {
                disabled.bringToFront = true;
                disabled.bringForward = true;
            } else if (sortedGroup[sortedGroup.length - 1] === targetFace) {
                disabled.sendToBack = true;
                disabled.sendBackward = true;
            }

            return  (
                // TODO: these are mac specific symbols.
                <>
                    <Button disabled={disabled.bringToFront} label="Bring to Front &#8679; &#8984; ]" onClick={this.bringToFront} variant="action"/>
                    <Button disabled={disabled.bringForward} label="Bring Forward &#8984; ]" onClick={this.bringForward} variant="action"/>
                    <Button disabled={disabled.sendBackward} label="Send Backward &#8984; [" onClick={this.sendBackward} variant="action"/>
                    <Button disabled={disabled.sendToBack} label="Send to Back &#8679; &#8984; [" onClick={this.sendToBack} variant="action"/>
                </>
            );
        }
        return [];
    }
}
