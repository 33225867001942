import { Tool } from './Tool';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export class OrbitTool extends Tool {
    toolOnActivate() {
        const { render } = this.props.store;
        render.controlsFlat.setLeftDragOrbit();
        render.controls3D.setLeftDragOrbit();
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.controlsFlat.disableLeftDrag();
        render.controls3D.disableLeftDrag();
    }
}
