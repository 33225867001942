import * as THREE from 'three';

const loader = new THREE.TextureLoader();

export function loadTexture(url: string): Promise<THREE.Texture> {
    return new Promise((resolve, reject) => {
        loader.load(url, (texture: THREE.Texture) => {
            resolve(texture);
        }, undefined, reject);
    });
}