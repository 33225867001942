import Dialog, { DialogProps } from '@react/react-spectrum/Dialog';
import ModalContainer from '@react/react-spectrum/ModalContainer';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';

/**
 * Base class for a modal dialog that produces a result of type Result.
 */
export class ModalDialog<Result = void> {
    /** A unique ID associated with the dialog. */
    private dialogId?: number = undefined;

    /** The resolve function associated with the promise returned by the `show` method. */
    private resolve?: (result: Result | void) => void;

    /** The CSS class applied to the dialog. */
    @observable
    className: string = 'ModalDialog';

    /** The title of the dialog. */
    @observable
    title: string = 'Title';

    /** The content of the dialog (if the `renderContent` method is not overridden). */
    content: ReactNode = 'Content.';

    /** The label displayed on the cancel button. Use the empty string to hide the button. */
    @observable
    cancelLabel: string = 'Cancel';

    /** The label displayed on the confirm button. Use the empty string to hide the button. */
    @observable
    confirmLabel: string = 'OK';

    /** The label displayed on the secondary button. Use the empty string to hide the button. */
    @observable
    secondaryLabel: string = '';

    /** True if the confirm button (and secondary button) should be disabled. */
    @observable
    confirmDisabled: boolean = false;

    /** Indicates which button should receive focus when the dialog opens. */
    @observable
    autoFocusButton: 'confirm' | 'secondary' | 'cancel' | null = 'confirm';

    /** Derived classes must call the base class constructor. */
    protected constructor(
        title: string = 'Title',
        content: ReactNode = 'Content.',
        confirmLabel: string = 'OK',
        cancelLabel: string = 'Cancel',
    ) {
        this.title = title;
        this.content = content;
        this.confirmLabel = confirmLabel;
        this.cancelLabel = cancelLabel;
    }

    /** Called when the dialog is opened. Subclasses may override this method. */
    protected onOpened(): void {
    }

    /** Called to render the content of the dialog. Subclasses may override this method. */
    protected renderContent(): ReactNode {
        return this.content;
    }

    /** Called when the dialog is about to close. Subclasses may override this method. */
    protected onClosing(): void {
    }

    /**
     * Called when the user confirms the dialog. Subclasses may override this method to return
     * a result. This result will be returned to the caller of the show method.
     */
    protected async getConfirmResult(): Promise<Result | void> {
    }

    /**
     * Called when the user cancels the dialog. Subclasses may override this method to return
     * a result. This result will be returned to the caller of the show method.
     */
    protected async getCancelResult(): Promise<Result | void> {
    }

    /** Called when the user confirms the dialog. */
    @action.bound
    private async onConfirm(): Promise<void> {
        if (this.resolve) {
            this.onClosing();
            this.resolve(await this.getConfirmResult());
            this.resolve = undefined;
        }
    }

    /** Called when the user cancels the dialog. */
    @action.bound
    private async onCancel(): Promise<void> {
        if (this.resolve) {
            this.onClosing();
            this.resolve(await this.getCancelResult());
            this.resolve = undefined;
        }
    }

    /** Called when the user presses the escape key. */
    @action.bound
    private async onEscapeKey(): Promise<void> {
        if (this.dialogId !== undefined) {
            ModalContainer.hide(this.dialogId);
            this.dialogId = undefined;
        }
        await this.onCancel();
    }

    /**
     * Shows the dialog, returning a promise that resolves to a result once the user confirms or
     * cancels the dialog.
     */
    async show(): Promise<Result | void> {
        // We set disableEscKey so that dialogs can handle the escape key themselves as needed.
        // (The escape key implementation in ModalContainer closes the dialog even when keyboard
        // events have their propagation stopped.)
        return new Promise(resolve => {
            this.resolve = resolve;
            const props: ModalDialogProps<Result> = {
                model: this,
                renderContent: this.renderContent.bind(this), // bind so subclasses don't have to
                onConfirm: this.onConfirm,
                onCancel: this.onCancel,
                onEscapeKey: this.onEscapeKey,
                disableEscKey: true,
            };
            const dialogComponent = <ModalDialogComponent {...props} />;
            this.dialogId = ModalContainer.show(dialogComponent);
            this.onOpened();
        })
    }
}

interface ModalDialogProps<Result> {
    model: ModalDialog<Result>;
    renderContent: () => ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
    onEscapeKey: () => void;
    disableEscKey: boolean;
}

@observer
class ModalDialogComponent<Result> extends
    Component<ModalDialogProps<Result> & DialogProps> {
    render() {
        // Notes:
        // - We exclude disableEscKey from otherProps because it's consumed by ModalContainer
        //   from DialogImplementation -- there's no need to set it on Dialog.
        // - We exclude ref from otherProps to avoid type errors.
        const {
            model,
            renderContent,
            onConfirm,
            onCancel,
            onEscapeKey,
            disableEscKey,
            ref,
            ...otherProps
        } = this.props;

        // Note that otherProps must come after the event handlers below, and role must come
        // after otherProps.
        return (
            <Dialog
                className={model.className}
                title={model.title}
                cancelLabel={model.cancelLabel}
                confirmLabel={model.confirmLabel}
                secondaryLabel={model.secondaryLabel}
                confirmDisabled={model.confirmDisabled}
                autoFocusButton={model.autoFocusButton}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onClose={onCancel}
                onKeyDown={this.onKeyDown}
                {...otherProps}
                role="dialog">
                {renderContent()}
            </Dialog>
        )
    }

    @action.bound
    private onKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Escape') {
            this.props.onEscapeKey();
            e.stopPropagation();
            e.preventDefault();
        }
    }
}
