import { ReactNode } from 'react';
import { ModalDialog } from './ModalDialog';

/** A dialog that prompts the user to confirm or cancel. */
export class PromptDialog extends ModalDialog<boolean> {
    /**
     * Displays a dialog with the given title and content, returning a promise that resolves
     * to true if the user confirms and false if the user cancels the dialog.
     * @param title The dialog title.
     * @param content The dialog content.
     * @param confirmLabel The label displayed on the confirm button.
     * @param cancelLabel The label displayed on the cancel button.
     * @example
     *      if (await PromptDialog.show('Save', 'Do you want to save your file?', 'Yes', 'No')) {
     *          saveFile();
     *      }
     */
    static async show(
        title: string,
        content: ReactNode,
        confirmLabel = 'OK',
        cancelLabel = 'Cancel',
    ): Promise<boolean> {
        const dialog = new PromptDialog(title, content, confirmLabel, cancelLabel);
        return !!await dialog.show();
    }

    protected async getConfirmResult(): Promise<boolean> {
        return true;
    }
}
