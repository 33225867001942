import Button from '@react/react-spectrum/Button';
import CycleButton from '@react/react-spectrum/CycleButton';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Heading from '@react/react-spectrum/Heading';
import AddCircle from '@react/react-spectrum/Icon/AddCircle';
import ChevronLeft from '@react/react-spectrum/Icon/ChevronLeft';
import ChevronRight from '@react/react-spectrum/Icon/ChevronRight';
import Delete from '@react/react-spectrum/Icon/Delete';
import Pause from '@react/react-spectrum/Icon/Pause';
import Play from '@react/react-spectrum/Icon/Play';
import Refresh from '@react/react-spectrum/Icon/Refresh';
import StepBackward from '@react/react-spectrum/Icon/StepBackward';
import StepForward from '@react/react-spectrum/Icon/StepForward';
import Slider from '@react/react-spectrum/Slider';
import Well from '@react/react-spectrum/Well';
import classNames from 'classnames';
import { action, computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { AppMode } from '../common/AppMode';
import { utils } from '../common/utils';
import { NumberInput } from '../components/NumberInput';
import { TooltipButton } from '../components/TooltipButton';
import '../css/AnimationPanel.css';
import { Analytics, EventName } from '../services/Analytics';
import { FoldAngle, FoldingStep } from '../store/GeometryStore';
import { StoreComponent } from './StoreComponent';

const SNAPSHOT_MARGIN = 4;

@inject('store')
@observer
export class AnimationPanel extends StoreComponent {
    // When animating, we alternate between a transition (motion from one keyframe's pose to the
    // next keyframe's pose) and a hold (keeping a fixed pose). A timeline with five keyframes looks
    // like this:
    //
    // step0              step1                 step2                 step3              step4
    //   |---------------|=====|---------------|=====|---------------|=====|---------------|
    //     transition0    hold0   transition1   hold1   transition2   hold2   transition3

    private startTime: number = 0;
    private startProgress: number = 0;
    private endProgress: number = 0;
    private speed: number = 0;
    private animationFrameRequest?: number = undefined;

    @observable
    private isAnimating: boolean = false;

    @computed
    private get stepCount() {
        return this.props.store.geometry.foldingSteps.length;
    }

    @computed
    private get transitionCount() {
        return Math.max(0, this.stepCount - 1);
    }

    @computed
    private get totalDuration() {
        const { ui } = this.props.store;
        return Math.max(0, this.transitionCount * ui.transitionDuration
            + (this.transitionCount - 1) * ui.holdDuration);
    }

    @computed
    private get selectedStep(): number | undefined {
        const { ui } = this.props.store;
        const { step, fraction } = this.getStepAndFraction(ui.animationProgress);
        return fraction < 0.01 ? step : (fraction > 0.99 ? step + 1 : undefined);
    }


    @computed
    private get canAnimate() {
        return this.transitionCount > 0;
    }

    @computed
    private get canPlayBackward() {
        const { ui } = this.props.store;
        return this.canAnimate && ui.animationProgress > 0;
    }

    @computed
    private get canPlayForward() {
        const { ui } = this.props.store;
        return this.canAnimate && ui.animationProgress < 1;
    }

    componentWillUnmount() {
        this.stopAnimation();
    }

    render() {
        const { ui } = this.props.store;
        if (ui.appMode === AppMode.Preview) {
            if (this.canAnimate) {
                return this.renderInPreviewMode();
            } else {
                return null;
            }
        }
        return this.renderInFoldMode();
    }

    private renderInPreviewMode() {
        return (
            <div className="AnimationPanel AnimationPanel--preview">
                {this.renderPlaybackControls()}
            </div>
        );
    }

    private renderInFoldMode() {
        const { ui } = this.props.store;
        return (
            <div className={classNames('AnimationPanel', {
                'AnimationPanel--expanded': ui.showKeyframes
            })}>
                <CycleButton
                    className="AnimationPanel__expandCollapseButton"
                    action={ui.showKeyframes ? 'collapse' : 'expand'}
                    actions={[
                        { name: 'collapse', icon: <ChevronRight />, label: 'Collapse' },
                        { name: 'expand', icon: <ChevronLeft />, label: 'Expand' },
                    ]}
                    onChange={this.toggleShowKeyframes} />

                {ui.showKeyframes &&
                    <div className="AnimationPanel__keyframes">
                        <Heading variant="subtitle2">
                            KEYFRAMES
                        </Heading>
                        <div className="AnimationPanel__row">
                            <Well className="AnimationPanel__keyframeContainer">
                                {this.renderKeyframes()}
                                <TooltipButton
                                    className="AnimationPanel__addKeyframeButton"
                                    icon={<AddCircle />}
                                    label={this.transitionCount === 0 ? 'Add keyframe' : ''}
                                    tooltip={this.transitionCount === 0 ? '' : 'Add keyframe'}
                                    placement="right"
                                    variant="action"
                                    quiet
                                    onClick={this.addKeyframe} />
                            </Well>
                            <div className="AnimationPanel__durations">
                                <FieldLabel
                                    className="AnimationPanel__durationLabel"
                                    label="Transition"
                                    position="left">
                                    <NumberInput
                                        className="AnimationPanel__numberInput"
                                        min={0.1}
                                        max={3}
                                        step={0.1}
                                        largeStep={1}
                                        decimalPlaces={3}
                                        value={ui.transitionDuration}
                                        onChange={this.setTransitionDuration}
                                        suffix=" s"
                                        quiet />
                                </FieldLabel>
                                <FieldLabel
                                    className="AnimationPanel__durationLabel"
                                    label="Hold"
                                    position="left">
                                    <NumberInput
                                        className="AnimationPanel__numberInput"
                                        min={0}
                                        max={3}
                                        step={0.1}
                                        largeStep={1}
                                        decimalPlaces={3}
                                        value={ui.holdDuration}
                                        onChange={this.setHoldDuration}
                                        suffix=" s"
                                        quiet />
                                </FieldLabel>
                            </div>
                        </div>
                    </div>
                }

                <div className="AnimationPanel__playback">
                    <Heading variant="subtitle2">
                        ANIMATION
                    </Heading>
                    {this.transitionCount > 0 &&
                        this.renderPlaybackControls()
                    }
                    {this.transitionCount === 0 &&
                        <i className="AnimationPanel__addKeyframesMessage">
                            Add keyframes to create an animation
                        </i>
                    }
                </div>
            </div>
        );
    }

    private renderPlaybackControls() {
        const { ui } = this.props.store;
        return (
            <div className="AnimationPanel__playbackContainer">
                <div className="AnimationPanel__playbackControlsRow">
                    <Button variant="action" quiet aria-label="Play backward" icon={<Play />}
                        style={{ transform: 'rotate(180deg)' }}
                        disabled={!this.canPlayBackward}
                        onClick={this.playBackward} />
                    <Button variant="action" quiet aria-label="Step backward"
                        icon={<StepBackward />}
                        disabled={!this.canPlayBackward}
                        onClick={this.stepBackward} />
                    <Button variant="action" quiet aria-label="Pause" icon={<Pause />}
                        disabled={!this.isAnimating}
                        onClick={this.pause} />
                    <Button variant="action" quiet aria-label="Step forward" icon={<StepForward />}
                        disabled={!this.canPlayForward}
                        onClick={this.stepForward} />
                    <Button variant="action" quiet aria-label="Play forward" icon={<Play />}
                        disabled={!this.canPlayForward}
                        onClick={this.playForward} />
                </div>
                <div className="AnimationPanel__timelineRow">
                    <Slider
                        className="AnimationPanel__timelineSlider"
                        min={0}
                        max={1}
                        step={0.01}
                        filled
                        value={ui.animationProgress}
                        onChange={this.setProgress}
                        onChangeEnd={this.endSetProgress} />
                    <span>{Math.round(this.totalDuration * 1000) / 1000} s</span>
                </div>
            </div>
        );
    }

    private renderKeyframes() {
        return this.props.store.geometry.foldingSteps.map((foldingStep, i) =>
            <AnimationKeyframe
                key={i}
                index={i}
                snapshotUrl={foldingStep.snapshotUrl}
                selected={i === this.selectedStep}
                selectKeyframe={this.selectKeyframe}
                updateKeyframe={this.updateKeyframe}
                removeKeyframe={this.removeKeyframe} />
        );
    }

    @action.bound
    private selectKeyframe(index: number) {
        this.selectKeyframeInternal(index);
        Analytics.event(EventName.AnimationKeyframeSelect, { keyframeCount: this.stepCount });
    }

    private selectKeyframeInternal(index: number) {
        this.stopAnimation(false);

        const { ui } = this.props.store;
        const time = index * (ui.transitionDuration + ui.holdDuration);
        const { totalDuration } = this;
        this.setProgress(totalDuration === 0 ? 0 : Math.min(time / totalDuration, 1));

        // If any of the fold angles are null (unconstrained), first set all fold angles to
        // numeric values, then set unconstrained fold angles to null.
        const { geometry } = this.props.store;
        const foldingStep = geometry.foldingSteps[index];
        if (foldingStep.foldAngles.some(angle => angle === null)) {
            const nonNullAngles = foldingStep.foldAngles.map((angle, i) =>
                angle === null ? foldingStep.dihedralAngles[i] : angle);
            geometry.setAllFoldAngles(nonNullAngles)
            setTimeout(() => {
                geometry.setAllFoldAngles(foldingStep.foldAngles)
            }, 0);
        } else {
            geometry.setAllFoldAngles(foldingStep.foldAngles)
        }
    }

    @action.bound
    private addKeyframe() {
        const foldingStep = this.captureFoldingStep();
        const { geometry, ui } = this.props.store;
        geometry.foldingSteps.push(foldingStep);
        ui.animationProgress = 1;
        Analytics.event(EventName.AnimationKeyframeAdd, { keyframeCount: this.stepCount });
    }

    @action.bound
    private updateKeyframe(index: number) {
        const foldingStep = this.captureFoldingStep();
        this.props.store.geometry.foldingSteps.splice(index, 1, foldingStep);
        Analytics.event(EventName.AnimationKeyframeUpdate, { keyframeCount: this.stepCount });
    }

    @action.bound
    private removeKeyframe(index: number) {
        this.props.store.geometry.foldingSteps.splice(index, 1);
        if (this.stepCount > 0) {
            this.selectKeyframeInternal(Math.min(index, this.stepCount - 1));
        }
        Analytics.event(EventName.AnimationKeyframeRemove, { keyframeCount: this.stepCount });
    }

    @action.bound
    private toggleShowKeyframes() {
        const { ui } = this.props.store;
        ui.showKeyframes = !ui.showKeyframes;
    }

    @action.bound
    private setTransitionDuration(value: number | undefined) {
        if (value !== undefined) {
            this.props.store.ui.transitionDuration = value;
            Analytics.event(EventName.AnimationTransitionType);
        }
    }

    @action.bound
    private setHoldDuration(value: number | undefined) {
        if (value !== undefined) {
            this.props.store.ui.holdDuration = value;
            Analytics.event(EventName.AnimationHoldType);
        }
    }

    private captureFoldingStep(): FoldingStep {
        const foldAngles: FoldAngle[] = [];
        const dihedralAngles: number[] = [];
        const { render } = this.props.store;
        render.crease.edges.forEach((edge, i) => {
            let angle = edge.foldAngle;
            let dihedral = 0;
            if (edge.isCrease) {
                if (angle === null) {
                    // Calculate the average dihedral angle of the triangle mesh edges
                    // corresponding to this crease edge.
                    let sum = 0;
                    let count = 0;
                    const meshEdges = render.solver.triangleMesh.edgesForwardMapping[i];
                    meshEdges.forEach(meshEdge => {
                        const dihedral = render.solver.dihedrals[meshEdge];
                        if (dihedral !== undefined) {
                            sum += dihedral;
                            count++;
                        }
                    });
                    const roundoff = 1e10;
                    dihedral = count > 0
                        ? Math.round(utils.convertToDegrees(sum / count) * roundoff) / roundoff : 0;
                } else if (edge.assignment === 'V') {
                    angle = -angle;
                }
            }
            foldAngles.push(angle);
            dihedralAngles.push(dihedral);
        });
        const snapshotUrl = render.renderSnapshot(false, SNAPSHOT_MARGIN);
        return { foldAngles, dihedralAngles, snapshotUrl };
    }

    @action.bound
    private playBackward() {
        this.playAnimation(-this.transitionCount);
    }

    @action.bound
    private stepBackward() {
        this.playAnimation(-1);
    }

    @action.bound
    private stepForward() {
        this.playAnimation(1);
    }

    @action.bound
    private playForward() {
        this.playAnimation(this.transitionCount);
    }

    @action.bound
    private pause() {
        this.stopAnimation();
        Analytics.event(EventName.AnimationPause);
    }
    
    private stopAnimation(selectKeyframe: boolean = true) {
        if (this.isAnimating) {
            this.isAnimating = false;
            if (this.animationFrameRequest !== undefined) {
                window.cancelAnimationFrame(this.animationFrameRequest);
                this.animationFrameRequest = undefined;
            }
    
            // Select the nearest keyframe.
            if (selectKeyframe) {
                this.selectNearestKeyframe();
            }

            // Resume auto-centering after a delay.
            setTimeout(() => {
                this.props.store.render.controls3D.enableAnimatedTarget();
            }, 3000);
        }
    }

    private selectNearestKeyframe() {
        // Shift to the nearest keyframe and update the store with the current state.
        const { ui } = this.props.store;
        let { step, fraction } = this.getStepAndFraction(ui.animationProgress);
        if (fraction >= 0.5) {
            step++;
        }
        this.selectKeyframeInternal(step);
    }

    /**
     * Animates the folding sequence, starting from the current animation progress.
     * @param {Number} numberOfSteps The number of folding steps to include in the
     * animation. May be positive or negative.
     */
    private playAnimation(numberOfSteps: number) {
        this.isAnimating = true;
        const { geometry, render, selection, ui } = this.props.store;
        selection.deselectAll();
        render.controls3D.disableAnimatedTarget();
        render.solver.setFixedFace(geometry.bottomFaceIndex);
        this.startTime = Date.now() / 1000;
        this.startProgress = ui.animationProgress;
        const { step, fraction } = this.getStepAndFraction(this.startProgress);
        if (numberOfSteps > 0) {
            const endStep = step + numberOfSteps + (fraction === 0 ? 0 : 1);
            const endTime = endStep * ui.transitionDuration + (endStep - 1) * ui.holdDuration;
            this.endProgress = Math.min(endTime / this.totalDuration, 1);
        } else {
            const endStep = step + numberOfSteps + (fraction === 1 ? 1 : 0);
            const endTime = endStep * (ui.transitionDuration + ui.holdDuration);
            this.endProgress = Math.max(0, endTime / this.totalDuration);
        }
        this.speed = Math.sign(numberOfSteps) / this.totalDuration;
        if (this.animationFrameRequest === undefined) {
            this.animationFrameRequest = window.requestAnimationFrame(this.renderAnimationFrame);
        }
        Analytics.event(EventName.AnimationPlay, {
            keyframeCount: this.stepCount,
            stepCount: numberOfSteps,
        });
    }

    @action.bound
    private renderAnimationFrame() {
        const currentTime = Date.now() / 1000;
        const elapsedTime = currentTime - this.startTime; // in seconds
        const progress = Math.max(0, Math.min(this.startProgress + elapsedTime * this.speed, 1));
        const isDone = (this.speed > 0 && progress >= this.endProgress)
            || (this.speed < 0 && progress <= this.endProgress);
        const clampedProgress = isDone ? this.endProgress : progress;
        this.applyProgressChange(clampedProgress);
        if (isDone || !this.isAnimating) {
            this.animationFrameRequest = undefined;
            this.stopAnimation();
        } else {
            this.animationFrameRequest = window.requestAnimationFrame(this.renderAnimationFrame);
        }
    }

    @action.bound
    private setProgress(value: number | null) {
        this.stopAnimation(false);
        this.applyProgressChange(value!);
    }

    @action.bound
    private endSetProgress() {
        this.selectNearestKeyframe();
        Analytics.event(EventName.AnimationScrub);
    }

    private applyProgressChange(progress: number) {
        const { geometry, render, ui } = this.props.store;
        ui.animationProgress = progress;

        if (this.totalDuration > 0) {
            const { step, fraction } = this.getStepAndFraction(progress);
            const smoothstep = (x: number) => x * x * (3 - 2 * x);
            const t = smoothstep(fraction);
            const previousAngles = geometry.foldingSteps[step].foldAngles;
            const previousDihedrals = geometry.foldingSteps[step].dihedralAngles;
            const nextAngles = geometry.foldingSteps[step + 1].foldAngles;
            const nextDihedrals = geometry.foldingSteps[step + 1].dihedralAngles;
            const foldAngles = nextAngles.map((nextAngle, i) => {
                const previousAngle = previousAngles[i];
                const previousAngleNumber = previousAngle === null ? previousDihedrals[i] as number
                    : previousAngle;
                const nextAngleNumber = nextAngle === null ? nextDihedrals[i] as number : nextAngle;
                return (1 - t) * previousAngleNumber + t * nextAngleNumber;
            });
            render.solver.setTargetCreaseAngles(foldAngles);
        }
    }

    private getStepAndFraction(progress: number) {
        const { ui } = this.props.store;
        const { transitionDuration, holdDuration } = ui;
        const { transitionCount, totalDuration } = this;
        const stepDuration = transitionDuration + holdDuration;
        const time = progress * totalDuration;
        const step = Math.min(Math.floor(time / stepDuration), transitionCount - 1);
        const stepTime = time - step * stepDuration;
        const fraction = Math.min(stepTime / transitionDuration, 1);
        return { step, fraction };
    }
}

interface AnimationKeyframeProps {
    index: number;
    snapshotUrl: string;
    selected?: boolean;
    selectKeyframe: (index: number) => void;
    updateKeyframe: (index: number) => void;
    removeKeyframe: (index: number) => void;
}

class AnimationKeyframe extends Component<AnimationKeyframeProps> {
    render() {
        const { snapshotUrl, selected } = this.props;
        return (
            <div className="AnimationKeyframe">
                <Button
                    className="AnimationKeyframe__button"
                    style={{ backgroundImage: `url(${snapshotUrl})` }}
                    selected={selected}
                    variant="action"
                    onClick={this.selectKeyframe} />
                {selected &&
                    <div className="AnimationKeyframe__actions">
                        <TooltipButton
                            className="AnimationKeyframe__actionButton"
                            icon={<Refresh size="XS" />}
                            tooltip="Update keyframe"
                            placement="bottom"
                            variant="action"
                            quiet
                            onClick={this.updateKeyframe} />
                        <TooltipButton
                            className="AnimationKeyframe__actionButton"
                            icon={<Delete size="XS" />}
                            tooltip="Delete keyframe"
                            placement="bottom"
                            variant="action"
                            quiet
                            onClick={this.removeKeyframe} />
                    </div>
                }
            </div>
        );
    }

    @action.bound
    private selectKeyframe() {
        this.props.selectKeyframe(this.props.index);
    }

    @action.bound
    private updateKeyframe() {
        this.props.updateKeyframe(this.props.index);
    }

    @action.bound
    private removeKeyframe() {
        this.props.removeKeyframe(this.props.index);
    }
}
