import ButtonGroup from '@react/react-spectrum/ButtonGroup';
import Rule from '@react/react-spectrum/Rule';
import { action } from 'mobx';
import React, { Component, ReactElement, ReactNode } from 'react';
import { KeyboardShortcut } from './KeyboardShortcut';
import { TooltipButton } from './TooltipButton';
import { TooltipContent } from './TooltipContent';

/** Properties of the Toolbar component. */
export interface ToolbarProps {
    /** The ID of the active tool. */
    activeToolId: string;

    /** The event handler to call when the user selects a tool. */
    setActiveToolId: (activeToolId: string) => void;
}

/**
 * Displays a vertical list of mutually-exclusive tool buttons.
 *
 * Example:
 * 
 *      <Toolbar
 *          activeToolId={this.activeToolId}
 *          setActiveToolId={this.setActiveToolId}>
 *          <ToolbarButton
 *              toolId="select"
 *              icon={<Select />}
 *              name="Select Tool"
 *              shortcut="V"
 *              description="Click to select an object."
 *          />
 *          <ToolbarDivider />
 *          <ToolbarButton
 *              toolId="rectangle"
 *              icon={<Rectangle />}
 *              name="Rectangle Tool"
 *              shortcut="R"
 *              description="Click and drag to create a rectangle."
 *          />
 *      </Toolbar>
 */
export class Toolbar extends Component<ToolbarProps> {
    render() {
        const { activeToolId, setActiveToolId } = this.props;
        return (
            <div className="Toolbar">
                <ButtonGroup
                    className="Toolbar--buttonGroup"
                    orientation="vertical" 
                    value={activeToolId}
                    onChange={this.setActiveToolId}>
                    {React.Children.map(this.props.children, child => {
                        if (React.isValidElement(child)) {
                            const element = child as ReactElement;
                            const newProps = {
                                value: element.props.toolId,
                                setActiveToolId,
                            };
                            return React.cloneElement(element, newProps);
                        }
                        return child;
                    })}
                </ButtonGroup>
            </div>
        );
    }

    @action.bound
    private setActiveToolId(toolId: number | string | (number | string)[]) {
        // The value of a ButtonGroup may be a number, a string, or an array of numbers or strings.
        // In our case, we know it's definitely a string, so we cast it.
        this.props.setActiveToolId(toolId as string);
    }
}

/** Properties of the ToolbarButton component. */
export interface ToolbarButtonProps {
    toolId: string;
    icon: ReactElement;
    name: string;
    shortcut?: string;
    additionalAccess?: ReactNode;
    description?: string;
}

interface ToolbarInternalProps {
    setActiveToolId: (activeToolId: string) => void;
}

/** Displays a tool button within a Toolbar and sets up a keyboard shortcut for it. */
export class ToolbarButton extends Component<ToolbarButtonProps> {
    render() {
        const {
            toolId,
            name,
            shortcut,
            additionalAccess,
            description,
            setActiveToolId,
            ...otherProps
        } = this.props as ToolbarButtonProps & ToolbarInternalProps;
        const tooltipContentProps = {
            heading: name,
            shortcut,
            additionalAccess,
            description,
        };
        return (
            <TooltipButton
                {...otherProps}
                variant="tool"
                tooltip={<TooltipContent {...tooltipContentProps} />}
                placement="right"
                tooltipVariant="info">
                {shortcut &&
                    <KeyboardShortcut
                        keys={shortcut}
                        onPressed={setActiveToolId}
                        arg={toolId} />
                }
            </TooltipButton>
        );
    }
}

/** Displays a divider within a Toolbar. */
export class ToolbarDivider extends Component {
    render() {
        return (
            <TooltipButton style={{ height: 'auto' }} {...this.props} disabled>
                <Rule variant="small" />
            </TooltipButton>
        );
    }
}
