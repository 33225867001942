import { action, observable } from "mobx";

export enum Theme {
    Dark = 'dark',
    Light = 'light',
}

export class SettingsStore {
    @observable
    theme: Theme = Theme.Dark;

    @action.bound
    toggleTheme() {
        this.theme = this.theme === Theme.Dark ? Theme.Light : Theme.Dark;
    }
}
