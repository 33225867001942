import { boundMethod } from 'autobind-decorator';
import { Bounds2, CreaseVertex, geom, Vector2 } from 'crease';
import { IReactionDisposer, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import * as THREE from 'three';
import { AppMode } from '../common/AppMode';
import { SHADOW_INTENSITY } from '../common/constants';
import { utils } from '../common/utils';
import { MessageDialog } from '../components/MessageDialog';
import '../css/View3D.css';
import { Side } from '../store/InfoStore';
import { StoreComponent, StoreProps } from '../UI/StoreComponent';
import { EdgeTubes } from './EdgeTubes';
import { MeshSurface } from './MeshSurface';
import { PlasticWindows } from './PlasticWindows';

@inject('store')
@observer
export class View3D extends StoreComponent {
    // Keep ref to container element.
    private container = React.createRef<HTMLDivElement>();
    private ground = new THREE.Group();
    private shadowLight = new THREE.DirectionalLight(0xffffff, 0.1);
    private disposers: IReactionDisposer[] = [];

    constructor(props: StoreProps) {
        super(props);

        // Init scenes and renderer.
        // Do this only once in the lifetime of this object.

        // Light casting shadows across scene.
        this.shadowLight.castShadow = true;
        this.shadowLight.position.set(0.5, -0.5, 1);
        this.shadowLight.shadow.camera.near = 0.1;
        this.shadowLight.shadow.camera.far = 25;
        this.shadowLight.shadow.camera.left = -1;
        this.shadowLight.shadow.camera.right = 1;
        this.shadowLight.shadow.camera.top = 1;
        this.shadowLight.shadow.camera.bottom = -1;
        this.shadowLight.shadow.mapSize.height = 2048;
        this.shadowLight.shadow.mapSize.width = 2048;

        // Add lights.
        const { render } = this.props.store;
        render.scene.add(this.makeLight(0xffffff, 0.2, new THREE.Vector3(-2, -1, 2)));
        render.scene.add(new THREE.AmbientLight(0xffffff, 0.9));

        // Add ground plane.
        const gridDim = 10;
        const groundGrid = new THREE.GridHelper(gridDim, 100);
        const groundGridMaterial = groundGrid.material as THREE.LineBasicMaterial;
        groundGridMaterial.vertexColors = false;
        const groundPlaneGeometry = new THREE.PlaneBufferGeometry(gridDim, gridDim);
        const shadowPlane = new THREE.Mesh(groundPlaneGeometry,
            new THREE.ShadowMaterial({
                polygonOffset: true,
                polygonOffsetFactor: 1,
                fog: false,
                opacity: SHADOW_INTENSITY,
                // side: THREE.DoubleSide,
                transparent: true,
            }));
        shadowPlane.receiveShadow = true;
        const groundPlane = new THREE.Mesh(groundPlaneGeometry,
            new THREE.MeshBasicMaterial({
                polygonOffset: true,
                polygonOffsetFactor: 1,
                opacity: 0.75,
                side: THREE.DoubleSide,
                transparent: true,
            }));
        groundGrid.rotateX(Math.PI/2);
        this.ground.add(groundGrid);
        this.ground.add(groundPlane);
        this.ground.add(shadowPlane);
        render.scene.add(this.ground);

        // Add fog.
        render.scene.fog = new THREE.FogExp2(0x000000, 0.4);

        // Add warning modal for touch events.
        render.controls3D.addEventListener('touchend', this.showTouchscreenWarning);

        // Set up color bindings.
        render.colorBinder.bind(render.backgroundScene, 'background', '--color-3D-background');
        render.colorBinder.bind(render.scene.fog, 'color', '--color-3D-background');
        render.colorBinder.bind(groundPlane.material, 'color', '--color-3D-background');
        render.colorBinder.bind(groundGrid.material, 'color', '--color-3D-grid');
    }

    @boundMethod
    private showTouchscreenWarning() {
        MessageDialog.show("Warning", 'Touchscreens are not fully supported in this app.');
        const { render } = this.props.store;
        render.controls3D.removeEventListener('touchend', this.showTouchscreenWarning);
    }

    componentDidMount() {
        const { info, geometry, preferences, render, ui } = this.props.store;
        if (this.container.current) {
            // Add threejs canvas to dom.
            this.container.current.appendChild(render.renderer.domElement);
            render.colorBinder.update();
        }
        // Update camera and renderer aspect/view sizes.
        this.handleWindowResize();
        // Start animation loop.
        this.animate();
        // Handle resize events.
        window.addEventListener('resize', this.handleWindowResize);

        // Update if app mode changed.
        this.addReaction(() => ui.appMode,
            (appMode) => {
                // Update ground plane visibility.
                this.ground.visible = appMode === AppMode.Preview;
                this.handleWindowResize();
                render.forceRenderFlag = true;
            },
        );

        // Update color bindings if the theme changed.
        this.addReaction(() => preferences.theme,
            () => {
                // Wait a cycle so the CSS properties have a chance to update.
                setTimeout(() => {
                    render.colorBinder.update();
                    render.forceRenderFlag = true;
                }, 0);
            }
        );

        // Update if active tool changed.
        this.addReaction(() => ui.activeToolId,
            () =>{
                render.forceRenderFlag = true;
            },
        );

        // Update solver.
        this.addReaction(() => geometry.creaseJSON,
            () => {
                const angles = this.getTargetFoldAngles();
                render.solver.setTargetCreaseAngles(angles);
                render.forceRenderFlag = true;
            },
        );

        // Update artwork.
        this.addReaction(() => ({
                exterior: this.props.store.getTexture(Side.Exterior),
                interior: this.props.store.getTexture(Side.Interior),
            }),
            ({ exterior, interior }) => {
                render.thickenedMesh.setArtwork(exterior, interior);
                render.forceRenderFlag = true;
            },
        );

        // Update substrate.
        this.addReaction(() => ({
                substrate: info.substrate,
                creaseBounds: geometry.creaseBounds,
            }),
            ({ substrate, creaseBounds }) => {
                const edgeTexture = substrate ? substrate.getEdgeTexture(creaseBounds) : null;
                render.thickenedMesh.updateSubstrateTexture(edgeTexture);
                render.forceRenderFlag = true;
            },
        );

        // Update thickness.
        this.addReaction(() => this.props.store.substrateThickness,
            (substrateThickness) => {
                this.ground.position.z = -substrateThickness / 2;
                render.forceUpdatePositions = true;
                render.forceRenderFlag = true;
            },
        );

        // Update substrate orientation.
        this.addReaction(() => this.props.store.info.isSubstrateHorizontal,
            () => {
                render.forceUpdatePositions = true;
                render.forceRenderFlag = true;
            },
        );

        // Update panel order.
        this.addReaction(() => ({
                overlappingGroups: geometry.overlappingGroups,
                creaseJSON: geometry.creaseJSON,
            }),
            ({ overlappingGroups, creaseJSON }) => {
                const panelOrderData = render.thinMesh.panelOrderData;
                // Sort overlapping groups by this.props.panelOrder.
                creaseJSON.faces.forEach((_, faceIndex) => {
                    panelOrderData[3*100*(2*faceIndex)] = 0;
                    panelOrderData[3*100*(2*faceIndex + 1)] = 0;
                });
                overlappingGroups.forEach(sortedGroup => {
                    sortedGroup.forEach((faceIndex, i) => {
                        panelOrderData[3*100*(2*faceIndex)] = sortedGroup.length - i - 1;
                        for (let j = i+1; j < sortedGroup.length; j++) {
                            panelOrderData[3*(100*(2*faceIndex) + j - i)] = sortedGroup[j];
                        }
                        panelOrderData[3*100*(2*faceIndex + 1)] = i;
                        for (let j = 0; j < i; j++) {
                            panelOrderData[3*(100*(2*faceIndex + 1) + j + 1)] = sortedGroup[j];
                        }
                    });
                })
                render.thinMesh.panelOrderDidChange();
                render.forceUpdatePositions = true;
                render.forceRenderFlag = true;
            },
        );

        // Preview Mode.
        this.addReaction(() => ({
                appMode: ui.appMode,
                bottomFaceIndex: geometry.bottomFaceIndex,
                triangleMesh: geometry.triangleMesh,
            }),
            ({ appMode, bottomFaceIndex, triangleMesh }) => {
                if (appMode === AppMode.Preview) {
                    render.orientModelOnGroundPlane(bottomFaceIndex, triangleMesh);
                }
                render.thinMesh.setPreviewMode(appMode === AppMode.Preview);
                render.thickenedMesh.setPreviewMode(appMode === AppMode.Preview);
                render.controls.constrainOrbit = appMode === AppMode.Preview;
                if (appMode === AppMode.Preview) {
                    render.scene.add(this.shadowLight);
                    // render.resetCamera();
                    render.camera.up.set(0, 0, 1);
                    render.camera.updateProjectionMatrix();
                    render.controls3D.animateToTarget(MeshSurface.getCenter());
                } else {
                    render.scene.remove(this.shadowLight);
                }
                render.forceRenderFlag = true;
            },
        );
    }

    // Collect reaction disposers so we can dispose them when this component is unmounted.
    private addReaction<Data>(expression: () => Data, effect: (data: Data) => void) {
        this.disposers.push(reaction(expression, effect));
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        this.disposers.forEach(disposer => disposer());
        this.disposers = [];
    }

    @boundMethod
    private handleWindowResize() {
        if (!this.container.current) {
            return;
        }
        // Get container dimensions and use them for scene sizing.
        const width = this.container.current.clientWidth;
        const height  = this.container.current.clientHeight;
        // Handle window resize needed in case home page was scrolling (there is a weird offset due to scroll bar presence).
        this.props.store.render.handleWindowResize(width, height);
    }

    private makeLight(color: number, intensity: number, position: THREE.Vector3) {
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.copy(position);
        return light;
    }

    private getTargetFoldAngles() {
        const edges = this.props.store.geometry.creaseJSON.edges;
        return edges.map((edge) => {
            if (edge.foldAngle === undefined || edge.assignment === 'B' || edge.assignment === 'U') {
                return null;
            }
            let angle = edge.foldAngle;
            angle *= edge.assignment === 'V' ? -1 : 1;
            return angle;
        });
    }

    private calculateOverlappingGroups() {
        const { geometry, render } = this.props.store;
        const { triangleMesh } = geometry;
        const { crease, faceOffsetter } = render;
        const faceNormals = faceOffsetter.normals(render.solver.normals);
        const positionsThinMesh = faceOffsetter.positions(render.solver.positions);

        const COPLANARITY_TOL = 0.001;
        const NORMAL_TOL = 0.9999;
        const OVERLAPPING_RAYCAST_TOL = 0.000001;

        // First compute positions, and normals for all faces.
        const normals = crease.faces.map((face) => {
            const triangles = triangleMesh.facesForwardMapping[face.index];
            const avgNormal = new THREE.Vector3();
            // Avg up to ten triangles to get face normal.
            for (let i = 0; i < 10; i++) {
                if (i === triangles.length) {
                    break;
                }
                avgNormal.add(new THREE.Vector3().fromArray(faceNormals[triangles[i]]));
            }
            return avgNormal.normalize();
        });

        // Get a positions on outer loop.
        const positions = crease.faces.map((face) => {
            const vertex = face.vertexLoops[0][0];
            return new THREE.Vector3().fromArray(positionsThinMesh[triangleMesh.verticesForwardMapping[vertex.index][0]]);
        });

        // Find groups that are coplanar.  Order[(num of faces)^2]
        const coplanarGroups: number[][] = [];
        const added = crease.faces.map(() => false);
        for (let i = 0; i < crease.faces.length; i++) {
            if (added[i]) {
                continue;
            }
            const group = [i];
            for (let j = i + 1; j < crease.faces.length; j++) {
                // Check that normals are aligned (opp direction is ok).
                if (Math.abs(normals[i].dot(normals[j])) < NORMAL_TOL) {
                    continue;
                }
                // Check coplanarity.
                const vector = positions[j].clone().sub(positions[i]);
                if (Math.abs(normals[i].dot(vector)) > COPLANARITY_TOL) {
                    continue;
                }
                group.push(j);
                added[j] = true;
            }
            if (group.length > 1) {
                coplanarGroups.push(group);
            }
        }

        // Check within each group for overlapping faces.
        // Objects to use for computations.
        const zAxis = new THREE.Vector3(0, 0, 1);
        const q = new THREE.Quaternion();
        const p = new THREE.Vector3();

        const overlappingGroups: number[][] = [];
        coplanarGroups.forEach((group) => {
            const overlappingFaces = [];

            // Precompute some params for this group.
            const groupBounds: Bounds2[] = [];
            const groupVertices: CreaseVertex[][] = [];
            const groupPoints2D: THREE.Vector2[][] = [];
            const groupPoints2DArray: Vector2[][] = [];
            q.setFromUnitVectors(normals[group[0]], zAxis);
            for (let i = 0; i < group.length; i++) {
                const faceI = crease.faces[group[i]];
                const boundingVerticesI = faceI.vertexLoops[0];
                groupVertices.push(boundingVerticesI);
                const boundingPointsI = boundingVerticesI.map((vertex) => {
                    p.fromArray(positionsThinMesh[triangleMesh.verticesForwardMapping[vertex.index][0]]);
                    p.applyQuaternion(q);
                    return new THREE.Vector2(p.x, p.y);
                });
                groupPoints2D.push(boundingPointsI);
                groupBounds.push(geom.getBounds(boundingPointsI.map(el => el.toArray())) as Bounds2);
                groupPoints2DArray.push(boundingPointsI.map(pt => pt.toArray() as Vector2));
            }

            for (let i = 0; i < group.length; i++) {
                const faceIBounds2D = groupBounds[i];
                const boundingVerticesI = groupVertices[i];
                const boundingPointsI = groupPoints2D[i];
                const boundingPointsIArray = groupPoints2DArray[i];
                const faceNormalI = normals[group[i]];
                const adjacentFacesI = crease.faces[group[i]].incidentFaces;
                for (let j = i + 1; j < group.length; j++) {
                    // Check if these faces have already been added.
                    if (overlappingFaces.indexOf(group[i]) >= 0 && overlappingFaces.indexOf(group[j]) >= 0) {
                        continue;
                    }

                    let intersection = false;
                    // check if faces are adjacent.
                    if (adjacentFacesI.indexOf(crease.faces[group[j]]) >= 0) {
                        const faceNormalJ = normals[group[j]];
                        if (faceNormalI.dot(faceNormalJ) > 0) {
                            // If normals are aligned, these faces are not overlapping.
                            continue;
                        } else {
                            // If normals are not aligned, then they are opposite.
                            // This means the crease between the faces is folded to 180.
                            intersection = true;
                        }
                    }
                    
                    if (!intersection) {
                        // Check for overlapping bounds of boundingPointsI and boundingPointsJ.
                        // This could still yield some false positives for overlapping test.
                        const faceJBounds2D = groupBounds[j];
                        if (!geom.doBoundsIntersect(faceIBounds2D, faceJBounds2D, 0)) {
                            continue;
                        }

                        // Next do (more expensive) raycasting test.
                        const boundingVerticesJ = groupVertices[j];
                        const boundingPointsJ = groupPoints2D[j];
                        const boundingPointsJArray = groupPoints2DArray[j];
                        // Check if any of boundingVerticesJ are inside boundingVerticesI.
                        for (let vertexIndexJ = 0; vertexIndexJ < boundingVerticesJ.length; vertexIndexJ++) {
                            const vertexJ = boundingVerticesJ[vertexIndexJ];
                            // Check if points are shared between two faces.
                            if (boundingVerticesI.indexOf(vertexJ) >= 0) {
                                continue;
                            }
                            // Check if positionJ is in bounds of faceI.
                            const positionJArray = boundingPointsJArray[vertexIndexJ];
                            if (!geom.isPointInsideBounds(positionJArray, faceIBounds2D)) {
                                continue;
                            }
                            const positionJ = boundingPointsJ[vertexIndexJ];
                            // Ignore points that are very close to each other (raycasting may create errors).
                            let badPoint = false;
                            for (let vertexIndexI = 0; vertexIndexI < boundingVerticesI.length; vertexIndexI++) {
                                const positionI = boundingPointsI[vertexIndexI];
                                if (positionI.clone().sub(positionJ).lengthSq() < OVERLAPPING_RAYCAST_TOL) {
                                    badPoint = true;
                                    break;
                                }
                            }
                            if (badPoint) {
                                continue;
                            }
                            // Check if any points on the boundary of face J are inside face I.
                            if (geom.isPointInsidePolygon(positionJArray, boundingPointsIArray)) {
                                intersection = true;
                                break;
                            }
                        }
                        // Check if any of boundingVerticesJ are inside boundingVerticesI.
                        if (!intersection) {
                            for (let vertexIndexI = 0; vertexIndexI < boundingVerticesI.length; vertexIndexI++) {
                                const vertexI = boundingVerticesI[vertexIndexI];
                                // Check if points are shared between two faces.
                                if (boundingVerticesJ.indexOf(vertexI) >= 0) {
                                    continue;
                                }
                                // Check if positionI is in bounds of faceJ.
                                const positionIArray = boundingPointsIArray[vertexIndexI];
                                if (!geom.isPointInsideBounds(positionIArray, faceJBounds2D)) {
                                    continue;
                                }
                                const positionI = boundingPointsI[vertexIndexI];
                                // Ignore points that are very close to each other (raycasting may create errors).
                                let badPoint = false;
                                for (let vertexIndexJ = 0; vertexIndexJ < boundingVerticesJ.length; vertexIndexJ++) {
                                    const positionJ = boundingPointsJ[vertexIndexJ];
                                    if (positionJ.clone().sub(positionI).lengthSq() < OVERLAPPING_RAYCAST_TOL) {
                                        badPoint = true;
                                        break;
                                    }
                                }
                                if (badPoint) {
                                    continue;
                                }
                                // Check if any points on the boundary of face I are inside face J.
                                if (geom.isPointInsidePolygon(positionIArray, boundingPointsJArray)) {
                                    intersection = true;
                                    break;
                                }
                            }
                        }
                    }
                    // If intersection, add to overlapping faces.
                    if (intersection) {
                        if (overlappingFaces.indexOf(group[i]) < 0) {
                            overlappingFaces.push(group[i]);
                        }
                        if (overlappingFaces.indexOf(group[j]) < 0) {
                            overlappingFaces.push(group[j]);
                        }
                    }
                }
            }
            if (overlappingFaces.length) {
                overlappingGroups.push(overlappingFaces);
            }
        });
        // Check if groups have changed.
        let changed = false;
        if (geometry.overlappingGroups.length === overlappingGroups.length) {
            // Make a shallow copy of previous array so we can remove already matched groups.
            const previousOverlappingGroups = geometry.overlappingGroups.slice();
            for (let i = 0; i < overlappingGroups.length; i++) {
                let matchFound = false;
                const groupI = overlappingGroups[i];
                for (let j = 0; j < previousOverlappingGroups.length; j++) {
                    const groupJ = previousOverlappingGroups[j];
                    if (utils.arraysHaveSameElements(groupI, groupJ)) {
                        previousOverlappingGroups.splice(j, 1); // Remove match from future tests.
                        matchFound = true;
                        break;
                    }
                }
                if (!matchFound) {
                    changed = true;
                    break;
                }
            }
        } else {
            changed = true;
        }
        if (changed) {
            geometry.overlappingGroups = overlappingGroups;
        }
    }

    @boundMethod
    private animate() {
        window.requestAnimationFrame(this.animate);
        const { geometry, info, render, selection, ui, substrateThickness } = this.props.store;
        if (ui.appMode === AppMode.Home) {
            return;
        }

        const { showFlat } = ui;
        let solverDidUpdate = false;
        if (!showFlat) {
            if (render.solver.isInitialized) {
                solverDidUpdate = render.solver.step();
            }
        }

        // Calculate overlapping groups.
        if (solverDidUpdate) {
            this.calculateOverlappingGroups();
        }

        // Update positions in cases of substrate thickenss or panel ordering changes.
        // Force update positions happens in cases of eg show flat state, updte substrate/thickness, rotate to bottom face.
        if (solverDidUpdate || render.forceUpdatePositions) {
            // Only apply enough offsetting to prevent z fighting - more will make the edge tube cliping look weird.
            const { triangleMesh, overlappingGroups } = geometry;
            const positions = render.faceOffsetter.offsetPositions(triangleMesh, showFlat,
                render.solver, overlappingGroups);
            EdgeTubes.updatePositions(positions);
            MeshSurface.updatePositions(positions);
            // TODO: use faceoffsetter positions here.
            PlasticWindows.updatePositions(render.solver.positions);
            render.faceOffsetter.updatePositions();
            render.thickenedMesh.updatePositions(substrateThickness, info.isSubstrateHorizontal,
                showFlat, overlappingGroups);
            render.forceUpdatePositions = false;
            render.forceRenderFlag = true;
        }

        if (render.controls) {
            if (selection.selectedEdges.length === 0 && this.props.store.preferences.enableAutoCentering) {
                // Center camera on mesh center
                render.controls.animateToTarget(MeshSurface.getCenter());
            }
            render.controls.update();
        }

        // Force render flag happens in cases of eg controls update, preview mode change, tools state change, etc.
        if (solverDidUpdate || render.forceRenderFlag) {
            if (showFlat) {
                render.renderWireFrame();
            } else {
                render.render3D();
            }
            render.forceRenderFlag = false;
        }
    };

    render() {
        return (
            <div id='View3DContainer' ref={this.container} />
        );
    }
}
