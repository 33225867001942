import { boundMethod } from 'autobind-decorator';
import { TriangleMesh } from 'crease';
import { inject, observer } from 'mobx-react';
import * as THREE from 'three';
import { EDGE_HOVER_THICKNESS } from '../common/constants';
import { utils } from '../common/utils';
import { Analytics, EventName } from '../services/Analytics';
import { EdgeTubes } from '../view3D/EdgeTubes';
import { EdgeTubesRaycasting as EdgeTubesNonInstanced } from '../view3D/EdgeTubesRaycasting';
import { Modifiers, Tool } from './Tool';

export const EDGE_RAYCAST_THICKNESS = 24; // pixels

@inject('store')
@observer
export class EdgeSelectionTool extends Tool {
    private edgeHoverObject = new EdgeTubes({
        depthWrite: false,
        thickness: EDGE_HOVER_THICKNESS,
    });
    private edgeRaycastObject = new EdgeTubesNonInstanced(EDGE_RAYCAST_THICKNESS);

    toolOnActivate() {
        const { render } = this.props.store;
        render.addObject3Ds([
            this.edgeHoverObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.bind(this.edgeHoverObject, 'color', '--color-edge-hover');

        this.addAutorun(() => {
            this.setTriangleMesh(this.props.store.geometry.triangleMesh);
        });
    }

    toolOnDeactivate() {
        const { render } = this.props.store;
        render.removeObject3Ds([
            this.edgeHoverObject.getObject3D(),
        ], render.toolScene);
        render.colorBinder.unbind(this.edgeHoverObject, 'color');

        this.edgeHoverObject.dispose();
        this.edgeRaycastObject.dispose();
    }

    private getIntersectionUserData(intersections: THREE.Intersection[]) {
        // Handle no intersection case.
        if (intersections.length === 0) {
            return null;
        }
        if (!intersections[0].object || !intersections[0].object.parent) {
            return null;
        }

        // Get user data from parent.
        const { userData } = intersections[0].object.parent;
        if (userData === undefined || userData === null) {
            return null;
        }
        const { creaseEdgeIndex } = userData;
        if (creaseEdgeIndex === undefined) {
            return null;
        }
        if (this.props.store.geometry.selectableEdgeIndices.indexOf(creaseEdgeIndex) < 0) {
            return null;
        }
        return creaseEdgeIndex;
    }

    onNullEvent() {
        this.edgeHoverObject.showEdges([]);
    }

    onHoverMove(intersections: THREE.Intersection[]) {
        // For now, leave hover out of store.
        const edgeIndex = this.getIntersectionUserData(intersections);
        if (edgeIndex === null) {
            this.edgeHoverObject.showEdges([]);
        } else {
            // Only allow one edge or edge chain to be hovered at a time.
            const { render } = this.props.store;
            const chainedEdges = utils.getChainedEdges(edgeIndex, render.crease);
            this.edgeHoverObject.showEdges(chainedEdges);
        }
        return true;
    }

    onLeftClick(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers) {
        // Unhover
        this.edgeHoverObject.showEdges([]);

        const edgeIndex = this.getIntersectionUserData(intersections);
        if (edgeIndex === null) {
            // If nothing is selected and we are not holding shift, deselect all.
            if (!modifiers.shiftKey) {
                this.props.store.selection.deselectAll();
            }
            return false;
        }

        const { render } = this.props.store;
        const chainedEdges = utils.getChainedEdges(edgeIndex, render.crease);

        // Update store.
        const { selection } = this.props.store;
        const { selectedEdges } = selection;
        if (!selectedEdges.includes(edgeIndex)) {
            // If shift is held down, add to previous selection.
            if (modifiers.shiftKey) {
                selection.selectEdges(chainedEdges);
            } else {
                selection.setSelectedEdges(chainedEdges);
            }
        } else {
            // If shift is held down, remove from previous selection.
            if (modifiers.shiftKey) {
                selection.deselectEdges(chainedEdges);
            } else {
                // If this crease is already selected, then set this edge as the last seleted edge.
                // This will orient the fold angle tool around it.
                const nextSelectedEdges = selectedEdges.slice();
                chainedEdges.forEach(edgeIndex => {
                    const index = nextSelectedEdges.indexOf(edgeIndex);
                    if (index >= 0) {
                        nextSelectedEdges.splice(index, 1);
                    }
                });
                nextSelectedEdges.push(...chainedEdges);
                selection.setSelectedEdges(nextSelectedEdges);
            }
        }

        Analytics.event(EventName.EdgeSelectClick,
            { modifier: modifiers.shiftKey ? 'shift' : 'none' })
        return true;
    }

    onLeftDragStart(intersections: THREE.Intersection[], screenCoords: THREE.Vector2,
        modifiers: Modifiers) {
        // If shift is not held down, delete previous selections.
        if (!modifiers.shiftKey) {
            this.props.store.selection.deselectAll();
        }
        // Unhover any edges.
        this.edgeHoverObject.showEdges([]);
        return false;
    }

    onRightDragStart() {
        // Unhover any edges.
        this.edgeHoverObject.showEdges([]);
        return false;
    }

    onScrollDragStart() {
        // Unhover any edges.
        this.edgeHoverObject.showEdges([]);
        return false;
    }

    private setTriangleMesh(triangleMesh: TriangleMesh) {
        if (triangleMesh.vertices.length === 0) {
            return;
        }

        const { geometry, render } = this.props.store;
        this.edgeHoverObject.updateGeometry();
        this.edgeRaycastObject.setTriangleMesh(triangleMesh, render.unitsPerPixel);
        // Set not visible by default.
        this.edgeHoverObject.showEdges([]);
        // Filter raycasting targets to only edges that are creases.
        const targetIndices = geometry.selectableEdgeIndices;
        this.raycastingTargets = [...render.thinMesh.getMaskingObject3Ds(),
            ...this.edgeRaycastObject.getObject3Ds(targetIndices)];
        this.updatePositions();
    }

    @boundMethod
    protected updateScale() {
        this.edgeRaycastObject.updateScale(this.props.store.render.unitsPerPixel);
    }

    @boundMethod
    protected updatePositions() {
        // Get zero offset positions and update raycaster geo.
        const { render, ui } = this.props.store;
        const positions = render.faceOffsetter.positions(ui.showFlat
            ? render.solver.flatPositions : render.solver.positions);
        this.edgeRaycastObject.updatePositions(positions);
    }
}
