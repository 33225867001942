import { Crease, CreaseEdgeAssignment, creaseToTriangleMesh, TriangleMesh, vec } from 'crease';
import { action, computed } from 'mobx';
import { MILLIMETERS_PER_POINT, TRIANGULATION_MINIMUM_ANGLE } from '../common/constants';
import { AccountStore } from './AccountStore';
import { GeometryStore } from './GeometryStore';
import { InfoStore, Side } from './InfoStore';
import { RenderStore } from './RenderStore';
import { SelectionStore } from './SelectionStore';
import { UIStore } from './UIStore';
import { PreferencesStore } from './PreferencesStore';

export type EdgeInfo = {
    index: number,
    vertex1: number,
    vertex2: number,
    assignment?: CreaseEdgeAssignment,
    foldAngle? : number,
    rightFace: number,
    leftFace: number,
}

export class Store {
    readonly account = new AccountStore();
    readonly geometry = new GeometryStore();
    readonly info = new InfoStore();
    readonly render = new RenderStore();
    readonly selection = new SelectionStore();
    readonly ui = new UIStore();
    readonly preferences = new PreferencesStore();

    constructor() {
        // We must get the current saved preferences and use them to se the controls pan behavior on load.
        this.render.enablePan(!this.preferences.enableAutoCentering);
    }

    // --- Computed values that come from multiple stores ---

    @computed
    get lastSelectedEdge(): EdgeInfo | null {
        const { selectedEdges } = this.selection;
        if (selectedEdges.length === 0) {
            return null;
        }
        const { creaseJSON } = this.geometry;
        const index = selectedEdges[selectedEdges.length - 1];
        const { vertex1, vertex2, foldAngle, assignment} = creaseJSON.edges[index];
        const { rightFace, leftFace } = this.render.crease.edges[index];
        return {
            index,
            vertex1,
            vertex2,
            foldAngle,
            assignment,
            rightFace: rightFace ? rightFace.index : -1,
            leftFace: leftFace ? leftFace.index : -1,
        };
    }

    @computed
    get substrateThickness() {
        const { substrate } = this.info;
        if (!substrate) {
            return 0;
        }
        const { creaseBounds } = this.geometry;
        const size = vec.subtract(creaseBounds.max, creaseBounds.min);
        const scale = Math.max(size[0], size[1]);
        return substrate.thickness / scale / MILLIMETERS_PER_POINT;
    }

    getTexture(side: Side): THREE.Texture | null {
        const artwork = this.info.getArtwork(side);
        if (!artwork.hidden && artwork.texture) {
            return artwork.texture;
        } else if (this.info.substrate) {
            const creaseBounds = this.geometry.creaseBounds;
            return side === Side.Exterior
                ? this.info.substrate.getSide1Texture(creaseBounds, this.info.isSubstrateHorizontal)
                : this.info.substrate.getSide2Texture(creaseBounds, this.info.isSubstrateHorizontal);
        }
        return null;
    }

    // --- Actions that affect multiple stores ---

    @action
    loadCrease(
        filename: string,
        crease: Crease,
        dieline?: SVGSVGElement,
        triangleMesh?: TriangleMesh,
    ) {
        this.selection.deselectAll();

        // Create an offset triangle mesh.
        triangleMesh = triangleMesh || creaseToTriangleMesh(crease, TRIANGULATION_MINIMUM_ANGLE);
        const offsetTriangleMesh = this.render.faceOffsetter.offsetTriangleMesh(triangleMesh, crease);
        
        this.geometry.loadCrease(crease, offsetTriangleMesh);
        this.render.setTriangleMesh(crease, triangleMesh, offsetTriangleMesh,
            this.geometry.bottomFaceIndex);

        this.info.loadCrease(filename, this.geometry.creaseBounds, dieline);
        this.render.resetView();
        this.ui.showFlat = false;
    }

    @action
    selectAllEdges() {
        this.selection.selectEdges(this.geometry.selectableEdgeIndices);
    }

    @action
    resetDieline() {
        this.geometry.setFoldAngles(null, this.geometry.selectableEdgeIndices);
        this.render.resetDieline();
    }

    @action
    setEnableAutoCentering(state: boolean) {
        this.preferences.enableAutoCentering = state;
        this.render.enablePan(!state)
    }

    @action
    setShowFlat(state: boolean) {
        this.ui.showFlat = state;
        this.render.setShowFlat(state);
    }
}
