// @ts-ignore
import { modalManager } from '@react/react-spectrum/ModalContainer';
// @ts-ignore
import SpectrumOverlay from '@react/react-spectrum/OverlayTrigger/js/Overlay';
import React, { cloneElement, Component } from 'react';
import ReactDOM from 'react-dom';
import '../css/FoldAnglePopover.css';
import { action } from 'mobx';

/** Properties of the Overlay component. */
export interface OverlayProps {
    /** Indicates whether or not to display the overlay. */
    show: boolean;

    /**
     * Provides the className of the overlay content, so that the component can ignore mouse clicks
     * within that content rather than dismissing the overlay.
     */
    contentClassName: string;

    /**
     * The event handler to call when the user presses the Escape key or clicks outside the
     * overlay.
     */
    onClose?: () => void;
}

/**
 * Renders a single child as an overlay.
 * 
 * Implementation based on React Spectrum's OverlayTrigger:
 * https://git.corp.adobe.com/React/react-spectrum-v2/blob/master/src/OverlayTrigger/js/OverlayTrigger.js
 */
export class Overlay extends Component<OverlayProps> {
    private _mountNode: HTMLDivElement | null = null;
    private _overlay: JSX.Element | null = null;
    private _listeningForDismiss: boolean = false;

    componentDidMount() {
        this._mountNode = document.createElement('div');
        this._renderOverlay();
        this._listenForDismiss();
    }

    componentDidUpdate() {
        this._renderOverlay();
        this._listenForDismiss();
    }

    componentWillUnmount() {
        modalManager.removeFromModal(this._overlay);
        ReactDOM.unmountComponentAtNode(this._mountNode!);
        this._mountNode = null;
        this._listenForDismiss(true);
    }

    render() {
        // Remove previous overlay from modalManager.
        if (this._overlay) {
            modalManager.removeFromModal(this._overlay);
        }

        // Create (or re-create) the Spectrum overlay.
        const [overlayContent] = React.Children.toArray(this.props.children);
        this._overlay = this._makeOverlay(overlayContent);

        // Return an element here, otherwise overlay ends up outside the Spectrum Provider.
        return <div style={{ display: 'none' }} />;
    }

    private _makeOverlay(overlayContent: any) {
        // We use Spectrum's Overlay class, but we don't rely on its rootClose property, because it
        // only closes the overlay after a full mouse click (down and up events), and it only cares
        // about the left mouse button. Instead, we apply our own root-close behavior in the
        // _listenForDismiss and _handlePointerDown methods.
        return (
            <SpectrumOverlay
                show={this.props.show}
                onHide={this.props.onClose}
                target={this}
                rootClose={false}>
                {cloneElement(overlayContent)}
            </SpectrumOverlay>
        );
    }

    private _renderOverlay() {
        // Only add overlay to modalManager when it is shown.
        if (this._overlay && this._mountNode) {
            if (this._overlay.props.show) {
                modalManager.addToModal(this._overlay, true);
            }

            ReactDOM.unstable_renderSubtreeIntoContainer(this, this._overlay, this._mountNode);
        }
    }

    private _listenForDismiss(forceRemove: boolean = false) {
        // Add or remove (as appropriate) a listener for pointer down events on the document root.
        if (!forceRemove && this.props.show && !this._listeningForDismiss) {
            document.addEventListener('pointerdown', this._handlePointerDown, true);
            this._listeningForDismiss = true;
        } else if ((forceRemove || !this.props.show) && this._listeningForDismiss) {
            document.removeEventListener('pointerdown', this._handlePointerDown, true);
            this._listeningForDismiss = false;
        }
    }

    @action.bound
    private _handlePointerDown(e: PointerEvent) {
        // Notify the owner that the overlay should be dismissed if it's currently showing and
        // the user clicked outside the overlay content.
        if (this.props.show) {
            let target: HTMLElement | null = e.target as HTMLElement;
            while (target) {
                if (target.classList.contains(this.props.contentClassName)) {
                    return;
                }
                target = target.parentElement;
            }
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }
}
