import { AdobeIMS } from '@identity/imslib';
import { IAdobeIdData } from '@identity/imslib/adobe-id/IAdobeIdData';
import { IEnvironment } from '@identity/imslib/adobe-id/IEnvironment';
import { AccountService, IMSEnvironment, UserProfile } from './AccountService';

/**
 * IMSAccountService provides access to Identity Management Services (IMS) through imslib.js. 
 */
export class IMSAccountService implements AccountService {
    private imsApi!: AdobeIMS;
    private imsClientId!: string;
    private imsEnvironment!: IMSEnvironment;

    async initialize(
        imsClientId: string,
        imsEnvironment: IMSEnvironment,
    ): Promise<UserProfile | undefined> {
        this.imsClientId = imsClientId;
        this.imsEnvironment = imsEnvironment;
        
        // Configure IMS.
        const imsConfig: IAdobeIdData = {
            client_id: imsClientId,
            scope: 'openid,AdobeID',
            locale: 'en_US',
            environment: imsEnvironment === IMSEnvironment.STAGING ? IEnvironment.STAGE : IEnvironment.PROD,
            onReady: null,
            onAccessToken: null,
            onAccessTokenHasExpired: null,
            onError: null,
            onReauthAccessToken: null,
        };

        // Initialize IMS.
        this.imsApi = new AdobeIMS(imsConfig);
        await this.imsApi.initialize();

        // If the user is already signed in, get their profile. Otherwise, initiate sign in.
        const userProfile = await this.getUserProfile();
        if (!userProfile) {
            this.imsApi.signIn();
        }
        return userProfile;
    }

    /**
     * Determines whether the user is signed in or signed out once IMS is fully initialized, then
     * returns the user's profile or undefined.
     */
    private async getUserProfile(): Promise<UserProfile | undefined> {
        let userProfile: UserProfile | undefined = undefined;
        if (this.imsApi.isSignedInUser()) {
            const { userId, displayName, email } = await this.imsApi.getProfile();
            const accessToken = this.imsApi.getAccessToken()!.token;
            const [pictureUrl, featureFlags] = await Promise.all([
                this.getAvatarUrl(accessToken),
                this.getFeatureFlags(accessToken),
            ]);
            userProfile = { userId, displayName, email, pictureUrl, featureFlags };
        }
        return userProfile;
    }

    /**
     * Gets an array of feature flags associated with the current user from Floodgate.
     * @param accessToken The IMS access token.
     */
    private async getFeatureFlags(accessToken: string): Promise<string[]> {
            // Note that the IMS client ID must be registered in the Adobe IO admin console and
            // subscribed to the Sophia SDK in order to retrieve feature flags, as described here:
            // https://wiki.corp.adobe.com/display/ADBPersonalization/Environments.
            const options: RequestInit = {
            headers: {
                'x-api-key': this.imsClientId,
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const host = this.imsEnvironment === IMSEnvironment.STAGING ?
            'https://p13n-stage.adobe.io' : 'https://p13n.adobe.io';
        const url = `${host}/fg/api/v3/feature?clientId=${this.imsClientId}`;
        const response = await fetch(url, options);
        const data = await response.json();
        // TODO: What happens if there are multiple releases?
        return data.releases.length ? data.releases[0].features : [];
    }

    /**
     * Gets the URL of the user's Creative Cloud profile avatar image.
     * @param accessToken IMS access token.
     */
    private async getAvatarUrl(accessToken: string): Promise<string> {
        const fetchOptions: RequestInit = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const response = await fetch('https://cc-collab.adobe.io/profile', fetchOptions);
        const data = await response.json();
        return data.user.avatar;
    }

    /**
     * Starts the IMS sign-in flow (if the user isn't signed in already).
     * @param username The user's email address (optional).
     */
    signIn(username?: string): void {
        if (!this.imsApi.isSignedInUser()) {
            this.imsApi.signIn({ puser: username! });
        }
    }

    /**
     * Starts the IMS sign-out flow (if the user is signed in).
     */
    signOut(): void {
        if (this.imsApi.isSignedInUser()) {
            this.imsApi.signOut();
        }
    }
}
