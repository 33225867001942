import Button from '@react/react-spectrum/Button';
import ButtonGroup from '@react/react-spectrum/ButtonGroup';
import FieldLabel from '@react/react-spectrum/FieldLabel';
import Heading from '@react/react-spectrum/Heading';
import Icon from '@react/react-spectrum/Icon';
import AddCircle from '@react/react-spectrum/Icon/AddCircle';
import Delete from '@react/react-spectrum/Icon/Delete';
import Switch from '@react/react-spectrum/Icon/Switch';
import ViewRow from '@react/react-spectrum/Icon/ViewRow';
import Visibility from '@react/react-spectrum/Icon/Visibility';
import VisibilityOff from '@react/react-spectrum/Icon/VisibilityOff';
import Select from '@react/react-spectrum/Select';
import { boundMethod } from 'autobind-decorator';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import PromiseFileReader from 'promise-file-reader';
import React from 'react';
import { ToolId } from '../common/ToolId';
import { utils } from '../common/utils';
import { FileOpener } from '../components/FileOpener';
import { MessageDialog } from '../components/MessageDialog';
import { PromptDialog } from '../components/PromptDialog';
import { TooltipButton } from '../components/TooltipButton';
import '../css/PackagePropertiesPanel.css';
import '../css/RightPanel.css';
import { Analytics, EventName } from '../services/Analytics';
import { Side } from '../store/InfoStore';
import { allSubstrates, SubstrateName } from '../substrates/allSubstrates';
import { ImportArtworkModal } from './ImportArtworkModal';
import { StoreComponent } from './StoreComponent';

@inject('store')
@observer
export class PackagePropertiesPanel extends StoreComponent {
    private async importArtwork(side: Side, file: File) {
        // Get filename and extension.
        const filenameWithExtension = file.name;
        const extension = utils.getExtension(filenameWithExtension);
    
        if (extension && !['jpg', 'jpeg', 'png'].includes(extension)) {
            MessageDialog.show('Import Artwork', `Unsupported file extension "${extension}".`);
            return;
        }

        // Read the file into a data URL.
        const artworkURL = await PromiseFileReader.readAsDataURL(file);

        // Show the import artwork dialog.
        const { dieline } = this.props.store.info;
        const texture = await ImportArtworkModal.show(artworkURL, dieline, side);
        if (texture) {
            this.props.store.info.loadArtwork(artworkURL, texture, side);
        }
    }

    private showArtwork(side: Side) {
        this.props.store.info.showArtwork(side);
        const sideName = side === Side.Exterior ? 'exterior' : 'interior';
        Analytics.event(EventName.ArtworkShow, { side: sideName });
    }

    private hideArtwork(side: Side) {
        this.props.store.info.hideArtwork(side);
        const sideName = side === Side.Exterior ? 'exterior' : 'interior';
        Analytics.event(EventName.ArtworkHide, { side: sideName });
    }

    private deleteArtwork(side: Side) {
        this.props.store.info.deleteArtwork(side);
        const sideName = side === Side.Exterior ? 'exterior' : 'interior';
        Analytics.event(EventName.ArtworkDelete, { side: sideName });
    }

    @boundMethod
    private swapArtwork() {
        this.props.store.info.swapArtwork();
        Analytics.event(EventName.ArtworkSwap);
    }

    @boundMethod
    private async resetDieline() {
        const content = (
            <span>
                Resetting the dieline will undo all folding and return it to a flat state.<br/>
                Are you sure you want to proceed?
            </span>
        );
        if (await PromptDialog.show('Reset Dieline', content, 'Yes', 'No')) {
            Analytics.event(EventName.EdgeAngleResetAll);
            this.props.store.resetDieline();
        }
    }

    private get substrateOptions() {
        return Object.values(allSubstrates).map(substrate => ({
            icon: substrate.name.startsWith('Paperboard') ?
                this.makePaperboardIcon(substrate.thickness) :
                this.makeCorrugatedIcon(substrate.thickness),
            label: substrate.label,
            value: substrate.name,
        }));
    }

    @boundMethod
    private onSubstrateChange(substrateName: string | string[]) {
        this.setSubstrate(substrateName as SubstrateName);
    }

    private async setSubstrate(substrateName: SubstrateName) {
        await allSubstrates[substrateName].loadAllTextures();
        this.props.store.info.substrate = allSubstrates[substrateName];
        Analytics.event(EventName.MaterialSelect, { materialName: substrateName });
    }

    private makePaperboardIcon(thickness: number) {
        const w = 30 * (thickness - 0.32) + 5;
        return (
            <Icon>
                <svg className="PackageProperties__substrateIcon" viewBox="0 0 100 100">
                    <line x1="0" y1="50" x2="100" y2="50" strokeWidth={w} />
                </svg>
            </Icon>
        );
    }

    private makeCorrugatedIcon(thickness: number) {
        const w = 1 * (thickness - 0.8) + 3;
        const h = 4 * (thickness - 0.8) + 10;
        const n = 20;
        let path = '';
        for (let i = 0; i < n; i++) {
            const x = 100 * i / (n - 1);
            const y = 50 - (h - 2 * w) * Math.sin(i * 4 * Math.PI / (n - 1));
            path += (i === 0 ? 'M' : 'L') + Math.round(x) + ' ' + Math.round(y);
        }
        return (
            <Icon>
                <svg className="PackageProperties__substrateIcon" viewBox="0 0 100 100">
                    <line x1="0" y1={50 - h} x2="100" y2={50 - h} strokeWidth={w} fill="none"/>
                    <line x1="0" y1={50 + h} x2="100" y2={50 + h} strokeWidth={w} fill="none"/>
                    <path d={path} strokeWidth={w} fill="none"/>
                </svg>
            </Icon>
        );
    }

    @boundMethod
    private startGroundFaceSelection() {
        this.props.store.selection.deselectAll();
        this.props.store.ui.activeToolId = ToolId.GroundFaceSelectionTool;
    }

    @boundMethod
    private setIsSubstrateHorizontal(value: string | number | React.ReactText[]) {
        this.props.store.info.isSubstrateHorizontal = value === 'true';
    }

    render() {
        const { activeToolId } = this.props.store.ui;
        const { dieline, substrate, isSubstrateHorizontal } = this.props.store.info;
        const substrateName = substrate ? substrate.name : '';
        const isCorrugated = substrateName.startsWith('Corrugated');
        const exteriorArtwork = this.props.store.info.getArtwork(Side.Exterior);
        const interiorArtwork = this.props.store.info.getArtwork(Side.Interior);
        return (
            <>
                <div className="PackagePropertiesPanel RightPanel">
                    <Heading variant="subtitle2">
                        PACKAGE PROPERTIES
                    </Heading>
                    <div className="PackagePropertiesPanel__groupContainer">
                        <FieldLabel label="Material">
                            <Select className="PackagePropertiesPanel__substrateSelect"
                                onChange={this.onSubstrateChange}
                                options={this.substrateOptions}
                                value={substrateName}
                            />
                        </FieldLabel>
                        <FieldLabel label="Fluting Orientation" disabled={!isCorrugated}>
                            <ButtonGroup value={isSubstrateHorizontal.toString()}
                                onChange={this.setIsSubstrateHorizontal}>
                                <TooltipButton icon={<ViewRow />} value="false"
                                    disabled={!isCorrugated} style={{ transform: 'rotate(90deg)' }}
                                    tooltip="Vertical" />
                                <TooltipButton icon={<ViewRow />} value="true"
                                    disabled={!isCorrugated} tooltip="Horizontal" placement="right" />
                            </ButtonGroup>
                        </FieldLabel>
                    </div>
                    <div className="PackagePropertiesPanel__groupContainer">
                        {!exteriorArtwork.url &&
                            <FileOpener accept=".jpg,.jpeg,.png"
                                onFileSelected={(file: File) => this.importArtwork(Side.Exterior, file)}>
                                <Button className="PackagePropertiesPanel__addArtworkButton"
                                    icon={<AddCircle className="PackagePropertiesPanel__addIcon" />}
                                    disabled={dieline === null}
                                    label="Exterior Art" quiet />
                            </FileOpener>
                        }
                        {exteriorArtwork.url &&
                            <div className="PackagePropertiesPanel__row">
                                <img className="PackagePropertiesPanel__artwork" src={exteriorArtwork.url} alt=""></img>
                                <div className="PackagePropertiesPanel__sideLabel">Exterior Art</div>
                                {!exteriorArtwork.hidden &&
                                    <Button icon={<Visibility size="S"/>}
                                        variant="action" quiet
                                        onClick={() => this.hideArtwork(Side.Exterior)} />
                                }
                                {exteriorArtwork.hidden &&
                                    <Button icon={<VisibilityOff size="S"/>}
                                        variant="action" quiet
                                        onClick={() => this.showArtwork(Side.Exterior)} />
                                }
                                <Button icon={<Delete size="S"/>}
                                    variant="action" quiet
                                    onClick={() => this.deleteArtwork(Side.Exterior)} />
                            </div>
                        }
                        <TooltipButton className="PackagePropertiesPanel__swapButton"
                            icon={<Switch className="PackagePropertiesPanel__swapIcon" size="S"/>}
                            disabled={!exteriorArtwork.url && !interiorArtwork.url}
                            variant="action" quiet tooltip="Swap exterior and interior art"
                            onClick={this.swapArtwork} />
                        {!interiorArtwork.url &&
                            <FileOpener accept=".jpg,.jpeg,.png"
                                onFileSelected={(file: File) => this.importArtwork(Side.Interior, file)}>
                                <Button className="PackagePropertiesPanel__addArtworkButton"
                                    icon={<AddCircle className="PackagePropertiesPanel__addIcon" />}
                                    disabled={dieline === null}
                                    label="Interior Art" quiet />
                            </FileOpener>
                        }
                        {interiorArtwork.url &&
                            <div className="PackagePropertiesPanel__row">
                                <img className="PackagePropertiesPanel__artwork" src={interiorArtwork.url} alt=""></img>
                                <div className="PackagePropertiesPanel__sideLabel">Interior Art</div>
                                {!interiorArtwork.hidden &&
                                    <Button icon={<Visibility size="S"/>}
                                        variant="action" quiet
                                        onClick={() => this.hideArtwork(Side.Interior)} />
                                }
                                {interiorArtwork.hidden &&
                                    <Button icon={<VisibilityOff size="S"/>}
                                        variant="action" quiet
                                        onClick={() => this.showArtwork(Side.Interior)} />
                                }
                                <Button icon={<Delete size="S"/>}
                                    variant="action" quiet
                                    onClick={() => this.deleteArtwork(Side.Interior)} />
                            </div>
                        }
                    </div>
                    <div className="PackagePropertiesPanel__groupContainer">
                        <TooltipButton
                            variant="action"
                            className={classNames('PackagePropertiesPanel__groundFaceSelectionButton', {
                                'PackagePropertiesPanel__groundFaceSelectionButton--selected': activeToolId === ToolId.GroundFaceSelectionTool,
                            })}
                            selected={activeToolId === ToolId.GroundFaceSelectionTool}
                            onClick={this.startGroundFaceSelection}
                            tooltip={<span>Click on a panel to indicate which<br/>
                                part of the package is the bottom</span>}>
                                Set Package Bottom
                        </TooltipButton>
                    </div>
                    <div className="PackagePropertiesPanel__groupContainer">
                        <TooltipButton variant="action"
                            onClick={this.resetDieline}
                            tooltip={<span>Reset dieline back to flat state.<br/>
                                This action will remove all fold<br/>
                                angles previously set.</span>}>
                                Reset Dieline
                        </TooltipButton>
                    </div>
                </div>
            </>
        );
    }
}
